/**
 * A general module for page selections
 * that are mostly text, images and links
 */

$module: 'page-section';

.#{$module} {
	padding: 40px 0  (40px - 11.5px) 0;// - paragraph margin bottom
	background-color: #fff;
	text-align: center;

	&__wrap {
		@extend .container-fluid;
		padding-right: 20px;
		padding-left: 20px;
		max-width: 898px + 20px + 20px; //+left/right padding
	}
	&__testimonial h3 {
		font-style: italic;
		line-height: 1.3;
		padding-bottom: 10px;
	}
	&__testimonial h4 {
    	margin-bottom: 0px;
	}
	&__btn {
		@extend %button;
	}
	p {

	}
}

// ==========================================================================
// Small devices (tablets, 768px and up)
// ==========================================================================

// @media (min-width: $screen-sm-min) {
//   .#{$module} {
//   }
// } // end: Small devices (tablets, 768px and up) ==========================


// ==========================================================================
// Medium devices (desktops, 992px and up)
// ==========================================================================

@media (min-width: $screen-md-min) {
  .#{$module} {
		padding: 50px 0  (90px - 11.5px) 0;// - paragraph margin bottom
  }
} // end: Medium devices (desktops, 992px and up) ========================



// ==========================================================================
// Large devices (large desktops, 1200px and up)
// ==========================================================================

// @media (min-width: $screen-lg-min) {
//   .#{$module} {
//
//   }

// } // end: Large devices (large desktops, 1200px and up) ==================
