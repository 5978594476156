// TODO: REORGANIZE AND COMPLETE THIS FILE

// Percentages

.size-w-25 {
  width: 25% !important;
}

.size-w-50 {
  width: 50% !important;
}

.size-w-75 {
  width: 75% !important;
}

.size-w-100 {
  width: 100% !important;
}

.size-h-25 {
  height: 25% !important;
}

.size-h-50 {
  height: 50% !important;
}

.size-h-75 {
  height: 75% !important;
}

.size-h-100 {
  height: 100% !important;
}

.size-maxw-50 {
  max-width: 50% !important;
}

.size-maxw-100 {
  max-width: 100% !important;
}

.size-maxh-50 {
  max-height: 50% !important;
}

.size-maxh-100 {
  max-height: 100% !important;
}


// MAX-WIDTH
.size-maxw-100px {
    max-width: 100px !important;
}

.size-maxw-500px {
  max-width: 500px !important;
}

.size-maxw-750px {
  max-width: 750px !important;
}

.size-maxw-1000px {
  max-width: 1000px !important;
}

.size-maxw-1200px {
  max-width: 1200px !important;
}

.size-maxw-1400px {
  max-width: 1400px !important;
}

// MIN-HEIGHT
.size-minh-25vh {
  min-height: 25vh !important;
}

.size-minh-50vh {
  min-height: 50vh !important;
}

.size-minh-75vh {
  min-height: 75vh !important;
}

.size-minh-100vh {
  min-height: 100vh !important;
}
