$module: fap-search;

.#{$module} {
  padding: 2rem 5% 1rem;
  background-color: #dadfe1;
  text-align: center;

  &__label {
    @extend %diamond-title;
		@extend %diamond-title-variant--green;
		margin: 0 auto 40px;
		max-width: 700px;
  }

  // Outer Blue Container

  &__container {
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: $blue;

    // Inner Container
    &-inner {
      display: table;
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
    }

    // Left and Right Component Cells
    &-cell {
      display: table-cell;
      width: 50%;
      vertical-align: top;
      background-color: #dadfe1;

      &:first-child {
        border-right: 10px solid $blue;
      }

      &:last-child {
        border-left: 10px solid $blue;
      }
    }
  }

  &__title {
    @extend .tile__title;

    .#{$module}--name & {
      br {
        display: none;
        @media (min-width: 1250px) {
          display: block;
        }
      }
    }
  }

  // Input

  &__input {
    display: block;
    width: 100%;
    height: 52px;
    margin: 0 .5rem 1rem 0;
    padding: .75rem .5rem;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #3fbfad;
    font-size: 1rem;

    &.#{$module}__zip {
      display: inline-block;
      vertical-align: middle;
      width: 49%;
      margin: 0 0 1rem;
      border: 0;
      background-color: #fff;
    }
  }

  // Select

  select {
    display: inline-block;
    vertical-align: middle;
    height: 52px;
    margin: 0 2% 1rem 0;
    padding: .75rem .5rem;
    border: 1px solid #3fbfad;
    background: #dadfe1;
    font-size: 1rem;

    &.#{$module}__radius {
      width: 49%;
    }

    &.#{$module}__country {
      width: 100%;
    }

    &.#{$module}__province {
      width: 100%;
    }
  }

  &__radius-label {
    display: block;
    height: 18px;
    margin-top: -0.75rem;
    margin-bottom: 0;
    font-size: .8rem;
    text-align: left;
  }

  &__indicator-text {
    display: block;
    margin-top: .5rem;
    text-align: center;
    font-size: .9rem;
    font-weight: 600;
  }

  &__submit {
    display: block;
    width: 100%;
  }

  &__ace-certified-logo {
    display: block;
    width: 90%;
    height: auto;
    max-width: 100px;
    margin: 2rem auto 0;
  }

  // LOCATION SEARCH
  &--location {

  }

  // NAME SEARCH
  &--name {
    @media (min-width: 1131px), (min-width: 768px) and (max-width: 991px) {
      .#{$module}__input:first-child {
        margin-bottom: 22px; // Accounts for radius-label height in fap location search component to keep inputs aligned
      }
    }
  }

  // STACKED AT THESE VIEWPORTS

  @media (max-width: 767px), (min-width: 992px) and (max-width: 1130px) {
    &__container {
      &-inner {
        display: block;
      }

      &-cell {
        display: block;
        width: 100%;

        &:first-child {
          margin-bottom: .75rem;
          border-right: 0;
        }

        &:last-child {
          border-left: 0;
        }
      }
    }
  }

  @media (max-width: 440px) {
    &__label {
      margin-bottom: 20px;
    }

    &__container {
      padding: .75rem;
    }

    &__ace-certified-logo {
      margin: 1rem auto .5rem;
    }
  }
}
