$module: "main-foot";

.main-foot {
	padding-bottom: 10px;
	background-color: $blue;
	color: $blue-light;

	&__contact {
		@include clearfix;
		padding: 110px 0 30px;
		background: url("/images/layout/2017/dist/logotype-ace.svg") no-repeat 50% 30px;
		background-size: 120px auto;

		.widget__title {
			display: none;
		}
	}

	&__fineprint {
		font-size: remUnit(16px);
		line-height: 1.25;
		text-align: center;

		small {
			font-size: remUnit(11px);
		}
	}

	&__logo {
		display: none;
		float: left;
		width: 140px;
		height: auto;
		margin-right: 30px;
	}

	&__row {
		@include clearfix;
	}

	&__wrap {
		@extend .container-fluid;
		max-width: 1400px;
		padding-right: 20px;
		padding-left: 20px;
	}

	&__badges {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin-bottom: 1rem;
		text-align: center;
	}

	&__badge {
		margin: 0.5rem;
	}

	.widget--accordion {
		.widget__title {
			display: block;
			line-height: 1.1;

			@media (min-width: $screen-lg-min) {
				padding: 0;
			}
		}
	}

	.widget__link-list {
		margin-top: 14px;
	}

	@media (min-width: $screen-xs-min) {
		&__contact {
			padding: 50px 0 30px 140px;
			background-position: 0 50px;
		}
	}

	@media (min-width: $screen-lg-min) {
		padding-top: 40px;

		&__contact {
			padding: 0;
			background: none;
		}

		&__logo {
			display: block;
		}

		&__wrap {
			padding-left: floor(($grid-gutter-width * 0.5));
			padding-right: ceil(($grid-gutter-width * 0.5));
		}

		.widget__head {
			background-color: transparent !important;
			cursor: default !important;
			pointer-events: none;
		}

		.widget__link-list {
			display: block !important;
			height: auto !important;
			margin-top: 14px !important;
		}
	}
}
