$module: 'panel-nav';


#panelNav {
  display: none;
  width: 100%;
  position:static;
  top:-32px;
}

#panelNav.affix {
   position: fixed;
   top: 70px;
   z-index:10;
}
@media (min-width: $screen-md-min) {
	#panelNav {display: block;}
}

.#{$module} {
	@extend %font-roboto-medium;
  font-size: remUnit(15px);

  .navbar {
    margin-bottom: 0;
  }
  .nav li.active:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -14px;
    width: 0;
    height: 0;
    border-top-style: solid;
    border-top-width: 14px;
    border-top-width: solid 14px #000;
    border-left: solid 14px transparent;
    border-right: solid 14px transparent;
    z-index: 10;
  }

  @each $label, $map in $map-stepped-color-red {

    $index: $label;
    $bg: map-get($map, bg);
    $bgHover: map-get($map, bgHover);

    #panelNav .nav {
      li:nth-child(#{$index}) {
        background-color: $bg;
        &:after {
          border-top-color: $bg;
        }
        &.active:after {
          border-top-color: $bgHover;
        }
        &:hover a,
        & > a:hover {
          background-color: $bgHover;
        }
        &.active a {
          background-color: $bgHover;
        }

      }
    }
  }


  .navbar-nav {
    margin: 0 0 0 0;
  }

  /* customize nav style */
  .navbar-custom {
		background-color: #2e2e2e;
		font-weight:700;
		text-transform: uppercase;
		border-width:0;
  }
  .navbar-custom  .navbar-nav>li>a {
  	color: #fff;
  	font-weight: $font-weight-regular;
		letter-spacing: 1px;
  }
  .navbar-custom  .navbar-nav li>a:hover, .navbar-nav li .open, .navbar-custom .navbar-nav .active a  {
  	background-color: #000;
  }
  .navbar-custom .dropdown-menu{
  	right:0;
  }
  .navbar-custom .navbar-nav>.dropdown>a .caret {
  	border-top-color: #999;
  	border-bottom-color: #999;
  }

  .navbar-collapse.in { /*3.0.2 bug workaround*/
      overflow-y: visible;
  }

  .navbar-toggle {
  	outline:0;
  }

  .divider {
  	height:100px;
  }

  .panel {
  	border-width:0;
  }

	@media (min-width: 767px) {
    .navbar-nav.nav-justified > li{
        float:none;
    }
  }
}
