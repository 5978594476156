$module: 'team';

.#{$module} {

	&__brief {
		@include make-xs-column(12, 20px);
		margin-bottom: 20px;

		a {
			display: block;
			background-color: $gray-light;
      transform: translate3d(0, 0, 0);
		}

		& a:active,
		& a:focus,
		& a:hover {
			text-decoration: none;
			outline: none;

			.#{$module}__brief-img {
				transform: scale(1.15);
			}

			.#{$module}__brief-title {
				color: $red;
				@include ace-btn-hover-transition();
			}
		}
	}

	&__brief-head {
		padding: 15px;
		text-align: center;
	}

	&__brief-img {
		@include img-responsive;
		transition: all 0.4s;
	}

	&__brief-img-wrap {
		position: relative;
		overflow: hidden;
	}

	&__brief-subtitle {
		color: $red;
		font-size: remUnit(14px);
		font-weight: $font-weight-semibold;
		text-transform: uppercase;
	}

	&__brief-title {
		color: $blue;
		font-size: remUnit(18px);
		font-weight: $font-weight-semibold;
		@include ace-btn-hover-transition();
	}

	&__full {
		@include make-row(0);
		margin-bottom: 30px;
		padding-bottom: 20px;
		border-bottom: 1px solid $gray-med;
	}

	&__full-content-wrap {
		@include make-xs-column(12, 0);
	}

	&__full-head {
		margin-bottom: 25px;
	}

	&__full-img {
		margin-bottom: 15px;
		padding-top: 65%;
		background-size: cover;
		background-position: 50% 20%;
		background-repeat: no-repeat;
	}

	&__full-img-wrap {
		@include make-xs-column(12, 0);
	}

	&__full-subtitle {
		color: $red;
		font-size: remUnit(14px);
		font-weight: $font-weight-semibold;
		text-transform: uppercase;
	}

	&__full-title {
		margin-bottom: 5px;
		font-size: remUnit(28px);
	}

	&__row {
		@include make-row(20px);
	}

	&__subtitle {
		margin-bottom: 15px;
		font-size: remUnit(24px);
	}

	&__title {
		font-size: remUnit(28px);
	}

	@media (min-width: 35.5em) {
		&__brief {
			@include make-xs-column(6, 20px);
		}
	}

	@media (min-width: $screen-md-min) {
		&__brief {
			@include make-md-column(4, 20px);
		}

		&__brief-title {
			font-size: remUnit(20px);
		}

		&__full {
			@include make-row(40px);
			padding-bottom: 90px;
		}

		&__full-content-wrap {
			@include make-md-column(8, 40px);
		}

		&__full-img {
			padding-top: 137%;
		}

		&__full-img-wrap {
			@include make-md-column(4, 40px);
		}

		&__full-title {
			font-size: remUnit(54px);
		}

		&__title {
			font-size: remUnit(54px);
		}
	}
}
