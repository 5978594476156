%button {
	@include ace-btn($hover-transition: true);
	border-radius: 0;
}


.btn {
	@extend %button;

	&.btn--teaser {
		@extend %button;

		display: block;
		width: 100%;
		padding: 20px;
		font-family: $font-family-roboto;
		font-size: remUnit(12.5px);
		font-weight: $font-weight-light;
		letter-spacing: 2px;
		white-space: normal;

		@include ace-btn-theme(green);

		strong {
			font-weight: $font-weight-medium;
		}
	}
}

.btn-supplemental {
	@extend %ace-btn-primary;
	@include ace-btn-size(micro);
	margin-bottom: 10px;
	font-size: remUnit(12px);
}

//////////////////////////////////////////////////////////////////////
// BUTTON CLASSES
// Add these to markup where needed but avoid extending them directly
// eaxmple: @extend .some-button
// Instead, use the placeholer extends from the ace btn mixin
// library directly and create new extends here/there where needed.
// example @extend %some-button
// If there's a special case, use the mixins directly in modules.
/////////////////////////////////////////////////////////////////////

// Primary Buttons =============================================

.ace-btn-base {
	@extend %ace-btn-base;
}

.ace-btn-primary {
	@extend %ace-btn-primary;
}

.ace-btn-primary--red {
	@extend %ace-btn-primary;
}
.ace-btn-primary--blue {
	@extend %ace-btn-primary--blue;
}
.ace-btn-primary--green {
	@extend %ace-btn-primary--green;
}
.ace-btn-primary--yellow {
	@extend %ace-btn-primary--yellow;
}
.ace-btn-primary--dark-cyan {
	@extend %ace-btn-primary--dark-cyan;
}
.ace-btn-primary--reversed {
	@extend %ace-btn-primary--reversed;
}

// Jumbo Buttons =============================================

.ace-btn-primary-jumbo {
	@extend %ace-btn-primary-jumbo;
}
.ace-btn-primary-jumbo--red {
	@extend %ace-btn-primary-jumbo;
}
.ace-btn-primary-jumbo--blue {
	@extend %ace-btn-primary-jumbo--blue;
}
.ace-btn-primary-jumbo--green {
	@extend %ace-btn-primary-jumbo--green;
}

.ace-btn-primary-jumbo--reversed {
	@extend %ace-btn-primary-jumbo--reversed;
}

// Stroke Buttons =======================================
.ace-btn-stroke {
	@extend %ace-btn-stroke;
}
.ace-btn-stroke--red {
	@extend %ace-btn-stroke;
}
.ace-btn-stroke--blue {
	@extend %ace-btn-stroke--blue;
}
.ace-btn-stroke--blue-red {
	@extend %ace-btn-stroke--blue-red;
}
.ace-btn-stroke--green {
	@extend %ace-btn-stroke--green;
}
.ace-btn-stroke--gray {
	@extend %ace-btn-stroke--gray;
}

.ace-btn-stroke--reversed {
	@extend %ace-btn-stroke--reversed;
}

// Stroke Micro Buttons ==================================
.ace-btn-stroke-micro {
	@extend %ace-btn-stroke-micro;
}
.ace-btn-stroke-micro--reversed {
	@extend %ace-btn-stroke-micro--reversed;
}

// Floating Buttons =======================================

.ace-btn-floating {
	@extend %ace-btn-floating;
}
.ace-btn-floating--reversed {
	@extend %ace-btn-floating--reversed;
}
.ace-btn-floating--black {
	@extend %ace-btn-floating--black;
}
.ace-btn-floating--red {
	@extend %ace-btn-floating--red;
}
.ace-btn-floating--blue {
	@extend %ace-btn-floating--blue;
}
.ace-btn-floating--green {
	@extend %ace-btn-floating--green;
}
.ace-btn-floating--yellow {
	@extend %ace-btn-floating--yellow;
}
.ace-btn-floating--white {
	@extend %ace-btn-floating--white;
}


// Double & Jumbo Double Buttons ======================

//////////////////////////////////////////////////////////
//  These require a wrapper parent element of the same
//  name but with "-wrap" added to it.
//  Example:
//  ```
//      <div class="ace-btn-jumbo-double-wrap">
//         <a clas="ace-btn-jumbo-double"></a>
//         <a clas="ace-btn-jumbo-double"></a>
//     </div>
//  ```
//  ```
//      <div class="ace-btn-jumbo-double--reversed-wrap">
//         <a clas="ace-btn-jumbo-double--reversed"></a>
//         <a clas="ace-btn-jumbo-double--reversed"></a>
//     </div>
//  ```
////////////////////////////////////////////////////////

// Double =============================================
.ace-btn-double {
	@include ace-btn-double();
}
.ace-btn-double--red {
	@include ace-btn-double();
}
.ace-btn-double--reversed {
	@include ace-btn-double(reversed);
}
//  Jumbo Double
.ace-btn-double-jumbo {
	@include ace-btn-double-jumbo();
}
.ace-btn-double-jumbo--reversed {
	@include ace-btn-double-jumbo(reversed);
}


// Double Single & Jumbo Double Single Buttons ========

//////////////////////////////////////////////////////////
//  These require two immediate child elements of
//  <em> or <span> or a combination of the two.
//
//  Examples:
//  ```
//      <a class="ace-btn-double-single">
//          <span></span><span></span>
//      </a>
//  ```
//  ```
//      <a class="ace-btn-double-single">
//          <em></em><span></span>
//      </a>
//  ```
////////////////////////////////////////////////////////

// Double Single =======================================
.ace-btn-double-single {
	@extend %ace-btn-double-single;
}
.ace-btn-double-single--red {
	@extend %ace-btn-double-single;
}
.ace-btn-double-single--reversed {
	@extend %ace-btn-double-single--reversed;
}
// Jumbo Double Single
.ace-btn-double-single-jumbo {
	@extend %ace-btn-double-single-jumbo;
}
.ace-btn-double-single-jumbo--red {
	@extend %ace-btn-double-single-jumbo;
}
.ace-btn-double-single-jumbo--reversed {
	@extend %ace-btn-double-single-jumbo--reversed;
}
