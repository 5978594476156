$module: 'accreditation';

.#{$module} {
  &__title {
    text-transform: uppercase;
    color: $red;
  }

  &__certs-section {
    text-align: center;
    background-color: $red;
    color: #fff;
    padding: 30px 40px 10px 40px;
    margin-bottom: 30px;

    h3 {
      color: inherit;
    }
  }

  &__blue-card {
    &-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @media (min-width: 700px) {
        flex-direction: row;
        margin-bottom: 2rem;
      }
    }

    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    margin-bottom: 1rem;
    padding: 2rem 1rem;
    background-color: $blue;
    color: #fff;
    text-align: center;

    @media (min-width: 700px) {
      width: 48%;
      margin-bottom: 0;

      &:first-child {
        margin-right: 4%;
      }
    }

    h3 {
      color: #fff;
      line-height: 1.3;
    }
  }

  &__side-by-side {

    &-image {
      width: 100%;
      margin-bottom: 1rem;

      @media (min-width: 640px) {
        width: 230px;
        padding-right: 1rem;
      }

      img {
        max-width: 100%;

        @media (min-width: 640px) {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      &--small {
        width: 100%;
        margin-bottom: 1rem;

        @media (min-width: 640px) {
          width: 120px;
          padding-right: 1rem;
        }

        img {
          max-width: 100%;

          @media (min-width: 640px) {
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }
    }

    &-text {
      width: 100%;
    }

    &-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 1rem;

      @media (min-width: 640px) {
        flex-direction: row;
      }
    }
  }
}
