$module: cecs-earned;

.#{$module} {
    display: table;
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }

    @media (min-width: $screen-sm-min) {
        width: auto;
    }

    &__circle {
        display: table-cell;
        width: 70px;
        vertical-align: middle;
        font-family: $font-family-roboto;
        font-size: .7rem;

        &-shape {
            display: block;
            position: relative;
            width: 60px;
            height: 60px;
            border-radius: 30px;
            background-color: #e53d46;
            text-align: center;
            color: #fff;

            &-inner {
                display: block;
                position: absolute;
                margin: 0 auto;
                top: 50%;
                right: 0;
                left: 0;
                -o-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }

        &-number {
            font-family: $font-family-muli;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1;
        }

        &-text {
        }
    }

    &__title {
        display: table-cell;
        vertical-align: middle;
        font-family: $font-family-roboto;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.2;
        color: #e53d46;

        &-sub {
            color: #000;
            font-size: .8rem;
            padding-left: 2px;
        }
    }
}