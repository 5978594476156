$module: 'content';

.content {
	background-color: #fff;

	&__row {
		@include make-row();
	}

	&__row--secondary-fixed {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;

		.#{$module}__secondary {
			float: none;
			width: 100%;
			left: 0;
			right: 0;
			padding: 0;
		}

		.#{$module}__primary {
			float: none;
			width: 100%;
			padding-left: 0;
			padding-right: 0;
			left: 0;
			right: 0;
		}

		@media (min-width: 1100px) {
			flex-direction: row-reverse;

			.#{$module}__secondary {
				width: 280px;
			}

			.#{$module}__primary {
				width: calc(100% - 280px);
				padding-left: 2rem;
			}
		}
	}

	&__wrap {
		@extend .container-fluid;
		max-width: 1400px;
	}

	@media (min-width: $screen-md-min) {

	}

	// Custom widths for these columns
	&__features {
		@include make-sm-column(12);

		@media (min-width: $screen-md-min) {
			width: $grid-features-width;
		}
	}

	&__primary {
		@include make-sm-column(12);

		@media (min-width: $screen-md-min) {
			width: $grid-primary-width;
			left: $grid-secondary-width;
		}

	    &--full {
	      width: 100%;
	      left: 0;
	      float: none;
	    }
	}

	&__primary-two-column {
		@include make-sm-column(12);

		@media (min-width: $screen-md-min) {
			width: $grid-primary-width + $grid-features-width;
			left: $grid-secondary-width;
		}
	}

    &__primary-two-column-left { // to be used in tandem with content__secondary-two-column-right
		@include make-sm-column(12);

		@media (min-width: $screen-md-min) {
			width: calc(100% - 335px);
			left: 0;
		}
	}

	&__secondary {
		@include make-sm-column(12);

		@media (min-width: $screen-md-min) {
			width: $grid-secondary-width;
			right: $grid-primary-width;
		}
	}
	&__secondary-two-column {
		@include make-sm-column(12);

		@media (min-width: $screen-md-min) {
			width: $grid-secondary-width;
			right: $grid-primary-width + $grid-features-width;
		}
	}

    &__secondary-two-column-right { // to be used in tandem with content__primary-two-column-left
		@include make-sm-column(12);

		@media (min-width: $screen-md-min) {
			width: 100%;
      		max-width: 334px;
			left: 0;
		}
	}

	&--low-profile {
		padding-top: 30px;
	}

	@media (min-width: $screen-md-min) {
		&--low-profile {
			padding-bottom: 80px;
		}
	}
}
