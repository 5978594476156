$module: numerical-section-heading;

.#{$module} {
  display: inline-block;
  width: 2.5em;
  border-radius: 1.25em;
  line-height: 2.5;
  box-shadow: 0 0 0 1px $green;
  color: $green;

  // Size Modifiers

  &--small {
    width: 2em;
    border-radius: 1em;
    line-height: 2;
  }

  &--medium {
    width: 2.5em;
    border-radius: 1.25em;
    line-height: 2.5;
  }

  &--large {
    width: 3em;
    border-radius: 1.5em;
    line-height: 3;
  }

  &--xlarge {
    width: 4em;
    border-radius: 2em;
    line-height: 4;
  }

  // Color Modifiers

  &--blue {
    box-shadow: 0 0 0 1px $blue;
    color: $blue;

    // Border (box-shadow) thickness modifier
    &-thick {
      box-shadow: 0 0 0 2px $blue;
      color: $blue;
    }
  }

  &--gray {
    box-shadow: 0 0 0 1px $gray;
    color: $gray;

    &-thick {
      box-shadow: 0 0 0 2px $gray;
      color: $gray;
    }
  }

  &--green {
    box-shadow: 0 0 0 1px $green;
    color: $green;

    &-thick {
      box-shadow: 0 0 0 2px $green;
      color: $green;
    }
  }

  &--red {
    box-shadow: 0 0 0 1px $red;
    color: $red;

    &-thick {
      box-shadow: 0 0 0 2px $red;
      color: $red;
    }
  }

  &--white {
    box-shadow: 0 0 0 1px #fff;
    color: #fff;

    &-thick {
      box-shadow: 0 0 0 2px #fff;
      color: #fff;
    }
  }
}
