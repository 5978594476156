$module: 'post-full';

.post-full {
  .CorrectAnswer {
	display: inline-block;
    box-shadow: 0 0 0 3px $red;

    a {
      color: inherit;
    }
  }

	h2:not(.sub-section__label) {
		margin-top: 15px;
		font-size: remUnit(24px);

    &.bordered-bottom {
      margin-top: 1.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #ebeff2;
      font-size: 2rem;
    }
	}

  &--article {

    h2, h3, h4,
    h2 strong, h3 strong, h4 strong {
      font-weight: 500;
    }
  }


  p, li:not(.paginate-item), {
    a:not([class^="ace-btn"]) {
      color: $red;
      font-weight: 400;
    }
  }

	p {
		margin-bottom: 15px;
	}

	&__author-img {
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		@include img-responsive;
	}

	&__description {
		margin-bottom: 20px;
		border-bottom: 1px solid $gray-med;
	}

	&__description-table {
		@extend .table;
		@extend .table-condensed;

		td {
			border: none !important;
			font-size: remUnit(14px);
		}
	}

	&__description-term {
		color: $blue;
		font-weight: $font-weight-semibold;
		text-transform: uppercase;
	}

	&__foot {
		margin-top: 30px;
	}

	&__meta {
		position: relative;
		padding-left: 70px;
		margin-bottom: 25px;
		font-size: remUnit(12.5px);
	}

	&__key-points {
		margin: 30px -30px;
		padding: 30px;
		background-color: $gray-light;
		ul {
			color: $blue;
			padding-left: 1em;
			font-size: remUnit(14px);
			line-height: lh(14px, 22px);
			li {
				margin-bottom: 20px;
			}
		}
	}

	&__key-points-tight {
		margin: 20px -20px;
		padding: 25px;
		ul {
			li {
				margin-bottom: 10px;
			}
		}
	}

	&__meta a { color: $gray; }

	&__print {
		margin: 30px 0;
		text-align: center;
		.btn {
			width: 100%;
		}
	}

	&__review-btn-load-more {
		@extend %button;
		@extend %ace-btn-floating--blue;
	}

	&__review-tools, &__load-more{
		padding-top: 20px;
		padding-bottom: 40px;
		text-align: center;
	}

	&__review-wrap {
		padding-top: 10px;
	}

	&__schedule {
		padding-top: 20px;
	}

	&__share {
		@include clearfix;
    margin-top: 30px;
    margin-bottom: 30px;

    h4 {
      margin-bottom: 0;
      text-transform: uppercase;
      font-family: $font-family-roboto;
      font-weight: $font-weight-medium;
      font-size: .8125rem;
      color: $gray;
    }
	}

	&__share-wrap {
		display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
		vertical-align: middle;
	}

	&__share-btn {
		display: flex;
        justify-content: center;
        align-items: center;
		margin: 5px 5px 5px 0;
		font-size: remUnit(35.2px);

		&:last-child {
			margin-right: 0;
		}

        img {
            display: block;
            width: 35px;
            height: auto;
        }
	}

	&__taxonomy {
		margin: 0;
		padding: 30px 0;
		border-top: 1px solid $gray-med;
		border-bottom: 1px solid $gray-med;
	}

	&__taxonomy li {
		display: inline-block;
	}

	&__taxonomy li a {
		@extend %button;
		@include ace-btn-theme($theme: gray-light);
		padding-top: 3px;
		padding-bottom: 3px;
		border-radius: 500px;
		font-family: $font-family-muli;
		font-size: remUnit(14px);
		font-weight: $font-weight-light;
		text-transform: none;
		text-decoration: none;
	}

	&__taxonomy-term {
		margin-right: 10px;
		@extend %font-roboto-medium;
		font-size: remUnit(13px);
		text-transform: uppercase;
	}

	&__embed-video {
		margin-bottom: 50px;
	}

	& .tile {
		margin: 38px auto;
	}

  &__course-component {
    display: inline-block;
    vertical-align: middle;
    margin: 0 .5rem .5rem 0;

    &-icon {
      display: inline-block;
      vertical-align: middle;
      font-size: 1.5rem;
      line-height: 1;
    }

    &-name {
      display: inline-block;
      vertical-align: middle;
    }
  }

	@media (min-width: $screen-md-min) {
		h2 {
			margin-top: 0;
			}

		p {
			margin-bottom: 25px;
		}

		&__meta {
			display: none;
			font-size: remUnit(14px);
		}

		&__key-points {
			margin-left: 0;
			margin-right: 0;
		}

		&__schedule {
			padding-top: 0;
		}
	}
}
