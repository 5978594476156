// Used in: HealthCoachDetail.cshtml and HealthCoachGrid.cshtml

.pre-req-overlay-reveal {
  color: #f5f0e0;
  text-decoration: underline;

  &:hover {
    color: darken(#f5f0e0, 5%);
  }

  .hero-panel-variant--crt-detail-pt & {
    @media (max-width: #{$screen-md-min - 1}) {
      color: $red;
    }
  }
}

// Used in: Views/Shared/BlogPost.cshtml

.expert-article-social-bar {
  width: 100%;

  @media (min-width: 500px) {
    display: table;
  }

  &__left,
  &__right {
    @media (min-width: 500px) {
      display: table-cell;
      vertical-align: middle;
    }
  }
  &__right {
    @media (min-width: 500px) {
      text-align: right;
    }

    img {
      display: inline-block;
      width: 150px;
      height: auto;
    }
  }
}

// Used in: Views/Home/Index.cshtml (marquee) | Areas/Certification/Views/[PersonalTrainerDetail | GroupFitnessDetail | HealthCoachDetail].cshtml
// <a class="ace-btn-stroke--reversed ace-btn-cta"> + <a class="ace-btn-primary ace-btn-cta">
.ace-btn-cta {
  width: 100%;
  max-width: 250px;
  margin: 0 .5rem .5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}


.video-responsive {
  overflow: hidden;
  position: relative;
  height: 0;
  width: 100%;
  margin: 0;
  padding: 56.25% 0 0 0;

  iframe {
    display: block;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border: 0;
  }
}

// HC & PT Story Pages
.cert-bg-gradient {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(240,242,243,1) 30%, rgba(240,242,243,1) 70%, rgba(255,255,255,1) 100%);
}

// BUNDLE & SAVE
// coned course detail page - main well

$bundleSaveVerticalBreakPoint: '(min-width: 992px) and (max-width: 1199px), (max-width: 600px)';

.bundle-save-main-wrap {
    text-align: center;
}

.bundle-save-main {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    padding: 1rem 0;
    border: 2px solid #3fbfad;
    border-radius: 2px;

    @media #{$bundleSaveVerticalBreakPoint} {
        display: inline-flex;
        width: auto;
        flex-direction: column;
        align-items: center;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 2rem;
        text-align: left;

        @media #{$bundleSaveVerticalBreakPoint} {
            width: auto;
            border: 0;
            padding: 1rem;
        }

        &:first-child {
            border-right: 2px solid #6a666a;

            @media #{$bundleSaveVerticalBreakPoint} {
                width: auto;
                border: 0;
            }

            img {
                width: 100%;

                @media #{$bundleSaveVerticalBreakPoint} {
                    width: 250px;
                }
            }
        }

        &:last-child {
            flex: 1;
            white-space: nowrap;
            align-items: flex-start;
            min-width: 50%;

            @media #{$bundleSaveVerticalBreakPoint} {
                white-space: normal;

                br {
                    display: none;
                }
            }
        }
    }

    &__description {
        margin: 0 0 .5rem !important;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.2;
        color: #02263c;

        @media (min-width: 1200px) and (max-width: 1300px) {
            font-size: 1.2rem;
        }
    }
}

.articles-pagination-wrap {
    margin: 0 auto;
    padding: 1rem;
    background-color: #fff;
}


// CEC Power Pass Lockup

.cec-pp-lockup {
    text-align: center;

    &__headline {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.25rem;
        line-height: 1;
        white-space: nowrap;
        color: $purple;
        font-family: $font-family-muli;
        font-weight: 700;
        font-size: 1.375rem;
    }

    &__description {
        margin-bottom: .5rem;
        color: $gray;
        font-size: remUnit(13px);
        line-height: 1.2;
        font-weight: 600;
    }

    &__image {
        display: inline-block;
        position: relative;
        vertical-align: baseline;
        height: 18px;
        margin-right: .5rem;
    }
}

// ACE PRO EXCLUSIVE ETHICS CALLOUT

.ace-pro-exclusive-ethics-callout {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    max-width: 600px;
    margin: 0 auto 2rem;

    @media (max-width: 480px) {
        flex-direction: column;
    }

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 1rem;

        &:first-child {
            flex: 2;
            background-color: #3fbfad;
        }

        &:last-child {
            flex: 1;
            background-color: #5cc9b9;
        }
    }

    &__heading {
        margin-bottom: .15rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.4rem;
    }

    &__description {
        margin-bottom: 0;
        font-weight: 400;
        color: #fff;
        line-height: 1.2;
    }
}
