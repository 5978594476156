$module: ACEOnly;

.#{$module} {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    left: 0;
    top: 0;
    z-index: 1;
    background-image: url('/images/layout/2017/shared/ace-only/ace-only-snipe.png');
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
  }

  // &.slat-card__link {
  //   &::after {
  //     background-image: url('/images/layout/2017/shared/ace-only/ace-only-snipe-green.png');
  //   }
  // }

  &.#{$module}Tools {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: #f0f2f3;
    box-shadow: 0 0 5px 2px #ccc;

    .#{$module}ToolsList {
      list-style: none;
      padding-left: 0;
      text-align: left;

      li {
        margin-bottom: .5rem;
      }

      a {
        display: inline;
        line-height: 1.2;
      }

      .icon-wrap {
        display: inline;
        line-height: 1;
      }
    }

    &::after {
      display: none;
    }

    .#{$module}Image {
      display: block;
      width: 80px;
      height: auto;
      margin: 0 auto 1rem;
    }
  }
}


// ACE ONLY TOOLBAR

.ace-only {
  &__wrap {
    display: none;
    position: relative;
    width: 100%;
    z-index: 9999;
    border-bottom: 1px solid #fff;

    @media (min-width: 1095px) {
      display: block;
    }

    ul {
      margin: 0;
      list-style: none;
    }
  }

  &__home-btn {
    background-color: #e53d46;
    color: #fff;
  }

  &__account {
    display: block;
    position: absolute;
    margin: 0 auto;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    font-size: .9rem;
    overflow: visible;

    &:hover {
      > span:last-child {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }

    a {
      display: block;
      padding: .9rem 0;
      background-color: transparent;
      color: #fff;
      font-weight: 400;
      white-space: nowrap;
      transition: all .3s ease;
    }

    &-info {
      display: none;
      height: 50px;
      background-color: #02263c;
      text-align: right;

      &--show {
        display: block;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        margin-top: .85rem;
        padding-right: 1rem;
        color: #fff;
        font-size: .9rem;
      }
    }

    &-name {
      display: block;
      position: relative;
    }

    &-logout {
      display: none;
      position: absolute;
      margin: 0 auto;
      top: 100%;
      left: 0;
      right: 0;
      visibility: hidden;
      opacity: 0;
      background-color: #02263c;
      border-top: 0;

      > a {
        padding: .5rem 0;
        font-size: .8rem;
      }
    }
  }

  &__nav {
    position: relative;

    .hero-tabs__tab-nav {
      display: table;
      width: 100%;
      margin: 0;
      padding: 0;

      > li {
        display: table-cell;
        position: relative;
        width: auto;

        &.active {
          &::before {
            border-bottom: 0;
            border-top: 13px solid #f0f2f3;
            top: 47px;
          }

          .hero-tabs__tab, {
            background-color: #f0f2f3;
            color: #e53d46;
          }
        }

        .hero-tabs__tab {
          white-space: nowrap;
        }
      }
    }

    .hero-tabs__tab {
      padding: 15px 10px;
    }
  }

  &__sub-nav {
    display: none;
    position: absolute;
    width: 100%;
    padding: 0;
    left: 0;
    top: 48px;
    background-color: rgba(255,255,255,.9);
    z-index: 9999;

    &--show {
      display: block;
    }

    li {
      display: block;
      position: relative;
      width: 100%;
      border-right: 1px solid #dadfe1;
    }

    a {
      display: block;
      position: relative;
      padding: 10px 20px;
      text-align: left;
      line-height: 1;
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

// Is ACE Only
.ace-only-display{
  border: 1px solid #e50005;
  background: #eee url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAAASBAMAAABIlfZyAAAAMFBMVEUAAAAARn8ARn8ARn8ARn8ARn8ARn8ARn8ARn8ARn8ARn8ARn8ARn8ARn8ARn8ARn8BRYT8AAAAD3RSTlMAd0S77hEimd2qZsyIM1WpnLJDAAAB0ElEQVQoz6XSz4rTUBQG8K/VtsP0b0BGGRgaAq5cJCUbB4SgG8FNs3ChbiYD7lvHB5iKuE7BB0jAB9DNrFt8gSq4n/EJbNrrlLYz/TxJSQ3MrvNbhHtvOLnfvTm4DU2DOH5/eAD81kQDonjfbl1gLW+vRznNw0aOlNlrii4cCisui0dTF4kKdcTqCv+9JUMUfPXuASNwatt2vN8vPtccWkjcIRKdeabOueJQ3pxJ7TTPHhKF9txFiX9z+28+n6C+/LkvZzgwo2zM7/wGU7nAlyc59tPVMynm5Gjpk/r5rLNElVbzMhNTuRxjdIVYiR9tuwVghyEAXv9h6x4bXxc7dMvKG/WwESwwmGCwQqxMsQBwRBcoctycydbh6LpEvRnB6SJV41PNj8CJxNL26jQM4yEAk8k19p1LyfBjMK5xr23BD5F6STEHozhkz1xi7Zx68mj3UFagVeCh8mC4SJ3ONe1UIVAXxUCyTGU/qcBdPsMxZ1X2UZdbHmLQXqFi62lZnp/iw3gmxQsEFGMAu4wNd9mAGckJ0WRfPrZpl1eyJPOw6pAfPPhpu+ARqU6SRmmuSvSSH9VRuCm+jqy84SEjWGELtcfsYgsVStttoZqEvr1/xwTkflTOFaUAAAAASUVORK5CYII=") bottom right no-repeat;
}
