@use "sass:math";

$module: 'tile';

.#{$module} {
	margin-bottom: 40px;
	padding: 22px;
	background-color: $gray-light;
	text-align: center;

	&__body {
		font-size: remUnit(14px);
		line-height: 1.57142857;
		margin-bottom: 20px;
	}
	&__button, &__btn {
		@include ace-btn-primary();
		display: block;
		width: 100%;
	}

  &__image {
    position: absolute;
    background-size: 0;
    background-color: rgba(2, 38, 60, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

	&__credits {
		padding: 10px 0;
		border-bottom: 1px solid $gray-med;
		vertical-align: middle;
		color: $blue;
		font-size: remUnit(16px);
		font-weight: $font-weight-semibold;
		text-align: center;

    img {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      max-width: 40%;
      height: auto;
    }

		span {
			display: inline-block;
			white-space: nowrap;
		}
	}

	&__credits:last-child {
		border: none;
	}

	&__title {
		font-size: remUnit(18px);
	}

  &__indicator-text {
    font-size: remUnit(13.6px);
    font-weight: 400;
  }

	&__post-brief {
		margin-bottom: 20px;
		@include make-xs-column(12, 0);
	}

	&__post-brief:last-child {
		margin-bottom: 0;
	}

	&__post-brief-row {
		@include make-row(0);
	}

	&__post-brief-title {
		margin: 0;
		padding-top: 10px;
		font-size: remUnit(13px);
		text-align: left;
	}

  &__post-brief-bg-img {
    display: block;
    box-sizing: border-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -9999em -9999em;

    @media (min-width: $screen-sm-min) {
      background-position: center center;
      width: 100%;
      height: 200px;
    }
    @media (min-width: $screen-md-min) {
      height: 100px;
    }
    @media (min-width: $screen-lg-min) {
      float: left;
      width: calc(50% - 10px);
      height: 75px;
      margin-right: 10px;
    }

    &--inline-duplicate {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto;

      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }
  }

	&--info-box {
		position: relative;
		padding: 0;
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;

		&::before {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 0;
			content: '';
			background: $blue;
		}

		.#{$module}__body {
			position: relative;
			z-index: 1;
			color: #fff;
			text-align: left;
		}

		.#{$module}__info {
			padding: 20px;
		}

		.#{$module}__title {
			position: relative;
			z-index: 1;
			color: #fff;
			font-size: remUnit(24px);
			font-weight: $font-weight-light;
			text-align: left;
		}

		.#{$module}__title::after {
			display: block;
			content: '';
			margin: 10px 0;
			width: 100%;
			height: 4px;
			background-color: $red;
		}

		.#{$module}__btn {
			@include ace-btn-primary($theme: reversed, $block: true);
			position: relative;
			z-index: 1;

		}
	}

	&--photo-feature {
		position: relative;
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;

		&::before {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 0;
			content: '';
			background: $blue;
		}

		.#{$module}__title {
			position: relative;
			z-index: 1;
			color: #fff;
			font-size: remUnit(36px);
			font-weight: $font-weight-light;
			text-align: left;
		}

		.#{$module}__logo {
			display: block;
			position: relative;
			z-index: 1;
			width: 100px;
			height: auto;
			margin-bottom: 15px;
		}

		.#{$module}__btn {
			@include ace-btn-primary($block: true);
			position: relative;
			z-index: 1;
		}
	}

	&--photo-feature-quote {
		.#{$module}__quote {
			position: relative;
			z-index: 1;
			margin-bottom: 26px;

			&-text,&-name,&-designation {
				position: relative;
				z-index: 1;
				color: #fff;
				text-align: left;
			}
			&-name,&-designation {
				display: block;
				font-weight: $font-weight-regular;
				font-size: remUnit(15px);
			}
		}

		.#{$module}__quote-text {
			font-weight: $font-weight-light;
			font-size: remUnit(26px);
			line-height: lh(26px,31.25px);
		}
		.#{$module}__quote-name {

		}
		.#{$module}__quote-designation {

		}


	}

	&--subscribe {
		text-align: center;

		.#{$module}__body {
			font-size: remUnit(14px);
			line-height: 1.57142857;
		}

		.#{$module}__subscribe-form {
			margin-top: 20px;
		}

		.#{$module}__subscribe-input {
			@extend .form-control;
			padding-left: 0;
			background-color: transparent;
			border: none;
			border-bottom: 1px solid $blue;
		}

		.#{$module}__subscribe-input-wrap {
			@extend .form-group;
		}

		.#{$module}__subscribe-label {
			@extend .sr-only;
		}

		.#{$module}__subscribe-submit {
			@include ace-btn-primary();
			display: block;
			width: 100%;
		}

		.#{$module}__title {
			.icon {
				display: block;
				margin: 0 auto 20px auto;
				font-size: remUnit(45px);
			}
		}
	}

    &--affiliate-marketing{
        padding-bottom: 0;

        .#{$module}__head{
            text-align: left;
            text-decoration: underline;
            margin-bottom: 0;
        }

        .#{$module}__body{
            text-align: left;
            margin-top: 0;
            padding-top: 0;
        }

        .#{$module}__body ul{
            padding-left: 0;
        }

        .#{$module}__body ul li{
            list-style-type: none;
            padding-bottom: 1rem;
            padding-left: 0;
        }
    }

  &--payment-option {
    text-align: left;

    @media (min-width: $screen-sm-min) {
      width: calc(50% - 1.25rem);
      float: left;
    }

    .#{$module}__title {
      font-weight: bold;
    }

    .#{$module}__sub-title {
      margin-bottom: 1.5rem;
      font-weight: bold;
      color: $red;

      span {
        color: $gray;
        font-size: 1rem;
        font-weight: normal;
      }
    }

    &:first-of-type {
      margin-bottom: 1rem;

      @media (min-width: $screen-sm-min) {
        margin-right: 2.5rem;
      }
    }

    &__button {
      width: 100%;
    }
  }

	// When the title's headline has a hanging left image
	// like certified subscribe
	&--title-img {

		.#{$module}__head {
			position: relative;
			display: table;
			max-width: 274px;
			margin: 0 auto remUnit(18px) auto;
		}
		.#{$module}__title-img,
		.#{$module}__title {
			display: table-cell;
			vertical-align: middle;
			// 318px - (22px * 2) = 274px
			padding-left: percentage(math.div(22px, 274px));
		}

		.#{$module}__title-img {
			text-align: center;
			width: percentage(math.div(113px, 274px));
			img {
				width: 100%;
				height: auto !important;
				box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
				transform: rotate(-8.37deg);
			}
		}
		.#{$module}__title {
			text-align: left;
		}
	} // &--title-img

	&--blue {
		background-color: $blue;

		.#{$module}__title {
			color: $red;
		}

		.#{$module}__body {
			color: #fff;
		}

		.#{$module}__btn {
			@include ace-btn-primary($theme:reversed-red-icon, $block: true);
		}
	}

	@media (min-width: $screen-sm-min) {
		&__post-brief {
			margin-bottom: 20px;
			@include make-xs-column(6, 40px);
		}

		&__post-brief-row {
			@include make-row(40px);
		}
	}

	@media (min-width: $screen-md-min) {
    &__image {
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
    }

		&__title,
		&--title-img .#{$module}__title {
			font-size: remUnit(24px);
		}

		&--info-box {
			padding-top: 200px;

			&::before {
				display: none;
			}

			.#{$module}__body {
				text-align: center;
			}

			.#{$module}__info {
				background: rgba(2,38,60,0.8);
			}

			.#{$module}__title {
				text-align: center;
			}

			.#{$module}__title::after {
				width: 100px;
				margin-right: auto;
				margin-left: auto;
			}
		}

		&--photo-feature {
			position: relative;
			padding-top: 350px;

			&::before {
				display: none;
			}

			.#{$module}__logo {
				position: absolute;
				top: 20px;
				right: 15px;
				margin: 0;
			}
		}

		&--photo-feature-landscape {
			padding-top: 20px;
			padding-left: 50%;

			.#{$module}__logo {
				position: relative;
				top: auto;
				right: auto;
				margin-bottom: 25px;
			}
		}

		&__post-brief {
			@include make-xs-column(12, 0);
		}

		&__post-brief-row {
			@include make-row(0);
		}
	}

	@media (min-width: $screen-lg-min) {
		&__post-brief {
			margin-bottom: 15px;
		}

		&__post-brief-img-wrap {
			display: block;
			box-sizing: border-box;
			float: left;
			width: 50%;
			padding-right: 10px;
		}

		&__post-brief-title {
			display: block;
			float: left;
			width: 50%;
		}
	}
}
