body {
	position: relative;
}

hr {
	margin-top: 40px;
	margin-bottom: 40px;
	border-color: $gray-med;
}

.nav-active {
	@media (max-width: 1199px) {
		overflow: hidden;
	}
}

.nav-active body {
	@media (max-width: 1199px) {
		position: relative;
		height: 100%;
		overflow: hidden;
	}
}

.video-wrap {
	display: block;
	position: relative;
	overflow: hidden;
}

%overlay {
	width: 90%;
	max-width: 1000px;
	padding: 30px;
}

%overlay-head {
	margin: -30px -30px 30px;
	padding: 10px 30px;
	background: $gray-med;
}
