$module: standard-overlay;

.#{$module} {
  @extend %overlay;
  display: none;

  &__head {
    @extend %overlay-head;
    padding-right: remUnit(48px);
  }

  &__body {
    p {
      font-size: remUnit(14px);
    }
    a:not([class^="ace-btn"]) {
      color: $red;
      font-weight: 400;
    }
  }

  &__title {
    @extend %font-muli-bold;
    margin: 0;
    font-size: remUnit(24px);
    color: $blue;
  }

  &__close {
    @extend %button;
    appearance: none;
    border: none;
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__sub-item {
    display: none;
  }

  &__sub-item-content {

  }

  &__button {
    @extend %ace-btn-floating--red;
  }
}
