$module: scrollspy-side-nav;

##{$module} {
    &.affix {
        position: static;
    }

    li.active a {
        color: #e53d46;
        text-decoration: underline;
    }

    > li > a,
    > ul > li > a {
        padding: 0;

        &:hover,
        &:focus {
            background-color: transparent;
        }
    }
    @media (max-width: 991px) {
        display: none;
    }
}
