$module: 'partners';

.partners {
	padding-top: 30px;
	padding-bottom: 30px;
	background: #f7f9f9;

    &--white {
        background-color: #fff;
    }

	&__head {
		margin-bottom: 50px;
	}

	&__image {
		@include img-responsive;
		@include center-block;
	}

	&__more-btn {
		@extend %ace-btn-floating--green;
		display: none;
		float: right;
		margin-right: 10px;
		padding: 0;
		font-size: remUnit(12px);
		line-height: 30px;

	}

	&__slider-wrap {
		@include clearfix;
		position: relative;
		padding-right: 40px;
		padding-left: 40px;
	}

	&__slide-next,
	&__slide-prev {
		@extend %ace-btn-floating--green;
		position: absolute;
		left: 0;
		top: 50%;
		margin: 0;
		padding: 0;
		font-size: remUnit(30px);
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);

	}

	&__slide-next {
		left: auto;
		right: 0;
	}

	&__title {
		@extend %diamond-title;
		@extend %diamond-title-variant--gray;
		max-width: 750px;
		@extend %margin-auto;
	}

	&__wrap {
		@extend .container-fluid;
		max-width: 1400px;
		padding-right: 20px;
		padding-left: 20px;
	}

	@media (min-width: $screen-md-min) {
		padding-top: 50px;
		padding-bottom: 75px;

		&__more-btn {
			display: block;
		}
	}
}
