$module: 'checkmark-list';

$map-icon-color:(
  1: ( color: 'black' ),
  2: ( color: 'blue' ),
  3: ( color: 'gray' ),
  4: ( color: 'green' ),
  5: ( color: 'magenta' ),
  6: ( color: 'purple' ),
  7: ( color: 'red' ),
  8: ( color: 'white' ),
  9: ( color: 'yellow' ),
);

@mixin checklist($font-size: 1.125rem, $icon-color: green) {
    list-style: none;
    padding: 0;

    $path: "/images/webcontent/assets/global/icons/checkmark-bold-";

    > li {
        position: relative;
        margin-bottom: #{$font-size * .8};
        padding-left: #{$font-size * 1.5};
        font-size: $font-size;
        text-align: left;

        @media (max-width: 991px) {
            $font-size-m: $font-size * .833;

            font-size: $font-size-m;
            margin-bottom: #{$font-size-m * .8};
            padding-left: #{$font-size-m * 1.5};
        }

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            display: block;
            position: absolute;
            content: "";
            background-image: url(#{$path}#{$icon-color}.svg);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            width: 1em;
            height: .76em;
            left: 0;
            top: .4em;
        }
    }
}

.checkmark-list {
    @include checklist();
}

@each $index, $map in $map-icon-color {
    $map-color: map-get($map, color);

    .#{$module}--#{$map-color} {
        > li {
            &::before {
                background-image: url(/images/webcontent/assets/global/icons/checkmark-bold-#{$map-color}.svg);
            }
        }
    }
}

