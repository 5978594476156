$module: 'faq';

.#{$module} {
	&__group {
		// margin-right: -20px;
		// margin-left: -20px;
	}

	&__panel {
		@extend .panel;
	}

	&__panel-body {
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: remUnit(16px);
		text-align: left;

		a {
			color: $red;
		}
	}

	&__panel-title {
		margin: 0;
		padding: 0;
		border-bottom: 1px solid #fff;

		a {
			@extend %button;
			@include ace-btn-theme(blue);
			display: block;
			position: relative;
			padding: 20px 60px 20px 20px;
			font-family: $font-family-roboto;
			font-weight: $font-weight-regular;
			font-size: remUnit(14px);
			text-align: left;
			text-transform: uppercase;
			letter-spacing: 1px;
			white-space: normal;

			.icon {
				position: absolute;
				right: 30px;
				top: 50%;
				-webkit-transform: translateY(-50%) rotate(180deg);
				-ms-transform: translateY(-50%) rotate(180deg);
				transform: translateY(-50%) rotate(180deg);
			}
		}

		a.collapsed {
			.icon {
				transform: translateY(-50%) rotate(0deg);
			}
		}
	}

	&__group--nested {
		margin-right: 0;
		margin-left: 0;

		.#{$module}__panel-body {
			margin-bottom: 10px;
			padding: 10px 20px 20px;
			border-bottom: 1px solid $gray-med;
		}

		.#{$module}__panel-inner {
			max-width: 700px;
			margin-right: auto;
			margin-left: auto;
		}

		.#{$module}__panel-title {
			border: none;

			a {
				background-color: transparent;
				color: $blue;
				padding: 10px 60px 10px 20px;
				font-family: $font-family-muli;
				font-weight: $font-weight-regular;
				font-size: remUnit(21px);
				line-height: 1.25;
				text-transform: none;
				letter-spacing: 0;

				.icon {
					top: 25px;
					-webkit-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					transform: rotate(180deg);
					font-size: remUnit(14px);
					fill: $blue;
				}
			}

			a.collapsed {
				.icon {
					-webkit-transform: rotate(0deg);
					-ms-transform: rotate(0deg);
					transform: rotate(0deg);
				}
			}
		}
	}

	@media (min-width: $screen-md-min) {
		&__panel-title {
			a {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}

		&__group--nested {
			.#{$module}__panel-title {
				a {
					font-size: remUnit(24px);
				}
			}
		}
	}
}
