// Google Site Search Box Overides
table.gsc-search-box {
  *, *:before, *:after {
    box-sizing: content-box !important;
  }

  td {
    border: none !important
  }
}

td.gsib_a input {
  background:transparent !important;
}

.gsc-control-searchbox-only.gsc-control-searchbox-only-en {
  padding: 9px 0 5px 0 !important;
}

div.gsc-input-box {
  border: none !important;
  background: transparent !important;
  box-shadow:none !important;

  table {
    background: transparent !important
  }
}

input.gsc-input {
  text-indent:0 !important;
}

input.gsc-input:focus {
  background: transparent !important;
}

.gsst_b {
  display:none !important;
}

input.gsc-search-button, input.gsc-search-button:hover, input.gsc-search-button:focus {
  padding: 3px !important;
  border-color: transparent !important;
  background-color: transparent !important;
  background-size: auto 100% !important;
  background-repeat: no-repeat !important;
  background-position: right !important;

  .main-head__slide-search-row & {

    @media (min-width: $screen-md-min) {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 14px;
      right: 6px;
    }
  }

}

div.main-head__search-row {

  .gsc-control-searchbox-only.gsc-control-searchbox-only-en {
    padding: 0 !important;
  }

  input.gsc-search-button, input.gsc-search-button:hover, input.gsc-search-button:focus {
    background-image: url(/images/icon-search-white.png) !important;
    width: 13px;
    height: 13px;
  }

  input:focus, input {
    color: #fff !important;
  }

  input::-webkit-input-placeholder {
    color: #fff !important;
  }
}

// Google Site Search Results Overides

.gsc-control-cse, .gsc-table-result {
  font-family: Muli,sans-serif !important;

  .gs-title, .gs-title b {
    height: 2.2em !important;
    font-size: 1.4em !important;
    color: #02263c !important;
    text-decoration: none !important;
    font-weight: 300 !important;

    :hover {
      text-decoration: underline !important;
    }

    b{
      font-weight: 500 !important;
      font-size: 1em !important;
    }
  }

  .gsc-tabhActive, .gsc-tabhInactive {
    width:20% !important;
    margin-right: 1%;
    font-size: 1.4em !important;
    font-weight: normal !important;
     @media (max-width: $screen-sm) {
      width: auto !important;
    }
    @media (max-width: $screen-xs) {
      display: block !important;
      overflow: hidden;
      width: 100% !important;
    }
  }

  .gsc-tabhActive {
      border:none !important;
      background-color: $gray !important;
      color: #fff !important;
  }

  .gsc-tabsArea {
    border: none !important;
    height: auto !important;
        > div {
                height: 40px !important;
                @media (max-width: $screen-xs) {
                    overflow: hidden !important;
                    height: auto !important;
                }
              }
  }
  .gsc-tabHeader {
      height: 40px !important;
      line-height: 40px !important;
  }

  .gsc-above-wrapper-area {
    border: none !important;
    padding: 0 !important;
  }
}

.hide-gcse-tabs {
  .gsc-tabsArea {
    display: none;
  }
}

.widget {
  .gsc-control-searchbox-only.gsc-control-searchbox-only-en {
    padding: 0px !important;

    .gsc-search-box.gsc-search-box-tools {
      position: relative;
      width: 100%;
      border-bottom: 1px solid #02263c;
      margin: 0px;

      td.gsc-input, td.gsc-search-button {
        padding: 6px 0px;

        .gsc-input-box {
          td.gsib_a, td.gsib_b {
            padding: 0px;

            input.gsc-input {
              color: #02263c;
            }
          }
        }
      }
    }
  }
}

// ACE MASTER MAIN HEADER SLIDE SEARCH
.ACEMaster .main-head__slide-search,
.ACEMaster .main-head__search-row {
  form.gsc-search-box {
    font-size: 13px;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 4px;
    margin-left: 0;
    width: 100%;
  }

  table.gsc-search-box {
    top: auto !important;
    position: static !important;
  }

  td.gsc-input {
    margin: 0 !important;
    padding: 8px !important;
    padding-right: 12px !important;
    float: none !important;
    height: auto !important;
    width: auto !important;
    font-size: 14px !important;
    color: #000 !important;
  }

  div.gsc-input-box {
    height: 25px !important;
  }

  td.gsib_a {
    width: 100% !important;
  }

  input.gsc-input {
    width: 350px !important;
    height: auto !important;
    margin: 0px !important;
    padding: 0px !important;
    border: 0 !important;
    font-size: 16px !important;
    color: #000 !important;
    float: none !important;
  }

  .main-head__slide-search-row input.gsc-search-button,
  .main-head__slide-search-row input.gsc-search-button:hover,
  .main-head__slide-search-row input.gsc-search-button:focus {
    position: absolute !important;
    top: 12px !important;
    right: 6px !important;
  }

  .cse .gsc-search-button input.gsc-search-button-v2,
  input.gsc-search-button-v2 {
    width: 13px !important;
    height: 13px !important;
    min-width: 13px !important;
    margin-top: 2px !important;

    @media (min-width: 992px) {
      width: 20px !important;
      height: 20px !important;
      min-width: 20px;
    }
  }

  .cse input.gsc-search-button,
  input.gsc-search-button {
    border: 1px solid transparent !important;
  }
}

// ACE MASTER MAIN HEADER MOBILE SEARCH
.ACEMaster .main-head__search-row {
  input.gsc-input {
    color: #fff !important;
  }

  .gsc-search-button input.gsc-search-button {
    position: static !important;
  }
}
