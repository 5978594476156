// ===========================================
// Panel showing programs of study containing
// 3 pricing tables where the middle column
// is larger than the other 2 columns
// Example: CRT-3.1 > Certifications Detail
// =========================================
$module: 'pic-card-panel';

.#{$module} {
	$width-pic-card-gutters: 20px;
	$width-pic-card-gutters-split: ($width-pic-card-gutters * 0.5);

	&__wrap {
		@extend .container-fluid;
		padding: 40px 0 0;
		text-align: center;
	}

	&__title {
		font-size: remUnit(21px);
		font-weight: $font-weight-semibold;
		text-align: center;
	}

	&__copy {
		max-width: 630px;
		margin: 0 auto 30px;
		font-size: remUnit(16px);
		text-align: center;
	}

	&__footer {
		padding-top: 20px;
		padding-bottom: 20px;
		font-size: remUnit(16px);
		vertical-align: middle;
	}

	&__reference {
		display: inline-block;
		color: $green;
		font-size: remUnit(24px);
		font-weight: 600;
		line-height: 1;
		vertical-align: middle;
	}

	&__row {
		@include make-row(0);
		padding-top: 20px;
	}
	// All  Columns
	&__pic-card {
		margin-bottom: $width-pic-card-gutters;
		padding-left: 10px;
		padding-right: 10px;
	}
	// Special case: Specialist programs: crt1.0-certifications
	// 2col - xs
	// 3col - sm - md
	// 4col - lg
	&--4col-2col-xs {
		.#{$module}__pic-card {
			position: relative;
			float: left;
			width: 50%;
			min-height: 1px;
		}

		.#{$module}__pic-card--cta {
			float: none;
			width: 100%;
			clear: both;
		}
	}

	&--gray-light {
		background-color: $gray-light;
	}

	&--specialist &__pic-card, &--authors &__pic-card {
		width: 100%;
	}

	@media (min-width: 550px) {
		&--specialist &__pic-card {
			float: left;
			width: 50%;
		}
	}

	@media (min-width: $screen-xs-min) {
		&--authors &__pic-card {
			width: $width-1-3;
		}
	}

	// Small devices (tablets, 768px and up)
	// ===============================================
	@media (min-width: $screen-sm-min) {
		padding-top: 30px;
		padding-bottom: 0;
		border: none;

		&__title {
			font-size: remUnit(36px);
		}
		// All three colums
		&__pic-card {
			position: relative;
			float: left;
			width: 50%;
			min-height: 1px;
		}

		&--specialist &__pic-card {
			width: $width-1-3;
		}

		&--authors &__pic-card {
			width: 25%;
		}
		// 4 colums
		&--4col &__pic-card {
			width: $width-1-3;
		}

		&--4col-2col-xs {
			.#{$module}__pic-card--cta {
				float: left;
				width: $width-1-3;
				clear: none;
			}
		}
	}
	// Large devices (large desktops, 1200px and up)
	// ===============================================
	@media (min-width: $screen-lg-min) {
		&--authors &__pic-card {
			width: 20%;
		}

		&--specialist &__pic-card {
			width: 25%;
		}
		// All three colums
		&--4col &__pic-card {
			width: 25%;
		}
		// the first and last in a row have padding removed on one side
		&--2col &__pic-card {
			&:nth-child(2n + 1) {
				padding-left: 0;
			}

			&:nth-child(2n) {
				padding-right: 0;
			}
		}
	}
}
