$module: 'ace-react-form';

.#{$module} {
	padding: 50px 20px;
	background-position: 50% 50%;
	background-size: cover;

  &__form {
    .tile__download-wrap & {
      text-align: left;

      .#{$module}__form-group {
        color: $gray;
      }
      .#{$module}__form-label {
        color: $gray;
      }
      .#{$module}__form-input {
        color: $gray;
        font-size: remUnit(16px);
        @include placeholder($gray);
      }
      .#{$module}__form-checkbox {
        label {
					color: $gray !important;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
          &::before {
            border-color: $gray !important;
          }
        }
      }
      .#{$module}__form-submit {
        @include ace-btn-primary();
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    }
  }

	&__header {
		text-align: center;
		margin: 0 auto 40px auto;
	}

	&__label {
		@include sub-section-label-styles();
		@extend %diamond-title;
		@extend %diamond-title-variant--green;
		max-width: 700px;
	}

	&__copy, &__description {
		@extend .hidden-xs;
		@extend .hidden-sm;
		max-width: 630px;
		margin-right: auto;
		margin-bottom: 50px;
		margin-left: auto;
		color: #fff;
		font-size: remUnit(16px);
		text-align: center;
	}

	&__image-copy {
		@extend .visible-xs;
		@extend .visible-sm;
		color: #fff;
		font-size: remUnit(15px);
		padding-right: 130px;
	}

	&__image-copy--tabbed {
		@extend .visible-md;
		@extend .visible-lg;
	}

	// Display user feedback messages by adding
	// .errors or .success to the parent form element
	&__form-feedback {
		margin-top: 5px;
		margin-bottom: 5px;
		display: none;

		form.success & {
			display: block;
			color: $green;
		}

		form.errors & {
				display: block;
				color: #d93a42;
		}
	}

	&__form-group {
		@extend .form-group;
		margin-bottom: 5px;

        &.error {
            height: auto;
        }
	}

	&__form-label {
		color: $green;
	}

	&__form-input {
		@extend .form-control;
		@include placeholder($green);
		padding-right: 0;
		padding-left: 0;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid $green;
		color: #fff;
		font-size: remUnit(18px);

    &.error {
      border-bottom: 1px solid $red;
    }
	}

	&__form-checkbox, &__form-radio {
		display: block;
		@include styled-radios-and-checkboxes(#fff, $green, #fff, 13px);

		&:first-child {
			margin-top: 25px;
		}
		label {
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			&:before {}
		}
	}

	&__form-submit {
		@extend %ace-btn-stroke--reversed;
		width: 100%;
		margin-top: 25px;

	}

	&__form-wrap {
		@include make-xs-column(12, 0);
	}

	&__image {
		display: block;
		// @extend .pull-right;
		float: right;
		max-width: 100px;
		height: auto;
		margin-right: 10px;
		transform: rotate(-6.46deg);
	}

	&__image-wrap {
		@include make-xs-column(12, 0);
		padding-top: 20px;
	}

	&__row {
		@include make-row(0);

		&--flex {
			display: flex;
			text-align: center;
			flex-direction: column;

			@media (min-width: $screen-md-min) {
				margin: 0 auto;
				flex-direction: row;
			}

			.#{$module}__image-list-wrap {
				display: block;

				@media (min-width: $screen-md-min) {
					width: 50%;
				}
			}

			.#{$module}__list-wrap,
			.#{$module}__image-wrap,
			.#{$module}__form-wrap {
				display: block;
				float: none;
				width: auto;
				text-align: left;

				@media (min-width: $screen-md-min) {
					display: inline-block;
				}
			}
			.#{$module}__image-wrap {
				margin: 0 auto 2rem;
				max-width: 500px;

				@media (min-width: $screen-md-min) {
					max-width: none;
				}
			}
			.#{$module}__form-wrap {
				max-width: 600px;
				margin-left: auto;
				margin-right: auto;

				@media (min-width: $screen-md-min) {
					width: 60%;
					max-width: none;
				}
			}
		}

    &--salary-survey {
      .#{$module}__column-container {

        @media (min-width: $screen-sm-min) {
          display: table;
          width: 100%;
        }
      }
      .#{$module}__column {
        @media (min-width: $screen-sm-min) {
          display: table-cell;
          vertical-align: top;

          &--right {
            width: 50%;
          }
        }
      }

      .#{$module}__form-group {
        height: 67px;

        &.error {
            height: auto;
        }

        @media (min-width: $screen-sm-min) {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      select {
        height: 67px;
        background-color: $blue;
        color: $green;
      }

      .#{$module}__footer {
        margin-top: 2rem;
      }

      .#{$module}__footer-cell {

        @media (min-width: $screen-sm-min) {
          display: inline-block;
          vertical-align: middle;
          width: 50%;
          padding-right: 1rem;
        }

        .#{$module}__form-group {
          height: auto;
        }

        &--right {
          @media (min-width: $screen-sm-min) {
            text-align: right;
          }
        }
      }

      .#{$module}__form-submit {
        @include ace-btn-primary(red);
      }
    }
	}

	&__tab {
		// @extend %button;
		text-transform: uppercase;
		@extend %font-roboto-medium;
		font-size: remUnit(14px);
		letter-spacing: 1px;
		display: block;
		padding: 5px 0;
		color: #8d969d;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 4px solid transparent;

		@include ace-btn-hover {
			border-color: $green;
			color: #fff;
			text-decoration: none;
		}
	}

	&__tab-content {
		@extend .tab-content;
	}

	&__tab-nav {
		margin: 0;
		padding: 0 0 5px 0;
		list-style: none;
		text-align: center;
		@include clearfix;

		li {
			position: relative;
			display: inline-block;
			padding: 0 15px;
		}

		li.active {
			.#{$module}__tab {
				border-color: $green;
				color: #fff;
			}
		}

		&.#{$module}__tab-nav--select {
			position: absolute;
			width: 100%;
			left: 0;
			right: 0;
			top: .75rem;
			z-index: 2;
			margin: 0;
			padding: 0;
		  border-radius: 2px;
		  font-size: 1.2rem;
			cursor: pointer;
			background-color: $blue;

		  li {
				display: none;
				width: 100% !important;
				padding: 0.25rem;
		    border-radius: 3px;
				float: none !important;

				.#{$module}__tab {
					display: block;
					width: 100%;
					border: 0;
					font-size: .9rem;
					text-align: center;
					color: #fff;
				}

		    &.active {
					display: flex;
					justify-content: space-between;
		      align-items: center;
		      border: 1px solid #111;
		      border-radius: 3px;
		      box-shadow: 0 0 2px #aaa;
		      color: #111;
		    }
		  } // li
		  &.active {
		    border: 1px solid #aaa;

		    li {
		      border: none;
		      box-shadow: none;
		      display: block;
		    }
		  } // &.active
		} // ul-select

		@at-root #selected--ace-ul-select {
			position: absolute;
			top: -2rem;
			width: 100%;
			padding: 10px 0;
			border: 0;
			font-size: .9rem;
			text-align: left;
			color: $green;
			text-transform: uppercase;
			font-weight: 500;
			font-family: $font-family-roboto;

			@media (min-width: $screen-md-min) {
				display: none;
			}
		}

		@at-root .#{$module}__tab-nav--select__arrow {
			float: right;
			position: relative;
			top: -4px;
		}

	}
	&__tab {
		font-size: remUnit(11px);
	}

	&__tab-pane {
		@extend .tab-pane;
	}

	&__title {
		color: #fff;
		font-size: remUnit(28px);
		font-weight: $font-weight-semibold;
		text-align: center;
	}

	&__list-title {
		color: #fff;
		font-size: remUnit(24px);
		font-weight: $font-weight-semibold;
	}

	&__list-wrap {
		@include make-xs-column(12, 0);
		@extend .hidden-xs;
		@extend .hidden-sm;

		ul {
			margin: 0;
			padding: 0 0 0 20px;
			color: #fff;
			font-size: remUnit(16px);
			font-weight: $font-weight-light;
		}

		ul li span {
			color: $green;
		}
	}

	&__wrap {
		@extend %margin-auto;
		max-width: 900px;
		@include clearfix;
	}

	&--blue {
		background-color: $blue;
	}

	// V2 --> Three Column Layout

	&--v2 {
		background-color: $gray-med;

		.#{$module}__title,
		.#{$module}__copy {
			color: $blue;
		}
		.#{$module}__row {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: flex-start;
			width: 100%;
			max-width: 1000px;
		}
		.#{$module}__list-wrap,
		.#{$module}__image-wrap,
		.#{$module}__form-wrap {
			width: auto;
			float: none;
		}

		// LIST

		.#{$module}__list-title {
			color: $blue;
		}
		.#{$module}__list-wrap ul {
			list-style: none;
			padding-left: 10px;

			@media (min-width: $screen-md-min) {
				white-space: nowrap;
			}

			li {
				margin-bottom: .5rem;
				color: $blue;
				&::before {
					content: "+";
					position: relative;
					left: -.5rem;
				}

				span {
					color: $blue;
				}
			}
		}

		// IMAGE

		.#{$module}__image {
			transform: none;
			max-width: 200px;

			@media (max-width: 630px) {
			  display: none;
			}
		}

		// FORM

		.#{$module}__form {

		}

		.#{$module}__form-input {
			@include placeholder($green);
			border-bottom: 1px solid $blue;
			color: $green;

			&.error {
				border-bottom: 1px solid $red;
			}
		}

		.#{$module}__form-checkbox {
			@include styled-radios-and-checkboxes($blue, $blue, $blue, 13px);
		}

		.#{$module}__form-submit {
			@include ace-btn-stroke(green);
		}
	}



	// event-registration-form
	@at-root #event-registration-form {
		.#{$module}__column-container {
			display: table;
			width: 100%;

      @media (max-width: 600px) {
        display: block;
      }
		}

		.#{$module}__column {
			display: table-cell;
			width: 50%;
			vertical-align: top;

      @media (max-width: 600px) {
        display: block;
        width: 100%;
      }

			&-left {
				padding-right: 1rem;

        @media (max-width: 600px) {
          padding-right: 0;
        }
			}
			&-right {
				padding-left: 1rem;

        @media (max-width: 600px) {
          padding-left: 0;
        }

        .#{$module}__form-input {
          border: 1px solid $green;
          padding-left: .5rem;
          padding-right: .5rem;

          @media (max-width: 600px) {
            width: 100%;
          }
        }

        select.#{$module}__form-input {
          padding: 1rem .5rem;
        }
			}
		}

    .#{$module}__form-group {
      margin-bottom: 1rem;

      &.error {
        height: auto;
      }

      &.gender-radio-buttons {
        .gender-label {
          display: inline-block;
          margin-right: 1rem;
          font-size: 16px;
        }
      }
    }

		.#{$module}__form-input {
			font-size: 16px;
      font-weight: bold;
			color: $gray;
      background-color: transparent;
		}

		.#{$module} {

		}

    #NameChangeLink {
      font-size: .8rem;
    }
	}


	@media (min-width: $screen-iphone-6-min ) {

		&__tab-nav {
			padding-bottom: 20px;
			li {
				float: left;
				width: 50%;

			}
		}

		&__tab {
			display: inline-block;
			width: auto;
			font-size: remUnit(12px);
		}

	}

	@media (min-width: $screen-xs-min) {
		&__tab-nav {
			li {
				display: table-cell;
			}
		}

		&__tab {
			font-size: remUnit(12px);
		}
	}

	@media (min-width: $screen-sm-min) {
		&__form-wrap {
			@include make-xs-column(6, 40px);
		}

		&__image-wrap {
			@include make-xs-column(6, 40px);
		}

		&__row {
			@include make-row(40px);
		}

		&__tab-nav {
			li {
				display: inline-block;
				width: auto;
				float: none;
				padding: 0 10px;
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}
		&__tab {
			font-size: remUnit(12px);
		}
	}

	@media (min-width: $screen-md-min) {
		padding-top: 50px;
		padding-bottom: 100px;
		border: none;

		&__image-copy--tabbed {
			margin-top: 40px;
			text-align: left;
			padding-right: 0;

		}

		&__form {
			margin-top: -20px;
		}

		&__form-input {
			font-size: remUnit(20px);
		}

		&__form-submit {
			width: auto;
		}

		&__form-wrap {
			@include make-md-column(4, 40px);
			padding-right: 0;
		}

		&__form-wrap--double {
			@include make-md-column(6, 80px);
		}

		&__form-wrap--tabbed {
			@include make-md-column(6, 40px);
		}

		&__image {
			max-width: 175px;
			// margin-top: -20px;
			float: none;
			margin: -20px auto 0 auto;
			// padding-right: 20px;
		}

		&__image--tabbed {
			float: left;
			margin-top: 0;
	    max-width: 175px;
			padding: 0 20px 0 10px;
		}

		&__image-wrap {
			@include make-md-column(4, 40px);
		}

		&__image-wrap--double {
			@include make-md-column(6, 80px);

			.#{$module}__image {
				max-width: 200px;
				margin-top: 0;
			}
		}

		&__image-wrap--tabbed {
			@include make-md-column(6, 40px);
		}

		&__label {
			margin-bottom: 40px;
		}

		&__list-wrap {
			@include make-md-column(4, 40px);
			padding-left: 0;
		}

		&__tab-content {
			padding-top: 20px;
		}

		&__tab-nav {
			padding-bottom: 30px;
		}

		&__tab {
			font-size: remUnit(15px);
		}

		&__title {
			font-size: remUnit(36px);
		}
	}
}

// Exclusive Video Content
.#{$module}--exclusive-video {
	border: none;
	padding-top: 50px;
	padding-bottom: 50px;

	.#{$module}__wrap {
		padding-left: 0;
		padding-right: 0;
	}

	.#{$module}__row {
		max-width: 750px;
		margin: 0 auto;
	}


	h2, p, label, input {
		color: #fff;
	}
	p {
		font-size: remUnit(16px)
	}

	&__form-input {
		@extend .form-control;
		@include placeholder(#fff);
		padding-right: 0;
		padding-left: 0;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #fff;
		color: #fff;
		font-size: remUnit(18px);
    transition: all .3s ease;

    &.error {
      padding-left: .25rem;
      border-bottom-color: transparent;
      box-shadow: 0 0 0 2px #3be2da;
    }
	}

	&__form-label {
		@extend %margin-auto;
		margin-bottom: 20px;
		float: left;
		text-align: left;
	}

	.#{$module}__form-checkbox {
		display: block;
		@include styled-radios-and-checkboxes(#fff, #fff, #fff, 13px);

		&:first-child {
			margin-top: 25px;
		}
		label {
			&:before {}
		}
	}

	&__form-submit {
		@extend %ace-btn-primary--reversed;
		width: 100%;
		margin-top: 25px;
	}

	&__content-wrap {
		// @extend .container-fluid;
		width: 100%;
		padding-right: 0px;
		padding-left: 0px;
		float: left;
	}

	form {
		&.success {
			.#{$module}__form-feedback {
				display: block;
				color: $green;
			}
		}
		&.errors {
			.#{$module}__form-feedback {
        display: block;
        padding: 0;
        color: rgb(59, 226, 218);
        font-weight: 300;
        font-size: .9rem;
			}
		}
	}

	.#{$module}__video-image {
		text-align: center;
		img {
			width: 100%
		}
	}

	@media (min-width: $screen-sm-min) {
		.#{$module}__content-wrap,.#{$module}__form-wrap {
		max-width: 800px;
		width: 50%;
		float: left;
		padding-right: 20px;
		padding-left: 20px;
		}
		.#{$module}__content-wrap {
			padding-right: 50px
		}
		.#{$module}__form-wrap {
			padding-left: 50px
		}
	}
}
