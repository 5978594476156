$module: 'exercise-card-grid';

.#{$module} {
	@extend %margin-auto;
	text-align: left;
	font-size: 0; // remove inline-block whitespace
	padding-bottom: 10px;
	margin-bottom: 30px;
	&__header {
		@include clearfix();

		&--bordered {
			padding-top: 25px;
			border-top: 1px solid $gray-med;
		}
		&--filters {
			padding-top: 20px;
			border-top: 1px solid $gray-med;
		}
	}

	&__title-wrap,
	&__tools-wrap {
		width: 100%;
	}

	&__title {
		@extend %font-muli-bold;
		font-size: remUnit(24px);
		$color: $blue;
		margin-bottom: 20px;
	}

	&__tools-wrap {
		@include make-row(0);
		margin-bottom: 20px;
	}
	&__form {

		select {
			border-color: $red;
		}
		@include selectric-variant(#fff, $red, #fff, $red);
	}
	&__filter-wrap {
		@include make-sm-column(4,0);
	}

	&__form-label {
		@extend .sr-only;
	}

	&__row {
		width: 100%;
		font-size: 0; // eleminate white space
	}

	&__cell {
		width: 100%;
		font-size: 1rem; // override inline-block white-space fix
		display: inline-block;
		vertical-align: top;
		margin-bottom: 20px;
	}
	// Small devices (tablets, 768px and up)
	// ==========================================================================
	@media (min-width: $screen-sm-min) {
		// Regular Slat Cards
		// 2 Columns (default)
		&__cell {
			width: 50%;
		}
		&__filter-wrap {

			&:first-child {
				padding-left: 0;
				padding-right: 10px
			}
			&:nth-child(2) {
				padding-left: 10px;
			}
			&:last-child {
				padding-left: 20px;
			}
		}
		// Just the
		&--3col {
			.#{$module}__cell {
				width: $width-1-3;

				&:first-child {
					padding-left: 0 !important;
					padding-right: 10px !important;
				}

				&:nth-child(2) {
					padding-left: 10px;
				}

				&:last-child {
					padding-right: 0;
					padding-left: 20px;
				}
			}
		}

		&--4col {
			.#{$module}__cell {
				width: 50%;
				padding-left: 10px;
				padding-right: 10px;
			}
		}
		// end: --RELATED GRID =================
	}
	// Medium devices (desktops, 992px and up)
	// ==========================================================================
	@media (min-width: $screen-md-min) {
		&__title-wrap,
		&__tools-wrap {
			position: relative;
			float: left;
			min-height: 1px;
			padding-left: 0;
			padding-right: 0;
		}

		&--sort {
			.#{$module}__title-wrap {
				width: $width-2-3;
			}

			.#{$module}__tools-wrap {
				width: $width-1-3;
			}

			.#{$module}__form {
				float: right;
			}
		}

		&--4col {
			.#{$module}__cell {
				width: 25%;
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}
}
