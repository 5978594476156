// DISPLAY
.dis-inline {
  display: inline !important;
}

.dis-block {
  display: block !important;

  &-md-up {
    @media (min-width: $screen-md-min) {
      display: block !important;
    }
  }
}

.dis-inline-block {
  display: inline-block !important;

  &-sm-down {
    @media (max-width: $screen-md-min - 1) {
      display: inline-block !important;
    }
  }
}

.dis-table {
  display: table !important;
}

.dis-table-cell {
  display: table-cell !important;
}

.dis-inline-table {
  display: table !important;
}

.dis-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.dis-none {
  display: none !important;
}

.pseudo-el-hide {
  &::before,
  &::after {
    display: none !important;
  }
}

// POSITION

.pos-static {
  position: static !important;
}

.pos-absolute {
  position: absolute !important;
}

.pos-relative {
  position: relative !important;

  &-sm-down {
    @media (max-width: $screen-md-min - 1) {
      position: relative;
    }
  }
}

.pos-fixed {
  position: fixed !important;
}

.pos-sticky {
  position: sticky !important;
}

// FLEX ALIGNMENT

.justify-content-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.justify-content-flex-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.justify-content-flex-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

.justify-content-space-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
}

.justify-content-space-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.align-items-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.align-items-flex-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.align-items-flex-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}

.align-items-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}

// VERTICAL ALIGN
.valign-top {
  vertical-align: top;
}

.valign-middle {
  vertical-align: middle;
}

.valign-bottom {
  vertical-align: bottom;
}

// POSITIONING VALUES

.pos-top {
  &-0 {
    top: 0 !important;
  }
  &-1rem {
    top: 1rem !important;
  }
  &-2rem {
    top: 2rem !important;
  }
  &-3rem {
    top: 3rem !important;
  }
  &-25pct {
    top: 25% !important;
  }
  &-50pct {
    top: 50% !important;
  }
  &-100pct {
    top: 100% !important;
  }
}

.pos-bottom {
  &-0 {
    bottom: 0 !important;
  }
  &-1rem {
    bottom: 1rem !important;
  }
  &-2rem {
    bottom: 2rem !important;
  }
  &-3rem {
    bottom: 3rem !important;
  }
  &-25pct {
    bottom: 25% !important;
  }
  &-50pct {
    bottom: 50% !important;
  }
  &-100pct {
    bottom: 100% !important;
  }
}

.pos-left {
  &-0 {
    left: 0 !important;
  }
  &-1rem {
    left: 1rem !important;
  }
  &-2rem {
    left: 2rem !important;
  }
  &-3rem {
    left: 3rem !important;
  }
  &-25pct {
    left: 25% !important;
  }
  &-50pct {
    left: 50% !important;
  }
  &-100pct {
    left: 100% !important;
  }
}

.pos-right {
  &-0 {
    right: 0 !important;
  }
  &-1rem {
    right: 1rem !important;
  }
  &-2rem {
    right: 2rem !important;
  }
  &-3rem {
    right: 3rem !important;
  }
  &-25pct {
    right: 25% !important;
  }
  &-50pct {
    right: 50% !important;
  }
  &-100pct {
    right: 100% !important;
  }
}

.trans {
  &-0 {
    &-x {
      transform: translateX(0);
    }
    &-y {
      transform: translateY(0);
    }
    &-xy {
      transform: translate(0, 0);
    }
  }

  &-50 {
    &-x {
      transform: translateX(50%);
    }
    &-y {
      transform: translateY(50%);
    }
    &-xy {
      transform: translate(50%, 50%);
    }
  }
  &-50neg {
    &-x {
      transform: translateX(-50%);
    }
    &-y {
      transform: translateY(-50%);
    }
    &-xy {
      transform: translate(-50%, -50%);
    }
  }
}

// OVERFLOW

.overflow {
  &-x {
    &-visible {
      overflow-x: visible !important;
    }
    &-hidden {
      overflow-x: hidden !important;
    }
    &-scroll {
      overflow-x: scroll !important;
    }
    &-auto {
      overflow-x: auto !important;
    }
  }
  &-y {
    &-visible {
      overflow-y: visible !important;
    }
    &-hidden {
      overflow-y: hidden !important;
    }
    &-scroll {
      overflow-y: scroll !important;
    }
    &-auto {
      overflow-y: auto !important;
    }
  }
  &-xy {
    &-visible {
      overflow: visible !important;
    }
    &-hidden {
      overflow: hidden !important;
    }
    &-scroll {
      overflow: scroll !important;
    }
    &-auto {
      overflow: auto !important;
    }
  }
}

// FLOAT

.float {
  &-none {
    float: none !important;
  }
  &-left {
    float: left !important;
  }
  &-right {
    float: right !important;
  }
}

.no-scroll {
  overflow: hidden !important;

  &-y {
    overflow-y: hidden !important;
  }
  &-x {
    overflow-x: hidden !important;
  }
}