$module: 'sub-nav';

.#{$module} {
	&__overlay {
		@extend %overlay;
		display: none;
	}

	&__overlay-close {
		@extend %button;
		apperance: none;
		border: none;
		background: transparent;
		position: absolute;
		top: 0;
		right: 0;
	}

	&__overlay-head {
		@extend %overlay-head;
	}

	&__overlay-title {
		@extend %font-muli-bold;
		margin: 0;
		font-size: remUnit(24px);
		color: $blue;
	}

	&.sticky {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		width: 100%;
		background-color: $blue;

		.#{$module}__button-wrap {
			padding: 10px 30px;
		}
	}
}
