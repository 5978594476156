@use "sass:math";

$module: 'compare-table';


.#{$module} {
  width: 100%;
  // max-width: 1400px;
  margin: 0 auto;

  &-container {
    display: none;
    background-color: rgba(255, 255, 255, 0.75);

    @media (min-width: $screen-md-min) {
      display: block;
    }
  }


	// local variables
	// padding/margins
	$cell-pad-top:         12px;
	$cell-pad-right:       12px;
	$cell-pad-bottom:      12px;
	$cell-pad-left:        12px;
	$cell-pad-all:         $cell-pad-top $cell-pad-right $cell-pad-bottom $cell-pad-left;
	$page-gutter:          20px;
	$page-gutter-split:    $page-gutter * 0.5;
	// colors
	$border-color:         #dadfe1;
	// box/grid definitions
	$num-programs:         3;

	$page-context:         1400px;
	$width-labels-px:      319px;
	$width-labels-percent: percentage(math.div($width-labels-px, $page-context));
	$width-tables:         100% - $width-labels-percent;
	$width-cells-percent:  percentage(math.div(1, $num-programs));
	$width-border:         1px;
	$width-borders-total:  $width-border * $num-programs + (2 * $width-border);// includes labels
	$height-header-row:    150px;
	$height-footer-row:    100px;

	// $offset-tables-left:   $width-labels-px + $page-gutter - $width-border;
	$offset-tables-left:   percentage(math.div($width-labels-px + ($page-gutter * 0.5) - $width-border, $page-context));
  //<editor-fold>


	// Begin module container properties
	position: relative;
	// top: -1 * $height-header-row;
	top: 0;

	// Outer structure
	// --------------------------
	&__header {
		@extend .container-fluid;
    display: table;
    width: 100%;
    height: auto;
		background-color: rgba(#fff, 0.75);
		padding-right: 0;
		padding-left: $offset-tables-left;
		padding-right: $page-gutter;
	}
	&__header-cell {
    display: table-cell;
    height: auto;
		min-height: 1px;
		position: relative;
    vertical-align: bottom;
		width: $width-cells-percent;

		&--highlighted {
			background-color: #fff;
		}

	}

	&__body {
		background-color: #fff;
		padding-bottom: 0;
	}

	&__table {
		padding: 0 20px;
		clear: both;
		background-color: #fff;
	}

	&__footer {
    position: relative;
		width: 100%;
    padding-right: 0;
		background-color: #fff;
	}
	&__footer-row {
		@include make-row(0);
    position: relative;
		background-color: #fff;
		padding-right: $page-gutter;
		padding-left: $offset-tables-left;
		margin-bottom: 14px;
	}
	&__footer-cell {
		height: 100%;
		min-height: 1px;
		position: relative;
		float: left;
		width: $width-cells-percent;
	}

	// Sections Structure
	// ----------------------------------------
	&__section-header { // <header>
		width: 100%;
		position: relative;
		padding: 30px 12px 10px $cell-pad-left;
	}
	&__section-title { // <h3>
		@extend %font-roboto-bold;
		color: $red;
		text-transform: uppercase;
		font-size: remUnit(14px);
		margin: 0
	}

	// Data labels column
	// ----------------------------------------
	&__labels-column { // <ul>
		width: $width-labels-percent;
		float: left;
		overflow: visible;
		background: #f0f2f3;
	}
	// Data labels rows
	&__label-cell { // <li>
		width: 100%;
	}
	&__label-cell,&__data-cell {
		padding: $cell-pad-all;
	}
	&__label-text {
		display: inline-block;
		@extend %font-muli-bold;
		color: $blue;
		font-size: remUnit(14px);
		margin: 0;
		padding: 0;
		line-height: lh(14px,16px);
	}
  &__label-sub-list {
    display: block;
    @extend %font-muli-regular;
    color: $blue;
    font-size: remUnit(12px);
    padding-left: 1rem;

    a {
      @extend %font-muli-regular;
      color: $blue;
      font-size: remUnit(12px);
    }
  }
	// Outer structure of table data
	// ----------------------------------------
	&__data-wrap {
		overflow: hidden;
		// box-sizing: content-box;
		line-height: normal;
	}
	&__data-container {
		width: 100%;
		// width: auto;
		transform: translate3d(0px, 0px, 0px);
		user-select: none;
		display: block;
		cursor: move;
		touch-action: pan-y;
		float: left;
	}
 //---------------------------------------------

 // Normalize <ul> and <li>
 &__labels-column, &__data-row {
	 list-style: none;
	 padding: 0;
	 margin: 0;
 }
 //---------------------------------------------

	// Data Rows (horizontal <ul>)
	// ----------------------------------------
	&__data-row {
		width: 100%;
		text-align: center;
		overflow: hidden; // prevents fout scenario
	}

	//  Data Cells -
	//  - child <li>'s of &__data-row
	//  - child <li>'s of &__labels-column
	// ----------------------------------------
	&__data-cell {
		position: relative;
		float: left;
		width: $width-cells-percent;
		min-height: 1px;
		display: table;
		vertical-align: middle;
		text-align: center;
	}
	// Data (first-child <span> of &__data-cell)
	&__data {
		display: table-cell;
		vertical-align: middle;
	}
	&__data-yes,
  &__data-no {
		display: inline-block;
		color: $blue;
		&:before {
			color: $blue;
		}
	}

  &__data-yes {
    width: 1rem;
    margin-left: .2rem;
    font-size: 1rem;

    &::before {
      content: url('/images/layout/2017/certification/checkmark.svg');
    }

    &--green {
      display: inline-block;
      vertical-align: middle;
      width: 1.5rem;

      &::before {
        content: url('/images/layout/2017/certification/checkmark-green.svg');
      }
    }
  }

  &__data-no {
    font-size: remUnit(13px);
    width: 10px;

    &::before {
      content: "\2014";
    }
  }

	// Additional span with text info
	&__data-info {
		font-size: remUnit(14.5px);
		color: $blue;

        &--light {
            font-weight: 400;
        }

        &--sub {
          display: block;
          font-size: remUnit(13px);
        }
	}

	&__sub-item-toggle-trigger {
		cursor: pointer;

		.sub-items-visible & {
			.icon--angle-down-bold {
				transform: rotate(180deg);
			}
		}
	}

	&__sub-item {
		display: none;

		.sub-items-visible & {
			display: table-row;
		}
	}

	// Borders
	// =====================================
	&__label-cell,
	&__data-row {
		border-top: 1px solid $border-color;
	}
	&__labels-column,
	&__data-wrap {
		border-bottom: 1px solid $border-color;
	}
	&__labels-column {
		border-right: 1px solid $border-color;
		border-left: 1px solid $border-color;
	}
	&__data-row, &__data-cell {
		border-right: 1px solid $border-color;
	}
	&__data-cell:last-child {
		border-right: none;
	}
	&__footer-cell, &__header-cell {
		// border-left: 1px solid rgba(red, 1.0);
		// border-right: 1px solid rgba(#cddc39, 1.0);

	}
	&__footer-cell:first-child, &__header-cell:first-child {
		// border-right: none;
		// border-left: 1px solid rgba(red, 1.0);
	}
	// For Debuggin borders
	// &__section-header,.ct-footer-item__price {display: none;}
	// .ct-footer-item__body {padding: 0;}

	// ==========================================================================
	// Medium devices (desktops, 992px and up)
	// ==========================================================================
	@media (min-width: $screen-md-min) {

		&__header {

		}
		&__body-table {

		}
		&__footer {

		}

	}
	// end: Medium devices (desktops, 992px and up) ========================

  &.#{$module}--pair {

    $num-programs:         2;

    $page-context:         1400px;
    $width-labels-px:      319px;
    $width-labels-percent: percentage(math.div($width-labels-px, $page-context));
    $width-tables:         100% - $width-labels-percent;
    $width-cells-percent:  percentage(math.div(1, $num-programs));
    $width-border:         1px;
    $width-borders-total:  $width-border * $num-programs + (2 * $width-border);// includes labels
    $height-header-row:    150px;
    $height-footer-row:    100px;

    // $offset-tables-left:   $width-labels-px + $page-gutter - $width-border;
    $offset-tables-left:   percentage(math.div($width-labels-px + $page-gutter - $width-border, $page-context));

    // Outer structure
    // --------------------------
    .#{$module}__header {
      padding-left: $offset-tables-left;
    }
    .#{$module}__header-cell {
      width: $width-cells-percent;
    }
    .#{$module}__data-cell {
      width: $width-cells-percent;
    }

    .#{$module}__footer-cell {
      width: $width-cells-percent;
    }
    // Data labels column
    // ----------------------------------------
    .#{$module}__labels-column { // <ul>
      width: $width-labels-percent;
    }
  }
  &.#{$module}--pair {

    $num-programs:         2;

    $page-context:         1400px;
    $width-labels-px:      319px;
    $width-labels-percent: percentage(math.div($width-labels-px, $page-context));
    $width-tables:         100% - $width-labels-percent;
    $width-cells-percent:  percentage(math.div(1, $num-programs));
    $width-border:         1px;
    $width-borders-total:  $width-border * $num-programs + (2 * $width-border);// includes labels
    $height-header-row:    150px;
    $height-footer-row:    100px;

    // $offset-tables-left:   $width-labels-px + $page-gutter - $width-border;
    $offset-tables-left:   percentage(math.div($width-labels-px + $page-gutter - $width-border, $page-context));

    // Outer structure
    // --------------------------
    .#{$module}__header {
      padding-left: $offset-tables-left;
    }
    .#{$module}__header-cell {
      width: $width-cells-percent;
    }
    .#{$module}__data-cell {
      width: $width-cells-percent;
    }

    .#{$module}__footer-cell {
      width: $width-cells-percent;
    }
    // Data labels column
    // ----------------------------------------
    .#{$module}__labels-column { // <ul>
      width: $width-labels-percent;
    }
  }
  //</editor-fold>

  &.#{$module}--pair-plus-ad {
    $page-context:         1400px;
    $width-labels-px:      319px;
    $width-ad-px:          339px;
    $width-labels-percent: percentage(math.div($width-labels-px, $page-context));
    $width-ad-percent:     percentage(math.div($width-ad-px, $page-context));
    $width-header-cells:   percentage( math.div(((1400 - 349) * 0.5), 1400));

    // Outer structure
    // --------------------------
    .#{$module}__header {
      padding-left: 0;
    }
    .#{$module}__header-cell {
      width: $width-header-cells;

      &--ad {
        width: $width-labels-percent;
        vertical-align: middle;
      }
    }
    .#{$module}__data-cell {
      width: 50%;
    }

    .#{$module}__footer-cell {
      width: 50%;
    }
    // Data labels column
    // ----------------------------------------
    .#{$module}__labels-column { // <ul>
      width: $width-labels-percent;
    }
  }
}
