$module: 'filter-tools';

.#{$module} {
	padding-bottom: 20px;

	&__button {
		@include ace-btn-primary($theme:red, $size:micro);
		font-size: remUnit(12px);
	}

	&__button-refine {
		@include ace-btn-primary($theme:blue, $size:micro);
		display: block;
		margin-top: 1.5rem;
	}

	&__dropdown {
		width: 100%;
		//margin-top: -10px;
		padding: 10px;
		border-radius: 0;
	}

	&__dropdown-checkbox-wrap {
		@extend .checkbox;

		label {
			display: block;
			font-size: remUnit(14px);
		}
	}

	&__dropdown-toggle {

		@include ace-btn-primary($theme: gray-light-red-icon, $block:true);
		@include button-size(6px, 10px, 16px, 1.444444, 0);
		font-size: remUnit(16px);
		line-height: 1.444444;
		letter-spacing: normal;
		padding: 6px 35px 6px 10px;
		white-space: nowrap;
		position: relative;
		font-family: $font-family-muli;
		font-weight: $font-weight-light;
		text-align: left;
		text-transform: none;

		.icon-wrap {
			position: absolute;
			right: 10px;
			top: 50%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	&__form-label {
		color: $blue;
	}

	&__form-label--sr-only {
		@extend .sr-only;
	}

	&__form-select,
	.selectric-filter-tools__form-select {
		@include selectric-variant($blue, $gray-light, transparent, $blue);
	}

	&__form-select--inline,
	.selectric-filter-tools__form-select--inline,
	&__overlay .selectric-filter-tools__form-select--inline {
		@include selectric-variant($red, #fff, $red, $red);
	}

	&__form-wrap {
		@extend .container-fluid;
		display: none;
		max-width: 1400px;
		padding-right: 0;
		padding-left: 0;
	}

	&__head {
		padding-bottom: 20px;
	}

	&__overlay {
		@extend %overlay;
		display: none;
	}

	&__overlay-close {
		@extend %button;
		apperance: none;
		border: none;
		background: transparent;
		position: absolute;
		top: 0;
		right: 0;
	}

	&__overlay-head {
		@extend %overlay-head;
	}

	&__overlay-title {
		@extend %font-muli-bold;
		margin: 0;
		font-size: remUnit(24px);
		color: $blue;
	}

	&__row {
		@include make-row(0);
	}

	&__taxonomy {
		display: inline-block;
		margin: 0;
		padding: 10px 0 0;
		list-style: none;
		line-height: 1;
	}

	&__taxonomy li {
		display: inline-block;
		margin-bottom: 4px;
	}

	&__taxonomy li a {
		@extend %ace-btn-tag-filter--blue;
	}

	&__title {
		@extend %font-muli-bold;
		display: inline-block;
		font-size: remUnit(24px);
		color: $blue;
	}

	&__filter-group {
		@extend .form-group;
		position: relative;
	}

	&__filter-wrap {
		@include make-xs-column(12, 0);
		padding-bottom: 10px;
	}

	&__sort-wrap {
		@include make-xs-column(12, 0);
	}

	&__wrap {
		@extend .container-fluid;
		padding-right: 20px;
		padding-left: 20px;
	}

	@media (min-width: $screen-md-min) {
		padding-bottom: 30px;

		&__button-wrap {
			display: none;
		}

		&__dropdown {
			width: auto;
			margin-top: 0;
			margin-left: 5px;
		}

		&__row {
			@include make-row(10px);
		}

		&__filter-wrap {
			width: 20%;
			margin-right: .5rem;
			padding-bottom: 0;
		}

		&__form-select--inline,
		.selectric-filter-tools__form-select--inline {
			display: inline-block;
			width: auto;
			vertical-align: middle;
		}

		&__form-wrap {
			display: block;
		}

		&__sort-wrap {
			@include make-md-column(4, 10px);
			vertical-align: middle;
		}

		&__taxonomy {
			padding-top: 30px;
		}

		&__form.sticky {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 1000;
			width: 100%;
			background-color: $blue;

			.#{$module}__button-wrap {
				padding: 10px 30px;
			}

			.#{$module}__form-body {
				float: right;
				width: $grid-primary-width + $grid-features-width;
				padding: 10px 30px;
			}

			.#{$module}__form-label {
				color: #fff;
			}
		}
	}
}
