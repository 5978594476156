$module: partner-card;

.#{$module} {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin-bottom: 2rem;
    text-align: center;
    background-color: #fff;
    border: 2px solid $gray-light;

    &__image-container {
        display: flex;
        width: 100%;
        padding: .25rem 0;
        flex-direction: column;
        justify-content: center;
    }

    &__image {
        display: block;
        width: auto;
        max-height: 120px;
        margin: 0 auto;
    }

    &__title-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 60px;
        padding: .5rem;
        background-color: $gray-med;
    }

    &__title {
        margin: 0 auto;
        text-align: center;
        font-family: $font-family-roboto;
        font-size: remUnit(14px);
        font-weight: $font-weight-medium;
        color: $blue;
    }

    &__button.ace-btn-primary--green {
        color: #fff;

        &--placeholder {
            display: block;
            height: 40px;
        }
    }
    // REGION WRAPPER

    &__region-wrap {
        padding: 20px;
    }

    &__region-title {
        margin-bottom: 1rem;
        padding-bottom: .5rem;
        border-bottom: 1px solid $gray-med;
        color: $gray;
        font-size: 1.125rem;
    }
    // PRO DISCOUNTS
    &--pro-discounts {
        .#{$module} {
            &__image-container {
                min-height: 130px;
            }
        }
    }
}
