/*
* Base Button Style
* The shared default values for all ace buttons
*
* -- use in all new types created
* -- should only be called directly from types
* -- can be called inderectly using @include ace-btn()
*
*/
@mixin ace-btn-base() {
	font-weight: $ace-btn-font-weight;
	font-size: $ace-btn-font-size;
	font-family: $ace-btn-font-family;
	text-decoration: none;
	text-align: center;
	text-transform: $ace-btn-text-transform;
	letter-spacing: $ace-btn-letter-spacing;
	line-height: $ace-btn-line-height;
	appearance: none; // just in case it's a button or input


	&:focus,
	&:active,
	&:hover {
		text-decoration: none;
		outline: none;

		.icon,
		.icon-wrap {
			text-decoration: none;
		}
	}
}
