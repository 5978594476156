$stickyCta: sticky-cta;

@function svgRhomboid($fillOpacity: .15) {
	@return 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="181" height="83" viewBox="0 0 181 83" fill="none"><path d="M65.1801 83L0 0H181V83H65.1801Z" fill="white" fill-opacity="#{$fillOpacity}"/></svg>';
}

@function svgRhomboidTall($fillOpacity: .15) {
	@return 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="181" height="160" viewBox="0 0 181 160" fill="none"><path d="M65.1801 160L0 0H181V160H65.1801Z" fill="white" fill-opacity="#{$fillOpacity}"/></svg>';
}

.main-head.sticky {
    position: relative;
}

.section-container {
    &--#{$stickyCta} {
        @media (min-width: 600px) {
            top: 0;
            position: sticky;
            z-index: 10;
        }
    
        .section-container__inner {
            max-width: none;
            padding: 0;
            margin: 0;

            @media (max-width: 599px) {
                margin-top: -20%;
            }
        }
    }
}

.#{$stickyCta} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
    background-color: $green;
    background-image: url(svgRhomboid()), linear-gradient(90deg, #3FBFAD -0.09%, #5282DE 99.99%);
    background-position: right top, left center;
    background-size: auto 100%, cover;
    background-repeat: no-repeat, no-repeat;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, .3);
    text-align: center;

    @media (max-width: 599px) {
        width: 100%;
        flex-direction: column;
        padding: 25% 1rem 1rem;
        background-image: url(svgRhomboidTall()), linear-gradient(90deg, #3FBFAD -0.09%, #5282DE 99.99%);
    }

    &-headline {
        margin: .5rem 1rem;
        font-size: 22px;
        font-weight: 700;
        color: #fff;
        text-align: center;

        @media (max-width: 599px) {
            margin: 0 0 1rem;
        }
    }

    &-buttons-wrap {
        @media (max-width: 400px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        @media (min-width: 600px) {
            padding: .25rem 0;
        }
    }

    .ace-btn-primary,
    .ace-btn-stroke--reversed {
        padding-top: .65rem;
        padding-bottom: .65rem;

        @media (max-width: 400px) {
            display: block;
        }
    }

    .ace-btn-stroke--reversed {
        border-color: $yellow;

        &:hover {
            border-color: #fff;
        }
    }

    &--mobile {
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        padding: .75rem;
        transform: translateY(-100%);
        transition: transform .5s, opacity .5s, z-index .5s;
        z-index: -1;

        &.active {
            opacity: 1;
            z-index: 12;
            transform: translateY(0);
        }
    }

    &--purple {
        background-color: $purple;
        background-image: url(svgRhomboid(.05)), linear-gradient(90deg, #66418C 14.83%, #923C69 98.92%);

        @media (max-width: 599px) {
            background-image: url(svgRhomboidTall(.05)), linear-gradient(90deg, #66418C 14.83%, #923C69 98.92%);
        }
    }
}
