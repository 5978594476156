$module: 'page-head';

.#{$module} {
	margin-bottom: 40px;
	padding: 40px 0;
	background-position: 50% 50%;
	background-size: cover;
	text-align: center;

	&__author-img {
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		@include img-responsive;
	}

	&__cta {
		&-wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			flex-direction: column;
			align-items: center;

			@media (min-width: $screen-md-min) {
				flex-direction: row;
				justify-content: flex-start;
			}
		}

		&-button {
			margin-bottom: 1rem;
		}

		&-lockups {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-start;
			margin-bottom: 1rem;

			@media (max-width: 767px) {
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}

		&-lockup-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: 1rem;
			padding-left: 1rem;

			@media (max-width: 767px) {
				margin: 0 auto;
				padding: .5rem;
			}

			&:first-child {
				margin: 0;
				padding: 0;

				@media (max-width: 767px) {
					margin: 0 auto;
					padding: .5rem;
				}
			}
		}
	}
	// CEC Club Lockup

	.cec-club-lockup {
		text-align: left;

		@media (max-width: 991px) {
			text-align: center;
		}

		&-top {
			white-space: nowrap;
			color: $purple;
			font-family: $font-family-muli;
			font-weight: 700;
			font-size: 1.375rem;
		}

		&-bottom {
			margin-bottom: .5rem;
			color: $gray;
			font-size: remUnit(13px);
			line-height: 1.2;
			font-weight: 600;
		}

		&-image {
			display: inline-block;
			position: relative;
			vertical-align: baseline;
			height: 18px;
			bottom: -1px;
		}
	}


	&__button-cart {
		max-width: 640px;

		.#{$module}__cta-wrap & {
			white-space: nowrap;

			@media (min-width: 992px) {
				margin-right: 1rem;
			}

			@media (max-width: 375px) {
				padding-left: 12px;
				padding-right: 12px;
			}
		}
	}

	&__button-cart-price {
		.#{$module}__cta-wrap & {
			display: inline-block;
			vertical-align: middle;
			font-family: $font-family-muli;
			margin-right: 15px;
			font-size: 160%;

			@media (max-width: 375px) {
				font-size: 145%;
			}
		}
	}

	&__button-cart-cta {
		.#{$module}__cta-wrap & {
			display: inline-block;
			vertical-align: middle;
		}
	}

	&__button-rating {
		@extend %button;
		padding: 0;
		vertical-align: middle;

		@include ace-btn-hover {
			text-decoration: underline;
		}

		span {
			color: $red;
		}
	}

	&__form {
		max-width: 640px;
		margin-bottom: 15px;

		@include selectric-variant($blue, $gray-med, $gray-med, $gray);

		.selectric {
			.button {
				&::after {
					border-top-color: $blue;
				}
			}
		}
	}

	&__form-label {
		@extend .sr-only;
	}

	&__header {
		max-width: 740px;
		margin: 0 auto 40px;
		padding: 0 20px;
	}

	&__label {
		@extend %diamond-title;
		@extend %diamond-title-variant--red;
		@extend %margin-auto;
		margin-bottom: 40px;
	}

	&__meta {
		display: none;
		position: relative;
		padding-left: 70px;
		font-size: remUnit(12.5px);
		text-align: left;

		a {
			color: #fff;
		}
	}

	&__meta--text-only {
		padding-left: 0;
	}

	&__meta-rating {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	&__star-rating {
		font-size: remUnit(15px);
		vertical-align: middle;
	}

	&__more-btn {
		@extend %ace-btn-floating--green;
	}

	&__primary {
		@include make-xs-column(12);
	}

	&__product-points {
		width: 63px;
		padding: 0 0 15px;
		background-color: $red;
		border-radius: 50%;
		color: #fff;
		font-family: $font-family-roboto;
		font-size: remUnit(8px);
		line-height: 1;
		text-align: center;
		position: absolute;
		left: 35px;
		bottom: 25px;

		span {
			display: block;
			font-family: $font-family-muli;
			font-size: remUnit(24px);
			line-height: 1.5;
			font-weight: $font-weight-semibold;
		}
	}

	&__row {
		@include make-row();
	}

	&__secondary {
		@include make-xs-column(12);
	}

	&__taxonomy {
		margin: 0 0 20px;
		padding: 0;
		list-style: none;
		line-height: 1;
	}

	&__taxonomy li {
		display: inline-block;
		font-family: $font-family-roboto;
		font-size: remUnit(14px);
		font-weight: $font-weight-medium;
		text-transform: uppercase;

		&::before {
			content: ', ';
		}

		&:first-child::before {
			content: '';
		}
	}

	&__title {
		margin: 0;
		color: #fff;
		font-size: remUnit(28px);

		&-link {
			color: #fff;

			&:hover {
				color: #d6d6d6;
				text-decoration: none;
			}
		}
	}

	&__wrap {
		@extend .container-fluid;
		max-width: 1400px;
	}

	&--cart {
		.#{$module}__title {
			color: $blue;
		}
	}

	&--hero {
		position: relative;
		min-height: 295px;
		padding: 0;
		text-align: center;

		.#{$module}__title-bar {
			position: absolute;
			bottom: 0;
			width: 100%;
			padding-top: 30px;
			padding-bottom: 40px;
			background: rgba(0,0,0,0.5);
			color: #fff;
		}

		.#{$module}__title {
			font-size: remUnit(28px);
		}
	}

	&--flush-bottom, &--load-article {
		margin-bottom: 0;
	}

	&--load-article {
		margin-top: 10px;
		background-position: center top;
	}

	&--issue {
		padding-top: 30px;
		padding-bottom: 30px;
		background: $blue;
		text-align: left;

		.#{$module}__meta {
			display: inline-block;
			margin: 25px 0;
		}

		.#{$module}__secondary-inner {
			display: none;

			img {
				@include img-responsive;
			}
		}

		.#{$module}__primary {
			@include make-xs-column(12, 60px);
			color: #fff;
		}
	}

	&.#{$module}--product {
		padding-top: 20px;
		padding-bottom: 20px;
		background: $gray-light;
		text-align: left;

		@media (min-width: $screen-md-min) {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		.#{$module}__wrap {
			@media (min-width: $screen-md-min) {
				padding: 0;
			}
		}

		.#{$module}__row {
			@media (min-width: $screen-md-min) {
				@include make-valign-row;
				margin: 0;
			}
		}

		.#{$module}__primary {
			text-align: center;

			@media (min-width: $screen-md-min) {
				@include make-valign-col(top);
				overflow: visible;
				width: 100%;
				padding-top: 0;
				padding-bottom: 0;
				text-align: left;
			}
		}

		.#{$module}__secondary {
			display: block;
			float: none;

			@media (min-width: $screen-md-min) {
				@include make-valign-col(top);
				position: relative;
				width: 415px;
			}
		}

		.#{$module}__secondary-inner {
			width: 100%;
			max-width: 250px;
			height: 250px;
			margin: 0 auto 20px;
			background-size: cover;
			background-position: 50% 0;
			position: relative;

			@media (min-width: $screen-xs-min) {
				max-width: 375px;
				height: 375px;
			}

			@media (min-width: $screen-md-min) {
				background-position: 50% 50%;
				background-size: cover;
				height: 350px;
			}
		}

		.#{$module}__taxonomy {
			margin-bottom: 10px;
			color: $red;
		}

		.#{$module}__title {
			color: $blue;
		}

		.#{$module}__form {
			@media (min-width: $screen-md-min) {
				margin-top: 40px;
			}
		}

		.#{$module}__product-points {
			left: 10px;
			bottom: 10px;

			@media (min-width: $screen-md-min) {
				width: 86px;
				padding: 0 0 20px;
				background-color: $red;
				border-radius: 50%;
				color: #fff;
				font-family: $font-family-roboto;
				font-size: remUnit(12px);
				line-height: 1;
				text-align: center;
				position: absolute;

				span {
					display: block;
					font-family: $font-family-muli;
					font-size: remUnit(36px);
					line-height: 1.5;
					font-weight: $font-weight-semibold;
				}
			}
		}
	}


	&.#{$module}--cec-club {
		&.#{$module}__button-cart {
		}
	}
}
// ==========================================================================
// Small devices (tablets, 768px and up)
// ==========================================================================

// @media (min-width: $screen-sm-min) {
//   .#{$module} {
//   }
// } // end: Small devices (tablets, 768px and up) ==========================


// ==========================================================================
// Medium devices (desktops, 992px and up)
// ==========================================================================

@media (min-width: $screen-md-min) {
  .#{$module} {
		padding-top: 85px;
		padding-bottom: 85px;

		&__button-cart-price {
			padding-right: 20px;
			margin-right: 20px;
		}

		&__meta {
			display: inline-block;
			font-size: remUnit(14px);
		    margin: 0 auto;
		}

		&__meta-rating {

		}
		&__star-rating {
			display: inline-block;
			padding-right: 10px;
			font-size: remUnit(20px);
		}

		&__primary {
			width: 100%;
			text-align: center;
		}

		&__secondary {
			width: 100%;
		    margin: 2rem auto;
		    display: inline-block;
		    text-align: center;
		}

		&__title {
			font-size: remUnit(56px);
		}

		&--hero {
			min-height: 660px;
			text-align: left;

			.#{$module}__title-bar {
				min-height: 330px;
				padding-top: 40px;
			}

			.#{$module}__title {
				font-size: remUnit(54px);
			}
		}

		&--issue {
			padding-top: 40px;
			padding-bottom: 40px;

			.#{$module}__primary {
				@include make-md-column(9, 60px);
				text-align: left;
			}

			.#{$module}__row {
				@include make-row(60px);
			}

			.#{$module}__secondary {
				@include make-md-column(3, 60px);
				display: block;
				margin: 0;
			}

			.#{$module}__secondary-inner {
				display: block;
			}
		}
  }
} // end: Medium devices (desktops, 992px and up) ========================



// ==========================================================================
// Large devices (large desktops, 1200px and up)
// ==========================================================================

// @media (min-width: $screen-lg-min) {
//   .#{$module} {
//
//   }

// } // end: Large devices (large desktops, 1200px and up) ==================
