// Defines hover effects for titles
// used primarily on cards/post-previews
//
// @param {string} $hover-font-color [optional] - hex or rgb(a) value
//

@mixin ace-btn-title-hover($hover-font-color: $red) {

	// include transitions
	@include ace-btn-hover-transition();
	&:focus,
	&:active,
	&:hover {
		text-decoration: none;
		outline: none;
		color: $hover-font-color;
	}

}
