//
// CEC
// A generic/responsive circle that can contain text.
// - Text should be defined within the module that this circle is placed inside of.
// - This circle needs to be placed inside of a container to contstrain it's size.
//
$module: 'cec-info';

.#{$module} {
	&__credits {
		@extend %font-muli-semibold;
	}

	&__label {
		@extend %font-roboto-medium;
	}

	&--thumbnail {
		color: #fff;
		.#{$module}__credits,.#{$module}__label {
			display: block;
			line-height: 1;
			color: #fff;
		}
		.#{$module}__credits {
			font-size: remUnit(20px);
		}
		.#{$module}__label {
			font-size: remUnit(11px);
		}
	}
}
