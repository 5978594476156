//
// Circle - Responsive
// A generic/responsive circle that can contain text.
// - Text should be defined within the module that this circle is placed inside of.
// - This circle needs to be placed inside of a container to contstrain it's size.
//
$module: 'shape-circle';

.#{$module} {
	position: relative;
  display: block;
  // margin: 2em 0;
  background-color: transparent;
  color: #222;
  text-align: center;

	&:after {
	  display: block;
	  padding-bottom: 100%;
	  width: 100%;
	  height: 0;
	  border-radius: 50%;
	  background-color: #fff;
	  content: "";
	}
	&__inner {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&__wrap {
		display: table;
		width: 100%;
		height: 100%;
	}
	&__content {
		display: table-cell;
		// padding: 1em;
		vertical-align: middle;
	}

	&--red:after {
			background-color: $red;
	}
	&--blue:after {
			background-color: $blue;
	}
}
