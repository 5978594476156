@use "sass:math";

$module: 'cube-variant';
// ACCT 1.0 - Sign In / Register
.#{$module}--sign-in {
	background-color: #fff;

	.cube__content {
		text-align: left;

		hr {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}

	.cube__content p {
		color: $gray;
	}

	.cube--sign-in {
		.cube__button {
			@extend %ace-btn-primary--red;
		}
	}

	.cube--sign-in-alt {
		background-color: #f0f2f3;

		.cube__button {
			@extend %ace-btn-primary--reversed;
		}
	}

	.cube__title {
		color: $blue;
	}

	.cube__form {
		margin-bottom: 30px;

		.cube__form-feedback.error {
			color: red;
		}
	}

	.cube__form-label {
		color: $gray;
		text-align: left;
	}

	.cube__form-input {
		@extend .form-control;
		@include placeholder($gray);
		padding-right: 0;
		padding-left: 0;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid $gray;
		color: $blue;
		font-size: remUnit(18px);
		width: 100%;
	}

	.cube__form-input-wrap {
		@extend .form-group;
	}

	.cube__form-submit-wrap {
		input[type="submit"], button[type="submit"] {
			@extend %ace-btn-primary--red;
		}
	}

	@media (max-width: $screen-sm-max) {
		.cube--sign-in-alt,
    .cube--sign-in--padded {
			padding: 20px;
		}
	}
}
// CRT 3.1 - How to Become a Personal Trainer
.#{$module}--crt-detail-pt-how-to {
	max-width: none;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom center;

	.cube {
		&.cube--img-only {
			border: none;
			margin-bottom: 25px;
		}

		&__content p,
		&__title {
			color: #fff;
		}

		&__title {
			font-size: remUnit(28px);
			line-height: lh(36px,44px);
		}

		&__button {
			@extend %ace-btn-stroke--reversed;
		}
	}
	@media (min-width: $screen-md-min) {
		.cube {
			&__title {
				font-size: remUnit(36px);
				max-width: 525px;
			}
		}
	}
}
// end: CRT 3.1 - How to Become a Personal Trainer
// CRT 3.1 - Why Choose Ace
.#{$module}--crt-detail-pt-why-ace {
	background-color: $gray-light;

	.cube {
		&.cube--img-only {
			border: none;
			margin-bottom: 25px;
		}
	}
	@media (min-width: $screen-md-min) {
		max-width: none;

		.cube {
			&__content p,
			&__title {
				max-width: 525px;
			}
		}
	}
}
// CRT 3.1 - Why Choose Ace
.#{$module}--exam-prep {
	// Handbook Download
	.cube--handbook {
		background-color: #fff;
		margin-top: 40px;

		.cube__button,
		.cube__title {
			color: $blue;
		}

		.cube__content p {
			color: $gray;
		}

		.cube__button {
			@extend %ace-btn-stroke--blue;
		}

		.cube__title {
			padding: 0;
			font-size: remUnit(28px);
		}

		.cube__title-image-wrap {
			padding-bottom: 24px;
      min-width: 240px;
      max-width: 240px;
      margin: 0 auto;
		}

		.cube__title-image {
      width: 100%;
			transform: rotate(0deg);
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
		}
	}
	// Study Center
	.cube--study-center {
		padding-bottom: 0;
		background-size: cover;
		background-position: center;

		.cube__content {
			padding: 30px;
		}

		.cube__title {
			font-size: remUnit(28px);
			padding: 30px 0 0;
			background-position: -110%;
			background-size: 0;

			.icon {
				margin: 0 auto 20px;
				font-size: remUnit(55px);
			}
		}

		.cube__content p,
		.cube__title {
			color: #fff;
		}

		.cube__button {
			@extend %ace-btn-stroke--reversed;
		}
	}
	@media (min-width: $screen-md-min) {
		.cube--study-center {
			.cube__title {
				padding-top: 0;
				font-size: remUnit(36px);
			}
		}
		// Handbook Download
		.cube--handbook {
			margin-top: 0;

			.cube__title-image-wrap {
				width: percentage(math.div(147px, 700px - 60px));
				height: auto;
				margin: 0 auto;
				padding: 24px 0;
			}

			.cube__title-image {
				width: 100%;
				height: auto;
			}

			.cube__title {
				font-size: remUnit(36px);
			}
		}
	}
}
// CRT 3.1.4 - IFT MODEL
.#{$module}--ift-model {
	.cube__title {
		line-height: lh(34px,44px);
	}

	.cube__button {
		@extend %ace-btn-floating--blue-red;
		padding: 0;
	}
	@media (min-width: $screen-md-min) {
		.cube {
			padding: 30px;
		}

		.cube--img-only {
			padding: 50% 0 0;
		}

		.cube {
			&__content p,
			&__title {
				margin-left: auto;
				margin-right: auto;
			}

			&__title {
				max-width: 470px;
			}

			&__content p {
				max-width: 530px;
			}
		}
	}
}

.#{$module}--ift-model-green {
	background-color: $green;
	padding-top: 40px;

	.cube__title {
		color: #fff;
		line-height: lh(34px,44px);
	}

	.cube__content {
		color: #fff;
		margin-top: 18px;
		margin-bottom: 30px;
	}

	.cube__button {
		@extend %ace-btn-floating--white;
		padding: 0;
	}
	@media (min-width: $screen-md-min) {
		padding-top: 0;
		.cube {
			padding: 30px;
			background-color: $green;
		}

		.cube--img-only {
			padding: 50% 0 0;
		}

		.cube {
			&__content {
				margin-top: 0;
				margin-bottom: 0;
			}
			&__content p,
			&__title {
				margin-left: auto;
				margin-right: auto;
			}

			&__title {
				max-width: 470px;
				color: #fff;
			}

			&__content p {
				max-width: 530px;
				color: #fff;
			}
		}
	}
}



.#{$module}--referral-reward {
	.cube--how {
		background-color: $gray-light;
		margin-top: 40px;
    padding-bottom: 0;

		.cube__content p {
			color: #000;
      padding-left: 1rem;
      padding-right: 1rem;
		}
		.cube__title {
			padding: 1rem 0;
			font-size: remUnit(28px);
      color: #000;
      line-height: 1;

      @media (min-width: $screen-md-min) {
        padding: 0;
      }

      &--big {
        padding: 1rem 1rem 0;
      }
		}
    .cube__title-p1 {
      color: #fff;
      font-weight: 100;
      font-size: 3.5rem;

      @media (min-width: $screen-sm-min) {
        font-size: 7.5vw;
      }

      @media (min-width: $screen-lg-min) {
        font-size: 120px;
      }
    }
    .cube__title-p2 {
      text-transform: capitalize;
      font-weight: 100;
      color: #4abfad;
      font-size: 3.5rem;

      @media (min-width: $screen-sm-min) {
        font-size: 7.5vw;
      }

      @media (min-width: $screen-lg-min) {
        font-size: 120px;
      }
    }
    .cube__title-p3 {
      display: block;
      font-weight: 100;
      color: #4abfad;
      font-size: 2.6rem;
      margin: 0 0 .5rem;

      @media (min-width: $screen-sm-min) {
        font-size: 5.5vw;
      }

      @media (min-width: $screen-lg-min) {
        font-size: 88px;
      }
    }
    .cube__title-p4 {
      display: block;
      text-transform: uppercase;
      color: #fff;
      font-size: .9rem;

      @media (min-width: $screen-sm-min) {
        font-size: 1.9vw;
      }

      @media (min-width: $screen-lg-min) {
        font-size: 30.4px;
      }
    }
	}
}



// E/R 1.0 - Education & Resources
.cube-row--edu-resources,
.cube__wrap--edu-resources {
	background-color: #fff;
}

.cube__wrap--edu-resources {
	padding: 0;
}

.#{$module}--edu-number-cube {
	background-color: $blue;

	.cube__content {
		color: #fff;
		padding-left: 20px;
		padding-right: 20px;
	}

	.cube__title {
		padding-top: 30px;
		padding-bottom: 0;
		font-size: remUnit(28px);
	}
	@media (min-width: $screen-md-min) {
		padding: 50% 0 0;

		.cube__content {
			padding: 30px;
		}

		.cube__title {
			padding-top: 0;
			font-size: remUnit(54px);
		}
	}
}

.#{$module}--edu-img-cube {
	padding: 20px;

	.cube__title {}
	@media (min-width: $screen-md-min) {
		padding: 50% 0 0;
	}
}
