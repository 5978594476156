$module: 'reveal-menu';

.#{$module} {
	&__group {
		// margin-right: -20px;
		// margin-left: -20px;
	}

	&__panel {
		//@extend .panel;

		&:first-child {
			border-top: 1px solid #b4bfc5;
		}
	}

	&__panel-body {
		padding: 0 20px 20px;
		background-color: #fff;
		border-bottom: 1px solid #b4bfc5;
		font-size: remUnit(16px);
		text-align: left;

		a {
			color: $red;
		}
	}

	&__panel-body-title {
		font-size: remUnit(13px);
		font-weight: $font-weight-semibold;
	}

	&__panel-primary {
		@include make-sm-column(12, 20px);

		.reveal-menu__group--specialist-curriculum & {
			position: relative;
			float: none;
			width: 100%;
			max-width: 992px;
			min-height: 1px;
			margin-left: auto;
			margin-right: auto;
			padding-left: 20px;
			padding-right: 20px;

			.#{$module}__panel-primary__main-description {
				padding-bottom: 30px;

				@media (min-width: $screen-md-min) {
					padding-bottom: 50px;
				}
			}
		}
	}

	&__panel-primary-description {
		@include make-sm-column(8, 10px);
		font-size: remUnit(13px);

		.reveal-menu__group--specialist-curriculum & {
			float: none;
			width: 100%;
		}
	}

	&__panel-primary-image {
		@include img-responsive;
		@include center-block;
	}

	&__panel-primary-image-wrap {
		@include make-sm-column(4, 10px);
	}

	&__panel-primary-row {
		@include make-row(10px);
		padding-bottom: 30px;
	}

	&__panel-row {
		@include make-row(20px);
	}

	&__panel-secondary {
		@include make-sm-column(12, 20px);
		padding-bottom: 30px;
	}

	&__panel-title {
		margin: 0;
		padding: 0;

		a {
			@extend %button;
			color: $blue;
			// background-color: transparent;
			background-color: #fff;
			display: block;
			position: relative;
			padding: 20px 60px 20px 20px;
			border-bottom: 1px solid #fff;
			font-family: $font-family-muli;
			font-weight: $font-weight-regular;
			font-size: remUnit(18px);
			text-align: left;
			text-transform: none;
			letter-spacing: 0;
			white-space: normal;

			.icon {
				position: absolute;
				right: 30px;
				top: 50%;
				-webkit-transform: translateY(-50%) rotate(180deg);
				-ms-transform: translateY(-50%) rotate(180deg);
				transform: translateY(-50%) rotate(180deg);
			}
		}

		a.collapsed {
			background-color: $gray-light;
			border-bottom: 1px solid #b4bfc5;

			.icon {
				transform: translateY(-50%) rotate(0deg);
			}

			.#{$module}__panel--white & {
				background-color: #fff;
			}
		}
	}

	@media (min-width: $screen-md-min) {
		&__panel-primary {
			@include make-xs-column(8, 40px);
		}
		&__panel-primary--pushed {
			@include make-xs-column(6, 40px);
			@include make-xs-column-push(2);
		}

		&__panel-primary--full {
			width: 100%;
			padding-right: 3.5rem;
		}

		&__panel-row {
			@include make-row(40px);
		}

		&__panel-primary-description {
			@include make-xs-column(9, 10px);
		}

		&__panel-primary-image-wrap {
			@include make-xs-column(3, 10px);
		}

		&__panel-primary-row {
			padding-bottom: 50px;
		}

		&__panel-secondary {
			@include make-xs-column(3, 40px);
		}

		&__panel-title {
			a {
				padding-top: 25px;
				padding-bottom: 25px;
			}
		}
	}
}
