$module: step-dots;

// GFI Story Page

.#{$module} {
    display: block;
    margin: 1rem auto;

    &__inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        position: relative;
        width: calc(66.66% + 4rem);
        margin: 0 auto;

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: calc(100% - 40px);
            border-bottom: 2px solid $blue;
            top: calc(50% - 1px);
            left: 0;
            z-index: 1;
        }
    }

    &__dot {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 40px;
        height: 40px;
        line-height: 1;
        font-size: 20px;
        font-weight: bold;
        background-color: #fff;
        color: $red;
        border: 2px solid $blue;
        border-radius: 50%;
        z-index: 2;
    }
}