// ===========================================
// Panel showing programs of study containing
// 3 pricing tables where the middle column
// is larger than the other 2 columns
// Example: CRT-3.1 > Certifications Detail
// =========================================
@use "sass:math";

$module: 'programs';

.#{$module} {
	// Module Variables: contexts from CRT-3.1 DT PSD
	// COLUMNS
	$width-reg-program-px: 340px; // right and left columns
	$num-reg-programs: 2;
	$width-feat-program-px: 380px; // middle column
	$num-feat-programs: 1;
	// GUTTERS
	$width-program-gutters: 20px;
	$width-program-gutters-split: ($width-program-gutters * 0.5);
	// TOTALS
	$total-num-programs: $num-reg-programs + $num-feat-programs;
	$total-width-reg-programs: $width-reg-program-px * $num-reg-programs;
	$total-width-feat-programs: $width-feat-program-px * $num-feat-programs;
	$total-width-program-gutters: $total-num-programs * $width-program-gutters;
	$programs-total-width: $total-width-reg-programs + $total-width-feat-programs + $total-width-program-gutters;
	$column-width-reg-program: percentage(math.div($width-reg-program-px + $width-program-gutters, $programs-total-width));
	$column-width-feat-program: percentage(math.div($width-feat-program-px + $width-program-gutters, $programs-total-width));
	// Test the total for possible rounding errors. Should be 100%
	$total-row-width-programs-percent: ($column-width-reg-program * 2) + $column-width-feat-program;
	@if $total-row-width-programs-percent != 100% {
		 @warn"$total-row-width-programs-percent is #{$total-row-width-programs-percent}. It should be 100%. Check your calculations.";
	}

	&__wrap {
		width: $total-row-width-programs-percent;
		max-width: $programs-total-width;
		padding: 20px;
		text-align: center;
		margin: 0 auto;
		@include clearfix;

		&--header {
			padding-bottom: 0;
		}

		&--footer {
			padding-top: 0;
		}
	}

	&__label {
		@extend %diamond-title;
		@extend %diamond-title-variant--gray;
		margin: 0 auto 40px;
		max-width: 700px;
	}

	&__title {
		font-size: remUnit(21px);
		font-weight: $font-weight-semibold;
		text-align: center;
	}

	&__copy {
		max-width: 630px;
		margin: 0 auto 30px;
		font-size: remUnit(16px);
		text-align: center;
	}

	&__button {
		@extend %ace-btn-stroke--red;
		margin: 0 auto 30px;
	}

	&__row {
		@include make-row(0);
		position: relative;
	}
	// All 3 Columns
	&__offering {
		margin-bottom: 30px;
		position: relative;
		padding-left: 10px;
		padding-right: 10px;
		float: left;
		min-height: 1px;
		display: block;
	}

	&__row--slider {
		padding: 0 20px;
		text-align: center;
		margin: auto;
	}

	&__slide-next,
	&__slide-prev {
		@extend %ace-btn-floating--blue;
		position: absolute;
		left: 0;
		top: 50%;
		margin: 0;
		padding: 0;
		font-size: remUnit(30px);
		transform: translateY(-50%);
		z-index: 50;
		visibility: visible;

		&.slick-disabled {
			.icon {
				fill: $gray-med;
			}
		}
	}

	&__slide-next {
		left: auto;
		right: 0;
	}

	&__slider {
		text-align: center;
		margin: auto;
		@include clearfix;
	}
	// This is the point where the arrows start
	// laying over top of the left and right tables.
	@media (min-width: 667px) {
		&__row--slider {
			padding: 0 30px;
		}
		// Limit width of container in instances where bullet points run longer than one line
		&__offering {
			max-width: 400px;
		}
	}
	// Medium devices (desktops, 992px and up)
	// ==========================================================================
	@media (min-width: $screen-md-min) {
		padding-top: 50px;
		padding-bottom: 50px;
		border: none;

		&__image-wrap {
			margin-top: 50px;
		}

		&__title {
			font-size: remUnit(36px);
		}

		&__copy {
			margin-bottom: 40px;
		}

		&__row {
			width: $total-row-width-programs-percent;
			// max-width: $programs-total-width;
			text-align: center;
			margin: auto;
		}

		// All three colums
		&__offering {
			position: relative;
			float: left;
			min-height: 1px;
			padding-left: $width-program-gutters-split;
			padding-right: $width-program-gutters-split;
		}
		//  Right and Left Columns
		&__row &__offering:first-child,
		&__row &__offering:last-child {
			width: $column-width-reg-program;
			max-width: $width-reg-program-px;
		}
		// Middle Column
		&__row &__offering:nth-child(2) {
			width: $column-width-feat-program;
			max-width: $width-feat-program-px;
		}

		&__button {
			margin-top: 20px;
		}
	}
	// At this point all 3 fit.
	// Swipe is shut off in JS here also.
	// might need to be changed with more added
	@media (min-width: 1120px) {
		&__slide-next,
		&__slide-prev {
			visibility: hidden;
		}
	}

  &.#{$module}--pair {
    // Module Variables: contexts from CRT-3.1 DT PSD
    // COLUMNS
    $width-feat-program-px: 380px; // left column
    $width-reg-program-px: 340px; // right column
    $num-reg-programs: 1;
    $num-feat-programs: 1;
    // GUTTERS
    $width-program-gutters: 20px;
    $width-program-gutters-split: ($width-program-gutters * 0.5);
    // TOTALS
    $total-num-programs: $num-reg-programs + $num-feat-programs;
    $total-width-reg-programs: $width-reg-program-px * $num-reg-programs;
    $total-width-feat-programs: $width-feat-program-px * $num-feat-programs;
    $total-width-program-gutters: $total-num-programs * $width-program-gutters;
    $programs-total-width: $total-width-reg-programs + $total-width-feat-programs + $total-width-program-gutters;
    $column-width-reg-program: percentage(math.div($width-reg-program-px + $width-program-gutters, $programs-total-width));
    $column-width-feat-program: percentage(math.div($width-feat-program-px + $width-program-gutters, $programs-total-width));
    // Test the total for possible rounding errors. Should be 100%
    $total-row-width-programs-percent: $column-width-reg-program + $column-width-feat-program;
    @if $total-row-width-programs-percent != 100% {
      @warn"$total-row-width-programs-percent is #{$total-row-width-programs-percent}. It should be 100%. Check your calculations.";
    }

    .#{$module}__wrap {
      width: $total-row-width-programs-percent;
      max-width: $programs-total-width;
    }

    .#{$module}__row {
      @include make-row(0);
    }

    // Medium devices (desktops, 992px and up)
    // ==========================================================================
    @media (min-width: $screen-md-min) {
      .#{$module}__row {
        width: $total-row-width-programs-percent;

        .slick-track {
          margin-left: -($width-feat-program-px * 0.5);
        }
      }

      // Left Column
      .#{$module}__row .#{$module}__offering.#{$module}__offering--featured {
        width: $column-width-feat-program;
        max-width: $width-feat-program-px;
      }

      //  Right Column
      .#{$module}__row .#{$module}__offering {
        width: $column-width-reg-program;
        max-width: $width-reg-program-px;
        float: none;
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  // &.#{$module}--quad {
  //   // Module Variables: contexts from CRT-3.1 DT PSD
  //   // COLUMNS
  //   $width-feat-program-px: 380px; // left column
  //   $width-reg-program-px: 340px; // right column
  //   $num-reg-programs: 1;
  //   $num-feat-programs: 1;
  //   // GUTTERS
  //   $width-program-gutters: 20px;
  //   $width-program-gutters-split: ($width-program-gutters / 2);
  //   // TOTALS
  //   $total-num-programs: $num-reg-programs + $num-feat-programs;
  //   $total-width-reg-programs: $width-reg-program-px * $num-reg-programs;
  //   $total-width-feat-programs: $width-feat-program-px * $num-feat-programs;
  //   $total-width-program-gutters: $total-num-programs * $width-program-gutters;
  //   $programs-total-width: $total-width-reg-programs + $total-width-feat-programs + $total-width-program-gutters;
  //   $column-width-reg-program: percentage(($width-reg-program-px + $width-program-gutters)/ $programs-total-width);
  //   $column-width-feat-program: percentage(($width-feat-program-px + $width-program-gutters)/ $programs-total-width);
  //   // Test the total for possible rounding errors. Should be 100%
  //   $total-row-width-programs-percent: $column-width-reg-program + $column-width-feat-program;
  //   @if $total-row-width-programs-percent != 100% {
  //     @warn"$total-row-width-programs-percent is #{$total-row-width-programs-percent}. It should be 100%. Check your calculations.";
  //   }
  //
  //   .#{$module}__wrap {
  //     width: $total-row-width-programs-percent;
  //     max-width: $programs-total-width;
  //   }
  //
  //   .#{$module}__row {
  //     @include make-row(0);
  //   }
  //
  //   // Medium devices (desktops, 992px and up)
  //   // ==========================================================================
  //   @media (min-width: $screen-md-min) {
  //     .#{$module}__row {
  //       width: $total-row-width-programs-percent;
  //
  //       .slick-track {
  //         margin-left: -($width-feat-program-px / 2);
  //       }
  //     }
  //
  //     // Left Column
  //     .#{$module}__row .#{$module}__offering.#{$module}__offering--featured {
  //       width: $column-width-feat-program;
  //       max-width: $width-feat-program-px;
  //     }
  //
  //     //  Right Column
  //     .#{$module}__row .#{$module}__offering {
  //       width: $column-width-reg-program;
  //       max-width: $width-reg-program-px;
  //       float: none;
  //       display: inline-block;
  //       vertical-align: top;
  //     }
  //   }
  // }
}
