@import './sticky-cta';
$module: 'main-head';

$mobile-nav-breakpoint: 1200px;

.main-head {
	position: relative;
	z-index: 1000;
	background-color: #fff;
	box-shadow: 0px 1px 4px rgba(0,0,0,0.3);
	-webkit-transition: background-color 250ms;
	transition: background-color 250ms;
	transform: translateZ(0); // Hack "position:fixed" delay on iOS

	.header-login--mobile {
		@media (min-width: $mobile-nav-breakpoint) {
			display: none !important;
		}
	}

	.header-login--desktop {
		display: table;
		@media (max-width: #{$mobile-nav-breakpoint - 1}) {
			display: none !important;
		}
	}

	.header-login__sub-nav {
		display: none;
		position: absolute;
		width: 100%;
		margin: 0;
		padding: .5rem 1rem .5rem 0;
		top: 70px;
		left: 0;
		right: 0;
		background-color: $blue;
		text-align: right;
		text-transform: uppercase;
		font-weight: 500;
		font-size: .8rem;
		font-family: $font-family-roboto;
		letter-spacing: 1px;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);

		@media (min-width: $mobile-nav-breakpoint) {
			display: none !important;
		}

		a {
			color: #fff;
			text-align: right;
		}

		&.info-visible {
			display: block;
		}

		&-triangle-up {
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			top: -15px;
			right: 15px;
			border-style: solid;
			border-width: 0 20px 15px 20px;
			border-color: transparent transparent $blue transparent;
		}
	}

	.header-shopping-cart {
		position: relative;

		&--mobile {
			display: block;
		}

		&--desktop {
			display: none;
		}

		@media (min-width: $mobile-nav-breakpoint) {
			&--mobile {
				display: none;
			}
	
			&--desktop {
				display: block;
			}
		}
	}

	.header-shopping-cart__count {
		font-style: normal;
		color: $red;

		&--in-cart {
			position: absolute;
			top: 15px;
			right: -6px;
			width: 1.2rem;
			height: 1.2rem;
			border-radius: .6rem;
			line-height: 1.2rem;
			background-color: $red;
			color: #fff;
			text-align: center;
			font-size: 0.6rem;
		}
	}

	&.sticky {
		position: fixed;
		top: 0;
		width: 100%;
	}

	&.selected {
		.header-shopping-cart__count {
			color: #fff;

			&--in-cart {
				color: $red;
				background-color: #fff;
			}

			@media (min-width: $mobile-nav-breakpoint) {
				color: $red;
			}
		}

		@media (max-width: #{$mobile-nav-breakpoint - 1}) {
			position: fixed;
			top: 0 !important;
			left: 0;
			z-index: 2000;
			width: 100%;
			height: 100%;
			overflow: auto;
			background-color: $red;
	
			.main-head__brand a {
				background: url($images-path + 'logo-ace.svg') no-repeat 0 50%;
			}
	
			.main-head__tools li a {
				color: #fff;
	
				&:hover,
				&:active,
				&.active,
				&:focus,
				&.focus,
				&.selected {
					border-bottom-color: #fff;
				}
			}
	
			.main-head__search-form {
				display: block;
			}
		}
	}
}

.main-head__nav {
	display: none;
	width: calc(100% + 40px);
	margin-right: -20px;
	margin-left: -20px;

	& > ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	& > ul li {
		border-top: 1px solid #ec6e74;

		&:last-child { border-bottom: 1px solid #ec6e74; }

		@media (min-width: $mobile-nav-breakpoint) and (max-width: 1450px) {
			padding-left: 12px;
			padding-right: 12px;
		}

		@media (min-width: 1451px) {
			padding-right: 16px;
			padding-left: 16px;
		}
	}

	& > ul li a {
		display: block;
		position: relative;
		padding: 15px 40px 15px 20px;
		color: #fff;
		font-family: $font-family-roboto;
		font-size: remUnit(14px);
		font-weight: $font-weight-medium;
		text-transform: uppercase;
		text-decoration: none;
		letter-spacing: 1px;

		&:hover,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: $blue;
		}

		.icon {
			position: absolute;
			right: 20px;
			width: 7px;
			height: 21.5px;
		}
	}

	& > ul li a br {
		display: none;

		@media (min-width: $mobile-nav-breakpoint) and (max-width: 1399px) {
			display: block;
		}
	}

	@media (min-width: $mobile-nav-breakpoint) {
		display: flex !important;
		align-items: stretch;
		width: auto;
		margin-right: auto;
		margin-left: 0;

		& > ul {
			display: flex;
			align-items: stretch;
		}

		& > ul li {
			display: flex;
			align-items: stretch;
			border: none !important;
		}

		& > ul li a {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
			color: $blue;
			line-height: 1.2;

			&:hover,
			&:active,
			&.active,
			&:focus,
			&.focus {
				background: transparent;
				border-bottom-color: $red;
			}
			.icon {
				display: none;
			}

			&::after { display: none; }

			&.expanded::after {
				display: block;
				position: absolute;
				top: auto;
				right: auto;
				bottom: -4px;
				left: 50%;
				content: '';
				-webkit-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
				border-right: 13px solid transparent;
				border-bottom: 13px solid $red;
				border-left: 13px solid transparent;
			}
		}

		& > ul li a span {
			display: block;
			text-align: center;
		}
	}
}

.main-head__nav-reveal {
	display: block;
	margin: 0 20px 0 -5px;
	padding: 0 0 0 0;
	background: transparent;
	border: none;
	box-shadow: none;
	color: $blue;
	line-height: 70px;

	.icon {
		height: 1.5em;
		width: 1.5em;
		display: inline-block;
		line-height: 1;
		fill: currentColor;
		vertical-align: middle;
	}

	.nav-active & .icon--menu {
		display: none;
	}

	& .icon--close {
		display: none;
	}

	.nav-active & .icon--close {
		display: inline-block;
		fill: #fff;
	}

	@media (min-width: $mobile-nav-breakpoint) {
		display: none;
	}
}

.main-head__wrap {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	max-width: 1400px;
	margin: 0 auto;
	padding-right: 20px;
	padding-left: 20px;

	@media (min-width: $mobile-nav-breakpoint) {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		flex-wrap: nowrap;
		padding-left: floor(($grid-gutter-width * 0.5));
		padding-right: ceil(($grid-gutter-width * 0.5));
	}
}

.main-head__brand {
	margin-left: 0;
	margin-right: auto;

	a {
		display: block;
		width: 80px;
		height: 70px;
		background: url($images-path + 'logo-ace-red.svg') no-repeat 0 50%;
		background-size: 80px auto;
	}

	@media (min-width: $mobile-nav-breakpoint) {
		margin-right: 0;
		padding-right: 16px;

		a {
			width: 100px;
			height: 70px;
			background-size: 100px 70px;
		}
	}
}

.main-head__tools {
	position: relative;
	margin-right: -5px;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: stretch;
	}

	li {
		padding-right: 5px;
		padding-left: 5px;
	}

	li a {
		display: block;
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		color: $blue;
		font-family: $font-family-roboto;
		font-size: remUnit(10px);
		font-weight: $font-weight-medium;
		line-height: 62px;
		text-transform: uppercase;
		text-decoration: none;
		letter-spacing: 1px;

		&:hover,
		&:active,
		&.active,
		&:focus,
		&.focus,
		&.selected {
			border-bottom-color: $red;
		}
	}

	li.header-login {
		display: table;

		a {
			display: table-cell;
		}

		.header-login__top a:first-child {
			max-width: 90px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	li a .icon {
		vertical-align: middle;
		font-size: remUnit(20px);

		&--cart {
			font-size: remUnit(29.6px);
		}

		&--profile {
			font-size: remUnit(29.6px);
		}
	}

	@media (min-width: $mobile-nav-breakpoint) {
		margin-right: 0;

		li {
			padding-right: 10px;
			padding-left: 10px;
		}
	}
}

.main-head__search {
	&-form {
		width: 100%;
		clear: both;
		display: none;
		margin-bottom: 20px;
		background-color: #c3343c;
		@include clearfix();
	}
	
	&-label {
		@extend .sr-only;
	}
	
	&-input {
		@extend .form-control;
		background-color: transparent;
		border: none;
		color: #fff;
		@include placeholder(#fff);
	}
	
	&-input-wrap {
		@include make-xs-column(9, 0);
	}
	
	&-row {
		@include make-row(0);
	}
	
	&-submit {
		@extend %button;
		padding-top: 6px;
		padding-bottom: 6px;
		background-color: #c3343c;
		color: #fff;
		border-color: transparent;
	
		.icon {
			fill: #fff;
		}
	
		@include ace-btn-hover {
			text-decoration: none;
			outline: 0;
			border-color: transparent;
			text-decoration: none;
	
			.icon {
				fill: #fff;
			}
		}
	}
	
	&-submit-wrap {
		@include make-xs-column(3, 0);
	}
}

.main-head__slide-search {
	@include clearfix();
	display: block;
	position: absolute;
	overflow: hidden;
	right: 0;
	top: 0;
	clear: both;
	visibility: hidden;
	width: 0;
	opacity: 0;
	background-color: #fff;
	border-top: 4px solid #fff;
	border-bottom: 4px solid $red;
	transition: width .15s linear, opacity .15s linear, visibility .15s linear;
	
	&.expanded {
		width: 350px;
		visibility: visible;
		opacity: 1;
	}
	
	&-reveal {
		display: none;
		
		@media (min-width: $mobile-nav-breakpoint) {
			display: block;
		}
	}

	&-label {
		@extend .sr-only;
	}
	
	&-input {
		@extend .form-control;
		height: 62px;
		padding: 0;
		background-color: transparent;
		border: none;
		@include placeholder($gray);
	}
	
	&-input-wrap {
		@include make-xs-column(9, 0);
	}
	
	&-row {
		@include make-row(0);
	}
	
	&-submit {
		@extend %button;
		height: 62px;
		padding-top: 0;
		padding-right: 10px;
		padding-bottom: 0;
		background-color: #fff;
		color: $red;
		border: none;
	
		.icon {
			font-size: remUnit(20px);
		}
	}
	
	&-submit-wrap {
		@include make-xs-column(3, 0);
	}
}

// FLEX ORDER

.main-head {
	&__nav-reveal {
		order: 1;
	}

	&__brand {
		order: 2;
	}

	&__tools {
		order: 3;
	}

	&__search-form {
		order: 4;
	}

	&__nav {
		order: 5;
	}

	@media (min-width: $mobile-nav-breakpoint) {
		&__brand {
			order: 1;
		}

		&__nav {
			order: 2;
		}

		&__tools {
			order: 3;
		}

		&__search-form {
			order: 4;
		}
	}
}


// TALK TO ADVISOR BUTTON

#info-request-desktop-wrap {
	display: flex;
	align-self: stretch;
	align-items: stretch;
	padding: 0;

	.info-request-desktop {
		display: none;
		flex-direction: row;
		align-items: stretch;

		@media (min-width: $mobile-nav-breakpoint) {
			display: flex;
		}

		br {
			display: none;

			@media (max-width: 1399px) {
				display: block;
			}
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: .9rem;
			padding: 1rem;
			border-color: transparent;
			flex-direction: column;
			line-height: 1.2;
			font-weight: 700;

			@media (max-width: 1399px) {
				padding: .5rem;
			}

			&:hover,
			&:focus {
				border-color: transparent;
			}
		}
	}
}

#info-request-mobile-wrap {
	display: none;
	order: 6;
	width: 100%;

	.selected & {
		@media (max-width: #{$mobile-nav-breakpoint - 1}) {
			display: block;
		}
	}

	.info-request-mobile {
		display: block;
		width: 100%;
		padding: 1rem;
	}
}


// CONED MENU

$cePathways: coned-pathways-list;

#education-pane.ce-2023 {
	.nav-pane__menu-group {
		width: 100%;
	}
	.nav-pane__sub-menu {
		width: 25%;

		@media (max-width: 991px) {
			width: calc(50% - 1rem);
			margin-right: 1rem;
		}

		@media (max-width: 500px) {
			width: 100%;
			margin: 0;
		}

		&:first-child {
			width: calc(50% - 1rem);
			margin-right: 1rem;

			@media (max-width: 991px) {
				width: 100%;
				margin: 0 0 1rem;
			}
		}

		.#{$cePathways} {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: stretch;
		
			@media (max-width: 500px) {
				flex-wrap: wrap;
			}
			
			&__item {
				display: flex;
				flex-direction: row;
				align-items: stretch;
				width: calc(33.33% - .5rem);
		
				@media (max-width: 500px) {
					width: calc(50% - .25rem);
					margin-bottom: .5rem;
		
					&:first-child {
						width: 100%;

						br {
							display: none;
						}
					}
				}
		
				@media (max-width: 414px) {
					width: 100%;
					margin-bottom: 1rem;

					&:first-child {
						margin-bottom: 1rem;
					}
				}
		
				&--power-pass {
					&:hover {
						.#{$cePathways}__item-icon-wrap {
							background-color: #6A5086;
						}
					}

					@media (max-width: 991px) {
						.#{$cePathways}__item-icon-wrap {
							background-color: $purple;
						}
					}
				}
		
				&--specialist-programs {
					&:hover {
						.#{$cePathways}__item-icon-wrap {
							background-color: #F2B645;
						}
					}

					@media (max-width: 991px) {
						.#{$cePathways}__item-icon-wrap {
							background-color: $yellow;
						}
					}
				}
		
				&--course-bundles {
					&:hover {
						.#{$cePathways}__item-icon-wrap {
							background-color: #5282DE;
						}
					}

					@media (max-width: 991px) {
						.#{$cePathways}__item-icon-wrap {
							background-color: $blue-med;
						}
					}
				}
			}
		
			&__item-link {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				text-decoration: none;
				width: 100%;
		
				&:link,
				&:visited,
				&:hover,
				&:active {
					text-decoration: none;
				}
			}
		
			&__item-icon-wrap {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				padding: 1rem;
				background-color: lighten($blue, 10%);
				transition: background-color .2s;

				@media (max-width: 500px) {
					padding: 1rem;
				}
			}
		
			&__item-icon {
				display: block;
				width: 100px;
				max-width: 90%;
				margin: 0 auto;
			}
		
			&__item-body {
				flex: 1;
				width: 100%;
				padding: 1rem;
				background-color: $blue;
			}
		
			&__item-title {
				display: block;
				margin-bottom: .35rem;
				color: #fff;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.1;
				letter-spacing: 0.0175rem;
				text-align: left;
				text-decoration: none;

				br {
					@media (max-width: 414px) {
						display: none;
					}
				}
			}
		
			&__item-description {
				margin-bottom: 0;
				color: #fff;
				font-size: 0.8rem;
				font-weight: 300;
				line-height: 1.2;
				text-align: left;
				text-decoration: none;
			}
		}
	}
	
	.nav-pane__menu-list-row {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	
		@media (max-width: 414px) {
			flex-direction: column;
		}
	
		.nav-pane__menu-list {
			width: calc(50% - 1rem);
			margin-right: 1rem;
	
			@media (max-width: 414px) {
				width: 100%;
				margin: 0 0 .5rem;
			}
		}
	}
}
