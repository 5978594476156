// ===========================================
// Panel showing programs of study containing
// 3 pricing tables where the middle column
// is larger than the other 2 columns
// Example: CRT-3.1 > Certifications Detail
// =========================================
@use "sass:math";

$module: 'program-detail';

.#{$module} {
	// Module Variables: contexts from CRT-3.1 DT PSD
	// COLUMNS
	$width-reg-program-px: 420px; // right and left columns
	$num-reg-programs: 1;
	$width-feat-program-px: 420px; // middle column
	$num-feat-programs: 1;
	// GUTTERS
	$width-program-gutters: 30px;
	$width-program-gutters-split: ($width-program-gutters * 0.5);
	// TOTALS
	$total-num-programs: $num-reg-programs + $num-feat-programs;
	$total-width-reg-programs: $width-reg-program-px * $num-reg-programs;
	$total-width-feat-programs: $width-feat-program-px * $num-feat-programs;
	$total-width-program-gutters: $total-num-programs * $width-program-gutters;
	$programs-total-width: $total-width-reg-programs + $total-width-feat-programs + $total-width-program-gutters;
	$column-width-reg-program: percentage(math.div($width-reg-program-px + $width-program-gutters, $programs-total-width));
	$column-width-feat-program: percentage(math.div($width-feat-program-px + $width-program-gutters, $programs-total-width));
	// Test the total for possible rounding errors. Should be 100%
	$total-row-width-programs-percent: ($column-width-reg-program * 2) + $column-width-feat-program;

	padding-top: 30px;
	padding-bottom: 30px;

	&__points {
		padding: 10px 0;
		font-family: $font-family-roboto;
		font-size: remUnit(14px);
		line-height: 1;
		text-align: center;
		text-transform: uppercase;

		span {
			display: block;
			width: 86px;
			margin: 0 auto 5px;
			background-color: $red;
			border-radius: 50%;
			color: #fff;
			font-family: $font-family-muli;
			font-size: remUnit(36px);
			line-height: 86px;
			font-weight: $font-weight-semibold;
		}
	}

	&__header-meta-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		width: 100%;
		max-width: 300px;
		margin: 0 auto;
	}

	&__wrap {
		@extend .container-fluid;
		position: relative;
		max-width: 1400px;
		padding-right: 20px;
		padding-left: 20px;
	}

	&__panel-wrap {
		@extend .container-fluid;
		max-width: $programs-total-width;
		padding: 20px;
		text-align: center;
	}

	&__label {
		@extend %diamond-title;
		@extend %diamond-title-variant--gray;
		margin: 0 auto 40px;
		max-width: 700px;
	}

	&__title {
		font-size: remUnit(21px);
		font-weight: $font-weight-semibold;
		text-align: center;
	}

	&__copy {
		max-width: 630px;
		margin: 0 auto 30px;
		font-size: remUnit(16px);
		text-align: center;
	}

	&__button {
		@extend %ace-btn-stroke--red;
		margin: 0 auto 30px;
	}

	&__note {
		font-size: remUnit(13px);
	}

	&__row {
		@include make-row(0);
	}
	// All 3 Columns
	&__offering {
		margin-bottom: 30px;

		&.#{$module}__offering--single-offering {
			float: none;
			margin-left: auto;
			margin-right: auto;
		}

		.affirm-as-low-as {
			margin-bottom: 0;
			color: $blue-med;
			font-weight: 600;
			font-size: 1.1rem;
		}

		.affirm-modal-trigger {
			color: $blue-med;
			text-decoration: underline;
		}
	}

	&__row &__offering:first-child,
	&__row &__offering:last-child {
		width: 100%;
	}

	&__row &__offering:nth-child(2) {
		width: 100%;
	}
	// ==========================================================================
	// XSmall devices (phone, 480px and up)
	// ==========================================================================
	// @media (min-width: $screen-xs-min) {
	//   .#{$module} {}
	// } // end: XSmall devices (phone, 480px and up) ==============================
	// ==========================================================================
	// Small devices (tablets, 768px and up)
	// ==========================================================================
	// @media (min-width: $screen-sm-min) {
	// 	.#{$module} {}
	// } // end: Small devices (tablets, 768px and up) ==========================
	// ==========================================================================
	// Medium devices (desktops, 992px and up)
	// ==========================================================================
	@media (min-width: $screen-md-min) {
		padding-top: 50px;
		padding-bottom: 75px;
		border: none;

		&__image-wrap {
			margin-top: 50px;
		}

		&__title {
			font-size: remUnit(36px);
		}

		&__copy {
			margin-bottom: 40px;
		}
		// All three colums
		&__offering {
			position: relative;
			float: left;
			width: 100%;
			min-height: 1px;
			padding-left: $width-program-gutters-split;
			padding-right: $width-program-gutters-split;
		}
		//  Right and Left Columns
		&__row &__offering:first-child,
		&__row &__offering:last-child {
			width: $column-width-reg-program;
		}
		// Middle Column
		&__row &__offering:nth-child(2) {
			width: $column-width-feat-program;
		}

		&__button {
			margin-top: 20px;
		}
	}

	@media (min-width: 1050px) {
		&__header-meta-wrap {
			width: auto;
			flex-direction: column;
			position: absolute;
			top: 0;
			left: 10px;
			padding: 0;
		}
	}
}


.ethics-course-badge {
	&-wrap {
		text-align: center;

		@media (min-width: 1050px) {
			margin-top: 2rem;
		}
	}

	&-icon {
		display: block;
		width: 24px;
		margin: 0 auto .25rem;
	}

	&-text {
		display: block;
		font-size: 1.3rem;
		font-weight: 800;
		line-height: 1.2;
		color: $green;
	}
}
