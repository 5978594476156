$module: 'slat-card';

.#{$module} {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	padding: 6px 0 8px 0;
	background-color: #fff;

	@media (min-width: $screen-md-min) {
		height: 155px;
		margin-bottom: 20px;
	}

	&:hover, &:focus {
		outline: none;

		a.#{$module}__link {
			outline-color: transparent;
		}

		.#{$module}__title {
			color: $red;
		}
	}

	&__thumbnail,
	&__header {
		display: block;
	}

	&__link {
		display: flex;
		position: relative;
		width: 100%;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: flex-start;
		text-decoration: none;

		*,
		*:hover,
		&:hover {
			text-decoration: none;
		}
	}

	&__link-text {
		@extend %ace-btn-floating--red;
		margin-top: 6px;

		@media (min-width: $screen-md-min) {
			margin-top: 12px;
		}
	}

	&__thumbnail {
		display: block;
		position: relative;
		width: 120px;

		@media (min-width: $screen-md-min) {
			width: 170px;
		}
	}

	&__header {
		position: relative;
		width: calc(100% - 120px);

		@media (min-width: $screen-md-min) {
			width: calc(100% - 170px);
		}
	}

	&__header-content {
		padding-left: 10px;

		@media (min-width: $screen-sm-min) {
			padding: 0 10px;
		}
	}

	&__icons {
		margin-bottom: .5rem;
	}

	&__icon {
		margin-bottom: .1rem;

		@media (max-width: 350px) {
			margin-bottom: .5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__image {
		position: relative;
		width: 100%; // raw = 300px
		padding-top: 83.33%; // 250 / 300 = 83.33%
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		overflow: hidden;
	}

	&__cec-info {
		position: absolute;
		right: 4px;
		bottom: 30px;
		width: 40px;
		height: 40px;
		transform: scale(0.75);
		color: #fff;

		@media (min-width: $screen-md-min) {
			transform: none;
		}

		.cec-info__credits {
			font-size: 1.1rem;
		}

		.cec-info__label {
			font-size: .6rem;
		}
	}

	&__cec-club-flag,
	&__ethics-course-badge,
	&__bundle-course-badge {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-weight: bold;
		font-size: 1rem;
		white-space: nowrap;

		@media (max-width: 350px) {
			white-space: normal;
			line-height: 1;
		}

		img {
			display: block;
			width: 15px;
			margin-right: .25rem;
		}
	}

	&__cec-club-flag {
		color: $purple;

		img {
			width: 15px;
		}
	}

	&__ethics-course-badge {
		color: $green;

		img {
			width: 15px;
		}
	}

	&__bundle-course-badge {
		color: $blue-med;

		img {
			width: 22px;
		}
	}

	&__rating {
		width: 100%;
		font-size: remUnit(14px);
		line-height: 1;
		margin-top: 7px;
		top: 0;
		text-align: center;

		@media (min-width: $screen-md-min) {
		}
	}

	&__title {
		font-weight: $font-weight-semibold;
		color: $blue;
		margin-bottom: 0;
		transition-property: color;
		transition-duration: $site-transition-duration;
		$lines-to-show: 3;
		$font-size: 16px;
		$line-height: lh($font-size,18px);
		$use-max-height: true;
		@include text-overflow( $lines-to-show, $font-size, $line-height, $use-max-height);

		@media (min-width: $screen-md-min) {
			$lines-to-show: 3;
			$font-size: 20px;
			$line-height: lh($font-size,24px);
			$use-max-height: true;
			@include text-overflow( $lines-to-show, $font-size, $line-height, true);
		}
	}

	&__meta {
		font-size: remUnit(11px);
		color: $gray;
		font-weight: $font-weight-regular;
		display: block;
	}
}
