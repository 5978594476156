$module: 'hero-message';

.#{$module} {
	padding: 50px 20px;
	background-position: 50% 50%;
	background-size: cover;
	text-align: center;

	&__btn {
		@extend %ace-btn-floating--white;
		font-size: 12px;
	}

	&__segment-list {
		margin: 30px 0;
		padding: 0;
		list-style: none;
		text-align: center;

		li {
			margin-bottom: 20px;
			padding: 0 20px;
		}

		li:last-child {
			margin-bottom: 0;
		}

		li strong {
			display: block;
			color: $red;
			font-size: remUnit(18px);
			font-weight: $font-weight-semibold;
		}
	}

	&__label {
		@include sub-section-label-styles();
		color: #fff;
	}

	&__title {
		@include sub-section-title-styles();
		color: #fff;
		line-height: 1.2;

		&--compact {
			max-width: 850px
		}
		&--semi-wide {
			max-width: 1080px;
		}
		&--wide {
			max-width: 1220px;
		}
		&--big-text {
			@media (min-width: $screen-md-min) {
				font-size: remUnit(54px);
			}
		}
	}


	&__content {
		max-width: 610px;
		@extend %margin-auto;
	}

	&__wrap {
		@extend .container-fluid;
		padding-right: 20px;
		padding-left: 20px;
	}

	&--gray {
		background-color: $gray-light;

		.#{$module} {
			&__btn {
				@extend %ace-btn-floating--blue-red;
				font-size: 12px;
			}
			&__title { color: $blue; }
			&__content p { color: $gray; }
		}
	}

	&--safety {
		padding: 40px 0;

		.#{$module} {
			&__title {
				margin-bottom: 10px;
				font-size: remUnit(18px);
			}
			&__content p { font-size: remUnit(13px); }
		}
	}

	&--white {
		background-color: #fff;
		.#{$module} {
			&__btn {
				@extend %ace-btn-floating--blue-red;
				font-size: 12px;
			}
			&__title { color: $blue; }
			&__content p { color: $gray; }
		}
	}

	&--scrollto {
		.#{$module} {
			&__btn {
				color: $red;
				.icon {
					transform: rotate(180deg);
					margin-bottom: 8px;
				}
				&:focus,
				&:active,
				&:hover {
					text-decoration: none;
					outline: none;
					color: $red;
				}
			}
		}
	}

	&--idea-world-scholarship {
		padding-top: 2rem;
		padding-bottom: 2rem;

		.hero-message__label {
			color: $blue;
			max-width: 1300px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@media (min-width: $screen-md-min) {
		padding-top: 140px;
		padding-bottom: 140px;

		&__btn {
			font-size: remUnit(14px);
		}
		&__title {
			line-height: lh(36px, 48px);
		}
		&__segment-list {
			margin: 40px 0;

			li {
				display: inline-block;
				margin: 0;
				padding: 0 40px;
				border-right: 1px solid #b4bfc5;
			}

			li:last-child {
				border: none;
			}

			li strong {
				font-size: remUnit(24px);
			}

			.#{$module}__btn {
				font-size: remUnit(14px);
			}
		}

		&--safety {
			padding-top: 40px;
			padding-bottom: 40px;

			.#{$module} {
				&__title {
					font-size: remUnit(24px);
				}
			}
		}

		&--idea-world-scholarship {
			padding-top: 3rem;
			padding-bottom: 3rem;
		}

	}
}
