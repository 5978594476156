.post-full.post-full--article {
  // includes/css/responsive-base.css
  .Width5pct   { width:5%   }
  .Width10pct  { width:10%  }
  .Width15pct  { width:15%  }
  .Width20pct  { width:20%  }
  .Width25pct  { width:25%  }
  .Width30pct  { width:30%  }
  .Width35pct  { width:35%  }
  .Width40pct  { width:40%  }
  .Width45pct  { width:45%  }
  .Width50pct  { width:50%  }
  .Width55pct  { width:55%  }
  .Width60pct  { width:60%  }
  .Width65pct  { width:65%  }
  .Width70pct  { width:70%  }
  .Width75pct  { width:75%  }
  .Width80pct  { width:80%  }
  .Width85pct  { width:85%  }
  .Width90pct  { width:90%  }
  .Width95pct  { width:95%  }
  .Width100pct { width:100% }

  // includes/css/responsive-blogs.css
  img { max-width:100% !important; height:auto !important; }
}
