/*
  POSITION - x axis first, y second
*/

// Centered x
.bg-pos-xc-yc { background-position: center center !important; }
.bg-pos-xc-yt { background-position: center top !important; }
.bg-pos-xc-yb { background-position: center bottom !important; }

// Left x
.bg-pos-xl-yc { background-position: left center !important; }
.bg-pos-xl-yt { background-position: left top !important; }
.bg-pos-xl-yb { background-position: left bottom !important; }

// Right x
.bg-pos-xr-yc { background-position: right center !important; }
.bg-pos-xr-yt { background-position: right top !important; }
.bg-pos-xr-yb { background-position: right bottom !important; }

// Percentages
.bg-pos-xc-y33  { background-position: center 33% !important; }
.bg-pos-x33-yc  { background-position: 33% center !important; }
.bg-pos-x33-yt  { background-position: 33% top !important; }
.bg-pos-x33-y33 { background-position: 33% 33% !important; }

.bg-pos-xc-y66  { background-position: center 66% !important; }
.bg-pos-x66-yc  { background-position: 66% center !important; }
.bg-pos-x66-yt  { background-position: 66% top !important; }
.bg-pos-x66-y66 { background-position: 66% 66% !important; }

.bg-pos-xc-y75  { background-position: center 75% !important; }
.bg-pos-x75-yc  { background-position: 75% center !important; }
.bg-pos-x75-yt  { background-position: 75% top !important; }
.bg-pos-x75-y75 { background-position: 75% 75% !important; }



/*
  SIZE
  - x (width) axis first, y (height) second
  - when single unit (px, em, %) is used, value only applies to first (x) and second value (y) is auto
*/

.bg-size-50         { background-size: 50% 50% !important; }
.bg-size-100        { background-size: 100% 100% !important; }
.bg-size-x100-yauto { background-size: 100% auto !important; }
.bg-size-xauto-y100 { background-size: auto 100% !important; }
.bg-size-auto       { background-size: auto auto !important; }
.bg-size-cover      { background-size: cover !important; }
.bg-size-contain    { background-size: contain !important; }
.bg-size-cover      { background-size: cover !important; }


/*
  REPEAT
*/

.bg-rpt-xy { background-repeat: repeat !important; }
.bg-rpt-x  { background-repeat: repeat-x !important; }
.bg-rpt-y  { background-repeat: repeat-y !important; }
.bg-rpt-no { background-repeat: no-repeat !important; }


/*
  ATTACHMENT
*/

.bg-attach-scroll { background-attachment: scroll !important; }
.bg-attach-fixed  { background-attachment: fixed !important; }
.bg-attach-local  { background-attachment: local !important; }

/*
  HIDE AT CERTAIN VIEWPORTS
*/

.bg-hide {
  &-xs {
    @media (min-width: 0px) and (max-width: $screen-sm-min - 1) {
      background-size: 0 0 !important;
      background-position: -9999em -9999em !important;
      background-image: none !important;
    }
  }
  &-sm {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
      background-size: 0 0 !important;
      background-position: -9999em -9999em !important;
      background-image: none !important;
    }
  }
  &-md {
    @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
      background-size: 0 0 !important;
      background-position: -9999em -9999em !important;
      background-image: none !important;
    }
  }
  &-lg {
    @media (min-width: $screen-lg-min) {
      background-size: 0 0 !important;
      background-position: -9999em -9999em !important;
      background-image: none !important;
    }
  }
}

/*
  OVERLAY
*/

.bg-has-overlay {
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }

  &-xs {
    &::before {
      display: none !important;
      @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
        display: block !important;
      }
    }

    &-up {
      &::before {
        display: none !important;
        @media (min-width: $screen-xs-min){
          display: block !important;
        }
      }
    }

    &-down {
      &::before {
        display: none !important;
        @media (max-width: $screen-sm-min - 1){
          display: block !important;
        }
      }
    }
  } // xs

  &-sm {
    &::before {
      display: none !important;
      @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        display: block !important;
      }
    }

    &-up {
      &::before {
        display: none !important;
        @media (min-width: $screen-sm-min){
          display: block !important;
        }
      }
    }

    &-down {
      &::before {
        display: none !important;
        @media (max-width: $screen-md-min - 1){
          display: block !important;
        }
      }
    }
  } // sm

  &-md {
    &::before {
      display: none !important;
      @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
        display: block !important;
      }
    }

    &-up {
      &::before {
        display: none !important;
        @media (min-width: $screen-md-min){
          display: block !important;
        }
      }
    }

    &-down {
      &::before {
        display: none !important;
        @media (max-width: $screen-lg-min - 1){
          display: block !important;
        }
      }
    }
  } // md

  &-lg {
    &::before {
      display: none !important;
      @media (min-width: $screen-lg-min) {
        display: block !important;
      }
    } // lg
  }

  &--05::before  { background-color: rgba(0,0,0,.05); }
  &--10::before  { background-color: rgba(0,0,0,.1 ); }
  &--15::before  { background-color: rgba(0,0,0,.15); }
  &--20::before  { background-color: rgba(0,0,0,.2 ); }
  &--25::before  { background-color: rgba(0,0,0,.25); }
  &--30::before  { background-color: rgba(0,0,0,.3 ); }
  &--35::before  { background-color: rgba(0,0,0,.35); }
  &--40::before  { background-color: rgba(0,0,0,.4 ); }
  &--45::before  { background-color: rgba(0,0,0,.45); }
  &--50::before  { background-color: rgba(0,0,0,.5 ); }
  &--55::before  { background-color: rgba(0,0,0,.55); }
  &--60::before  { background-color: rgba(0,0,0,.6 ); }
  &--65::before  { background-color: rgba(0,0,0,.65); }
  &--70::before  { background-color: rgba(0,0,0,.7 ); }
  &--75::before  { background-color: rgba(0,0,0,.75); }
  &--80::before  { background-color: rgba(0,0,0,.8 ); }
  &--85::before  { background-color: rgba(0,0,0,.85); }
  &--90::before  { background-color: rgba(0,0,0,.9 ); }
  &--95::before  { background-color: rgba(0,0,0,.95); }
  &--100::before { background-color: rgba(0,0,0,1  ); }

  /*
  SAMPLE USAGE:
    <div class="outer bg-has-overlay bg-has-overlay-xs bg-has-overlay--05"> // SHOWS light overlay on xs screens
      <div class="inner">Content</div>
    </div>

    <div class="outer bg-has-overlay bg-has-overlay-xs bg-has-overlay--05"> // SHOWS light overlay on xs screens
      <div class="inner">Content</div>
    </div>
  */
}

/*
  BACKGROUND COLOR
*/

.bg-color {
  &-blue {
    background-color: $blue !important;
  }
  &-blue-light {
    background-color: $blue-light !important;
  }
  &-blue-med {
    background-color: $blue-med !important;
  }
  &-gray {
    background-color: $gray !important;
  }
  &-gray-med {
    background-color: $gray-med !important;
  }
  &-gray-light {
    background-color: $gray-light !important;
  }
  &-green {
    background-color: $green !important;
  }
  &-magenta {
    background-color: $magenta !important;
  }
  &-purple {
    background-color: $purple !important;
  }
  &-red {
    background-color: $red !important;
  }
  &-red-dark {
    background-color: $red-dark !important;
  }
  &-red-x-dark {
    background-color: $red-x-dark !important;
  }
  &-yellow {
    background-color: $yellow !important;
  }
  &-yellow-light {
    background-color: $yellow-light !important;
  }
  &-white {
    background-color: #fff !important;
  }
}
