$module: 'social-tools';

.social-tools {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		margin-right: 10px;

		&:last-child {
			margin: 0;
		}
	}

	li a {
		display: block;
		width: 24px;
		height: 24px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__facebook a {
		background-image: url(/images/webcontent/assets/global/icons/social/facebook.png);
	}

	&__instagram a {
		background-image: url(/images/webcontent/assets/global/icons/social/instagram.png);
	}

	&__linkedin a {
		background-image: url(/images/webcontent/assets/global/icons/social/linkedin.png);
	}

	&__youtube a {
		background-image: url(/images/webcontent/assets/global/icons/social/youtube.png);
	}
}
