$module: 'specialist-hero';

.#{$module} {
	height: 530px;
	position: relative;
  margin-bottom: 40px;
  padding-top: 50%;
	background-size: cover;
	background-position: top center;

  &--has-inline-image {
    height: auto !important;
    padding-top: 0;
    background-position: -9999em -9999em;
    background-repeat: no-repeat;
    background-size: 0 0;

    @media (min-width: $screen-sm-min) {
      padding-top: 50%;
      background-position: top center;
      background-size: cover;
    }

    @media (min-width: $screen-md-min) {
      height: 47.14286vw !important;
    }

    .#{$module}__overlay {
      position: relative;

      @media (min-width: $screen-sm-min) {
        position: absolute;
      }
    }
  }

  &__inline-image {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;

    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }

	&__overlay {
		width: 100%;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		border-top: 1px solid rgba(#415c6d, 0.8);
		padding: 40px 20px 20px 20px;
		text-align: center;
		background-color: rgba($blue, 1);
		@include clearfix();

		@media (min-width: $screen-sm-min) {
			background-color: rgba($blue, 0.8);
		}
	}

	&__content-wrap {
		position: relative;
		max-width: 1400px;
		margin-right: auto;
		margin-left: auto;
	}

	&__label-wrap {
		width: 100%;
		position: absolute;
		text-align: center;
		top: -15px;
		right: 0;
		left: 0;

    &--bottom {
      top: initial;
      bottom: -15px;
    }
	}

	&__label {
		@include make-diamond($red, #fff);
		position: relative;
		margin: 0 auto;
	}

	&__info,
	&__title,
	&__title--subtitle {
		color: #fff;
	}

	&__title {
		font-size: remUnit(24px);
		margin-bottom: 20px;
	}

	&__title--subtitle {
		font-size: remUnit(18px);
		margin-bottom: 20px;
	}

	&__button {
		@extend %button;
		color: $red;
		@include ace-btn-hover {
			color: $red;
			text-decoration: none;
		}
	}

	&__points {
		display: inline-block;
		margin: 0 10px 10px 10px;
		color: #fff;
		font-family: $font-family-roboto;
		font-size: remUnit(10px);
		line-height: 1;
		text-align: center;
		text-transform: uppercase;
		vertical-align: top;

		span {
			display: block;
			width: 62px;
			margin: 0 0 5px 0;
			background-color: $red;
			border-radius: 50%;
			font-family: $font-family-muli;
			font-size: remUnit(25px);
			line-height: 62px;
			font-weight: $font-weight-semibold;
		}
	}

	&__sale {
		display: inline-block;
		width: 62px;
		margin: 0 10px 10px 10px;
		background-color: $green;
		border-radius: 50%;
		color: #fff;
		font-family: $font-family-roboto;
		font-size: remUnit(14px);
		line-height: 62px;
		font-weight: $font-weight-regular;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 1px;
		vertical-align: top;
	}

	&--detail {
		height: 633px;

		.#{$module}__overlay {
			padding: 40px 20px 0px 20px;
		}

		.#{$module}__description,
		.#{$module}__info {
			max-width: 800px;
			margin-right: auto;
			margin-left: auto;
		}

		.#{$module}__title, .#{$module}__title--subtitle {
			margin-bottom: 20px;
		}
	}

	&__badge-wrap {
		.hero-panel-variant--crt-detail-pt & {
			position: absolute;
			top: auto;
			bottom: 0;

			@media (min-width: $screen-sm-min) {
				bottom: 0;
			}
		}
	}


	@media (min-width: $screen-md-min) {
		$hero-image-width: 1400px;
		$hero-image-height: 660px;
		height: vw($hero-image-height, $hero-image-width);
		max-height: $hero-image-height;
		padding-top: 0;

		&__overlay,
		&--detail &__overlay {
			padding: 50px 30px 30px;
		}

		&__title {
			font-size: remUnit(54px);
			margin-bottom: 50px;
		}

		&__title--subtitle {
			font-size: remUnit(28px);
			margin-bottom: 50px;
		}

		&__points,
		&__sale {
			display: block;
			margin: 10px;
		}

		&__points {
			font-size: remUnit(14px);
			span {
				display: block;
				width: 86px;
				margin: 0 0 5px 0;
				font-size: remUnit(36px);
				line-height: 86px;
			}
		}

		&__sale {
			width: 86px;
			margin: 10px;
			font-size: remUnit(20px);
			line-height: 86px;
		}

		&__badge-wrap {
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}
