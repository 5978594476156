$module: 'pricing-table';

.#{$module} {
    // Variables
    $height-body-content: 312px;
    $height-body-content-featured: $height-body-content + 33px;
    $height-caption: 30px;
    margin: $height-caption auto 0;

    &__caption {
        height: $height-caption;
        overflow: auto;
        vertical-align: middle;
        text-align: center;

        .compare-grid__container & {
          display: none;
        }

        .pricing-table--featured & {
          display: block;
        }
    }

    &__caption-text {
        text-transform: uppercase;
        font-size: remUnit(17.6px);
        color: #fff;
        vertical-align: middle;
        @extend %font-roboto-bold;
    }

    &__body {
        text-align: center;
        padding: 26px 20px 20px;
        background-color: #eceff0;
        height: $height-body-content;
        min-height: $height-body-content;

        &.pic-card--sale {
            &::after {
                right: 10px;
                top: 30px;
            }
        }
    }

    &__title {
        font-size: remUnit(28px);
        @extend %font-muli-semibold;
        text-align: center;
    }

    &__list {
        margin-left: 0;
        padding-left: 0;
        font-size: remUnit(16px);
        display: inline-block;
        margin: 0 auto 10px;
        list-style: none;

        li {
            position: relative;
        }

        span.disc {
            font-size: 0.5em;
            margin-bottom: 0;
            display: inline-block;
            position: absolute;
            top: 6px;
            margin-left: -11px;

            &::after {
                content: "\25CF";
            }
        }
    }

    &__cost {

        &-inner {
            display: block;
            margin-bottom: .5rem;

            &-left,
            &-right {
                display: block;
                vertical-align: middle;
                padding-top: .25rem;
                padding-bottom: .25rem;
            }

            &-left {

                @media (min-width: $screen-xs-min) {
                    padding-right: .5rem;
                }
            }

            &-right {

                @media (min-width: $screen-xs-min) {
                    border-left: 1px solid $blue;
                    padding-left: calc(.5rem + 1px);
                }
            }
        }

        &-sign-in {
            font-weight: 600;
            line-height: 1.2;
            text-align: left;

            &-title {
                display: block;
                margin-bottom: .25rem;
                color: $green;
                font-size: remUnit(13px);

                @media (min-width: 450px) and (max-width: 667px) {
                    font-size: remUnit(16px);
                }

                &--larger {
                    font-size: remUnit(14px);
                    margin-bottom: 0;
                    white-space: nowrap;

                    @media (min-width: 450px) and (max-width: 667px) {
                        font-size: remUnit(16px);
                    }
                }

                &--largest {
                    font-size: remUnit(16px);
                    margin-bottom: 0;
                    white-space: nowrap;

                    @media (min-width: 450px) and (max-width: 667px) {
                        font-size: remUnit(18px);
                    }
                }
            }

            &-link {
                display: block;
                color: $blue;
                font-size: remUnit(13px);
                line-height: 1.2;

                @media (min-width: 450px) and (max-width: 667px) {
                    font-size: remUnit(14px);
                }

                a {
                    color: $red;
                    text-decoration: underline;
                }

                br {
                    display: none;

                    @media (max-width: 345px) {
                        display: block;
                    }
                }
            }
        }
    }

    &__price {
        color: $blue;
        font-size: remUnit(24px);
    }

    &__price--regular {
        font-size: remUnit(16px);
    }

    &__price--sale {
        @extend %font-muli-bold;
        font-size: remUnit(20px);
        color: $blue;
        line-height: 1.2;
    }

    &__price--retail {
        display: block;
        color: $gray;
        font-size: remUnit(13px);
    }

    &__cost--on-sale {
        .#{$module}__price--regular {
            display: inline-block;
            text-decoration: line-through;
        }

        .#{$module}__price--sale {
            display: inline-block;
            color: $red;
        }
    }

    &__footer {
        padding: 13px 0;
        color: #fff;
        background-color: $blue;
    }

    &__button {
        @include ace-btn-double-jumbo($theme: blue);
    }

    &--featured {
        margin-top: 0;

        .#{$module}__body {
            height: $height-body-content-featured;
            min-height: $height-body-content-featured;
        }

        .#{$module}__caption,
        .#{$module}__footer {
            background-color: $red;
        }

        .#{$module}__button {
            @include ace-btn-theme($theme: red);
        }
    }

    @media (min-width: $screen-md-min) {
        // offset left and right to account for featured caption
        margin-top: $height-caption;

        &__body {
            height: $height-body-content;
            min-height: $height-body-content;
        }

        &__title {
            font-size: remUnit(32px);
        }

        &__list {
            padding-left: 1em;
            list-style: disc;
            text-indent: -0.25em;

            li {
                padding-left: 0;
            }

            span.disc {
                display: none;
            }
        }

        &__price {
            margin-bottom: 2px;
        }

        &__price--regular,
        &__price--sale {
            font-size: remUnit(24px);
            font-weight: 700;
        }

        &__price--retail {
            display: block;
            font-size: remUnit(13px);
        }

        &--featured {
            margin-top: 0;

            .#{$module}__body {
                height: $height-body-content-featured;
                min-height: $height-body-content-featured;
            }
        }
    }
}
