// =====================================================
// .star-rating
//
// @see _helpers.scss for placeholder extends:
// @see _global-variables.scss for 1/4, 1/2, 3/4 fill widths
//
// HTML Requirements
// * - Requires a container element
// * -- that container must have a font-size declared
// * -- that font size dictates star size
// * -- font-size:15px; = 15px x 15px stars
//
// @example HTML
//
// [some container with font-size declared ]
//
/// <div class="star-rating star-rating--{rating goes here}">
/// 	<!-- Repeat for wrap and star--2,3,4,5 -->
/// 	<span class="star-rating__star-wrap">
/// 		<span class="star-rating__star star--1"></span>
/// 	</span>
/// 	<!-- end: repeat for wrap and star--2,3,4,5 -->
/// </div>
//
// [/end some container with font-size declared ]
// =====================================================
$module: 'star-rating';


.#{$module} {
	position: relative;
	line-height: 1;
	font-size: 1em;

	&__star-wrap,
	&__star {
		line-height: 1;
		background-repeat: no-repeat;
		background-size: 1em 1em;
	}

	&__star-wrap {
		height: 1em;
		width: 1em;
		line-height: 1;
		display: inline-block;
		position: relative;
		top: 0;
		bottom: 0;
		@include svg-star(lighten($yellow, 35%));
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}

	}

	&__star {
		height: 1em;
		width: 1em;
		position: absolute;
		right: 0;
		left: 0;
		@include svg-star($yellow);
	}

	// Ratings 1 thru 4
	// ===================================
	&--1,
	&--2,
	&--3,
	&--4 {
		@for $i from 2 through 5 {
			.star--#{$i} {
				@extend %star-empty;
			}
		}
	}
	&--1 .star--1 {@extend %star-1-4;}
	&--2 .star--1 {@extend %star-1-2;}
	&--3 .star--1 {@extend %star-3-4;}
	&--4 .star--1 {@extend %star-full;}


	// Ratings 5 thru 8
	// ===================================
	&--5,
	&--6,
	&--7,
	&--8 {
		@for $i from 3 through 5 {
			.star--#{$i} {
				@extend %star-empty;
			}
		}
	}
	&--5 .star--2 {@extend %star-1-4;}
	&--6 .star--2 {@extend %star-1-2;}
	&--7 .star--2 {@extend %star-3-4;}
	&--8 .star--2 {@extend %star-full;}

	// Ratings 9 thru 12
	// ===================================
	&--9,
	&--10,
	&--11,
	&--12 {
		@for $i from 4 through 5 {
			.star--#{$i} {
				@extend %star-empty;
			}
		}
	}
	&--9 .star--3 {@extend %star-1-4;}
	&--10 .star--3 {@extend %star-1-2;}
	&--11 .star--3 {@extend %star-3-4;}
	&--12 .star--3 {@extend %star-full;}

	// Ratings 13 thru 16
	// ===================================
	&--13,
	&--14,
	&--15,
	&--16 {
		@for $i from 5 through 5 {
			.star--#{$i} {
				@extend %star-empty;
			}
		}
	}
	&--13 .star--4 {@extend %star-1-4;}
	&--14 .star--4 {@extend %star-1-2;}
	&--15 .star--4 {@extend %star-3-4;}
	&--16 .star--4 {@extend %star-full;}

	// Ratings 17 thru 20
	// ===================================
	&--17 .star--5 {@extend %star-1-4;}
	&--18 .star--5 {@extend %star-1-2;}
	&--19 .star--5 {@extend %star-3-4;}
	&--20 .star--5 {@extend %star-full;}
}

.#{$module}--yellow {
	.#{$module}__star {
		@include svg-star($yellow);
	}
}