$module: 'accredited';

.#{$module} {
	padding: 0;
	background-color: $gray-light;
	text-align: center;

	&__wrap {
		@extend .container-fluid;
		padding: 2rem 1rem 1rem;
		text-align: center;
	}

	&__head {

	}

	&__description {
		font-size: remUnit(16px);
	}

	&__row {
		display: table;
		width: 100%;
		max-width: 300px;
		margin: 0 auto;
		border-bottom: 1px solid $gray-med;

		&:last-child {
			border-bottom: 0;
		}
	}

	&__cell {
		display: table-cell;
		width: 50%;
		vertical-align: middle;
		padding: .25rem;
	}

	&__image {
		@include center-block;
		width: 90%;
		height: auto;
	}

	&__caption {
		display: inline-block;
		color: $blue;
		font-size: remUnit(16px);
		font-weight: $font-weight-semibold;
	}

	@media (min-width: $screen-md-min) {
		&__title {
			font-size: remUnit(36px);
		}
	}
}
