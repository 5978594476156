$module: 'ct-header-item';

.#{$module} {
	text-align: center;

	&__inner {
		padding: .5rem;
	}

	&__caption {
		display: none;
		position: absolute;
		width: 100%;
		padding: .25rem;
		vertical-align: middle;
		bottom: 100%;
		left: 0;
		right: 0;
		background-color: #f2b645;

		.ct-header-item--featured & {
			display: block;
		}
	}

	&__caption-text {
		text-transform: uppercase;
		font-size: remUnit(17.6px);
		color: #fff;
		vertical-align: middle;
		position: relative;
		@extend %font-roboto-bold;
	}

	&__body {
		height: auto;
		text-align: center;
		padding: 10px 20px;
	}

	&__title {
		margin: 0 auto;
		font-size: 1.7rem;
		font-weight: 400;
	}

	&__pricing-wrap {
		padding: .5rem;

		.pricing-table__cost {
			&--on-sale {
				.pricing-table__price--sale {
					color: $red;
				}
			}
		}

		.pricing-table__price {
			font-size: 1.7rem;
			font-weight: 600;
		}
	}

	&__affirm-wrap {
		min-height: 28px;
		margin-bottom: 0;

		.affirm-as-low-as {
			margin-bottom: 0;
			color: $blue-med;
			font-weight: 600;
			font-size: 1.1rem;
		}

		.affirm-modal-trigger {
			color: $blue-med;
			text-decoration: underline;
		}
	}

	&__button {
		display: inline-block;
		width: 100%;
		height: 50px;
		line-height: 50px;
		padding: 0;
		color: #fff;
		background-color: $blue;
		@extend %font-roboto-medium;
		font-size: remUnit(14px);
		text-transform: uppercase;
		&:hover,
		&:active,
		&:focus {
			color: #fff;
			text-decoration: underline;
			outline: none;
		}

		&--muted {
			background-color: #29455a;
		}

		&--red {
			background-color: $red;
		}
	}
}
