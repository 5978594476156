// Based on post-related
@use "sass:math";

$module: 'exercise-card';

.#{$module} {
	width: 100%;
	min-height: 436px;
	display: block;
	position: relative;
	overflow: hidden;
	@include margin-auto;
	text-align: center;
	background-color: $blue;

	&:focus,
	&:hover {
		text-decoration: none;

		.#{$module}__image {
			transform: scale(1.15);

			&:before {
				display: block;
			}

			.#{$module}__link-txt,
			.#{$module}__title {
				text-decoration: underline;
			}
		}
	}

	&__header,
	&__image {
		width: 100%;
		display: block;
		position: relative;
	}

	&__thumbnail {
		overflow: hidden;
	}

	&__image,
	&__thumbnail {
		position: relative;
		height: 150px;
	}

	&__body {
		height: 285px;
		padding-top: 15px;
		padding-bottom: 20px;
		border-top: 1px solid #415c6d;
	}

	&__image {
		display: block;
		padding: 0;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		position: relative;
		transition: all 0.4s;

		&:before {
			content: "";
			display: none;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__header {
		padding-left: percentage(math.div(35px, 355px));
		padding-right: percentage(math.div(35px, 355px));
		margin-bottom: 13px;
	}

	&__title {
		position: relative;
		font-weight: $font-weight-semibold;
		color: #fff;
		margin: 0 auto;
		$lines-to-show: 2;
		$font-size: 20px;
		$line-height: lh($font-size,24px);
		$use-max-height: true;
		@include text-overflow( $lines-to-show, $font-size, $line-height, $use-max-height);
	}

  &__info {

  }

	&__link {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		font-size: remUnit(13px);
		padding: 13.5px 20px;
		line-height: 1;
		background-color: #1c3c50;
		color: #fff;
		@extend %font-roboto-medium;
		text-transform: uppercase;
		display: block;
		@include margin-auto;

		.icon {
			vertical-align: bottom;
		}
	}

	&__meter {
		width: 100%;
		text-align: center;
		font-size: 0; // remove white space
		margin-top: 15px;
		margin-bottom: 15px;
	}

	&--new,
	&--sale {
		&:after {
			width: 68px;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			padding-top: 6px;
			padding-right: 10px;
			padding-bottom: 6px;
			background-repeat: no-repeat;
			background-size: cover;
			text-align: right;
			text-transform: uppercase;
			font-size: remUnit(12px);
			@extend %font-roboto-medium;
		}
	}

	&--sale {
		&:after {
			color: #fff;
			content: "Sale";
			@include svg-featured-callout($green);
		}
	}

	&--new {
		&:after {
			color: $green;
			content: "New";
			@include svg-featured-callout(#fff);
		}
	}

  &-grid__cell--recipe {
    .#{$module} {
      min-height: 375px;

      @media (min-width: $screen-xs-min) {
        min-height: 300px;
      }
      @media (min-width: $screen-sm-min) {
        min-height: 325px;
      }
      @media (min-width: $screen-md-min) {
        min-height: 375px;
      }
    }

    .#{$module}__body {
      height: 180px;

      @media (min-width: $screen-sm-min) {
        height: 200px;
      }
      @media (min-width: $screen-md-min) {
        height: 265px;
      }
    }

    .#{$module}__info {
      p {
        color: #fff;
        padding: 0 1rem;
        $lines-to-show: 6;
        $font-size: 14px;
        $line-height: lh($font-size,21px);
        $use-max-height: true;
        @include text-overflow( $lines-to-show, $font-size, $line-height, $use-max-height);
      }
    }
  }
}
