@use "sass:math";

$module: 'cube';
/* Cube Row
========================================== */
.cube-row {
	@include make-row(0);
	padding-bottom: 15px;
}
/* Cube Panel
========================================== */
.cube-panel {
	margin: 0;
	background-color: inherit;
	border-radius: 0;
	border: none;
	box-shadow: none;
}
/* Cube
========================================== */
.#{$module} {
	/* Single Column */
	@include make-xs-column(12, 0);
	text-decoration: none;
	text-align: center;
	background-repeat: no-repeat;
	background-size: 0;
	background-position: 50% 0;


	&__wrap {
		@extend .container-fluid;
		max-width: 1400px;
		padding: 20px;
		background-color: #fff;

		&--fluid {
			max-width: none;
		}

		&--featured {
			background-color: $blue;
		}
	}


	&__label {
		display: none;
		position: absolute;
		top: 50px;
		right: 30px;
		left: 30px;
		text-align: center;
		margin: 0 auto;
		direction: ltr;

		&--relative {
			position: relative;
			left: auto;
			right: auto;
			top: auto;
			margin-bottom: 2rem;
		}
	}

	&__label--featured {
		@extend .hidden-md;
		@extend .hidden-lg;
		margin-bottom: 40px;
	}

  &__wrap-label {

    &--exam-prep {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
  }

	&__title {
		@include sub-section-title-styles();
		line-height: 1.2;
		margin: 0 auto 18px;
		padding: 0;
	}

	&__subtitle {
		font-size: 1rem;
	}

	&__title-marker {
		display: table;
		width: 50px;
		height: 48px;
		line-height: 48px;
		font-size: remUnit(28px);
		margin: 0 auto 10px;
		text-align: center;
		border: 1px solid $green;
		border-radius: 50%;
		&--white {
			color: #fff;
			border-color: #fff;
		}
		&--blue {
			color: $blue;
			border-color: $blue;
		}

	}

	&--img-only {
		padding: 75% 0 0;
		margin-bottom: 25px;
		background-size: cover;
	}

	&--rectangular &.#{$module}--img-only {
		padding: 50% 0 0;
	}

	&__button {
		@extend %ace-btn-stroke--red;
		@extend %margin-auto;
		@include clearfix();
		margin: percentage(math.div(30px, 700px)) auto;
	}

	&__button--blue {
		@extend %ace-btn-stroke--blue;
		@extend %margin-auto;
		@include clearfix();
		margin: percentage(math.div(30px, 700px)) auto;
	}

	&__button--white {
		@extend %ace-btn-stroke--reversed;
		@extend %margin-auto;
		@include clearfix();
		margin: percentage(math.div(30px, 700px)) auto;
		white-space: normal;
		color: #fff;
	}
	a.#{$module}__button-video {
		@extend %ace-btn-floating--white;
		margin: percentage(math.div(30px, 700px)) auto;
		color: #fff;
		@include ace-btn-hover {
			.#{$module}__play-icon {
				background: $red;
				span.icon-wrap .icon {
					fill: #fff;
				}
			}
		}
	}

	&__play-icon {
		display: block;
		bottom: 10px;
		width: 2.5em;
		background: #fff;
		border-radius: 1.25em;
		font-size: remUnit(20px);
		line-height: 2.5;
		text-align: center;
		margin-bottom: 10px;
		@extend %margin-auto;
		span.icon-wrap {
			.icon {
				fill: $red;
			}
		}

	}

	&__button-pricing {
		display: inline-block;
		margin-right: 10px;
		padding-right: 10px;
		border-right: 1px solid $gray;
		color: #fff;
	}

	&__content {
		//max-width: 600px;
		direction: ltr;

    p a {
      color: $red;
    }
	}

	&__content--featured {
		.#{$module}__button {
			@extend %ace-btn-floating--red;
		}

		p {
			color: #9da6ab;
		}

		.#{$module}__title {
			color: #fff;

			&:after {
				content: '';
				display: block;
				width: 100px;
				height: 4px;
				margin: 15px auto 0;
				background: $red;
			}
		}
	}

	.ncca-logo {
		display: block;
		@extend %margin-auto;
	}

	// Background Color Variants
	&--white {
		background-color: #fff;
	}

	&--blue, &__wrap--blue {
		background-color: $blue;
	}

	&--blue-light, &__wrap--blue-light {
		background-color: $blue-light;
	}

	&--gray, &__wrap--gray {
		background-color: $gray;
	}

	&--gray-med, &__wrap--gray-med {
		background-color: $gray-med;
	}

	&--gray-light, &__wrap--gray-light {
		background-color: $gray-light;
	}

	&--green, &__wrap--green {
		background-color: $green;
	}

	&--red, &__wrap--red {
		background-color: $red;
	}

	&--red-dark, &__wrap--red-dark {
		background-color: $red-dark;
	}
	// Text Color Variants
	&__content--text-white {
		color: #fff;
		.#{$module}__title, p {
			color: #fff;
		}
	}
	&__content--text-white-blue {
		color: #fff;
		.#{$module}__title {
			color: #fff;
		}
		p {
			color: $blue;
		}
	}


	&--dark {
		.#{$module}__content {
			color: $gray-med;
		}

		.#{$module}__title {
			color: #fff;
		}
	}

	&--fixed {
		padding-bottom: 30px;

		.#{$module}__button {
			@extend %ace-btn-stroke--blue;
		}

		.#{$module}__content {
			color: $blue;
		}

		.#{$module}__title {
			padding: 50px 20px;
			background-position: 50% 10%;
			background-size: cover;
			color: #fff;

			.icon {
				display: block;
				margin: 0 auto 15px;
				font-size: remUnit(35px);

				&--cloud {
					margin-bottom: 5px;
					font-size: remUnit(40px);
				}

				&--certifications {
					margin-bottom: 5px;
					font-size: remUnit(40px);
				}
			}
		}
	}
}
/* Goes to 2 Columns */
@media (min-width: $screen-md-min) {
	/* Cube Row
	========================================== */
	.cube-row {
		@include make-valign-row;
		padding: 0;

		&--fixed {
			display: block;
		}
	}

	.cube-row--desktop-rtl {
		direction: rtl;
	}
	/* Cube Panel
	========================================== */
	.cube-panel {
		padding-bottom: 0;
	}
	/* Cube
	========================================== */
	.cube {
		@include make-md-column(6, 0);
		@include make-valign-col(middle);
		padding: 120px 30px 30px;
		border: none;
		background-size: cover;

		&__wrap {
			position: relative;
			padding: 0;
		}

		&--img-only {
			padding: 50% 0 0;
			border: none;

			&.cube--compact {
				padding: 45% 0 0;
			}
		}

		&--compact {
			padding: 30px;
		}

		&--no-label {
			padding-top: 0;
		}

		&__content {
			display: block;
			text-align: center;
			//direction: ltr; /* override the direction switching on the row	*/
			color: #5f6568;
			font-size: remUnit(16px);
			line-height: lh(16px,24px);
			margin-left: auto;
			margin-right: auto;
			max-width: 550px;

			p {
				margin: 0 auto;
			}
		}

		&--rectangular,
		&--rectangular &.#{$module}--img-only {
			padding: 10.45% 30px 5%;
		}

		&__label {
			max-width: 550px;
			display: block;
		}

		&__title--big {
			font-size: remUnit(54px);
		}

		&__subtitle {
			font-size: 1.5rem;
		}

		&__wrap-label {
			position: absolute;
			top: 30px;
			left: 50%;
			z-index: 100;
			width: 700px;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);

      &--exam-prep {
        margin-top: 0;
        margin-bottom: 0;
      }
		}

		&__title-marker {
			width: 100px;
			height: 98px;
			margin: 0 auto 20px;
			line-height: 98px;
			font-size: remUnit(56px);
		}

		&--fixed {
			display: block;
			float: left !important;
			position: relative;
			padding: 50% 0 0;

			.#{$module}__button {
				@include ace-btn-stroke(reversed);
			}

			.#{$module}__content {
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				padding: 30px;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				color: #fff;

				p {
					max-width: 500px;
					margin-right: auto;
					margin-left: auto;
				}
			}

			.#{$module}__title {
				padding: 0;
				background-size: 0 0;

				.icon {
					margin: 0 auto 20px;
					font-size: remUnit(55px);

					&--cloud {
						margin-bottom: 10px;
						font-size: remUnit(65px);
					}

					&--certifications {
						margin-bottom: 10px;
						font-size: remUnit(65px);
					}
				}

				&--big {
					font-size: remUnit(54px);
				}
			}
		}
		//  ==== &--fixed

    &--border-right-white {
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 50%;
        width: 1px;
        right: 0;
        top: 50%;
        background-color: #fff;
        transform: translateY(-50%);
      }
    }
	}

}
