$module: 'offer';

// .offers {
// 	@each $index, $map in $map-stepped-color-blue {
// 		$bg: map-get($map, bg);
//
// 		.#{$module}:nth-child(#{'4n +'} #{$index}) {
// 			background-color: $bg;
// 		}
// 	}
// }

.offers {
	@each $index, $map in $map-stepped-color-blue-expanded {
		$bg: map-get($map, bg);

		.#{$module}:nth-child(#{'8n +'} #{$index}) {
			background-color: $bg;
		}
	}
}

.#{$module} {
	padding: 50px 0;
	background-color: $blue;
	color: #fff;

	&__wrap {
		@extend .container-fluid;
		padding-right: 20px;
		padding-left: 20px;
		text-align: center;
	}

	&__button,
	&__label,
	&__title {
		color: #fff;
	}

	&__label {
		@extend %diamond-title;
		@extend %diamond-title-variant--red;
		@extend %margin-auto;

		.#{$module}__label-inner {
			background-color: $red;
			color: #fff;

			&::after {
				border-left-color: $red;
			}

			&::before {
				border-right-color: $red;
			}
		}
	}

	&__description,
	&__title {
		font-weight: $font-weight-semibold;
	}

	&__title {
		font-size: remUnit(38px);
		line-height: 1;
		margin-bottom: 10px;
	}

	&__description {
		font-size: remUnit(19px);
		line-height: lh(19px,29px);
		padding-left: 25px;
		padding-right: 25px;
		color: $gray-med;
	}

	&__button {
		@extend %ace-btn-stroke--green;
		@include clearfix();
		width: 100%;
	}

	&__description,
	&__label {
		margin-bottom: 40px;
	}
	// XSmall devices (phone, 480px and up)
	// =================================================
	@media (min-width: $screen-xs-min) {
		&__wrap {
			// max-width: 700px + 20px + 20px; //+left/right padding
		}

		&__title {
			font-size: remUnit(48px);
		}

		&__description {
			font-size: remUnit(18px);
		}

		&__button {
			width: auto;
		}
	}
	// Small devices (tablets, 768px and up)
	// =================================================
	@media (min-width: $screen-sm-min) {
		&__wrap {
			max-width: 700px + 20px + 20px; //+left/right padding
		}

		&__title {
			font-size: remUnit(64px);
			margin-bottom: 3px;
		}

		&__description {
			font-size: remUnit(24px);
		}

		&__description,
		&__label {
			margin-bottom: 50px;
		}

		&__button {
			width: auto;
		}
	}
	// Medium devices (desktops, 992px and up)
	// =================================================
	@media (min-width: $screen-md-min) {}
}
