$module: certified-article;

%certified-article-tile-item {
  display: block;
  width: 100%;
  padding: 0;
  padding-bottom: 5px;
  background-color: rgba(255,255,255,.7);
  box-shadow: 1px 2px 2px rgba(34,25,25,0.15);
  list-style: none;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  column-break-inside: avoid;
  opacity: 1;
}

.post-full .#{$module} {
    // <editor-fold>
    em {
        font-style: italic;
    }

    strong {
        font-weight: 600;
    }

    h1, h2, h3, h4, h5 {
        strong {
            font-weight: 600;
        }
    }

    h1 {
    }

    h2 {
    }

    h3 {
    }

    h4 {
    }

    h5 {
    }

    img {
        max-width: 100%;
    }

    ul, ol {
        margin-bottom: 25px;
        padding-left: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }

        li {
            margin-bottom: .5rem;
            font-size: 90%;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    // </editor-fold>
    // CALLOUTS

    .#{$module}__callout {
        margin-bottom: 2rem;
        padding: 1rem;
        background-color: $gray-light !important;

        p, h1, h2, h3, h4, h5, li {
            margin-top: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--further-knowledge {
            background-color: $blue !important;

            p, h1, h2, h3, h4, h5, li {
                color: #fff;
            }

            a {
                color: $red;
            }
        }
    }
    // ASIDE

    .#{$module}__aside {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
        margin-bottom: 1rem;
        padding: 1rem;
        background-color: $green;
        color: #fff;
        text-align: left;
        font-size: 90%;

        p, h1, h2, h3, h4, h5, li {
            color: #fff;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            color: $red;
        }

        @media (min-width: $screen-sm-min) {
            max-width: 250px;
            margin-left: 1rem;
            float: right;
            @include clearfix();
        }
    }
    // TILE CONTAINER

    .#{$module}__tile-container {
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-size: 0;

        @media (min-width: 600px) {
            -webkit-column-count: 2;
            -webkit-column-gap: 15px;
            -webkit-column-fill: auto;
            -moz-column-count: 2;
            -moz-column-gap: 15px;
            -moz-column-fill: auto;
            column-count: 2;
            column-gap: 15px;
            column-fill: auto;
        }

        .#{$module}__tile-item {
            margin: 15px 0 0;
        }
    }
    // TILE ITEM

    .#{$module}__tile-item {
        @extend %certified-article-tile-item;
        margin-bottom: 1rem;

        &:first-of-type {
            margin-top: 0;
        }

        p {
            font-size: .85rem;
            margin-bottom: 0;
            padding-left: .5rem;
            padding-right: .5rem;

            > a {
                color: $gray !important;
            }
        }

        img {
            display: block;
            width: 100% !important;
            height: auto !important;
            max-width: 100% !important;
            margin: 0 auto .25rem;
        }

        &.#{$module}__tile-item--50-50 {

            @media (min-width: 600px) {
                display: inline-block;
                vertical-align: top;
                width: calc(50% - 6px);
                margin-left: 0;
                margin-right: 0;

                + .#{$module}__tile-item--50-50 {
                    margin-left: 6px;
                }
            }
        }
    }
    // IMAGE SIZES

    .#{$module}__img {

        &--half {
        }

        &--full {
        }

        &--left {
        }

        &--right {
        }
    }
    // REFERENCES

    .#{$module}__references {
        padding: 1rem 0 0;

        p {
            margin-bottom: 1rem;
            font-size: .85rem;
        }
    }
    // SIDE BY SIDE CONTENT WELLS

    .#{$module}__side-by-side {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 15px;

        @media (max-width: 600px) {
            flex-direction: column;
            justify-content: space-evenly;
        }

        @media (min-width: 992px) {
            margin-bottom: 25px;
        }

        &-item {
            width: calc(50% - 1rem);

            @media (max-width: 600px) {
                width: 100%;
                margin-bottom: 1rem;
            }
        }
    }
}
