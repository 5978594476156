$module: 'pricing-table-detail';

.#{$module} {

	// Variables
	$height-body-content: 312px;
	$height-body-content-featured: $height-body-content + 33px;
	$height-caption: 30px;

	max-width: 340px;
	margin: 0 auto;

	&__caption {
		height: $height-caption;
		overflow: auto;
		vertical-align: middle;
	}

	&__caption-text {
		text-transform: uppercase;
		font-size: remUnit(12.5px);
		color: #fff;
		vertical-align: middle;
		@extend %font-roboto-bold;
	}

	&__body {
		position: relative;
		text-align: center;
		padding: 26px 20px 20px;
		background-color: #eceff0;

	}

	&__form-label--sr-only {
		@extend .sr-only;
	}

	&__form-select {
		@extend .form-control;
	}

	&__form-select-wrap {
		@extend .form-group;

		select {
			border-color: transparent;
		}

		@include selectric-variant($gray, #fff, transparent, $gray);
	}

	&__title {
		font-size: remUnit(18px);
		@extend %font-muli-semibold;
		text-align: center;
	}

	&__list {
		margin-left: 0;
		padding-left: 0;
		font-size: remUnit(16px);
		display: inline-block;
		margin: 0 auto 10px auto;
		list-style: none;

		li {
			padding-left: 0.8em;
			position: relative;
		}

		span.disc {
			font-size: 0.6em;
			margin-bottom: 0;
			display: inline-block;
			position: absolute;
			top: 5px;
			margin-left: -8px;

		}
	}

	&__price {
		color: $blue;
		font-size: remUnit(16px);
	}
	&__price--regular {}

	&__price--sale {
		@extend %font-muli-bold;
		color: $red;
		font-size: remUnit(24px);
	}

	&__price--retail {
		display: block;
		color: $gray;
		font-size: remUnit(13px);
	}

	&__cost--on-sale {
		.#{$module}__price--regular {
			display: inline-block;
			text-decoration: line-through;
		}

		.#{$module}__price--sale {
			display: inline-block;
		}
	}

	&__footer {

	}

	&__button {
		@include ace-btn-primary-jumbo($theme: blue, $block: true);
	}

	&--featured {
		.#{$module}__caption,
		.#{$module}__footer {
			background-color: $red;
		}
		.#{$module}__button {
			@include ace-btn-theme(red);
		}
	}

	&--sale, &--pro-exclusive {
		.#{$module}__body:after {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			padding-top: 6px;
			padding-right: 10px;
			padding-bottom: 6px;
			background-repeat: no-repeat;
			background-size: cover;
			color: #fff;
			text-align: right;
			text-transform: uppercase;
			font-size: remUnit(12px);
			@extend %font-roboto-medium;
			@include svg-featured-callout($green);
		}
	}

	&--sale {
		.#{$module}__body:after {
			content: "Sale";
			width: 68px;
		}
	}

	&--pro-exclusive {
		.#{$module}__body:after {
			content: "ACE Pro Exclusive";
			width: 165px;
		}
	}

	// ==========================================================================
	// XSmall devices (phone, 480px and up)
	// ==========================================================================
	// @media (min-width: $screen-xs-min) {
	// } // end: XSmall devices (phone, 480px and up) ===========================
	// ==========================================================================
	// Small devices (tablets, 768px and up)
	// ==========================================================================
	// @media (min-width: $screen-sm-min) {
	// } // end: Small devices (tablets, 768px and up) ==========================
	// ==========================================================================
	// Medium devices (desktops, 992px and up)
	// ==========================================================================
	@media (min-width: $screen-md-min) {
		max-width: 100%;
		// offset left and right to account for featured caption
		margin-top: $height-caption;

		&__body {}

		&__title {
			font-size: remUnit(24px);
		}
		&__list {
			padding-left: 1em;
			list-style: disc;
			text-indent: -0.25em;

			li {
				padding-left: 0;
			}

			span.disc {
				display: none;
			}
		}

		&__price {
			margin-bottom: 2px;
		}

		&__price--regular {}

		&__price--sale {}

		&__price--retail {
			display: block;
			font-size: remUnit(13px);
		}

		&__cost {}

		&__cost--on-sale {
			.#{$module}__price--regular,
			.#{$module}__price--sale {
				display: inline-block;
			}
		}

		&--featured {
			margin-top: 0;
		}

	} // end: Medium devices (desktops, 992px and up) ========================
	// ==========================================================================
	// Large devices (large desktops, 1200px and up)
	// ==========================================================================
	// @media (min-width: $screen-lg-min) {
	//  .#{$module} {}
	// } // end: Large devices (large desktops, 1200px and up) ==================
}
