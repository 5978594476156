// Mixin defining a floating button
// Notes:
// - All arguments default to variables set in ace-buttons-settings.
//
// - Theme keys must be stored in the `$map-ace-btn-themes-floating` in
//   `_ace-buttons-themes.scss`
//
// - If passing individual arguments you must pass null as the first argument
// - and use name arguments unless all arguments are passed [see underloaded example].
//
// Examples:
// default:         ace-btn-floating()
// theme:           ace-btn-floating(red)
// overloaded:      ace-btn-floating(null, #000, #000)
// oveunderloaded:  ace-btn-floating(null, $icon-fill: #000)

@mixin ace-btn-floating($theme: null, $font-color: $ace-btn-font-color-floating, $icon-fill: $ace-btn-icon-fill-floating, $block:null) {
	// Check for theme and set args to those values
	@if $theme {
		$map: map-get($map-ace-btn-themes-floating, $theme);
		$font-color: map-get($map, font-color);
		$icon-fill: map-get($map, icon-fill);
	}
	// include shared styles of non floating button
	@include ace-btn-base();
	@if $block {
		display: block;
		width: 100%;
	} @else {
		display: inline-block;
	}
	border: none;
	background: transparent;
	color: $font-color;
	.icon {
		fill: $icon-fill;
	}
	&:focus,
	&:active,
	&:hover {
		color: $font-color;
	}
}
