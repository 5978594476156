html {
	color: $gray;
	font-family: $font-family-muli;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
	font-size: 100%; /* 16px */
	font-weight: $font-weight-light;
}

body {
	font-family: $font-family-muli;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
	font-size: remUnit(15px);
	line-height: 1.66666667;
	color: $gray;

	@media (min-width: $screen-md-min) {
		font-size: remUnit(18px);
		line-height: 1.44444444;
	}
}

a {
	color: $blue;
	text-decoration: none;
	cursor: pointer;

	&:hover,
	&:active,
	&.active,
	&:focus,
	&.focus {
		color: $blue;
		text-decoration: underline;
	}
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	color: $blue;
	font-weight: $font-weight-regular;
}

sup {
	top: 0;
	font-weight: $font-weight-regular;
	vertical-align: super;
}

h1, h2, h3, h4, h5, h6, p {
	sup {
		font-size: 50%;
	}
}

// Custom Fonts

@font-face {
	font-family: 'Ludicrous';
	font-weight: normal;
	src:
		url(/Fonts/Ludicrous/Ludicrous.eot) format('embedded-opentype'),
		url(/Fonts/Ludicrous/Ludicrous.woff) format('woff'),
		url(/Fonts/Ludicrous/Ludicrous.ttf) format('truetype'),
		url(/Fonts/Ludicrous/Ludicrous.otf) format('opentype');
}
