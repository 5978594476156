$module: 'find-exam';

.find-exam {
	padding: 50px 20px;
	background-position: 50% 50%;
	background-size: cover;
	border: 20px solid #fff;

	&__form-label {
		color: #fff;
	}

	&__form-label--sr-only {
		@extend .sr-only;
	}

	&__form-input {
		@extend .form-control;
		@include placeholder(#fff);
		padding-right: 0;
		padding-left: 0;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #fff;
		color: #fff;
		font-size: remUnit(18px);
	}

	&__form-submit {
		@extend %ace-btn-primary--reversed;
		width: 100%;
		margin-top: 10px;
	}

	&__form {
		max-width: 720px;
		margin-right: auto;
		margin-left: auto;
	}

	&__form-row {
		@include make-row(0);
	}

	&__form-input-wrap {
		@include make-xs-column(12, 0);
	}

	&__form-legend {
		padding-bottom: 5px;
		color: #fff;
		font-size: remUnit(13px);
		font-weight: $font-weight-light;
    text-align: center;
	}

	&__form-select {
		@extend .form-control;
	}

	&__form-select-wrap {
		@include make-xs-column(12, 0);
		//
		// .selectric {
		// 	background-color: transparent;
		// 	border-color: #fff;
		// 	-webkit-transition: background-color 250ms;
		// 	transition: background-color 250ms;
		//
		// 	.button {
		// 		padding-top: 12px;
		// 		padding-bottom: 12px;
		// 		background-color: transparent;
		// 		color: #fff;
		// 		font-size: remUnit(20px);
		//
		// 		&:after {
		// 			border-top-color: #fff;
		// 		}
		// 	}
		//
		// 	.label {
		// 		padding-top: 12px;
		// 		padding-bottom: 12px;
		// 		color: #fff;
		// 		font-size: remUnit(20px);
		// 	}
		// }
		//
		// .selectric-items {
		// 	border-color: #fff;
		// }
		//
		// .selectric-open .selectric {
		// 	background-color: #fff;
		//
		// 	.button {
		// 		color: $blue;
		//
		// 		&:after {
		// 			border-top-color: $blue;
		// 		}
		// 	}
		//
		// 	.label {
		// 		color: $blue;
		// 	}
		// }
	}

	&__form-submit-wrap {
		@include make-xs-column(12, 0);
	}

	&__title {
		margin-bottom: 40px;
		color: #fff;
		font-size: remUnit(21px);
		font-weight: $font-weight-semibold;
		text-align: center;

		.icon {
			display: block;
			margin: 0 auto 20px;
			font-size: remUnit(45px);
		}
	}

	&__wrap {
		@extend .container-fluid;
		padding-right: 0;
		padding-left: 0;
	}
	@media (min-width: $screen-md-min) {
		padding-top: 50px;
		padding-bottom: 100px;
		border: none;

		&__form-input {
			font-size: remUnit(20px);
		}

		&__form-input-wrap {
			@include make-md-column(4, 40px);
			@include make-valign-col(bottom);
		}

		&__form-row {
			@include make-row(40px);
			@include make-valign-row;
		}

		&__form-select-wrap {
			@include make-md-column(4, 40px);
			@include make-valign-col(bottom);
			overflow: visible;

			&--wide {
				@media (min-width: $screen-md-min) {
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}

		&__form-submit {
			width: auto;
			margin-top: 0;
		}

		&__form-submit-wrap {
			@include make-md-column(3, 40px);
			@include make-valign-col(bottom);

			&--wide {
				@media (min-width: $screen-md-min) {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		&__title {
			font-size: remUnit(36px);

			.icon {
				font-size: remUnit(65px);
			}
		}
	}
}
