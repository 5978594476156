////////////////////////////////////////////////////////////
// ACE BUTTONS BASE DEFAULTS //////////////////////////////
//////////////////////////////////////////////////////////
$ace-btn-namespace: ".ace-btn"; //prefix for all classes

// Box Sizing
$ace-btn-display:                  inline-block !default;
$ace-btn-block:                    null !default;
$ace-btn-padding:                  15px 20px !default;
$ace-btn-padding-em-adjust:        15px 20px !default; // b/c of larger font size on <em>
$ace-btn-padding-jumbo:            24px 32px !default;
$ace-btn-padding-jumbo-em-adjust:  15px 32px !default; // b/c of larger font size on <em>
$ace-btn-padding-micro:            9px 15px !default;

// Positioning
$ace-btn-margin:         0 auto !default;

// Borders
$ace-btn-border-width:   1px !default;
$ace-btn-border-style:   solid !default;

// Default Colors
// ===============================================
//-- Solid Button Defaults (primary/jumbo)
$ace-btn-font-color:        #fff !default;
$ace-btn-font-color-active: #fff !default;

$ace-btn-bg-color:          #e53d46 !default;
$ace-btn-bg-color-active:   #cd333c !default;

$ace-btn-icon-fill:         null !default;
$ace-btn-icon-fill-active:  null !default;

$ace-btn-border-color:      $ace-btn-bg-color !default;

//-- Stroke Button Defaults (stroke/ micro)
$ace-btn-font-color-stroke:        $red !default;
$ace-btn-font-color-active-stroke: #fff !default;

$ace-btn-bg-color-active-stroke:   $red !default;

$ace-btn-icon-fill-stroke:         null !default;
$ace-btn-icon-fill-active-stroke:  null !default;



// -- Floating Buttons
$ace-btn-font-color-floating: $red;
$ace-btn-icon-fill-floating:  $red;

// Border







//////////////////////////////////////////////////////////
// TYPOGRAPHY ////////////////////////////////////////////
//////////////////////////////////////////////////////////
$ace-btn-font-size:      remUnit(14px) !default;
$ace-btn-font-family:    $font-family-roboto !default;
$ace-btn-font-weight:    $font-weight-medium !default;
$ace-btn-line-height:    lh(14px, 16px) !default;
$ace-btn-letter-spacing: 1px !default;
$ace-btn-text-transform: uppercase !default;


//////////////////////////////////////////////////////////
// BUTTON OPTIONS ////////////////////////////////////////
//////////////////////////////////////////////////////////
$ace-btn-transition-duration: $site-transition-duration !default;
$ace-btn-floating: false !default;
