$module: 'hero-panel-variant';
$sub-module: 'hero-panel';

[class*='.#{$module}--'] {
	.hero-panel__description {
		max-width: none;
	}
}
// ======================================
// CRT 3.1 - How to Become a Personal Trainer
// ======================================

.#{$module}--crt-detail-pt {
	$hero-image-height: 770px;
	$hero-image-width: 1400px;

	.hero-panel__header {
		height: vw($hero-image-height, $hero-image-width);
	}

	.hero-panel__description {
		padding-bottom: 20px;

		p {
			color: $gray;
		}

		&--blue-mobile {
			@media (max-width: $screen-md-min - 1) {
				background-color: $blue;

				p {
					color: #fff;
				}
			}
		}

		// Health Coach Detail Page
		&.hero-panel__description--wide {
			@media (min-width: $screen-md-min) {
				padding: 0 15%;
			}

			p {
				font-size: .95rem;
			}
		}
	}

	.hero-panel__button {
		@extend %font-roboto-medium;
		font-size: remUnit(14px);
		padding: 20px 30px;
		margin-top: 25px;
	}
	@media (min-width: $screen-md-min) {
		height: vw($hero-image-height, $hero-image-width);

		.hero-panel__header {
			max-width: none;
		}

		.hero-panel__description {
			max-width: none;
			padding: 0 20%;

			p {
				color: #fff;
			}
		}
	}
}

.#{$module}--crt-how-to-pt {
	@media (min-width: $screen-md-min) {
		.hero-panel__header {
			max-width: 650px;
		}

		.hero-panel__description {
			max-width: none;
			padding: 0 22%;
		}
	}
	@media (min-width: $screen-lg-min) {
		.hero-panel__header {
			max-width: 550px;
		}

		.hero-panel__description {
			padding: 0 25%;
		}
	}
}
// ======================================
// Certifications CRT-1.0
// ======================================

.#{$module}--certifications {
	$hero-image-width: 1400px;
	$hero-image-height: 550px;

	.hero-panel__header {
		height: vw($hero-image-height, $hero-image-width);
	}

	.hero-panel__description {
		background-image: url($images-path + 'cube-row-blue-halftone.jpg');
	}

	.hero-panel__footer-row {
		display: none;

		img {
			width: auto;
			height: 100%;
		}
	}
	@media (min-width: $screen-md-min) {
		height: vw($hero-image-height, $hero-image-width);

		.hero-panel__header {
			max-width: none;
		}

		.hero-panel__description {
			max-width: none;
			padding: 0 20%;

			p {
				@include margin-auto();
				max-width: 641px;
				color: #fff;
			}

			img {
				display: none;
			}
		}

		.hero-panel__footer-row {
			display: block;
		}
	}
	@media (min-width:1120px) {
		.hero-panel__footer-row {
			height: auto;
		}
	}
}

// ======================================
// Certifications CRT-3.1-2 PT Grid
// ======================================

.#{$module}--certification-grid {
  background-position: center top;
  background-size: cover;

  .hero-panel__content {
    vertical-align: top;
    height: auto !important;
    padding-top: 50px;
    padding-bottom: 50px;
  }

	.hero-panel__header {
    display: block;
    position: static;
    height: auto !important;
	}

	.hero-panel__description {
    position: relative;
	}

  .hero-panel__title {
    position: static;
    padding-left: 1rem;
    padding-right: 1rem;
    transform: translate(0);
    top: 0;
    text-align: center;
  }

	@media (min-width: $screen-md-min) {
    height: auto;
    margin-bottom: -405px;

    .hero-panel__inline-bg-image {
      display: none;
    }

    .hero-panel__content {
      vertical-align: top;
      padding-top: 100px;
      padding-bottom: 550px;
    }

		.hero-panel__header {
      display: block;
			max-width: none;
		}

		.hero-panel__description {
			max-width: none;
			padding: 0 20%;

			p {
				@include margin-auto();
				max-width: 641px;
				color: #fff;
			}
		}

	}
	@media (min-width:1120px) {

	}
}

// ======================================
// Recertification ER-6.0
// ======================================

.#{$module}--recertification {
	/*
			If this one becomes and issue try
			- increase max width of description
		*/
	$hero-image-width: 1400px;
	$hero-image-height: 550px;

	.hero-panel__header {
		height: vw($hero-image-height, $hero-image-width);
	}

	.hero-panel__description {
		background-image: url($images-path + 'cube-row-blue-halftone.jpg');
	}

	.hero-panel__button {
		@extend %ace-btn-primary--red;
		margin: 25px auto 0;
	}

	.hero-panel__content-img {
		display: block;
		margin: 25px auto 0;
	}

	.hero-panel__footer-row {
		display: none;

		img {
			width: auto;
			height: 100%;
		}
	}
	@media (min-width: $screen-md-min) {
		height: vw($hero-image-height, $hero-image-width);

		.hero-panel__header {
			max-width: none;
		}

		.hero-panel__description {
			max-width: 678px;
			padding-bottom: 90px;

			p {
				@include margin-auto();
				color: #fff;
			}

			.hero-panel__content-img {
				display: none;
			}
		}

		.hero-panel__footer-row {
			display: block;
		}
	}
	@media (min-width: $screen-lg-min) {
		.hero-panel__button {
			padding: 24px 32px; //jumbo padding
		}
	}
}

// ======================================
//  IFT Model CRT-3.1.4
// ======================================

.#{$module}--crt-ift-model {
	.hero-panel__description {
		p {
			font-size: remUnit(18px);
		}
	}

	.hero-panel__title {
		font-size: remUnit(25px);
	}
	@media (min-width: $screen-iphone-6-plus-min) {
		.hero-panel__title {
			font-size: remUnit(30px);
		}
	}
	@media (min-width: $screen-md-min) {
		.hero-panel__header {
			max-width: 500px;
		}

		.hero-panel__description {
			max-width: none;
			padding: 0 22%;
		}
		.hero-panel__title {
			font-size: remUnit(35px);
		}
		.hero-panel__description p {
			font-size: remUnit(16px);
		}
	}
	@media (min-width: $screen-lg-min) {
		.hero-panel__header {
			max-width: 800px;
		}
		.hero-panel__title {
			font-size: remUnit(54px);
		}
		.hero-panel__description {
			padding: 0 30%;
		}
	}
}

// ======================================
// CRT 5.0 Study Center
// ======================================
.#{$module}--study-center {

		/* Temp aspect ratio change for video mockup
		$hero-image-height: 788px;
		$hero-image-width: 1400px;
		*/
		$hero-image-height: 367px;
		$hero-image-width: 1000px;
		height: vw($hero-image-height, $hero-image-width);

		// Hide video player controls in iOS
		*::-webkit-media-controls-panel {
			display: none !important;
			-webkit-appearance: none;
		}

		*::--webkit-media-controls-play-button {
			display: none !important;
			-webkit-appearance: none;
		}

		*::-webkit-media-controls-start-playback-button {
			display: none !important;
			-webkit-appearance: none;
		}

		.hero-panel__header, {
			height: 100%;
		}

		.hero-panel__content {
			width: 100%;
			height: vw($hero-image-height, $hero-image-width);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			background-color: rgba(#000,0.25);
		}

	@media (min-width: $screen-xs-min) {
		height: 30vw;

		.hero-panel__content {
			height: 30vw;
		}
	}

	@media (min-width: $screen-md-min) {
		height: 30vw;

		div.hero-panel__content header.hero-panel__header {
			width: 100%;
			height: 100%;
			text-align: center;
			position: absolute;
			display: table-cell;
			vertical-align: middle;
			top: 0;
			right: 0;
			left: 0;
		}

		.hero-panel__content {
			height: 30vw;
		}

		.hero-panel__title {
			position: relative;
			top: 50%;
			transform: translate(0, -50%);
		}

	}
}

// ======================================
// ER 3.0 Certified Magazine Index
// ======================================

.#{$module}--certified-index {
	.hero-panel__footer-row {
		padding-top: 20px;
		background: $blue;
	}
	@media (min-width: $screen-md-min) {
		.hero-panel__footer-row {
			padding-top: 0;
			background: transparent;
		}
	}
}
// ======================================
// ABT 1.0 About ACE Index
// ======================================

.#{$module}--about-ace {
	$hero-image-width: 1400px;
	$hero-image-height: 498px;

	.hero-panel__header {
		height: vw($hero-image-height, $hero-image-width);
	}
	@media (min-width: $screen-md-min) {
		height: vw($hero-image-height, $hero-image-width);

		.hero-panel__description {}
	}
}
// ======================================
// IDEA World Scholarship
// ======================================
.#{$module}--idea-world-scholarship {
	padding: 2rem 1rem;
	text-align: center;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	@media (min-width: $screen-md-min) {
		padding: 8rem 1rem;
	}

	.hero-panel__content {

	}

	.hero-panel__logo {
		display: block;
		margin: 0 0 1rem;

		@media (min-width: $screen-md-min) {
			position: absolute;
			top: 1rem;
			left: 1rem;
		}
	}

	.hero-panel__event-date {
		color: $yellow;
		font-weight: 600;
		margin: 1rem auto;
	}

	.hero-panel__event-deadline {
		line-height: 1.5;
		margin-bottom: 1rem;
		color: #fff;
	}

	.hero-panel__title {
		top: auto;
		transform: translate(0, 0);
	}
}

// ======================================
// BASIC TEMPLATE FOR NEW HEROS
// ======================================
// .#{$module}--some-identifier-here {
//
// 		$hero-image-width: 1400px;
// 		$hero-image-height: 550px;
//
// 	.hero-panel__header {
// 		height: vw($hero-image-height, $hero-image-width);
// 	}
//
// 	@media (min-width: $screen-md-min) {
// 		height: vw($hero-image-height, $hero-image-width);
//			.hero-panel__description {
//				max-width: none;
//			}
// 	}
// } // end: TEMPLATE =====================
