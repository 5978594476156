$module: 'pic-card';

.#{$module} {
    $module-height-sm: 169px;
    $module-height-md: 340px;
    width: 100%;
    height: $module-height-sm;
    display: block;
    padding: 0;
    color: #fff;
    text-align: center;
    position: relative;
    overflow: hidden;

    &--specialist, &--authors {
        height: 285px;
        // .#{$module}__outer-link--specialist-partner {
        // 	.#{$module}__title:after {
        // 		background: $green;
        // 	}
        // 	.#{$module}__info {
        // 		border-color: $green;
        // 	}
        // 	.#{$module}__link {
        // 		color: $green;
        // 	}
        // 	.#{$module}__note {
        // 		color: $green;
        // 	}
        //}
        @media (min-width: $screen-md-min) {
            height: $module-height-md;
        }

        .#{$module}__outer-link {
            background-position: center 20%;
        }

        .#{$module}__header {
            padding-top: 85px;

            @media (min-width: $screen-md-min) {
                padding-top: 25px;
            }
        }
    }

    &--certification {
        height: 285px;

        @media (min-width: $screen-md-min) {
            height: $module-height-md;
        }

        .#{$module}__outer-link {
            background-position: center 20%;
        }

        .#{$module}__header {
            padding-top: 85px;

            @media (min-width: $screen-md-min) {
                padding-top: 0;
            }
        }
    }

    &__outer-link {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &__inner-container {
        width: 100%;
        height: 100%;
        text-align: center;
        position: absolute;
        transition: all 0.4s;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__header {
        width: 100%;
        height: 68%;
        // add __title margin bottom to offset
        padding-top: 25px + 10px;
        padding-bottom: 25px;
    }

    &__note {
        color: $green;
    }

    &__title {
        line-height: 1;
        display: inline-block;
        position: relative;
        // bottom value needs added to __header padding top
        margin: 0 auto 10px;
        padding: 0 5px 6px;
        color: #fff;
        font-size: remUnit(24px);
        font-weight: $font-weight-semibold;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            margin: 0 auto;
            width: 100px;
            height: 4px;
            background: rgba($red, 1.0);
            transition: top 0.3s, margin 0.3s, width 0.5s, background 1s;
        }
    }

    &__fine-print {
        color: #fff;
        display: block;
        font-size: remUnit(12px);
    }

    &__info-box {
        width: 100%;
        bottom: 0;
        position: absolute;
    }

    &__info {
        display: block;
        text-align: center;
        padding: 20px 0;
        background: $blue;
        // white-space: nowrap;
        overflow: hidden;
        line-height: 1;
        @extend %font-muli-regular;

        img {
            margin: 14px auto 0;
            opacity: 0;
            display: none;
        }
    }

    &__caption {
        margin: 0 auto 14px;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 1.4;
        color: #fff;
        font-size: remUnit(16px);
        opacity: 0;
        display: none;
    }

    &__link {
        display: block;
        color: $red;
        @extend %font-roboto-medium;
        text-transform: uppercase;
        font-size: remUnit(15px);
    }

    &__link-prefix {
        color: #fff;

        &:after {
            // font-family: sans-serif;
            content: "\007c";
            padding: 0 11px;
            color: $gray;
        }
    }
    // Sale & New Callouts in upper right corner
    &--new, &--sale, &--newsale, &--pro-exclusive {
        &:after {
            width: 68px;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            padding-top: 6px;
            padding-right: 10px;
            padding-bottom: 6px;
            background-repeat: no-repeat;
            background-size: cover;
            text-align: right;
            text-transform: uppercase;
            font-size: remUnit(12px);
            @extend %font-roboto-medium;
        }
    }

    &--sale {
        &:after {
            content: "Sale";
        }
    }

    &--pro-exclusive {
        &:after {
            width: 165px;
            content: "ACE Pro Exclusive";
        }
    }

    &--sale, &--pro-exclusive {
        &:after {
            color: #fff;
            @include svg-featured-callout($green);
        }
    }

    &--new {
        &:after {
            color: #092B34;
            content: "New";
            font-weight:900;
            @include svg-featured-callout(#F2B732);
        }
    }

    &--newsale {
        &:after {
            color: #092b34;
            content: " ";
            width: 171px;
            margin-top: -67px;
            margin-right: -24px;
            height: 171px;
            background-image: url(/images/webcontent/assets/education-resources/continuing-education/card-flags/new-sale.svg);
        }
    }
    // Compact - used for specialists programs
    &--compact &__title {
        font-size: remUnit(20px);
    }
    // Used for specialist programs on crt1.0-certifications
    // see also: _pic-card-panel.scss
    // 2col - xs
    // 3col - sm - md
    // 4col - lg
    &--2col-xs {
        .#{$module}__header {
            display: table;
        }

        .#{$module}__title {
            font-size: remUnit(18px);
            line-height: lh(18px, 20.75px);
            margin: 0 auto;
            padding: 0 5px;
            display: table-cell;
            vertical-align: middle;

            &::after {
                display: none;
            }
        }

        .#{$module}__link-prefix::after {
            content: "";
            padding-left: 0;
            padding-right: 2px;
        }

        .#{$module}__link-text {
            @include visually-hidden;
        }
    }

    &--cta-card {
        background-color: #dde2e5;
        display: table;
        @include ace-btn-hover-transition();

        .#{$module}__cta-link {
            display: table-cell;
            vertical-align: middle;
            padding-left: 40px;
            padding-right: 40px;
        }

        .#{$module}__cta-title {
            display: block;
            font-size: remUnit(20px);
            line-height: 1.5;
            margin-bottom: 11.5px;
            @extend %font-muli-bold;
        }

        @include ace-btn-hover {
            outline: 0;
            text-decoration: none;
            background-color: $blue;

            .#{$module}__cta-title {
                color: #fff;
            }

            .#{$module}__cta-link,
            .#{$module}__cta-title {
                text-decoration: none;
            }
        }
    }

    &--static {
        height: auto;

        .#{$module}__caption {
            display: block;
            color: #fff;
            font-size: remUnit(13px);
            opacity: 1;
        }

        .#{$module}__header {
            height: auto;
            padding: 0;
        }

        .#{$module}__info {
            padding: 10px 0;
            background: transparent;
        }

        .#{$module}__info-box {
            position: static;
            bottom: auto;
        }

        .#{$module}__inner-container {
            position: static;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
            height: auto;
            padding: 20px;
            background: rgba(2,38,60,0.8);
        }

        .#{$module}__outer-link {
            height: auto;
            padding-top: 25%;
            overflow: visible;

            &--tall {
                @media (max-width: $screen-md-min - 1) {
                    padding-top: 50%;
                }
            }
        }

        .#{$module}__title {
            margin: 0;
            font-size: 20px;

            &:after {
                display: block;
                position: static;
                top: auto;
                right: auto;
                left: auto;
                height: 4px;
                margin-top: 15px;
            }
        }
    }
    // Small devices (tablets, 768px and up)
    // ===============================================
    @media (min-width: $screen-sm-min) {
        &--2col-xs {
            .#{$module}__title {
                // &::after {
                // 	display: block;
                // }
            }

            .#{$module}__link-prefix::after {
                content: "\007c";
                padding: 0 11px;
                color: #5f6567;
            }

            .#{$module}__link-text {
                @include visually-hidden--off;
            }
        }
    }
    // Medium devices (desktops, 992px and up)
    // ===============================================
    @media (min-width: $screen-md-min) {
        position: relative;
        height: $module-height-md;

        &__inner-container {
            // padding-top: 40px;
            top: 50%;
            transform: none;
        }

        &__header {
            height: auto;
            padding-top: 0;
            padding-bottom: 0;
            transform: none;
        }

        &__title {
            font-size: remUnit(32px);
        }

        &__fine-print {
            padding-top: 9%;
            transition: padding-top 0.3s;
        }

        &__info-box {
            width: 100%;
            height: 200px;
            position: relative;
            margin-top: 30px;
            top: 40%;
            // transform: translateY(50%);
        }

        &__info {
            width: 100%;
            height: 100%;
            background-color: rgba($blue, 1.0);
            border-top: 4px solid $red;
            border-bottom: 4px solid $red;
            transition: all 0.5s;
        }

        &__info {
            img {
                display: inline-block;
                opacity: 1;
            }
        }
        // hover
        .#{$module}__outer-link:focus,
        .#{$module}__outer-link:hover {
            .#{$module}__inner-container {
                // transform: translateY(-50%);
                padding-top: 30px;
                top: 0;
            }

            .#{$module}__title {
                font-size: remUnit(20px);
                margin: 0 auto;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 100%;
                    margin: 0 auto;
                    width: 0;
                    height: 0;
                    background: rgba($red, 0.0);
                    transition: top 0.3s, margin 0.3s, width 0.5s, background 1s;
                }
            }

            .#{$module}__fine-print {
                padding-top: 0;
                transition: padding-top 0.3s;
            }

            .#{$module}__info-box {
                padding-left: 30px; // maybe percent
                padding-right: 30px; // maybe percent
                top: 0;
            }

            .#{$module}__info {
                // transform: translateY(-50%);
                background-color: rgba($blue, 0.8);
            }

            .#{$module}__caption {
                opacity: 1;
                display: block;
            }
        }

        &--static {
            height: auto;

            .#{$module}__info {
                height: auto;
                border: none;
            }

            .#{$module}__info-box {
                position: static;
                top: auto;
                height: auto;
                margin: 0;
            }

            .#{$module}__inner-container {
                padding: 30px;
                transition: background 1s;
            }

            .#{$module}__outer-link:focus,
            .#{$module}__outer-link:hover {
                .#{$module}__info {
                    background: transparent;
                }

                .#{$module}__info-box {
                    padding-left: 0;
                    padding-right: 0;
                }

                .#{$module}__inner-container {
                    background-color: $blue;
                }

                .#{$module}__title {
                    &:after {
                        position: static;
                        left: auto;
                        right: auto;
                        top: auto;
                        margin: 15px auto 0;
                        width: 100px;
                        height: 4px;
                        background: $red;
                        transition: none;
                    }
                }
            }
        }

        &--2col-xs {
            .#{$module}__header {
                display: block;
            }

            .#{$module}__title {
                display: inline-block;
                margin: 0 auto 10px;
                padding-bottom: 6px;

                &::after {
                    display: block;
                }
            }
        }
    }
}
// end: module --------------------------------
