$module: 'hero-tabs';

.#{$module} {
	@extend %margin-auto;
	max-width: 760px;
	margin-right: -20px;
	margin-left: -20px;
	padding: 20px 20px 0;

	.sub-section--home-cert-stats & {
		@media (min-width: $screen-sm-min) {
			max-width: 650px;
		}

		.#{$module}__description {
			margin-bottom: 2rem;
		}

		.#{$module}__tab-content {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	&__btn-cta {
		@extend %ace-btn-stroke--reversed;
		@extend %margin-auto;
	}

	&__btn-cta--course-preview {
		@extend %ace-btn-stroke--green;
		padding: 1.2rem 3rem;
	}

	&__description {
		color: #fff;
	}

	&__pagination {
		margin-bottom: 10px;
		color: $green;
		font-size: remUnit(13px);
		text-align: center;
	}

	&__pagination-inner {
		display: inline-block;
		width: 2em;
		border: 1px solid $green;
		border-radius: 1em;
		line-height: 2;
	}

	&__tab {
		@extend %button;
		position: relative;
		display: block;
		padding: 10px 0;
		color: $blue;
		background-color: $gray-light;
		border-color: transparent;
		font-size: remUnit(10px);
		line-height: 1.25;
		white-space: normal;

		&:hover,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background: $green;
			color: #fff;
		}

		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}

		span {
			display: inline-block;
			vertical-align: middle;
		}
	}

	&__tab-content {
		@extend .tab-content;

		.float-new-flag {
			position: absolute;
			margin-top: -50px;
			margin-left: -50px;
			display: block;
		}

		@media (max-width: $screen-sm-min) {
			.float-new-flag {
				margin-left: 0;
				margin-top: -90px;
				margin-left: 15px;
			}
		}
	}

	&__tab-nav {
		margin: 0 -20px;
		padding: 30px 0 0;
		list-style: none;
		text-align: center;
		font-size: 0;

		li {
			width: 25%;
			position: relative;
			display: inline-block;
			border-right: 1px solid $gray-med;
			vertical-align: middle;

			&:last-child {
				border: none;
			}
		}

		li.active {
			border-color: $green;

			.#{$module}__tab {
				background: $green;
				color: #fff;
			}
		}

		li.active::before {
			display: block;
			position: absolute;
			top: -13px;
			left: 50%;
			content: '';
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
			border-right: 13px solid transparent;
			border-bottom: 13px solid $green;
			border-left: 13px solid transparent;
		}
	}

	&__tab-pane {
		@extend .tab-pane;
	}

	&__title {
		margin-bottom: 20px;
		color: #fff;
		font-size: remUnit(18px);
		font-weight: $font-weight-semibold;
		text-align: center;
	}

	&--blue {
		background: rgba(2,38,60,0.8);
	}

	&--transparent {
		background: rgba(2,38,60,0.0);
	}

	&--referral-rewards {
		.#{$module}__tab-content {
			min-height: 332px;

			@media (min-width: $screen-sm-min) {
			}
		}
	}

	@media (min-width: $screen-sm-min) {
		margin-right: auto;
		margin-left: auto;

		&__tab {
			padding: 10px;
		}
	}

	@media (min-width: $screen-md-min) {
		&__btn {
			font-size: remUnit(14px);
		}

		&__pagination {
			font-size: remUnit(18px);
		}

		&__tab {
			padding: 15px 20px;
			font-size: remUnit(14px);
		}

		&__title {
			font-size: remUnit(36px);
		}
	}
}
