.spc-m-x-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.spc-m-0 {
	margin: 0 !important;
}

.spc-m-t-0 {
	margin-top: 0 !important;
}

.spc-m-r-0 {
	margin-right: 0 !important;
}

.spc-m-b-0 {
	margin-bottom: 0 !important;
}

.spc-m-l-0 {
	margin-left: 0 !important;
}

.spc-m-x-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.spc-m-y-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.spc-m-half {
	margin: .5rem !important;
}

.spc-m-t-half {
	margin-top: .5rem !important;
}

.spc-m-r-half {
	margin-right: .5rem !important;
}

.spc-m-b-half {
	margin-bottom: .5rem !important;
}

.spc-m-l-half {
	margin-left: .5rem !important;
}

.spc-m-x-half {
	margin-right: .5rem !important;
	margin-left: .5rem !important;
}

.spc-m-y-half {
	margin-top: .5rem !important;
	margin-bottom: .5rem !important;
}

.spc-m-1 {
	margin: 1rem !important;
}

.spc-m-t-1 {
	margin-top: 1rem !important;
}

.spc-m-r-1 {
	margin-right: 1rem !important;
}

.spc-m-b-1 {
	margin-bottom: 1rem !important;
}

.spc-m-l-1 {
	margin-left: 1rem !important;
}

.spc-m-x-1 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.spc-m-y-1 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.spc-m-1-half {
	margin: 1.5rem !important;
}

.spc-m-t-1-half {
	margin-top: 1.5rem !important;
}

.spc-m-r-1-half {
	margin-right: 1.5rem !important;
}

.spc-m-b-1-half {
	margin-bottom: 1.5rem !important;
}

.spc-m-l-1-half {
	margin-left: 1.5rem !important;
}

.spc-m-x-1-half {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}

.spc-m-y-1-half {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.spc-m-2 {
	margin: 2rem !important;
}

.spc-m-t-2 {
	margin-top: 2rem !important;
}

.spc-m-r-2 {
	margin-right: 2rem !important;
}

.spc-m-b-2 {
	margin-bottom: 2rem !important;
}

.spc-m-l-2 {
	margin-left: 2rem !important;
}

.spc-m-x-2 {
	margin-right: 2rem !important;
	margin-left: 2rem !important;
}

.spc-m-y-2 {
	margin-top: 2rem !important;
	margin-bottom: 2rem !important;
}

.spc-m-3 {
	margin: 3rem !important;
}

.spc-m-t-3 {
	margin-top: 3rem !important;
}

.spc-m-r-3 {
	margin-right: 3rem !important;
}

.spc-m-b-3 {
	margin-bottom: 3rem !important;
}

.spc-m-l-3 {
	margin-left: 3rem !important;
}

.spc-m-x-3 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}

.spc-m-y-3 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.spc-m-4 {
	margin: 4rem !important;
}

.spc-m-t-4 {
	margin-top: 4rem !important;
}

.spc-m-r-4 {
	margin-right: 4rem !important;
}

.spc-m-b-4 {
	margin-bottom: 4rem !important;
}

.spc-m-l-4 {
	margin-left: 4rem !important;
}

.spc-m-x-4 {
	margin-right: 4rem !important;
	margin-left: 4rem !important;
}

.spc-m-y-4 {
	margin-top: 4rem !important;
	margin-bottom: 4rem !important;
}

.spc-m-5 {
	margin: 5rem !important;
}

.spc-m-t-5 {
	margin-top: 5rem !important;
}

.spc-m-r-5 {
	margin-right: 5rem !important;
}

.spc-m-b-5 {
	margin-bottom: 5rem !important;
}

.spc-m-l-5 {
	margin-left: 5rem !important;
}

.spc-m-x-5 {
	margin-right: 5rem !important;
	margin-left: 5rem !important;
}

.spc-m-y-5 {
	margin-top: 5rem !important;
	margin-bottom: 5rem !important;
}

.spc-p-0 {
	padding: 0 !important;
}

.spc-p-t-0 {
	padding-top: 0 !important;
}

.spc-p-r-0 {
	padding-right: 0 !important;
}

.spc-p-b-0 {
	padding-bottom: 0 !important;
}

.spc-p-l-0 {
	padding-left: 0 !important;
}

.spc-p-x-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.spc-p-y-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.spc-p-half {
	padding: .5rem !important;
}

.spc-p-t-half {
	padding-top: .5rem !important;
}

.spc-p-r-half {
	padding-right: .5rem !important;
}

.spc-p-b-half {
	padding-bottom: .5rem !important;
}

.spc-p-l-half {
	padding-left: .5rem !important;
}

.spc-p-x-half {
	padding-right: .5rem !important;
	padding-left: .5rem !important;
}

.spc-p-y-half {
	padding-top: .5rem !important;
	padding-bottom: .5rem !important;
}

.spc-p-1 {
	padding: 1rem !important;
}

.spc-p-t-1 {
	padding-top: 1rem !important;
}

.spc-p-r-1 {
	padding-right: 1rem !important;
}

.spc-p-b-1 {
	padding-bottom: 1rem !important;
}

.spc-p-l-1 {
	padding-left: 1rem !important;
}

.spc-p-x-1 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.spc-p-y-1 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

.spc-p-1-half {
	padding: 1.5rem !important;
}

.spc-p-t-1-half {
	padding-top: 1.5rem !important;
}

.spc-p-r-1-half {
	padding-right: 1.5rem !important;
}

.spc-p-b-1-half {
	padding-bottom: 1.5rem !important;
}

.spc-p-l-1-half {
	padding-left: 1.5rem !important;
}

.spc-p-x-1-half {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}

.spc-p-y-1-half {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.spc-p-2 {
	padding: 2rem !important;
}

.spc-p-t-2 {
	padding-top: 2rem !important;
}

.spc-p-r-2 {
	padding-right: 2rem !important;
}

.spc-p-b-2 {
	padding-bottom: 2rem !important;
}

.spc-p-l-2 {
	padding-left: 2rem !important;
}

.spc-p-x-2 {
	padding-right: 2rem !important;
	padding-left: 2rem !important;
}

.spc-p-y-2 {
	padding-top: 2rem !important;
	padding-bottom: 2rem !important;
}

.spc-p-3 {
	padding: 3rem !important;
}

.spc-p-t-3 {
	padding-top: 3rem !important;
}

.spc-p-r-3 {
	padding-right: 3rem !important;
}

.spc-p-b-3 {
	padding-bottom: 3rem !important;
}

.spc-p-l-3 {
	padding-left: 3rem !important;
}

.spc-p-x-3 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}

.spc-p-y-3 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.spc-p-4 {
	padding: 4rem !important;
}

.spc-p-t-4 {
	padding-top: 4rem !important;
}

.spc-p-r-4 {
	padding-right: 4rem !important;
}

.spc-p-b-4 {
	padding-bottom: 4rem !important;
}

.spc-p-l-4 {
	padding-left: 4rem !important;
}

.spc-p-x-4 {
	padding-right: 4rem !important;
	padding-left: 4rem !important;
}

.spc-p-y-4 {
	padding-top: 4rem !important;
	padding-bottom: 4rem !important;
}

.spc-p-5 {
	padding: 5rem !important;
}

.spc-p-t-5 {
	padding-top: 5rem !important;
}

.spc-p-r-5 {
	padding-right: 5rem !important;
}

.spc-p-b-5 {
	padding-bottom: 5rem !important;
}

.spc-p-l-5 {
	padding-left: 5rem !important;
}

.spc-p-x-5 {
	padding-right: 5rem !important;
	padding-left: 5rem !important;
}

.spc-p-y-5 {
	padding-top: 5rem !important;
	padding-bottom: 5rem !important;
}

//**************
// One-offs to be expanded
//*************/
.spc-p-l-0-sm-up {
	@media (min-width: $screen-sm-min) {
		padding-left: 0 !important;
	}
}


// Misc

.child-li-m-b-half {
  li {
    margin-bottom: .5rem !important;
  }
}

.child-li-m-b-1 {
  li {
    margin-bottom: 1rem !important;
  }
}

.child-li-m-b-1-half {
  li {
    margin-bottom: 1.5rem !important;
  }
}

.child-li-m-b-2 {
  li {
    margin-bottom: 2rem !important;
  }
}

.child-p-m-b-half {
  p {
    margin-bottom: .5rem !important;
  }
}

.child-p-m-b-1 {
  p {
    margin-bottom: 1rem !important;
  }
}

.child-p-m-b-1-half {
  p {
    margin-bottom: 1.5rem !important;
  }
}

.child-p-m-b-2 {
  p {
    margin-bottom: 2rem !important;
  }
}

/* Viewport Specific BR Tags */

.br-all {
  display: block !important;
}

.br-xs {
  display: none !important;

  @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
    display: block !important;
  }

  &-up {
    display: none !important;
    @media (min-width: $screen-xs-min){
      display: block !important;
    }
  }

  &-down {
    display: none !important;
    @media (max-width: $screen-sm-min - 1){
      display: block !important;
    }
  }
}

.br-sm {
  display: none !important;

  @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
    display: block !important;
  }

  &-up {
    display: none !important;
    @media (min-width: $screen-sm-min){
      display: block !important;
    }
  }

  &-down {
    display: none !important;
    @media (max-width: $screen-md-min - 1){
      display: block !important;
    }
  }
}

.br-md {
  display: none !important;

  @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
    display: block !important;
  }

  &-up {
    display: none !important;
    @media (min-width: $screen-md-min){
      display: block !important;
    }
  }

  &-down {
    display: none !important;
    @media (max-width: $screen-lg-min - 1){
      display: block !important;
    }
  }
}

.br-lg {
  display: none !important;

  @media (min-width: $screen-lg-min) {
    display: block !important;
  }
}
