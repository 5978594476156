$module: 'hero-panel';

// ===================================================
// Note: If the source image is not:
// 1400px x 420px (or 2800 x 840 etc..)
// A variant is needed see: _hero-panel-variant.scss
// ===================================================

.#{$module} {
	// Variables
	// ==========================
	$hero_title_color: #fff;
	$hero_title_font_size_sm: remUnit(30px);
	$hero_title_font_size_md: remUnit(35px);
	$hero_title_font_size_lg: remUnit(54px);
	$hero_title_line-height: lh(54px, 60px);

	$hero_subtitle_color: #fff;
	$hero_subtitle_font_size_sm: remUnit(16px);
	$hero_subtitle_font_size_md: remUnit(24px);
	$hero_subtitle_font_size_lg: remUnit(45px);
	$hero_subtitle_font_weight_sm: 500;	

	$hero_description_color: #fff;
	$hero_description_font_size_sm: remUnit(16px);
	$hero_description_font_size_lg: remUnit(16px);
	$hero_description_line-height: lh(16px, 24px);

	$hero_image_width: 1400px;
	$hero_image_height: 420px;
	$hero_footer_row_height: vw(133px, $hero-image-width);



	width: 100%;
	display: block;
	position: relative;
	overflow: hidden;
	background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

	&--margin-bottom {
		margin-bottom: 40px;
	}


	&__image {
		width: 100%;
		height: auto;
		display: block;
		border: 0;
		vertical-align: bottom;
		zoom: 1;
	}

	&__image--video {
		position: absolute;
		top: 50%;
		right: 0;
		bottom: 0;
		left: 0;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	&__description,
	&__header {
		text-align: center;
		position: absolute;
		height: 50%;
	}
	// On mobile scale the height of the header element
	// to match the proportions of the banner image.
	// Use the heigh of the image divided by 1400
	// to calculate the height in viwport units
	&__header {
		height: vw($hero-image-height, $hero-image-width);
		width: 100%;
		display: table-cell;
		vertical-align: middle;
		top: 0;
		right: 0;
		left: 0;
	}


	&__description {
		position: relative;
		top: 100%; // push it below the image and header
		right: 0;
		bottom: 0;
		left: 0;
		padding: 40px 20px;
		background-repeat: no-repeat;
		background-size: cover;
		font-size: $hero_description_font_size_sm;
		line-height: $hero_description_line-height;
		color: $hero_description_color;
		p {
			color: $hero_description_color;
		}

		&--mobile-bg-red,
		&--mobile-bg-blue {
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}

		&--mobile-bg-red {
			background-image: url('#{$images-path-temp}wallpaper-gym-red.jpg');

		}
		&--mobile-bg-blue {
			background-image: url('#{$images-path-temp}cube-row-blue-halftone.jpg');
		}
	}

	&__title {
		display: block;
		position: relative;
		top: 50%;
		transform: translate(0,-50%);
		color: $hero_title_color;
		line-height: $hero_title_line-height;
		margin: 0 auto;
		padding: 0;
		@extend %font-muli-semibold;
		font-size: $hero_title_font_size_sm;
	}

	&__subtitle {
		display: block;
		position: relative;
		top: 50%;
		transform: translate(0,-50%);
		color: $hero_title_color;
		line-height: $hero_title_line-height;
		margin: 0 auto;
		padding: 0;
		@extend %font-muli-semibold;
		font-size: $hero_subtitle_font_size_sm;
		font-weight: $hero_subtitle_font_weight_sm;
	}

	&__button {
		@extend %ace-btn-primary-jumbo--red;
		white-space: normal;
		margin: 0 auto 30px;

	}

	&__footer-row {
		width: 100%;
		text-align: center;
		padding: 0 20px 20px 20px;
	}

	&__search-form-wrap {
		max-width: 400px;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 20px;
		padding: 30px;
		background: rgba(0,0,0,0.6);
	}

	&__search-form {
		position: relative;
		border-bottom: 1px solid #fff;
	}

	&__search-label {
		@extend .sr-only;
	}

	&__search-input {
		@extend .form-control;
		@include placeholder(#FFFFFF);
		width: 100%;
		padding-right: 21px;
		position: relative;
		padding-left: 0;
		background-color: transparent;
		border: none;
		color: #fff;
	}

	&__search-submit {
		width: 28px;
		border: none;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		cursor: pointer;
		text-align: center;
		//padding-top: 2px 4px;
		background-color: transparent;
		color: #fff;
		font-size: remUnit(20px);
	}

	// ==========================================================================
	// Medium devices (desktops, 992px and up)
	// ==========================================================================
	@media (min-width: $screen-md-min) {
		height: vw($hero-image-height, $hero-image-width);
		display: table;
		vertical-align: middle;

		&__image {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		&__image--video {
			top: 50%;
		}

		&__content {
			position: relative;
			display: table-cell;
			vertical-align: middle;
		}

		&__description,
		&__header {
			position: relative;
			width: 100%;
			height: 0;
			display: table;
		}
		&__description {
			padding: 0;
			> p:last-of-type {
				margin-bottom: 0;
			}
		}

		// This is overly specified to keep from having
		// to repeat height: 0 in hero-panel-variant(s)
		div.#{$module}__content header.#{$module}__header,
		div.#{$module}__content div.#{$module}__header {
			// max-width: 35%;
			margin: auto;
			height: 0;
		}

		&__title {
			height: auto;
			position: relative;
			top: auto;
			margin-bottom: 13px;
			transform: none;
			font-size: $hero_title_font_size_md;
		}
		&__subtitle {
			height: auto;
			position: relative;
			top: auto;
			margin-bottom: 13px;
			transform: none;
			font-size: $hero_subtitle_font_size_md;
		}
		&__header:only-child {
			.#{$module}__title {
				margin-bottom: 0;
			}
		}

		&__description {
			// add left right padding in hero-panel-variant
			max-width: 807px;
			top: auto;
			background-position: 100% 100%;
			background-size: 0;
			font-size: $hero_description_font_size_sm;
			margin-left: auto;
			margin-right: auto;
		}

		&__footer-row {
			position: absolute;
			left: 0; right:0; bottom: 0;
			text-align: center;
			padding-top: 10px;
			padding-bottom: 20px;
			height: $hero_footer_row_height;
			max-height: 133px;
			img {
				width: auto;
				height: 100%;
			}
		}
	}
	// end: Medium devices (desktops, 992px and up) ========================
	// ==========================================================================
	// Large devices (large desktops, 1200px and up)
	// ==========================================================================
	@media (min-width: $screen-lg-min) {
		&__title {
			font-size: $hero_title_font_size_lg;
		}
		&__subtitle{
			font-size: hero_subtitle_font_size_lg;
		}
	}
	// end: Large devices (large desktops, 1200px and up) ==================
}
// end: $module
