// Temporary for placeholder icon
.icon {
	// display: inline-block;
	// padding-right: 5px;
	// padding-left: 5px;
	// font-size: remUnit(18px);
	// line-height: 1;
	// vertical-align: baseline;
}
.icon {
	height: 1em;
	width: 1em;
	display: inline-block;
	line-height: 1;
	fill: currentColor;
	vertical-align: -0.15em;

	/* ===================
		Color Variations
	  =================== */
	&--white {fill: #fff;}
	&--black {fill: #000;}
	&--red {fill: $red;}
	&--red-light {fill: #f8ced1;}
	&--blue {fill: $blue;}
	&--blue-light {fill: $blue-light;}
	&--gray {fill: $gray;}
	&--gray-med {fill: $gray-med;}
	&--gray-light {fill: $gray-light;}
	&--green {fill: $green;}


	&--chevron-down-load-more {
		width: 34px;
		height: 10px;
		display: block;
		margin: 11.5px auto 0 auto;
	}

	/* ===================
		Social Icons
	  =================== */
	&--share-facebook {fill: #3b5998;}
	&--share-twitter {fill: #49c9f2;}
	&--share-pinterest {fill: #cd2129;}
	&--share-linkedin {fill: #007bb6;}
	&--share-google-plus {fill: #df4a32;}
	&--share-email {fill: #1ca54b;}

	/* ===================
		Course Icons
	  =================== */

	&-course {
		width: 24px;
		height: 24px;
		background-position: center center;
		background-size: 24px 24px;
		background-repeat: no-repeat;
		$icons: app audio book cd dvd ebook in-person learning-activity online-content pdf podcast quiz-online quiz-paper study-group video-live video-recording virtual-coaching workbook;

		@each $icon in $icons {
			$url-prefix: '/images/webcontent/assets/education-resources/continuing-education/course-component-icons/';
			&-#{$icon} {
				background-image: url($url-prefix + 'icon-course-' + $icon + '.svg');
			}
		}
	}
}
