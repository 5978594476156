$module: flex-split;

.#{$module} {
	display: flex;
	flex-direction: column;
	width: 100%;

	@media (min-width: $screen-sm-min) {
		flex-direction: row;
	}

	&__item {
	}

	&--stack-xs {
		display: flex;
		flex-direction: column;

		@media (min-width: $screen-xs-min) {
			flex-direction: row;
		}
	}

	&--stack-sm, &--default {
		display: flex;
		flex-direction: column;

		@media (min-width: $screen-sm-min) {
			flex-direction: row;
		}
	}

	&--stack-md {
		display: flex;
		flex-direction: column;

		@media (min-width: $screen-md-min) {
			flex-direction: row;
		}
	}
	// 50-50    -> /our-efforts/professionalism

	&--50-50 {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0;

		@media (min-width: $screen-sm-min) {
			flex-direction: row;
			flex-wrap: nowrap;
			padding: 1rem 0;
		}

		.#{$module}__item {
			width: 100%;
			padding: 1rem;
			text-align: left;

			@media (min-width: $screen-sm-min) {
				width: 50%;

				&:nth-child(1) {
					padding-right: 1.5rem;
				}

				&:nth-child(2) {
					padding-left: 1.5rem;
				}
			}

			p {
				margin-bottom: 2rem;
				font-weight: 400;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	// 3 columns

	&--33pct {
		justify-content: center;
		width: 100%;
		padding: 0;

		@media (min-width: $screen-sm-min) {
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			padding: 1rem 0;
		}

		.#{$module}__item {
			width: 100%;
			margin-bottom: 2rem;
			padding: 0;

			@media (min-width: $screen-sm-min) {
				width: calc(33.33% - 1rem);
				margin-bottom: 0;
			}

			p {
				margin-bottom: 2rem;
				font-weight: 400;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	// 4 columns

	&--25pct {
		justify-content: center;
		width: 100%;
		padding: 0;

		@media (min-width: $screen-sm-min) {
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			padding: 1rem 0;
		}

		.#{$module}__item {
			width: 100%;
			margin-bottom: 2rem;
			padding: 0;

			@media (min-width: $screen-sm-min) {
				width: calc(25% - 1rem);
				margin-bottom: 0;
			}
		}
	}
	// 5 columns

	&--20pct {
		justify-content: center;
		width: 100%;
		padding: 0;

		@media (min-width: $screen-sm-min) {
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			padding: 1rem 0;
		}

		.#{$module}__item {
			width: 100%;
			margin-bottom: 2rem;
			padding: 0;

			@media (min-width: $screen-sm-min) {
				width: calc(20% - 1rem);
				margin-bottom: 0;
			}
		}
	}
	// Post Exam          -> /fitness-certifications/certification-exam

	&--post-exam {
		padding: 1rem 0;
		flex-direction: column-reverse;
		align-items: center;

		@media (min-width: $screen-sm-min) {
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: flex-start;
			text-align: left;
		}

		.#{$module}__item {
			padding: 10px 20px 0;

			&:nth-child(1) {
				@media (min-width: $screen-sm-min) {
					padding-right: 1.5rem;
				}

				@media (min-width: $screen-md-min) {
					padding-right: 3rem;
				}
			}
		}

		img {
			display: block;
			width: 80%;
			max-width: 342px;
			margin: 0 auto 1rem;

			@media (min-width: $screen-sm-min) {
				width: 342px;
				margin-bottom: 0;
			}
		}

		&-reverse {

			@media (min-width: $screen-sm-min) {
				flex-direction: row-reverse;
			}
		}
	}
	// Systems-Change Map -> /our-efforts/population-health-pfa

	&--sys-change-map {
		padding: 1rem 0;
		flex-direction: column-reverse;
		align-items: center;

		@media (min-width: $screen-sm-min) {
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: flex-start;
			margin-top: 2rem;
		}

		.#{$module}__item {
			text-align: left;

			&:nth-child(1) {
				text-align: center;
				padding-right: 2rem;
			}
		}

		img {
			display: block;
			width: 100%;
			max-width: 342px;

			@media (min-width: $screen-sm-min) {
				width: 342px;
			}
		}
	}
	// PFA Video          -> /our-efforts/population-health

	&--pfa-video {
		display: flex;
		width: 100%;
		padding: 1rem 0;
		flex-wrap: nowrap;
		flex-direction: column;

		@media (min-width: $screen-sm-min) {
			flex-direction: row;
			padding: 1rem 0;
		}

		.#{$module}__item {
			width: 100%;
			text-align: left;
			padding: 1rem;

			@media (min-width: $screen-sm-min) {
				width: 50%;

				.flex-split > div:nth-child(1) {
					padding-right: 1.5rem;
				}

				.flex-split > div:nth-child(2) {
					padding-left: 1.5rem;
				}
			}
		}
	}
	// GFI Testimonial

	&--testimonial-gfi {
		flex-direction: column;

		@media (min-width: $screen-sm-min) {
			flex-direction: row;
		}

		.#{$module}__item {
			padding: 1rem 0;

			@media (min-width: $screen-sm-min) {
				flex-direction: row;
				padding: 1rem;
			}

			&:nth-child(1) {
				padding-top: 0;

				@media (min-width: $screen-sm-min) {
					padding-top: 1rem;
				}
			}

			img {
				width: 60px;

				@media (min-width: $screen-sm-min) {
					width: auto;
				}
			}
		}
	}
	// GFI 3 Step Cards

	&--gfi-3-step {
		.#{$module}__item {

			@media (min-width: $screen-sm-min) {
				width: calc(33.33% - 1rem);
			}

			@media (min-width: $screen-lg-min) {
				width: calc(33.33% - 1.5rem);
				margin-bottom: 0;
			}

			p {
				margin-bottom: 2rem;
				font-weight: 400;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	// GFI Curriculum Intro

	&--gfi-curriculum-intro {
		.#{$module}__item {

			&:nth-child(1) {
				max-width: 404px;

				@media (min-width: $screen-sm-min) {
					padding-right: 1rem;
					min-width: 40%;
				}
			}
		}
	}
	// GFI Curriculum

	&--gfi-curriculum {
		.#{$module}__item {
			padding-left: 0;
			padding-right: 0;

			@media (min-width: $screen-sm-min) {
			}
		}
	}
	// GFI Career Support

	&--career-support {
		justify-content: space-between;

		.#{$module}__item {

			@media (min-width: $screen-sm-min) {
				width: calc(50% - 1rem);
				padding: .5rem 0;
			}
		}
	}
	// HC Live

	&--hc-live {
		justify-content: space-between;
		align-items: center;
		max-width: 340px;
		margin: 0 auto;

		@media (min-width: $screen-sm-min) {
			max-width: none;
		}

		.#{$module}__item {

			&:nth-child(1) {
				margin-bottom: 1rem;
			}

			@media (min-width: $screen-sm-min) {
				max-width: none;
				padding: .5rem 0;

				&:nth-child(1) {
					width: 35%;
					margin-bottom: 0;

					img {
						display: block;
						width: 100%;
					}
				}

				&:nth-child(2) {
					width: 70%;
					padding-left: 3rem;
				}
			}
		}

		&-reverse {

			@media (min-width: $screen-sm-min) {
				flex-direction: row-reverse;
			}

			.#{$module}__item {
				@media (min-width: $screen-sm-min) {
					&:nth-child(2) {
						padding-left: 0;
						padding-right: 3rem;
					}
				}
			}
		}
	}
	// 2 Checklist Columns

	&--2col-checklists {
		justify-content: space-between;
		align-items: flex-start;

		@media (max-width: 767px) {
			flex-direction: column;
		}

		> .#{$module}__item {
			width: calc(50% - 1rem);

			@media (max-width: 767px) {
				width: 100%;

				&:first-child {
					margin-bottom: 1rem;
				}
			}
		}
	}
	// IMG + Content Block

	&--img-plus-content-block,
	&--img-plus-content-block-reverse {
		justify-content: space-between;
		align-items: center;

		@media (max-width: 991px) {
			flex-direction: column;
		}

		> .flex-split__item {
			&:first-child {
				padding: 0;
				width: 300px;
				max-width: 25%;

				@media (max-width: 1199px) {
					width: 250px;
				}

				@media (max-width: 991px) {
					width: auto;
					max-width: none;
					margin: 0 auto;
					padding: 0 1rem 2rem;
				}

				img {
					display: block;
					max-width: 100%;
					border-radius: 50%;
					margin: 0 auto;
				}
			}

			&:nth-child(2) {
				flex: 1;
				padding-left: 2rem;

				@media (max-width: 991px) {
					padding: 0;
				}
			}
		}
	}

	&--img-plus-content-block-reverse {
		@media (min-width: 992px) {
			flex-direction: row-reverse;
		}

		> .flex-split__item {
			&:nth-child(2) {
				padding-left: 0;
				padding-right: 2rem;

				@media (max-width: 991px) {
					padding: 0;
				}
			}
		}
	}
}
