$module: 'page-tools';

.#{$module} {
	padding-bottom: 20px;

	a, button {
		display: block;
		flex: 1;
		padding: 9px 15px;
		font-size: remUnit(12px);
	}

	&.sticky {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		width: 100%;
		padding-bottom: 0;
		background-color: $blue;

		.#{$module}__wrap {
			padding: 10px 30px;

			@media (max-width: 600px) {
			  padding: 10px;
			}
		}
	}

	@media (min-width: $screen-md-min) {
		position: absolute;
		visibility: hidden;
	}
}
