$module: 'sub-section';

.#{$module} {
	padding: 40px 0;
	background-color: $gray-light;
	background-position: 50% 50%;
	background-size: cover;
	text-align: center;

	&--find-ace-pro {
		.widget__head {
			display: none;
		}
	}

	&--reverse {
		background-color: #fff;
	}

  &--red {
    background-color: $red;
    color: #fff;
  }

  &--blue {
    background-color: $blue;
    color: #fff;

    .#{$module}__label {
			@extend %diamond-title-variant--white;
		}

		.#{$module}__title {
			color: #fff;
		}
  }

  &.#{$module}--home-cert-stats {
    @media (max-width: $screen-sm-min - 1) {
      padding: 0;
      background-size: 0 0;
      background-position: -9999em -9999em;

      .#{$module}__wrap {
        background-color: $blue;
        padding-top: 40px;
      }

      .#{$module}__header {
        position: relative;
        margin: 0 auto;
        padding: 0;
      }

      .#{$module}__more-btn {

      }

      .#{$module}__label {
        position: relative;
        top: 0;
      }

      .#{$module}__title {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
      }

      .#{$module}__image-inline {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
  }

	&__btn-center {
		@extend %ace-btn-stroke--blue;
	}

	&__btn-load-more {
		// @extend %button;
		@extend %ace-btn-floating--blue;
	}

	&__description {
		@extend %margin-auto;
		font-size: remUnit(16px);
	}

	&__filter-label {
		text-transform: uppercase;
	}

	&__filter-select,
	.selectric-sub-section__filter-select {
		@include selectric-variant($red, #fff, $red, $red);
		margin-bottom: 15px;
		text-align: left;
	}

	&__filter-search-wrap {
		position: relative;
		max-width: 400px;
		margin-right: auto;
		margin-left: auto;
		border-bottom: 1px solid $blue;
	}

	&__filter-search-label {
		@extend .sr-only;
	}

	&__filter-search-input {
		@extend .form-control;
		width: 100%;
		padding-right: 21px;
		position: relative;
		padding-left: 0;
		background-color: transparent;
		border: none;
	}

	&__filter-search-submit {
		width: 28px;
		border: none;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		cursor: pointer;
		text-align: center;
		background-color: transparent;
		font-size: remUnit(20px);
	}

	&__header {
		max-width: 740px;
		margin: 0 auto 40px auto;
		padding: 0 20px;

		&--wide-description {
			max-width: none;
			.#{$module}__label,
			.#{$module}__title {
				max-width: 740px;
			}

			.#{$module}__description {
				max-width: 814px;
			}

		}

	}

	&__label {
		@extend %diamond-title;
		@extend %diamond-title-variant--blue;
		@extend %margin-auto;
		margin-bottom: 40px;

		&--blue {
			@extend %diamond-title-variant--blue;
		}

		&--red {
			@extend %diamond-title-variant--red;
		}
		&--white {
			@extend %diamond-title-variant--white;
		}
		&--gray {
			@extend %diamond-title-variant--gray;

		}
		&--green {
			@extend %diamond-title-variant--green;
		}

	}


	&__more-btn {
		@extend %ace-btn-floating--green;
		display: none;
		float: right;
		margin-right: 10px;
		padding: 0;
		font-size: remUnit(12px);
		line-height: 30px;
	}

	&__post {
		@include make-sm-column(4, 40px);
		opacity: 1;
		visibility: visible;

		&.hidden {
			opacity: 0;
			visibility: hidden;
		}
	}

	&--related {
		.#{$module}__post {
			@include make-sm-column(4, 0);
		}
		.#{$module}__wrap {
			padding-left: 10px;
			padding-right: 10px;
		}
	}


	&__post--full {
		@include make-sm-column(3, 20px);
	}

	&__post-preview {
		@include make-sm-column(6, 40px);
	}

	&__post-preview--full {
		@include make-sm-column(6, 20px);
	}

	&__row {
		max-width: 1080px;
		@extend %margin-auto;
	}

	&__row--full {
		max-width: 1400px;
	}
	&__row--no-max {
		max-width: none;
	}

  &__row--limit-width {
    max-width: calc(100% - 100px);

    @media (max-width: 47.999em) {
      max-width: none;
    }
  }

	&__row--sub-items-inline-block {

		.#{$module}__post {
			@media (min-width: $screen-sm-min) {
				float: none;
				display: inline-block;
			}
		}
	}

	&__slider-wrap {
		@include clearfix;
		position: relative;
		margin-right: -20px;
		margin-left: -20px;
		padding-right: 20px;
		padding-left: 20px;
	}

	&__slide-next,
	&__slide-prev {
		@extend %ace-btn-floating--green;
		position: absolute;
		left: 0;
		top: 50%;
		margin: 0;
		padding: 0;
		font-size: remUnit(30px);
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	&__slide-next {
		left: auto;
		right: 0;
	}

	&__title {
		@include sub-section-title-styles();
	}

	&__title--red {
		color: $red !important;
	}

  &__title--white {
    color: #fff !important;
  }

	&__wrap {
		@extend .container-fluid;
		max-width: 1080px;
		padding-right: 20px;
		padding-left: 20px;
	}

	&__item-grid {
		width: 100%;
		display: table;
		table-layout: fixed;
		margin-left: auto;
		margin-right: auto;
	}

	&__item-grid-row {
		@extend %margin-auto;
	}

	&--faq {
		padding-bottom: 0;
		background-color: #fff;
	}
	// Sizing Modifiers
	// ===========================
	&--full {
		.#{$module}__header {
			max-width: none;
		}

		.#{$module}__description {
			max-width: 740px;
		}

		.#{$module}__label {
			max-width: 740px;
		}

		.#{$module}__wrap {
			@extend .container-fluid;
			max-width: 1400px;
			padding-right: 20px;
			padding-left: 20px;

			&--narrow {
				@media (min-width: 48em) {
					padding-left: 80px;
					padding-right: 80px;
				}
			}
		}
		&-related {
			.#{$module}__wrap {
				padding-right: 10px;
				padding-left: 10px;
			}
		}
	}
	// Study Center Video Learning
	&--full-6col-sm3col {
		.#{$module}__wrap {
			padding-left: 10px;
			padding-right: 10px;
		}
		.#{$module}__post--full {
			position: relative;
	    float: left;
	    width: 50%;
	    min-height: 1px;
			padding-left: 5px;
			padding-right: 5px;

			.post-brief__btn-video {
				transform: scale(.75);
				transform-origin: center;
			}
		}
	}
	// ACE Answers
	&--ace-answers-events {
		text-align: left;

		@media (min-width: $screen-sm-min) {
		  text-align: center;
		}
	}

	// Themeing Modifiers
	// ===========================
	&--dark {
		background-color: $blue;

		.#{$module}__btn-center {
			@extend %ace-btn-stroke--reversed;
		}

		.#{$module}__description {
			color: #fff;
		}

		.#{$module}__label {
			@extend %diamond-title-variant--red;
		}

		.#{$module}__title {
			color: #fff;
		}
	}

	&--light {
		.#{$module}__label {
			@extend %diamond-title-variant--gray;
		}

		.#{$module}__title {
			color: #fff;
		}
	}

	&--white {
		background: #fff;
	}
	// General Modifiers/Utilities
	// ==============================
	&--flush-top {
		padding-top: 0;
	}

	&--flush-bottom {
		padding-bottom: 0;
	}
	@media (min-width: $screen-xs-min) {
		&--related {
			.#{$module}__wrap {
				padding-left: 20px;
				padding-right: 20px;
			}
			.#{$module}__post {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
		// Study Center Video Learning
		&--full-6col-sm3col {
			.#{$module}__wrap {
				padding-left: 10px;
				padding-right: 10px;
			}
			.#{$module}__post--full {
				padding-left: 10px;
				padding-right: 10px;

				.post-brief__btn-video {
					transform: scale(1);
					transform-origin: center;
					bottom: 10px;
					right: 10px;
				}
			}
		}
	}



	@media (min-width: $screen-md-min) {
		padding: 60px 0;

		&__filter-select--inline,
		.selectric-sub-section__filter-select--inline {
			display: inline-block;
			width: auto;
			margin-bottom: 0;
			vertical-align: middle;
		}

		&__header {
			margin-bottom: 60px;
		}
		&__header--flush-bottom {
			margin-bottom: 0;
		}

		&__more-btn {
			display: block;
		}

		&__item-grid-row {
			display: table-row;
		}

		&__item-grid-cell {
			width: 50%;
			display: table-cell;
			margin-bottom: 0;
		}

		&__item-grid-row--2col {
			.#{$module}__item-grid-cell {
				width: 50%;
				vertical-align: text-top;
			}

			.#{$module}__item-grid-cell--valign-middle {
				vertical-align: middle;
			}
		}

		&__item-grid-row--3col {
			.#{$module}__item-grid-cell {
				width: $width-1-3;
				vertical-align: text-top;
			}

			.#{$module}__item-grid-cell--valign-middle {
				vertical-align: middle;
			}
		}
		// Study Center Video Learning
		&--full-6col-sm3col {
			.#{$module}__wrap {
				padding-left: 20px;
				padding-right: 20px;
			}
			.#{$module}__post--full {
				width: 25%;
			}
		}

		&__slider-wrap {
			margin-right: auto;
			margin-left: auto;
			padding-right: 40px;
			padding-left: 40px;
		}
	}
}
