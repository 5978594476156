
.text {
  &-default {
    font-family: $font-family-muli;
    font-size: remUnit(15px);
    line-height: 1.66666667;
    color: $gray;

    @media (min-width: $screen-md-min) {
      font-size: remUnit(18px);
      line-height: 1.44444444;
    }
  }

  // FAMILY
  &-family {
    &-muli {
      font-family: $font-family-muli;
    }
    &-roboto {
      font-family: $font-family-roboto;
    }
    &-roboto-condensed {
      font-family: $font-family-roboto-condensed;
    }
    &-ludicrous {
    	font-family: Ludicrous;
    }
  }

  // COLORS
  &-black {
    color: #000 !important;
  }
  &-blue {
    color: $blue !important;
  }
  &-blue-light {
    color: $blue-light !important;
  }
  &-blue-med {
    color: $blue-med !important;
  }
  &-gray {
    color: $gray !important;
  }
  &-gray-med {
    color: $gray-med !important;
  }
  &-gray-light {
    color: $gray-light !important;
  }
  &-green {
    color: $green !important;
  }
  &-magenta {
    color: $magenta !important;
  }
  &-purple {
    color: $purple !important;
  }
  &-red {
    color: $red !important;
  }
  &-red-dark {
    color: $red-dark !important;
  }
  &-white {
    color: #fff !important;
  }
  &-yellow {
    color: $yellow !important;
  }
  &-yellow-light {
    color: $yellow-light !important;
  }

  // WEIGHT
  &-weight {
    &-light {
      font-weight: 300 !important;
    }
    &-normal {
      font-weight: 400 !important;
    }
    &-medium {
      font-weight: 500 !important;
    }
    &-bold {
      font-weight: 700 !important;
    }
    &-100 {
      font-weight: 100 !important;
    }
    &-200 {
      font-weight: 200 !important;
    }
    &-300 {
      font-weight: 300 !important;
    }
    &-400 {
      font-weight: 400 !important;
    }
    &-500 {
      font-weight: 500 !important;
    }
    &-600 {
      font-weight: 600 !important;
    }
    &-700 {
      font-weight: 700 !important;
    }
    &-800 {
      font-weight: 800 !important;
    }
    &-900 {
      font-weight: 900 !important;
    }
  }

  // STYLE
  &-style-normal {
    font-style: normal !important;
  }
  &-style-italic {
    font-style: italic !important;
  }

  // LINE HEIGHT
  &-lh {
    &-0 {
      line-height: 0 !important;
    }
    &-half {
      line-height: .5 !important;
    }
    &-1 {
      line-height: 1 !important;
    }
    &-1-1 {
      line-height: 1.1 !important;
    }
    &-1-2 {
      line-height: 1.2 !important;
    }
    &-1-3 {
      line-height: 1.3 !important;
    }
    &-1-4 {
      line-height: 1.4 !important;
    }
    &-1-5 {
      line-height: 1.5 !important;
    }
    &-1-6 {
      line-height: 1.6 !important;
    }
    &-1-7 {
      line-height: 1.7 !important;
    }
    &-1-8 {
      line-height: 1.8 !important;
    }
    &-1-9 {
      line-height: 1.9 !important;
    }
    &-2 {
      line-height: 2 !important;
    }
  }

  // SIZE

  &-size {
    &-200pct {
      font-size: 200%;
    }
    &-190pct {
      font-size: 190%;
    }
    &-180pct {
      font-size: 180%;
    }
    &-170pct {
      font-size: 170%;
    }
    &-160pct {
      font-size: 160%;
    }
    &-150pct {
      font-size: 150%;
    }
    &-140pct {
      font-size: 140%;
    }
    &-130pct {
      font-size: 130%;
    }
    &-120pct {
      font-size: 120%;
    }
    &-110pct {
      font-size: 110%;
    }
    &-100pct {
      font-size: 100%;
    }
    &-95pct {
      font-size: 95%;
    }
    &-90pct {
      font-size: 90%;
    }
    &-85pct {
      font-size: 85%;
    }
    &-80pct {
      font-size: 80%;
    }
    &-75pct {
      font-size: 75%;
    }
    &-70pct {
      font-size: 70%;
    }
    &-65pct {
      font-size: 65%;
    }
    &-60pct {
      font-size: 60%;
    }
    &-55pct {
      font-size: 55%;
    }
    &-50pct {
      font-size: 50%;
    }
    &-0 {
      font-size: 0;
    }
  }


  // WORDBREAK

  &-keep-all {
    -ms-word-break: keep-all;
    word-break: keep-all;
  }

  &-break-all {
    -ms-word-break: break-all;
    word-break: break-all;
  }

  &-break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  // WHITE SPACE NO WRAP

  &-wsp-nowrap {
    white-space: nowrap !important;

    &-xs {
      @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
        white-space: nowrap !important;
      }

      &-up {
        @media (min-width: $screen-xs-min){
          white-space: nowrap !important;
        }
      }

      &-down {
        @media (max-width: $screen-sm-min - 1){
          white-space: nowrap !important;
        }
      }
    }

    &-sm {
      @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        white-space: nowrap !important;
      }

      &-up {
        @media (min-width: $screen-sm-min){
          white-space: nowrap !important;
        }
      }

      &-down {
        @media (max-width: $screen-md-min - 1){
          white-space: nowrap !important;
        }
      }
    }

    &-md {
      @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
        white-space: nowrap !important;
      }

      &-up {
        @media (min-width: $screen-md-min){
          white-space: nowrap !important;
        }
      }

      &-down {
        @media (max-width: $screen-lg-min - 1){
          white-space: nowrap !important;
        }
      }
    }

    &-lg {
      @media (min-width: $screen-lg-min) {
        white-space: nowrap !important;
      }
    }
  }

  // WHITE SPACE NORMAL

  &-wsp-normal {
    white-space: normal !important;

    &-xs {
      @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
        white-space: normal !important;
      }

      &-up {
        @media (min-width: $screen-xs-min){
          white-space: normal !important;
        }
      }

      &-down {
        @media (max-width: $screen-sm-min - 1){
          white-space: normal !important;
        }
      }
    }

    &-sm {
      @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        white-space: normal !important;
      }

      &-up {
        @media (min-width: $screen-sm-min){
          white-space: normal !important;
        }
      }

      &-down {
        @media (max-width: $screen-md-min - 1){
          white-space: normal !important;
        }
      }
    }

    &-md {
      @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
        white-space: normal !important;
      }

      &-up {
        @media (min-width: $screen-md-min){
          white-space: normal !important;
        }
      }

      &-down {
        @media (max-width: $screen-lg-min - 1){
          white-space: normal !important;
        }
      }
    }

    &-lg {
      @media (min-width: $screen-lg-min) {
        white-space: normal !important;
      }
    }
  }

  // ALIGNMENT
  &-left {
    text-align: left !important;

    &-xs {
      @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
        text-align: left !important;
      }

      &-down {
        @media (max-width: $screen-sm-min - 1){
          text-align: left !important;
        }
      }
    }

    &-sm {
      @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        text-align: left !important;
      }
    }

    &-md {
      @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
        text-align: left !important;
      }
    }

    &-lg {
      @media (min-width: $screen-lg-min) {
        text-align: left !important;
      }
    }
  }

  &-center {
    text-align: center !important;

    &-xs {
      @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
        text-align: center !important;
      }

      &-down {
        @media (max-width: $screen-sm-min - 1){
          text-align: center !important;
        }
      }
    }

    &-sm {
      @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        text-align: center !important;
      }
    }

    &-md {
      @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
        text-align: center !important;
      }
    }

    &-lg {
      @media (min-width: $screen-lg-min) {
        text-align: center !important;
      }
    }
  }

  &-right {
    text-align: right !important;

    &-xs {
      @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
        text-align: right !important;
      }

      &-down {
        @media (max-width: $screen-sm-min - 1){
          text-align: right !important;
        }
      }
    }

    &-sm {
      @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        text-align: right !important;
      }
    }

    &-md {
      @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
        text-align: right !important;
      }
    }

    &-lg {
      @media (min-width: $screen-lg-min) {
        text-align: right !important;
      }
    }
  }

  &-justify {
    text-align: justify !important;
  }

  &-transform {
    &-none {
      text-transform: none !important;
    }
    &-lowercase {
      text-transform: lowercase !important;
    }
    &-uppercase {
      text-transform: uppercase !important;
    }
    &-capitalize {
      text-transform: capitalize !important;
    }
  }

  // DECORATION
  &-decoration {
    &-none {
      text-decoration: none !important;
    }
    &-overline {
      text-decoration: overline !important;
    }
    &-line-through {
      text-decoration: line-through !important;
    }
    &-underline {
      text-decoration: underline !important;
    }
  }
}
