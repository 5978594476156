.qn-chat-prompt {
    display: block;
    position: fixed;
    bottom: 150px;
    right: 40px;
    z-index: 2001;

    @media (max-width: $screen-sm-min - 1) {
        right: 20px;
        top: auto !important;
    }

    @media (min-width: $screen-sm-min) {
        z-index: 9999;
    }

    &__bubble {
        position: relative;
        width: auto;
        padding: .85rem 1.25rem;
        background-color: #fff;
        border-radius: 7px;
        box-shadow: 3px 4px 13px 0px rgba(0, 0, 0, 0.15);
        -webkit-user-select: none;
        user-select: none;
        z-index: 1;

        @media (min-width: $screen-sm-min) {
            padding: .85rem 3rem .85rem 1.5rem;
        }
        // Triangle Down
        &::after {
            content: "";
            display: block;
            position: absolute;
            right: 35px;
            bottom: -15px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 15px 15px 0;
            border-color: transparent #ffffff transparent transparent;
            box-shadow: 4px 0 6px -4px rgba(0, 0, 0, 0.15);
            z-index: 0;

            @media (min-width: $screen-sm-min) {
                display: none;
            }
        }

        &-close {
            position: absolute;
            top: -.75rem;
            right: -.75rem;
            width: 1.5rem;
            height: 1.5rem;
            padding: .1rem;
            margin: 0 auto;
            border-radius: 50%;
            color: $blue;
            background-color: #fff;
            cursor: pointer;
            box-shadow: 3px 3px 10px 2px rgba(0,0,0,.15);

            @media (min-width: $screen-sm-min) {
                right: auto;
                left: -.75rem;
            }

            svg {
                display: block;
                position: absolute;
                margin: 0 auto;
                top: 50%;
                right: 0;
                left: 0;
                text-align: center;
                transform: translateY(-50%);
            }
        }

        &-title {
            margin: 0 0 .25rem;
            font-size: .85rem;
            font-weight: bold;
            white-space: nowrap;
            color: $blue;
        }

        &-text {
            font-size: .85rem;
            margin: 0;
            white-space: nowrap;
            color: $blue;
            line-height: 1.3;
        }

        &-link {
            color: $red;
            text-decoration: underline;
        }
    }

    &__image {
        display: block;
        position: absolute;
        width: 75px;
        height: 75px;
        right: 0;
        bottom: -90px;
        border-radius: 50%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        z-index: 2001;

        @media (min-width: $screen-sm-min) {
            width: 70px;
            height: 70px;
            right: auto;
            bottom: auto;
            top: 50%;
            right: -37px;
            z-index: 1;
            transform: translateY(-50%);
        }
    }
}
