$module: 'slat-card-grid';

.#{$module} {
	@extend %margin-auto;
	text-align: left;

	&__header {
		padding-top: 25px;
		margin-bottom: 25px;
		@include clearfix();

		&--divider {
			border-top: 1px solid $gray-med;
		}
		&--share {
			padding-top: 0;
		}
	}
	&__header--share &__share-wrap {
		.widget {
			padding-bottom: 11.5px;
		}
	}
	&__header--share.#{$module}__header--divider {
		padding-top: 25px;
	}

	&__title-wrap,
	&__tools-wrap {
		width: 100%;
	}

	&__title {
		@extend %font-muli-bold;
		font-size: remUnit(24px);
		$color: $blue;
	}

	&__description {
		font-size: remUnit(16px);
		text-align: left;
	}

	&__form {
		select {
			border-color: $red;
		}
		@include selectric-variant($red, #fff, $red, $red);
	}

	&__form-label {
		@extend .sr-only;
	}

	&__row {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
		width: 100%;
		font-size: 0;
	}

	&__cell {
		display: flex;
		// flex: 1;
		align-self: stretch;
		align-items: stretch;
		width: 100%;
		font-size: 1rem;
		padding: .75rem 0;
	}

	&--bordered {
		border-bottom: 1px solid $gray-med;
		margin-bottom: 30px;
	}

	// Small devices (tablets, 768px and up)
	// ==========================================================================
	@media (min-width: $screen-sm-min) {
		&__row {
			flex-direction: row;
			flex-wrap: wrap;
		}
		// Regular Slat Cards
		// 2 Columns (default)
		&__cell {
			width: 50%;
			padding: .75rem;
		}
		&__header--share &__title-wrap {
			float: none;
			width: 100%;
		}
		&__header--share &__title {
			float: left;
			width: 50%;
		}
		&__header--share &__share-wrap {
			float: left;
			width: 50%;
			text-align: right;
			.widget {
				padding-bottom: 0;
				margin-top: -17px;

			}
		}

		// --RELATED GRID
		// =====================================
		// All slat-card-related
		&--related {
			.#{$module}__cell {
				
			}
		}

		&--related-3col {
			.#{$module}__cell {
				width: 33.33333%;
			}
		}

		&--related-4col {
			.#{$module}__cell {
				width: 50%;
			}
		}
		// end: --RELATED GRID =================


	}
	// Medium devices (desktops, 992px and up)
	// ==========================================================================
	@media (min-width: $screen-md-min) {
		&__title-wrap,
		&__tools-wrap {
			position: relative;
			float: left;
			min-height: 1px;
			padding-left: 0;
			padding-right: 0;
		}

		&--sort {
			.#{$module}__title-wrap {
				width: $width-2-3;
			}

			.#{$module}__tools-wrap {
				width: $width-1-3;
			}

			.#{$module}__form {
				float: right;
			}
		}

		&--related-4col {
			.#{$module}__cell {
				width: 25%;
			}
		}

		&__header--share &__description {
			clear: both;
			max-width: 675px;
		}
	}
}
