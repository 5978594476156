// Mixin defining a primary button
// Notes:
// - All arguments default to variables set in ace-buttons-settings.
//
// - Theme keys must be stored in the `$map-ace-btn-themes-primary` in
//   `_ace-buttons-themes.scss`
//
// - If passing individual arguments you must pass null as the first argument
// - and use name arguments unless all arguments are passed [see underloaded example].
//
// Examples:
// default:     ace-btn-primary() red background white text
// theme:       ace-btn-primary(red)
// overloaded:  ace-btn-primary(null, #000, #fff, #fff, #000, #e53d46, #000)
// underloaded: ace-btn-primary(null, $font-color: #000)


@mixin ace-btn-primary (
		$theme: null,
		$bg-color: $ace-btn-bg-color,
		$bg-color-active: $ace-btn-bg-color-active,
		$font-color: $ace-btn-font-color,
		$font-color-active: $ace-btn-font-color-active,
		$icon-fill: $ace-btn-icon-fill,
		$icon-fill-active: $ace-btn-icon-fill-active,
		$size: null,
		$double: null,
		$block: null
	)

{
	// Check for theme and set args to those values
	@if $theme {
		$map: map-get($map-ace-btn-themes-primary, $theme);
		$bg-color: map-get($map, bg-color);
		$bg-color-active: map-get($map, bg-color-active);
		$font-color: map-get($map, font-color);
		$font-color-active: map-get($map, font-color-active);
		$icon-fill: map-get($map, icon-fill);
		$icon-fill-active: map-get($map, icon-fill-active);
	}
	// include shared styles of non floating button
	@include ace-btn-base();

	// include button transition properties
	@include ace-btn-hover-transition();
	// doube and double-single handle their own display
	@if not $double {
		// jumbo or micro is passed through when this is called from an alias
		@include ace-btn-size($size);
		@if $block == true {
			display: block;
			width: 100%;
		} @else {
			display: inline-block;
		}
		// add border defs
		border-style: $ace-btn-border-style;
		border-width: $ace-btn-border-width;
	}
	border-color: $bg-color;
	color: $font-color;
	background-color: $bg-color;
	.icon {
		@if $icon-fill { // if specified
		fill: $icon-fill;
		} @else { // else same as font color
		fill: $font-color;
		}
	}

	&:visited {
		color: $font-color;
	}

	&:active,
	&:focus,
	&:hover {
		color: $font-color-active;
		background-color: $bg-color-active;
		border-color: $bg-color-active;


		.icon {
			@if $icon-fill { // if specified
			fill: $icon-fill-active;
			} @else { // else same as font color active
			fill: $font-color-active;
			}
		}
	}

	// Disabled State
	//
	// The disabled state uses the class .disabled, is-disabled,
	// and the form attribute disabled="disabled".
	// The use of !important is only added because this is a state
	// that must be applied to all buttons when in a disabled state.
	// &.disabled,
	// &.is-disabled,
	// &:disabled {}
}
