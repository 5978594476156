// Paginate
// - couldn't be "pagination"
// b/c of TWBS name collision...
//
$module: 'paginate';

.#{$module} {
	@extend %font-roboto-medium;
	text-align: center;
	display: block;
	margin: 50px auto;

	&__control {
		display: inline-block;
		font-size: remUnit(14px);
		padding: 5px;
		margin: 0 2px;
	}

	&__list {
		display: inline-block;
		margin: 0;
		padding: 0;
		list-style: none;
		font-size: 0;

	}
	&__item {
		display: inline-block;
		font-size: remUnit(16px);
		margin: 0 2px;

		&.active {
			a {
				color: $blue !important;
				font-weight: $font-weight-bold !important;
			}
		}

		a {
			color: #5f6568;
			padding: 10px 6px;
			font-weight: $font-weight-medium;
			@include ace-btn-hover {
				text-decoration: none;
			}
		}

		a.current-page {
			color: $blue;
			font-weight: $font-weight-bold;
		}

		a.next-sequence {
			padding-left: 3px;
			padding-right: 3px;
		}
	}

	@media (min-width: $screen-md-min) {

		&--con-ed-results {
			text-align: center;
		}

    &__control {
  		font-size: remUnit(15px);
  		padding: 10px;
    }

	}

}
