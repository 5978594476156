$module: 'connected';

.#{$module} {
	padding-top: 40px;
	padding-bottom: 50px;
	background-position: 50% 50%;
	background-size: cover;

	&__btn-center {
		@extend %ace-btn-stroke--reversed;
		margin-top: 20px;

	}

	&__description {
		max-width: 740px;
		margin-top: 40px;
		@extend %margin-auto;
		color: #fff;
		font-size: remUnit(16px);
		text-align: center;
	}

	&__label {
		@extend %diamond-title;
		@extend %diamond-title-variant--white;
		max-width: 720px;
		margin: 0 auto 30px auto;
	}

	&__form {
		@extend %radio-checkboxes--green-highlight;
		max-width: 750px;
		margin-right: auto;
		margin-left: auto;
	}

	&__form-label {
		color: #fff;
		text-align: left;
		padding-left: 10px;
	}

	&__form-input {
		@extend .form-control;
		@include placeholder(#fff);
		padding-right: 0;
		padding-left: 0;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #fff;
		color: #fff;
		font-size: remUnit(18px);
		width: 100%;
	}


	&__form-row {
		@include make-row(0);
		text-align: center;
		width: 100%;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}

	// Containers for the radios
	&__form-radio {
		display: block;
		position: relative;
		text-align: left;
		margin: 0 auto 10px auto;
		padding: 0 10px;
	}

	&__form-input-wrap {
		@include make-xs-column(12, 0);
		text-align: left;

	}

	&__form-legend {
		color: #fff;
		font-size: remUnit(13px);
		font-weight: $font-weight-light;
		text-align: center;
		margin-bottom: 20px;
		padding: 0;
	}

	&__form-submit-wrap {
		position: relative;
		margin: auto;

		input[type=email],input[type=text] {
			padding: 10px 30px 10px 10px;

		}
		input[type=submit],button[type=submit] {
			position: absolute;
			top: 0; right: 0; bottom: 0;
			background: transparent;
			border: none;
			&:focus {
				outline: none;
				transform: scale(1.2);
			}
		}
	}

	&__title {
		@include sub-section-title-styles();
		color: #fff;
    line-height: 1.25;
		max-width: 900px;
		margin-left: auto;
		margin-right: auto;

		.icon {
			display: block;
			margin: 0 auto 20px auto;
			font-size: remUnit(45px);
		}
	}

	&__wrap {
		@extend .container-fluid;
		padding-right: 20px;
		padding-left: 20px;
	}

}

// ==========================================================================
// XSmall devices (phone, 480px and up)
// ==========================================================================

// @media (min-width: $screen-xs-min) {
//   .#{$module} {
//   }
// } // end: XSmall devices (phone, 480px and up) ==============================


// ==========================================================================
// Small devices (tablets, 768px and up)
// ==========================================================================

// @media (min-width: $screen-sm-min) {
//   .#{$module} {
//   }
// } // end: Small devices (tablets, 768px and up) ==========================


// ==========================================================================
// Medium devices (desktops, 992px and up)
// ==========================================================================

@media (min-width: $screen-md-min) {
  .#{$module} {
		padding-top: 50px;
		padding-bottom: 75px;
		border: none;

		&__label {
			margin-bottom: 40px
		}

		&__form-row {
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&__form-radio {
			display: inline-block;
			padding: 0 10px;
		}
		&__form-input {
			font-size: remUnit(20px);
		}

		&__form-submit {
			width: auto;
			margin-top: 0;
		}

  }
} // end: Medium devices (desktops, 992px and up) ========================


// ==========================================================================
// Large devices (large desktops, 1200px and up)
// ==========================================================================

// @media (min-width: $screen-lg-min) {
//   .#{$module} {
//
//   }
// } // end: Large devices (large desktops, 1200px and up) ==================
