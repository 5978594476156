$module: 'post-brief';

.#{$module} {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 40px;
	background-color: #fff;

	&__img,
	&__head {
		text-align: center;
	}

	&__img-wrap {
		position: relative;
		overflow: hidden;
		flex-shrink: 0;
	}

	&__img {
		width: remUnit(134px);
		height: remUnit(109px);
		flex-shrink: 0;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		position: relative;
		transition: all .4s;
		&:before {
			content: "";
			display: none;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__head,
	&__taxonomy {
		color: $blue;
	}

	&__head {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		padding: 0 remUnit(15px);
	}

	&__taxonomy, h2.#{$module}__title {
		font-weight: $font-weight-semibold;
	}

	&__taxonomy {
		font-size: remUnit(14px);
		list-style: none;
		margin: 0 0 5px 0;
		padding: 0;
	}

	&__title {
		margin: 0;

		@include text-overflow(
			$lines-to-show: 3,
			$font-size: 16px,
			$line-height: lh(16px,17.5px)
		);
	}

	&__video-link {
		display: block;
		position: relative;
		margin-bottom: 15px;
		&:focus,
		&:active,
		&:hover {
			text-decoration: none;
			outline: none;
			.#{$module}__btn-video {
					background-color: $red;
					@include ace-btn-hover-transition();
					.icon {
						fill: #fff;
					}
			}
		}

    &--hide-img-edges {
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 3px;
        height: 100%;
        top: 0;
        bottom: 0;
        background-color: $gray-light;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
	}

	&__btn-video {
		display: block;
		position: absolute;
		right: 10px;
		bottom: 10px;
		width: 2.5em;
		background: #fff;
		border-radius: 1.25em;
		font-size: remUnit(20px);
		line-height: 2.5;
		text-align: center;
	}

	&--dark {
		background-color: $blue;

		.#{$module}__title {
			color: #fff;
			@include ace-btn-hover-transition();
			a {
				color: #fff;
			}
			&:focus,
			&:active,
			&:hover {
				text-decoration: none;
				outline: none;
				color: $red;
				a {
					text-decoration: none;
					outline: none;
					color: $red;
				}
			}
		}
	}

	&--video {
		display: block;
		margin-bottom: 20px;
		background-color: transparent;

		.#{$module}__img {
			display: block;
			width: 100%;
			height: auto;

			&:before {
				display: none;
			}
		}
	}

	&--exam-prep {
		.#{$module}__taxonomy {
			line-height: 1.1;
		}
		@media (max-width: $screen-xs-min) {
			.#{$module}__img-wrap {
				width: 40%;
			}

			.#{$module}__taxonomy {
				margin: 0 auto 5px;
				text-align: center;
			}
		}
	}


	&:focus,
	&:active,
	&:hover {
		text-decoration: none;
		outline: none;
		.#{$module}__title {
				color: $red;
				@include ace-btn-hover-transition();
		}
	}



	@media (min-width: $screen-sm-min) {
		width: 100%;
		float: none;
		overflow: hidden;
		margin-bottom: 40px;
		min-height: 320px;
		display: block;

		&__title {
			@include text-overflow(
				$lines-to-show: 3,
				$font-size: 24px,
				$line-height: lh(24px,28px)
			);
		}

		&__img,
		&__head {
			width: 100%;
			display: block;
			position: relative;
		}

		&__img {
			height: 160px;
			display: block;
			padding: 0;
		}

		&__head {
			left: 0;
			padding: 20px;
		}

		// &:hover, &:focus {
		// 	.#{$module}__img {
		// 		transform: scale(1.15);
		// 		&:before {
		// 			display: block;
		// 		}
		// 	}
		// }

		&--full {
			.#{$module}__title {
				@include text-overflow(
					$lines-to-show: 3,
					$font-size: 20px,
					$line-height: lh(24px,28px)
				);
			}
		}

		&--video {
			margin-bottom: 20px;
		}
	}
}
