// SELECTRIC

.selectric {
	.label {
		height: auto;
		padding-top: $padding-base-vertical;
		padding-bottom: $padding-base-vertical;
		font-size: $font-size-base;
		line-height: $line-height-base;

    .pricing-table-detail__form-select-wrap & {
      font-size: 14px;
    }
	}

	.button {
		height: auto;
		padding-top: $padding-base-vertical;
		padding-bottom: $padding-base-vertical;
		font-size: $font-size-base;
		line-height: $line-height-base;
	}

	&-items {
		@include selectric-variant($blue, #fff, $blue, $blue);

		li {
			font-size: remUnit(12px);
		}
	}
}
