// ======================================
//  Utility and Helper Classes
// ======================================

// ======================================
// Removes gutters from columns
// inside a row.
// Example: <div class="row no-gutter">
// ======================================
.row.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

//  Typography Helpers
//  ============================================
// ROBOTO  -------------------------------------
%font-roboto-regular {
	font-family: $font-family-roboto;
	font-weight: $font-weight-regular;
}
.font-roboto-regular {@extend %font-roboto-regular;}

%font-roboto-medium {
	font-family: $font-family-roboto;
	font-weight: $font-weight-medium;
}
.font-roboto-medium {@extend %font-roboto-medium;}

%font-roboto-bold {
	font-family: $font-family-roboto;
	font-weight: $font-weight-bold;
}
.font-roboto-bold {@extend %font-roboto-bold;}


// MULI -------------------------------------
%font-muli-xlight {
	font-family: $font-family-muli;
	font-weight: $font-weight-xlight;
}
.font-muli-xlight {@extend %font-muli-xlight;}

%font-muli-light {
	font-family: $font-family-muli;
	font-weight: $font-weight-light;
}
.font-muli-light {@extend %font-muli-light;}

%font-muli-regular {
	font-family: $font-family-muli;
	font-weight: $font-weight-regular;
}
.font-muli-regular {@extend %font-muli-regular;}

%font-muli-semibold {
	font-family: $font-family-muli;
	font-weight: $font-weight-semibold;
}
.font-muli-semibold {@extend %font-muli-semibold;}

%font-muli-bold {
	font-family: $font-family-muli;
	font-weight: $font-weight-bold;
}
.font-muli-bold {@extend %font-muli-bold;}

%margin-auto {
	margin-left: auto;
	margin-right: auto;
}

%blue-text {color: $blue;}
%red-text {color: $red;}
%white-text {color: #fff;}


//  Placeholder Helpers
// ==========================================================================

%visually-hidden {
  @include visually-hidden();
}

%radio-checkboxes--red-highlight {
  @include styled-radios-and-checkboxes(
    $input-border-color: #fff,
    $input-selected-color: $red,
    $label-color: #fff
  );
}
.radio-checkboxes--red-highlight {
  @extend %radio-checkboxes--red-highlight;
}


%radio-checkboxes--green-highlight {
  @include styled-radios-and-checkboxes(
    $input-border-color: #fff,
    $input-selected-color: $green,
    $label-color: #fff
  );
}

.radio-checkboxes--green-highlight {
  @extend %radio-checkboxes--green-highlight;
}


%custom-form-control {
  height: auto;
  box-shadow: none;
  -webkit-box-shadow: none;
  font-size: remUnit(16px);
  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: initial;
  }
}

%placeholder-focus-fade {
  &:focus {
    border-color: inherit;
    box-shadow: none;
    &::placeholder{
      transition: color 0.18s ease-in-out;
      color: rgba(#fff,0.0);
    }
  }
}

// ======================================
// SVG Icons
// @example scss
// .foo { @extend %name-of-placeholder
// to change orientation use rotate in
// your style declarations.
//
// @example scss
// .foo {
//    @extend %icon-certified-red;
//    transform: rotate(90deg);
// }
// ======================================

%icon-caret-red {
  @include svg-caret-right($red, 'background-image');
}

%icon-certified-red {
  @include svg-certified($red, 'background-image');
}

%icon-arrow-red {
  @include svg-certified($red, 'background-image');
}

// Star Rating Helpers
%star-empty {
	visibility: hidden;
}

%star-1-4 {
	width: $star-rating-width-1-4;
}
%star-1-2 {
	width: $star-rating-width-1-2;
}
%star-3-4 {
	width: $star-rating-width-3-4;
}

%star-full {
	width: $star-rating-width-full;
}
// ======================================
// Diamond Titles
//
// First element needs the diamond title and a variant
// Any modifiers just need the variant.
// Element mus have a span as a direct decendant
// @example html
// <h4 class="block__element"><span>text</span></h4>
// @example scss
// .block__element {
//		@extend %diamond-title;
//		@extend %diamond-title-variant--blue;
//		&--modifier {
//			@extend %diamond-title-variant--green
//		}
//	}
// ======================================
%diamond-title {
	@include diamond-title();
}
// Blue bg white text
%diamond-title-variant--blue {
	@include diamond-title-variant($blue, blue, #fff);
}
// Green bg white text
%diamond-title-variant--green {
	@include diamond-title-variant($green, green, #fff);
}
%diamond-title-variant--red {
	@include diamond-title-variant($red, red, #fff);
}
// Gray-med bg blue text
%diamond-title-variant--gray {
		@include diamond-title-variant($gray-med, gray, $blue);
}

// White bg blue text
%diamond-title-variant--white {
		@include diamond-title-variant(#fff, white, $blue);
}
