table {
	@extend .table;

	td {
		border-color: $gray-med;
		font-size: remUnit(14px);
	}

	th {
		border: none !important;
		color: $blue;
		font-size: remUnit(14px);
		font-weight: $font-weight-semibold;
		text-transform: uppercase;
	}

	&.no-table-border {
		> thead,
		> tbody,
		> tfoot {
			> tr {
				> th,
				> td {
					border-top: 0;
				}
			}
		}
	}
}
