//
%ace-btn-base {
	@include ace-btn();
}
%ace-btn-base--jumbo {
	@include ace-btn($size: jumbo);
}
.ace-btn-base--jumbo {
	@extend %ace-btn-base--jumbo;
}
%ace-btn-base--block {
	@include ace-btn($block: true);
}

//////////////////////////////////////////////////////////
// 1. Primary Buttons ///////////////////////////////////
////////////////////////////////////////////////////////
%ace-btn-primary {
	@include ace-btn-primary();
}

%ace-btn-primary--red {
	@include ace-btn-primary(red);
}

%ace-btn-primary--blue {
	@include ace-btn-primary(blue);
}
%ace-btn-primary--green {
	@include ace-btn-primary(green);
}

%ace-btn-primary--gray-light {
	@include ace-btn-primary(gray-light);
}

%ace-btn-primary--gray-light-green {
	@include ace-btn-primary(gray-light-green);
}

%ace-btn-primary--yellow {
	@include ace-btn-primary(yellow);
}

%ace-btn-primary--dark-cyan {
	@include ace-btn-primary(dark-cyan);
}

%ace-btn-primary--reversed {
	@include ace-btn-primary(reversed);
}

%ace-btn-primary--reversed-red-icon {
	@include ace-btn-primary(reversed);
}
//////////////////////////////////////////////////////////
// 2. Jumbo Buttons /////////////////////////////////////
////////////////////////////////////////////////////////
%ace-btn-primary-jumbo {
	@include ace-btn-primary-jumbo();
}
%ace-btn-primary-jumbo--red {
	@include ace-btn-primary-jumbo();
}

%ace-btn-primary-jumbo--blue {
	@include ace-btn-primary-jumbo(blue);
}
%ace-btn-primary-jumbo--green {
	@include ace-btn-primary-jumbo(green);
}

%ace-btn-primary-jumbo--reversed {
	@include ace-btn-primary-jumbo(reversed);
}
//////////////////////////////////////////////////////////
// 3. Double & Jumbo Double Buttons /////////////////////
//  These requires a wrapper parent element of the same
//  name but with "-wrap" added to it.
//  Example:
//  ```
//      <div class="ace-btn-jumbo-double-wrap">
//         <a clas="ace-btn-jumbo-double"></a>
//         <a clas="ace-btn-jumbo-double"></a>
//     </div>
//  ```
//  ```
//      <div class="ace-btn-jumbo-double--reversed-wrap">
//         <a clas="ace-btn-jumbo-double--reversed"></a>
//         <a clas="ace-btn-jumbo-double--reversed"></a>
//     </div>
//  ```
////////////////////////////////////////////////////////
// Double
%ace-btn-double {
	@include ace-btn-double();
}
%ace-btn-double--reversed {
	@include ace-btn-double(reversed);
}
//  Jumbo Double
%ace-btn-double-jumbo {
	@include ace-btn-double-jumbo();
}
%ace-btn-double-jumbo--reversed {
	@include ace-btn-double-jumbo(reversed);
}

//////////////////////////////////////////////////////////
// 3. Double Single & Jumbo Double Single Buttons ///////
//  These require two immediate child elements of
//  <em> or <span> or a combination of the two.
//
//  Examples:
//  ```
//      <a class="ace-btn-double-single">
//          <span></span><span></span>
//      </a>
//  ```
//  ```
//      <a class="ace-btn-double-single">
//          <em></em><span></span>
//      </a>
//  ```
////////////////////////////////////////////////////////
// Double Single
%ace-btn-double-single {
	@include ace-btn-double-single();
}
%ace-btn-double-single--reversed {
	@include ace-btn-double-single(reversed);
}
// Jumbo Double Single
%ace-btn-double-single-jumbo {
	@include ace-btn-double-single-jumbo();
}
%ace-btn-double-single-jumbo--reversed {
	@include ace-btn-double-single-jumbo(reversed);
}


//////////////////////////////////////////////////////////
// 4. Stroke Buttons ////////////////////////////////////
////////////////////////////////////////////////////////
%ace-btn-stroke {
	@include ace-btn-stroke();
}
%ace-btn-stroke--red {
	@include ace-btn-stroke(red);
}
%ace-btn-stroke--blue {
	@include ace-btn-stroke(blue);
}
%ace-btn-stroke--blue-red {
	@include ace-btn-stroke(blue-red);
}
%ace-btn-stroke--green {
	@include ace-btn-stroke(green);
}
%ace-btn-stroke--gray {
	@include ace-btn-stroke(gray);
}

%ace-btn-stroke--reversed {
	@include ace-btn-stroke(reversed);
}
//////////////////////////////////////////////////////////
// 5. Stroke Micro Buttons //////////////////////////////
////////////////////////////////////////////////////////
%ace-btn-stroke-micro {
	@include ace-btn-stroke-micro();
}

%ace-btn-stroke-micro--red {
	@include ace-btn-stroke-micro(red);
}

%ace-btn-stroke-micro--blue {
	@include ace-btn-stroke-micro(blue);
}

%ace-btn-stroke-micro--green {
	@include ace-btn-stroke-micro(green);
}

%ace-btn-stroke-micro--reversed {
	@include ace-btn-stroke-micro(reversed);
}
//////////////////////////////////////////////////////////
// 6. Floating Buttons //////////////////////////////////
////////////////////////////////////////////////////////
%ace-btn-floating {
	@include ace-btn-floating();
}

%ace-btn-floating--red {
	@include ace-btn-floating(red);
}

%ace-btn-floating--black {
	@include ace-btn-floating(black);
}

%ace-btn-floating--blue {
	@include ace-btn-floating(blue);
}

%ace-btn-floating--yellow {
	@include ace-btn-floating(yellow);
}

%ace-btn-floating--green {
	@include ace-btn-floating(green);
}

%ace-btn-floating--white {
	@include ace-btn-floating(white);
}
// red text blue icon
%ace-btn-floating--red-blue {
	@include ace-btn-floating(red-blue);
}
// blue text red icon
%ace-btn-floating--blue-red {
	@include ace-btn-floating(blue-red);
}
// white text red icon
%ace-btn-floating--white-red {
	@include ace-btn-floating(white-red);
}

%ace-btn-floating--reversed {
	@include ace-btn-floating(reversed);
}


%ace-btn-tag--gray-light {
	@include ace-btn-tag(gray-light);
}

%ace-btn-tag-filter--blue {
	@include ace-btn-tag($theme:blue, $type:filter);
}

%ace-btn-title-hover {
	@include ace-btn-title-hover;
}
