$module: link-list;

.#{$module} {

  &__item {}

  // ==================== //
  // ==== VARIATIONS ==== //
  // ==================== //

  // University Curriculum

  &.#{$module}--univ-curriculum {

    .reveal-menu__panel-primary {
      float: none;
      width: 100%;
    }

    .#{$module}__item {
      text-decoration: none;

      &::before,
      &::after {
        content: "";
        display: block;
      }

      &:hover {
        text-decoration: underline;
      }

      &--primary {
        display: inline-block;
        margin-bottom: 1rem;
        font-weight: 600;
      }

      &--no-href {
        display: block;
        margin-top: 1rem;
        margin-bottom: 1rem;
        text-decoration: none !important;
        color: $blue;
        font-weight: 600;
        cursor: default;
      }
    }
  }
}
