$module: assembler;

.#{$module} {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;

    @media (min-width: $screen-md-min) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    label {
        cursor: pointer;
    }

    &__header {
        width: 100%;
        margin-top: 2rem;
        display: none;

        @media (min-width: $screen-md-min) {
            width: calc(45% - 2rem);
            margin-top: 0;
            display: block;
        }
    }

    &__body {
        width: 100%;

        @media (min-width: $screen-md-min) {
            width: calc(55% - 2rem);
        }
    }

    &__section {

        &--disabled {
            position: relative;
            overflow: hidden;
            opacity: .2;

            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba(0,0,0,0);
            }
        }
    }

    &__title {
        font-weight: 600;
    }

    &__subtitle {
        color: $green;
        font-size: .9rem;
        font-weight: 600;
    }
    // IMAGES

    &__image {
        width: 463px;
        height: 463px;
        margin: 0 auto;
        background-color: black;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        transition: background-image .3s;

        @media (min-width: $screen-md-min) {
            width: 100%;
            height: auto;
            padding-top: 100%;
        }

        @media (max-width: 525px) {
            width: 100%;
            height: auto;
            padding-top: 100%;
        }
    }

    &__icons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        margin: 1rem auto 0;
        width: 100%;
        max-width: 600px;

        @media (min-width: $screen-md-min) {
            max-width: none;
            margin-top: 0;
        }
    }

    &__icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: calc(50% - 1rem);
        margin: .5rem;

        @media (min-width: $screen-md-min) {
            width: auto;
            margin: 2rem .5rem;
        }

        img {
            display: block;
            width: auto;
            height: 25px;
            margin: 0 auto .5rem;

            @media (min-width: $screen-md-min) {
                height: 35px;
            }
        }

        p {
            font-size: .6rem;
            color: $gray;
            line-height: 1.2;
            text-align: center;
            white-space: nowrap;

            @media (min-width: $screen-md-min) {
                font-size: .8rem;
            }
        }
    }
    // OPTION

    &__option-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        margin: 0 auto 1rem;
    }

    &__option {
        width: calc(50% - 1rem);
        padding: 2rem 1rem;
        border: 1px solid $gray;
        text-align: center;

        @media (max-width: 600px) {
            width: calc(50% - .3rem);
            padding: 1rem .75rem;
        }

        &--selected {
            border-color: $red;
            box-shadow: inset 0 0 0 3px $red;
        }
    }

    &__option-input {
        position: absolute;
        opacity: 0;
    }

    &__option-title {
        margin-bottom: 5px;
        font-size: 1.8rem;
        font-weight: 600;
        font-family: Muli;
        color: $gray;
    }

    &__option-subtitle {
        margin: 0 0 1rem;
        font-size: .9rem;
        font-weight: 400;
        font-family: Roboto;
        text-transform: uppercase;
        color: $gray;

        @media (max-width: 600px) {
            margin-bottom: .5rem;
        }
    }

    &__option-price {
        margin: 0;
        font-weight: 600;
        font-size: 1.65rem;
        line-height: 1;
        color: $gray;
        vertical-align: middle;

        @media (max-width: 600px) {
            display: block;
        }
    }
    // EXAM

    &__exam-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        margin-bottom: 2rem;
    }

    &__exam {
        width: calc(50% - 1rem);
        padding: 1rem;
        border: 1px solid $gray;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;

        @media (max-width: 600px) {
            width: calc(50% - .3rem);
            padding: .75rem;
        }

        &--selected {
            border-color: $red;
            box-shadow: inset 0 0 0 3px $red;
        }
    }

    &__exam-input {
        position: absolute;
        opacity: 0;
    }
    // PAYMENT PLANS

    &__payment-plan-wrap {
        display: none;
        margin-bottom: 2rem;
        padding: 2rem;
        background-color: $gray-light;

        &--visible {
            display: block;
        }

        @media (max-width: 600px) {
            padding: 1rem;
        }
    }

    &__payment-plan {
        position: relative;
        padding: 0;
        margin: 0;

        &::after {
            content: url;
            display: block;
            position: absolute;
            width: 20px;
            top: 35%;
            right: 1rem;
            z-index: 1;
        }
    }

    &__payment-plan-select {
        display: block;
        width: 1000px;
        max-width: 100%;
        margin: 0 auto;
        padding: 1rem;
        appearance: none;
        background-color: white;
        background-image: url("/images/layout/2017/shared/angle-down-bold.svg");
        background-position: right 1rem top 50%;
        background-size: 20px;
        background-repeat: no-repeat;

        @media (max-width: 600px) {
            padding: 1rem 2rem 1rem 1rem;
            background-position: right .5rem top 50%;
        }
    }
    // RESULT

    &__result {
    }
    // SELECTED PROGRAM

    &__selected-program {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 1.5rem;

        &--pt,
        &--pt-b {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            margin-bottom: 1.5rem;
        }
    }

    &__selected-program-description {
    }

    &__selected-program-title {
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 1.65rem;
    }


    &__selected-program-pricing {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        width: 90px;
    }

    &__selected-program-price {
        margin: 0;
        font-weight: 700;
        font-size: 1.65rem;
        line-height: 1;
        white-space: nowrap;
        text-align: right;

        &--regular {
        }

        &--sale {
        }
    }
    // BUY BUTTON

    &__buy-button {
        display: block;
        margin-bottom: 1rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    // ACE PRO TEXT

    &__ace-pro-savings-text {
        margin-bottom: 0;
        color: $green;
        text-transform: uppercase;
        font-family: Roboto;
        font-size: .85rem;
        font-weight: 500;
    }

    &__ace-pro-sign-in-text {
        font-size: .9rem;
        font-weight: 600;
        text-align: center;
        color: $gray;
    }

    &__due-at-checkout {
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        color: $red;
    }
    // VALIDATION MESSAGE

    &__validation-message-wrap {
        display: none;
        padding: 1rem;
        border: 1px solid $red;

        &--visible {
            display: block;
        }
    }

    &__validation-message-list {
        margin: 0;
        padding: 0 0 0 2rem;
        list-style: disc;

        li {
            font-size: .9rem;
            font-weight: 400;
            color: $red;
        }
    }
    //*******************//
    //   Tutor Session   //
    //*******************//

    &--tutor-session {
        .#{$module}__section {
            margin-bottom: 2rem;
        }

        .#{$module}__option-wrap {
            flex-wrap: wrap;
        }

        .#{$module}__option {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: stretch;
            margin-bottom: 2rem;
        }

        .#{$module}__option-title {
            width: 100%;
            max-width: 200px;
            margin: 0 auto;
            font-size: 1.2rem;
            line-height: 1.2;
        }


        .#{$module}__calendar-time-wrap {
            display: none;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;

            &--visible {
                display: flex;
            }

            @media (min-width: 500px) {
                flex-direction: row;
            }
        }

        .#{$module}__calendar,
        .#{$module}__time {
            display: block;
            width: 100%;
            text-align: center;

            @media (min-width: 500px) {
                width: calc(50% - 1rem);
            }
        }

        .#{$module}__calendar {
            margin-bottom: 2rem;

            @media (min-width: 500px) {
                margin-bottom: 0;
            }

            #calendar-option-input {
                padding: .5rem;
                text-align: center;
                text-shadow: 0 0 0 #5f6567;
                color: transparent;
                cursor: pointer;

                &:focus {
                    outline: none;
                }
            }

            #calendar-option-input::placeholder,
            #calendar-option-input:-ms-input-placeholder,
            #calendar-option-input::-ms-input-placeholder {
                color: #fff;
                opacity: 1;
            }
        }

        .#{$module}__calendar-title {
            padding: 1rem;
            text-transform: uppercase;
            background-color: $blue;
            color: #fff;
            font-size: .85rem;
        }

        .#{$module}__calendar-time-options {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }

        .#{$module}__calendar-time-option {
            display: block;
            margin-bottom: .5rem;
            cursor: pointer;

            input[name="time-option-input"] {
                position: absolute;
                opacity: 0;

                &:checked + .#{$module}__calendar-time-option-text {
                    background-color: $red;
                    color: #fff;
                }
            }
        }

        .#{$module}__calendar-time-option-text {
            display: block;
            width: 125px;
            padding: .15rem 0;
            border-radius: 12px;
            text-align: center;
            font-weight: 400;
            font-size: .95rem;
            background-color: #e4e4e4;
            color: #5f6567;
            cursor: pointer;
        }

        .#{$module}__user-input-phone-wrap {
            #user-input-phone {
                padding: .5rem;
            }
        }

        .#{$module}__user-input-message-wrap {
            #user-input-message {
                display: block;
                width: 100%;
                height: 200px;
                margin-bottom: .25rem;
                padding: 1rem;
            }
        }

        .#{$module}__user-input-message-counter {
            margin-bottom: 2rem;
            text-align: right;
            font-size: .85rem;
        }

        .#{$module}__pst-message {
            display: none;

            &--visible {
                display: block;
            }
        }

        .#{$module}__selected-time-wrap {
            display: none;

            &--visible {
                display: block;
            }
        }

        .#{$module}__selected-time-timestamp {
            position: relative;
            margin-bottom: 1rem;
            text-align: left;
            color: $green;
            font-weight: 600;
        }

        .#{$module}__selected-time-edit {
            position: absolute;
            right: 0;
            bottom: 0;
            color: $red;
            text-align: right;
            text-transform: uppercase;
            font-size: .9rem;
            font-weight: 600;
            cursor: pointer;
        }

        .#{$module}__countdown-message {
            position: relative;
            padding: 1rem;
            background-color: $green;
            color: #fff;
            text-align: center;

            &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                left: 1.5rem;
                top: -1rem;
                border-style: solid;
                border-width: 0 25px 25px 25px;
                border-color: transparent transparent $green transparent;
            }
        }
    }
}
