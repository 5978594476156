$module: 'hero-buttons';

.#{$module} {
	padding-top: 20px;
	padding-bottom: 20px;
	border-top: 1px solid $gray-light;

	&__button {
		@extend %button;
		color: #fff;
		background-color: #d31c26;
		border-color: transparent;
		position: relative;
		display: block;
		width: 100%;
		margin-bottom: 1px;
		padding-right: 40px;
		background-position: 50% 50%;
		font-family: $font-family-muli;
		font-weight: $font-weight-regular;
		font-size: remUnit(14px);
		line-height: 1.25;
		white-space: normal;
		text-align: left;
		text-transform: none;
		@include ace-btn-hover {
			color: #fff;
			background-image: none !important;
		}

		.icon {
			position: absolute;
			right: 15px;
			top: 17px;
			font-size: remUnit(14px);
		}

		&--cec-club {
			background-color: $purple;
		}
	}

	&__button-wrap {
		width: 100%;
	}

	&__head {
		padding-bottom: 20px;
	}

	&__row {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;

		@media (min-width: $screen-md-min) {
			flex-direction: row;
		}
	}

	&__title {
		@extend %font-muli-bold;
		font-size: remUnit(24px);
		$color: $blue;
	}

	&__wrap {
		@extend .container-fluid;
		padding-right: 20px;
		padding-left: 20px;
	}

	&--green {
		text-align: left;
		padding-top: 0;

		.#{$module}__button-wrap {

			@include make-md-column(4, 20px);
			@include make-lg-column(2, 20px);
			text-align: left;
		}
		.#{$module}__button {
			background-color: $green;
			text-transform: none;
			@extend %font-muli-regular;
			text-align: left;
		}
	}

	@media (min-width: $screen-md-min) {
		padding-bottom: 30px;

		&__button {
			padding-bottom: 40px;
			font-size: remUnit(24px);

			.icon {
				top: 22px;
			}
		}

		&__button-wrap {
			padding: 1px;
		}
		&--green {
			.#{$module}__button-wrap {
				padding-bottom: 20px;
			}
			.#{$module}__button {
				font-size: remUnit(20px);
			}
		}

	}
}
