// Used in ABT Section

$module: 'text-grid';


.#{$module} {
	@include clearfix;
	max-width: none;
	margin: 0 auto;
	padding: 0 10px;

	&__address {
		.icon {
			display: block;
			margin: 0 auto 15px auto;
			font-size: remUnit(45px);
		}
	}

	&__col {
		@include make-xs-column(12,20px);
		margin-bottom: 30px;
	}

	&--2col-contact {
		margin: 0 auto;
		max-width: 740px;
	}

	&--2col-contact &__col {
		@include make-sm-column(6,60px);
	}

	&--sm-3col &__col {
		@include make-sm-column(4,20px);
	}

	&--lg-5col &__col {
		@include make-sm-column(6,40px);
		@include make-md-column(4,40px);
		@include make-lg-column(2.4,40px);
	}

  &--lg-6col &__col {
    @include make-sm-column(6,40px);
    @include make-md-column(4,40px);
    @include make-lg-column(2,40px);
  }

	&--md-3col &__col {
		@include make-md-column(4,20px);
	}

	&--sm-2col {
		max-width: none;
	}
	&--sm-2col &__col {
		@include make-xs-column(12,20px);
		@include make-sm-column(6,20px);
		@include make-md-column(4,20px);
	}

	&__col--fill-white {
		margin-bottom: 0;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	&__col--fill-white &__fill-wrap {
		width: 100%;
		height: 100%;
		background-color: #fff;
	}
	&__col--fill-white &__content {
		height: 100%;
		padding: 20px;
	}
	&__title-sm,
	&__header-title-sm .sub-section__title {
		font-size: remUnit(20px);
		@extend %font-muli-semibold;
		padding-left: 20px;
		padding-right: 20px;
	}
	&__title {
		font-size: remUnit(24px);
		&--green {
			color: $green;
		}
	}
	&__title-lg {
		font-size: remUnit(36px);
	}
	&__title {
		font-size: remUnit(24px);
	}
	&__list {
		list-style: none;
		padding-left: 0;
		li  {
			margin-bottom: 12px;
		}
	}
	&__content {
		margin: 0 auto;
		max-width: 475px;
		font-size: remUnit(16px);

		p {
			font-size: remUnit(16px);
			line-height: lh(16px,24px);
		}
	}
	&__content-footer {
		padding: 15px 0;
	}
	&__phone-list {
		list-style: none;
		padding-left: 0;
		margin: 0 auto;

		li {
			margin-bottom: 3px;

			a {
				color: inherit;
			}
		}

		.icon {
			display: block;
			margin: 0 auto 15px auto;
			font-size: remUnit(45px);
		}
	}

	&__button--blue,
	&__button--red {
		margin-bottom: 11.5px;

	}

	&__button--blue {
		@include ace-btn-floating(blue, $block:true);
	}
	&__button--red {
		@include ace-btn-floating(red, $block:true);
	}
	&__button--fb {
		span.icon-wrap svg.icon.icon--facebook {
			fill: #3b5998
		}
	}



	@media (min-width: $screen-iphone-6-min ) {}
	@media (min-width: $screen-xs-min) {}
	@media (min-width: $screen-sm-min) {
		&--sm-2col &__col:nth-child(2n + 1) {
			clear: left;
		}
		// 2 across
		&--lg-5col &__col:nth-child(2n + 1) {
			clear: left;
		}

	}
	@media (min-width: $screen-md-min) {
		&--sm-2col &__col:nth-child(3n + 1) {
			clear: left;
		}
		&--sm-2col &__col:nth-child(2n + 1) {
			clear: none;
		}
		&--lg-5col &__col:nth-child(2n + 1) {
			clear: none;
		}
		&--lg-5col &__col:nth-child(3n + 1) {
			clear: left;
		}

		&__address {
			.icon {
				margin: 0 auto 15px auto;
				font-size: remUnit(90px);
			}
		}

		&__phone-list {
			.icon {
				margin: 0 auto 15px auto;
				font-size: remUnit(90px);
			}
		}
	}
	@media (min-width: $screen-lg-min) {
		&--lg-5col &__col:nth-child(3n + 1) {
			clear: none;
		}
	}

}
