$module: 'brief-set';

.#{$module} {
    // Container
    &-results {
        h1 {
            margin: 10px 0;
        }

        &--programs-benefits {
            .#{$module}-result {

                &__image-container {
                    @media (min-width: $screen-sm-min) {
                        width: 210px;
                    }
                }

                &__image {
                    @media (max-width: $screen-sm-min - 1) {
                        max-width: 210px;
                    }

                    @media (max-width: $screen-xs-min - 1) {
                        max-width: 150px;
                    }
                }
            }
        }

        &--military-financial-assistance {
            .#{$module}-result {
                &__image {
                    @media (max-width: $screen-sm-min - 1) {
                        margin: 0 auto 1rem;
                        max-width: 220px;
                    }

                    @media (max-width: $screen-xs-min - 1) {
                        max-width: 160px;
                    }
                }
            }
        }

        &--event-registration {
            .#{$module}-result {
                h1 {
                    font-size: 2.25rem;

                    @media (min-width: $screen-md-min) {
                        font-size: 3.5rem;
                    }
                }
            }
        }

        &--community-engagement-partnerships {
            .#{$module}-result__image-container {
                width: 166px;
                max-width: 166px;
                margin: 0 auto;
            }
        }
    }


    &-header {
        margin-top: 20px;
        margin-bottom: 0px;
        font-size: 1.25rem;
        padding-bottom: 5px;
        border-bottom: 1px solid #dadfe1;
    }
    // Elements
    &-result {
        padding: 1.5rem 0 1.25rem;
        border-bottom: 1px solid #dadfe1;

        &--author {
            padding: 1.5rem 0 1.65rem;
        }

        &--last-child-border-bottom-none {
            &:last-child:not(:only-child) {
                border-bottom: none;
            }
        }

        &__inner {
            font-size: .875rem;

            @media (min-width: 768px) {
                display: table;
                position: relative;
                width: 100%;
            }

            &--regular-font-size {
                font-size: 1.125rem;
            }
        }

        &__image {
            display: block;
            position: relative;
            width: 100%;
            height: auto;
            margin-bottom: 1rem;

            @media (min-width: 768px) {
                margin-bottom: 0;
            }

            &--portrait {
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;

                @media (min-width: 768px) {
                    max-width: none;
                }
            }

            &--small {
                max-width: 140px;
            }

            &--medium {
                max-width: 205px;
            }

            &--large {
                max-width: 325px;
            }
        }

        &__image-container {
            padding-top: 5px;
            padding-bottom: 5px;

            @media (min-width: 768px) {
                display: table-cell;
                vertical-align: top;
                width: 245px;
            }

            &--small {
                width: 140px;
            }

            &--medium {
                width: 205px;
            }

            &--large {
                width: 325px;
            }
        }

        &__content {
            padding-bottom: 5px;

            @media (min-width: 768px) {
                display: table-cell;
                vertical-align: top;
                padding-left: 1.25rem;
            }

            p {
                .#{$module}-result--author & {
                    font-size: 1.125rem;
                }
            }

            h1 {
                font-size: 3.5rem;
                font-weight: 600;
            }

            h2 {
                font-size: .875rem;
                font-weight: 500;
                margin-bottom: 10px;

                &.#{$module}-result__content-subtitle-red {
                    color: #e53d46;
                    font-weight: 700;
                    margin-bottom: 30px;
                    font-size: .875rem;
                }
            }

            h3 {
                margin-bottom: .5rem;
                font-size: 1.5rem;
            }

            h4 {
                line-height: 1.3em;
            }
        }
    }
}
