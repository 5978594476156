@mixin placeholder($color: #ccc) {
	&::-webkit-input-placeholder {
		color: $color;
	}
	&::-moz-placeholder {
		color: $color;
	}
	&:-ms-input-placeholder {
		color: $color;
	}
	&:-moz-placeholder {
		color: $color;
	}
	&:placeholder {
		color: $color;
	}
}


label {
	font-size: remUnit(16px);
	font-weight: $font-weight-light;
}

fieldset {
	margin: 20px 0 0;
	padding: 0;
	border: none;
}

legend {
	margin: 0;
	padding: 0 0 10px;
	border: none;
	color: $blue;
	font-weight: $font-weight-semibold;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="number"],
button,
textarea {
  font-family: inherit;
	&:focus {
		outline: 0;
		box-shadow: none;
		border-color: inherit;
	}
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="number"] {
	@extend %placeholder-focus-fade;
	box-shadow: none;
}

.form-control {
	@extend %custom-form-control;
}

.help-block { font-size: remUnit(16px); }

.input-reveal-wrap {
	label {
		margin-bottom: 0;
		font-size: remUnit(13px);
		opacity: 0;
		transition: opacity 250ms;
	}
}

.input-reveal-wrap.selected {
	input {
		@extend %placeholder-focus-fade;
	}

	label {
		opacity: 1;
	}
}
