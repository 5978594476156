$module: 'search-tools';

.#{$module} {
	&__head {
		padding-bottom: 10px;
	}

	&__taxonomy {
		display: inline-block;
		margin: 0;
		padding: 0;
		list-style: none;
		line-height: 1;
	}

	&__taxonomy li {
		display: inline-block;
		margin-bottom: 4px;
	}

	&__taxonomy li a {
		@extend %ace-btn-tag-filter--blue;
	}

	&__title {
		@extend %font-muli-bold;
		display: inline-block;
		font-size: remUnit(24px);
		color: $blue;
	}
	
	@media (min-width: $screen-md-min) {
		&__head {
			padding-bottom: 20px;
		}
	}
}
