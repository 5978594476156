$module: cert-approved-orgs;

.#{$module} {
    &__wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
    }
    &__item-wrap {
        width: 45%;
        min-width: 150px;
        padding: 1.5rem;
        text-align: center;
    }
    &__image {
        display: block;
        width: 100%;
        margin-bottom: .75rem;
    }
    &__cecs {
        font-weight: 600;
        font-size: .85rem;
    }
    @media (min-width: 600px) {
        &__wrap {
            justify-content: flex-start;
        }
        &__item-wrap {
            padding: 1rem;
            width: 33%;
        }
    }
    @media (min-width: 768px) {
        &__item-wrap {
            width: 25%;
        }
    }
    @media (min-width: 1200px) {
        &__item-wrap {
            width: 20%;
        }
    }
}

