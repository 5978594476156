$module: 'partners-programs';

.#{$module} {
    &__image {
        display: block;
        position: relative;      
        height: auto;
        margin-bottom: 1rem;

        @media (min-width: 768px) {
            margin-bottom: 0;
        }

        &--usreps {
            width: 100%;
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
        }

        &--report {
            max-height: 200px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
        }
    }

    &__link{
        a{
            color: #e53d46;
        }
    }
}
