$module: callout-call-us;

.#{$module} {
  display: table;
  margin: 0 auto;
  text-align: left;

  // BASE STYLES

  &__staff-image,
  &__info {
    display: table-cell;
    vertical-align: middle;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  // Left Cell

  &__staff-image {
    padding-left: 0;
    padding-right: 0;

    // Staff Image Element

    img {
      display: block;
      width: 60px;
      height: 60px;
      max-width: none;
      border-radius: 50%;

      @media (min-width: $screen-sm-min) {
        width: 65px;
        height: 65px;
      }
    }
  }

  // Right Cell

  &__info {
    padding-left: .5rem;
    padding-right: 0;

    // Phone Icon

    &-phone {
      display: block;
      position: absolute;
      bottom: .5rem;
      right: -1.85rem;
      font-size: 1.75rem;
    }

    // Copy Lines

    &-line {
      display: block;
      position: relative;
      line-height: 1;
      font-size: .8rem;
      white-space: nowrap;
      height: 1.1rem;

      &-1 {
        font-weight: 600;
        display: inline-block;
      }

      &-2 {
        font-weight: 400;
      }

      &-3 {
        font-weight: 600;
        color: $red;

        a {
          color: $red;
        }
      }
    }
  }

  //==== VARIATIONS ====//

  // CERT DETAIL PAGES

  &--cert-detail-container {
    position: relative;
    margin: 0 auto;
    bottom: 1.5rem;

    @media (min-width: $screen-md-min) {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    margin: 0;
    }
  }

  // CERT GRID PAGES

  &--cert-grid-container {
    position: relative;
    margin: 2rem auto 0;
    bottom: 0;

    .callout-call-us__info-line-3 {
      color: $green;
      a {
        color: $green;
      }
    }

    @media (min-width: $screen-md-min) {
      position: absolute;
      left: 10%;
      bottom: -6rem;
      margin: 0;
    }
  }

  // PT Grid

  &--pt-grid {
    display: inline-block;
    position: relative;
    padding: 0 20px;

    .callout-call-us__info-line-3 {
      color: $green;
      a {
        color: $green;
      }
    }
  }

  // SPECIALIST DETAIL PAGES

  &--specialist-detail-container {
    position: relative;

    .#{$module} {
      margin: 20px auto 0;

      @media (min-width: $screen-md-min) {
        float: left;
      }
    }
  }
}
