$module: 'event';

$aceAnswersEventWidth: 390px;

.#{$module} {
	padding-top: 20px;
	padding-bottom: 20px;
	border-top: 1px solid $gray-med;

	&__body {
		@include make-xs-column(12, 0);
	}

	&__cta-btn {
		@extend %ace-btn-stroke-micro;
	}

  &__more-btn {
    @extend %button;
		@include button-variant($green !important, transparent, transparent);
		@extend %margin-auto;
		@include clearfix();
		margin: 0;
    padding-left: 0;
		white-space: normal;

    &:hover {
      color: lighten($green, 10%) !important;
    }
  }

	&__date {
		@include make-xs-column(12, 0);
		padding-bottom: 10px;
		color: $blue;
		font-size: remUnit(16px);
		font-weight: $font-weight-semibold;
		text-align: center;
		text-transform: uppercase;
	}

	&__meta {
		margin: 0 0 10px;
		padding: 0;
		list-style: none;
		font-size: remUnit(14px);

		li {
			margin-bottom: 5px;
			color: $blue;
		}

		li.#{$module}__meta-price {
			text-transform: uppercase;
			font-weight: 700;
		}

		li.#{$module}__meta-price--free {
			color: $red;
		}
	}

	&__meta-location {
		font-size: remUnit(16px);
		font-weight: $font-weight-semibold;

		a {
			font-size: remUnit(14px);
		}
	}

	&__row {
		@include make-row(0);
	}

	@media (min-width: $screen-md-min) {
		&__body {
			@include make-md-column(9, 0);
			@include make-valign-col(middle);
			padding-right: 25px;
			padding-left: 25px;
		}

		&__date {
			@include make-md-column(3, 0);
			@include make-valign-col(middle);
			padding-top: 30px;
			padding-bottom: 30px;
			background-color: $gray-light;
			font-size: remUnit(14px);
			line-height: 1;

			span {
				display: block;
				font-size: remUnit(48px);
			}
		}

		&__date--image {
			padding-top: 0px;
			padding-bottom: 0px;
		}

		&__row {
			@include make-valign-row;
		}
	}

	// ACE Answers

	&--ace-answers {
		width: $aceAnswersEventWidth;
		margin-bottom: 1rem;
		text-align: left;
		border: 0;

		.#{$module}__row {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-start;
			flex-wrap: nowrap;
		}
		.#{$module}__date {
			float: none;
			width: 120px !important;
			min-width: 120px !important;
			padding: 1rem;
			line-height: 1;
			background-color: #fff;

			span {
				display: block;
				line-height: 1;
				font-size: remUnit(48px);
			}
		}
		.#{$module}__body {
			float: none;
			padding: 0 0 0 1.5rem;
		}
		.#{$module}__meta {
			margin-bottom: 5px;
		}
		.#{$module}__meta-medium {
			margin-bottom: 3px;
			font-size: remUnit(12px);
			font-weight: 400;
			line-height: 1.3;
			text-transform: none;
			color: $blue;
		}
		.#{$module}__meta-location {
			line-height: 1.4;
			font-weight: 600;
			color: $red;
			font-size: remUnit(19px);
		}
		.#{$module}__footer {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: nowrap;

			@media (min-width: $screen-xs-min) {
				flex-direction: row;
				align-items: center;
			}
		}
		.#{$module}__time {
			margin-right: .75rem;
			margin-bottom: 0;
			font-size: remUnit(13px);
			font-weight: 700;
			text-transform: uppercase;
			color: $blue;

			@media (min-width: $screen-sm-min) {
				white-space: nowrap;
			}
		}
		.#{$module}__more-btn {
			padding-top: 2px;
			padding-bottom: 0;
			white-space: nowrap;
		}
	}
}

#ace-answers-events {
	display: inline-flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 0;
	text-align: left;

	@media (min-width: #{$aceAnswersEventWidth + 34px}) {
		width: #{$aceAnswersEventWidth};
	}

	@media (min-width: #{($aceAnswersEventWidth * 2) + 34px}) {
		width: #{$aceAnswersEventWidth * 2};
	}

	@media (min-width: #{($aceAnswersEventWidth * 3) + 34px}) {
		width: #{$aceAnswersEventWidth * 3};
	}

	&.hide-extra-events {
		.event--ace-answers:nth-child(n+7) {
			display: none;
		}
	}
}
