$module: 'pricing-table';

.#{$module} {
  &__body {
    height: auto;
    min-height: 0;

    @media (min-width: $screen-md-min) {
      height: 475px;
      min-height: 475px;
    }
  }

  &__list {
    li {
      font-size: .825rem;
      margin-bottom: .25rem;
      text-align: left;
    }
  }

  &__description {
    font-size: .850rem;
    text-align: left;
  }

  &--featured {
    .#{$module}__body{
      height: auto;
      min-height: 0;

      @media (min-width: $screen-md-min) {
        height: 490px;
        min-height: 490px;
      }
    }
  }
}

// Bundle Cards
.programs--grid-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  .#{$module} {
    order: 2;
    width: 100%;
    max-width: 600px;
    margin: .5rem auto;

    &--featured {
      order: 1;
    }

    &__caption {
      overflow: visible;
    }

    &__body {
      display: flex;
      flex-direction: column;
      height: auto;
      min-height: 0;
    }

    &__title {
      order: 1;
    }

    &__cost {
      order: 2;
      margin-bottom: 1rem;
    }

    &__description {
      order: 3;
    }

    &__list {
      order: 4;
      width: 100%;
      margin: 0 auto;
      text-align: left;

      @media (min-width: $screen-xs-min) {
        width: 70%;
      }

      &--hidden {
        display: none;
      }

      &--show-more {
        display: block;
        margin: 1rem auto;
        text-align: center;
        color: #e53d46;
        font-weight: 500;
      }

      li {
        padding-left: .5rem;
      }
    }
  }
}

// Bundle Cards
.programs--grid-page-pt {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  .#{$module} {
    // order: 2;
    width: 100%;
    max-width: 600px;
    margin: .5rem auto;

    &--featured {
      // order: 1;
    }

    &__caption {
      overflow: visible;
    }

    &__body {
      display: flex;
      flex-direction: column;
      height: auto;
      min-height: 0;
    }

    &__title {
      // order: 1;
    }

    &__cost {
      // order: 2;
      margin-bottom: 1rem;
    }

    &__description {
      // order: 3;
    }

    &__list {
      // order: 4;
      width: 100%;
      margin: 0 auto;
      text-align: left;

      @media (min-width: $screen-xs-min) {
        width: 70%;
      }

      &--hidden {
        display: none;
      }

      &--show-more {
        display: block;
        margin: 1rem auto;
        text-align: center;
        color: #e53d46;
        font-weight: 500;
      }

      li {
        padding-left: .5rem;
      }
    }
  }
}
