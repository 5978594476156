// Based on post-related

@use "sass:math";

$module: 'slat-card-related';

.#{$module} {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	text-align: center;
	background-color: transparent;
	@include margin-auto;
	@include ace-btn-hover-transition();
	@include ace-btn-hover {
		outline: 0;
		text-decoration: none;

		.#{$module}__title {
			color: $red;
		}
	}

	&__image,
	&__header {
		width: 100%;
		display: block;
		position: relative;
	}

	&__thumbnail {
		position: relative;
		overflow: hidden;

		&--inline {
			display: block;
			width: 100%;
			height: auto;
			overflow: visible;
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		flex: 1;
		position: relative;
		padding-top: 20px;
		padding-bottom: 20px;
		background-color: #f0f2f3;
	}

	&__image {
		display: block;
		padding: 0 0 80%;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		position: relative;
		transition: all .4s;

		&--inline {
			background-image: none;
			width: 100%;
			height: auto;
			margin: 0 auto;

			&::before {
				content: none;
			}
		}

		&:before {
			content: "";
			display: none;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__header {
		padding-left: percentage(math.div(16px, 355px));
		padding-right: percentage(math.div(16px, 355px));
		padding-bottom: 10px;
		margin-bottom: 10px;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			margin: 0 auto;
			width: 100px;
			height: 5px;
			background: rgba($red, 1.0);
		}
	}

	&__title {
		position: relative;
		font-weight: $font-weight-semibold;
		margin: 0 auto;
		padding-bottom: .5rem;
		$lines-to-show: 2;
		$font-size: 20px;
		$line-height: lh($font-size, 24px);
		$use-max-height: false;
		@include text-overflow($lines-to-show, $font-size, $line-height, $use-max-height);
	}

	&__copy {
		position: relative;
		font-weight: $font-weight-regular;
		color: $gray;
		margin: 15px auto 10px auto;
		padding-left: percentage(math.div(16px, 355px)); // same as header
		padding-right: percentage(math.div(16px, 355px)); // same as header
		$lines-to-show: 5;
		$font-size: 13px;
		$line-height: lh($font-size, 19px);
		$use-max-height: false;
		@include text-overflow($lines-to-show, $font-size, $line-height, $use-max-height);

		@media (max-width: 767px) {
			height: auto;
		}
	}

	&__meta {
		display: block;
		max-width: 90%;
		margin: .25rem auto;
		text-align: center;
		font-size: remUnit(11px);
		font-weight: $font-weight-regular;
		color: $gray;
	}

	&__link-text {
		@extend %ace-btn-stroke-micro--red;
		margin: 0 auto;
	}

	&__link-text-prefix {
		text-decoration: line-through;
	}

	&__cec-info {
		position: absolute;
		left: 10px;
		bottom: 10px;
		width: 50px;
		height: 50px;
		color: #fff;
	}

	&__rating {
		width: 100%;
		text-align: center;
		font-size: 0; // remove white space
		margin-top: 15px;
		margin-bottom: 15px;

		.icon-wrap {
			font-size: 14px;
			padding-left: 2px;
			padding-right: 2px;
			display: inline-block;
		}
	}

	&--new,
	&--sale {
		&:after {
			width: 68px;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			padding-top: 6px;
			padding-right: 10px;
			padding-bottom: 6px;
			background-repeat: no-repeat;
			background-size: cover;
			text-align: right;
			text-transform: uppercase;
			font-size: remUnit(12px);
			@extend %font-roboto-medium;
		}
	}

	&--sale {
		&:after {
			color: #fff;
			content: "Sale";
			@include svg-featured-callout($green);
		}
	}

	&--new {
		&:after {
			color: $green;
			content: "New";
			@include svg-featured-callout(#fff);
		}
	}

	&--white &__body {
		background-color: #fff;
	}

	&--info {
		.#{$module}__link-text {
			@extend %ace-btn-floating--red;
		}

		.#{$module}__body {
			background-color: #fff;
		}

		.#{$module}__header {
			flex: 1;
		}

		.#{$module}__title {
			position: relative;
			font-weight: $font-weight-semibold;
			margin: 0 auto;
			$lines-to-show: 2;
			$font-size: 20px;
			$line-height: lh($font-size, 24px);
			$use-max-height: true;
			@include text-overflow($lines-to-show, $font-size, $line-height, $use-max-height);
		}

		@include ace-btn-hover {
			outline: 0;
			text-decoration: none;

			.#{$module}__title {
				color: $red;
			}
		}
	}

	&--long-content {

		.#{$module}__image,
		.#{$module}__thumbnail {
			height: 180px;
		}

		.#{$module}__thumbnail {
			background-size: contain;
			background-color: #fff;
		}

		.#{$module}__body {
			height: 260px;
		}

		.#{$module}__copy {
			$lines-to-show: 8;
			$font-size: 13px;
			$line-height: lh($font-size, 19px);
			$use-max-height: false;
			@include text-overflow($lines-to-show, $font-size, $line-height, $use-max-height);
		}
	}

	&--product &__header {
		margin-bottom: 31px;
	}

	&--product &__body {
		background-color: #fff;
	}

	&--no-body-copy {
		min-height: 220px;

		.#{$module}__body {
			height: 5rem;
		}

		.#{$module}__header {
			&:after {
				background: none;
			}
		}
	}

	&--career-opps {
		.#{$module}__header {
			margin-bottom: 0;

			&::after {
				display: none;
			}
		}

		.#{$module}__body {
			height: 200px;
		}

		.#{$module}__body,
		.#{$module}__copy {
			text-align: left;
		}

		.#{$module}__title {
			margin-bottom: .25rem;
			$lines-to-show: 1;
			$font-size: 24px;
			$line-height: lh($font-size, 24px);
			$use-max-height: true;
			@include text-overflow($lines-to-show, $font-size, $line-height, $use-max-height);
		}

		.#{$module}__taxonomy {
			color: $red;
			font-weight: 700;
			margin-bottom: .5rem;
		}

		.#{$module}__copy {
			margin: 0 auto 5px auto;
			$lines-to-show: 3;
			$font-size: 15px;
			$line-height: lh($font-size, 24px);
			$use-max-height: false;
			@include text-overflow($lines-to-show, $font-size, $line-height, $use-max-height);
		}
	}
}