// Add the apprpriate padding to buttons

@mixin ace-btn-size($size:null){
	@if $size == jumbo {
		padding: $ace-btn-padding-jumbo;
	}
	@else if $size == micro {
		padding: $ace-btn-padding-micro;
	}
	@else {
		padding: $ace-btn-padding;
	}
}
