$module: 'fade-overflow';
/**
 * Module for truncating content
 * to make it shorter on mobile
 * @example html
 * ```
 * <div class="fade-overflow">
 *  <div class="fade-overflow__content">
 *    <!-- content to truncate goes here -->
 *    <div class="fade-overflow__fade"></div>
 *    <button class="fade-overflow__trigger">
 *    Read<span class="more-txt"> More</span><span class="less-txt"> Less</span>
 *    </button>
 *  </div>
 * </div>
 *
 * ```
 *
 */
.fade-overflow {
  position: relative;
  height: auto;

  &__content {
    max-height: 92px;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }

  &__fade {
    width: 100%;
    position: absolute;
    right:  0;
    bottom: 0;
    left:   0;
    margin: 0;
    padding-top: 45px; // height of the fade
    text-align: center;
    background: linear-gradient(to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.50) 50%,
      rgba(255, 255, 255, 0.20) 100%);
    opacity: 1;
    transition: opacity 0.5s ease;
  }

	&--gray-light {
		.#{$module}__fade {
			background: linear-gradient(to top,
				rgba($gray-light, 1) 0%,
				rgba($gray-light, 0.50) 50%,
				rgba($gray-light, 0.20) 100%);
		}
	}

  // Event listener added to this in scripts.js
  &__trigger {
		@extend %ace-btn-stroke--blue;
		display: block;
		margin: 15px auto 0 auto;
  }

  &__trigger .less-txt {
    display: none;
  }

  &--expanded &__content {
    max-height: 1000px; // needs a height for transition
  }

  &--expanded &__fade {
    opacity: 0;
  }

  &--expanded &__trigger {
    .more-txt { display: none; }
    .less-txt { display: inline; }
		.icon {transform: rotate(180deg);}
  }

}

/**
 * Show full content on larger screens
 */
@media (min-width: $screen-md-min) {

  .fade-overflow {
    max-height: none;
    overflow: visible;

    &__content {
      max-height: none;
      overflow: visible;
    }

    &__fade,
    &__trigger {
      display: none;
    }
  }

}
