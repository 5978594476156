// Mixin defining a tag/filter tag
// Notes:
// - All arguments default to variables set in ace-buttons-settings.
//
// - Theme keys must be stored in the `$map-ace-btn-themes-primary` in
//   `_ace-buttons-themes.scss`
//
// - If passing individual arguments you must pass null as the first argument
// - and use name arguments unless all arguments are passed [see underloaded example].
//
// Examples:
// default:     ace-btn-tag() red background white text
// theme:       ace-btn-tag(red)
// overloaded:  ace-btn-tag(null, #000, #fff, #fff, #000, #e53d46, #000)
// type:        ace-btn-tag($type:filter)


@mixin ace-btn-tag (
		$theme: gray-light,
		$bg-color: $ace-btn-bg-color,
		$bg-color-active: $ace-btn-bg-color-active,
		$font-color: $ace-btn-font-color,
		$font-color-active: $ace-btn-font-color-active,
		$icon-fill: $ace-btn-icon-fill,
		$icon-fill-active: $ace-btn-icon-fill-active,
		$type: null,
		$block: null
	)

{
	// Check for theme and set args to those values
	@if $theme {
		$map: map-get($map-ace-btn-themes-tag, $theme);
		$bg-color: map-get($map, bg-color);
		$bg-color-active: map-get($map, bg-color-active);
		$font-color: map-get($map, font-color);
		$font-color-active: map-get($map, font-color-active);
		$icon-fill: map-get($map, icon-fill);
		$icon-fill-active: map-get($map, icon-fill-active);
	}
		// include shared styles of non floating button
		// @include ace-btn-base();
		padding: 4px 12px;
		border-radius: 500px;
		font-family: $font-family-muli;
		font-weight: $font-weight-regular;
		font-size: remUnit(14px);
		text-transform: none;
		text-decoration: none;
		vertical-align: middle;
		display: inline-block;
		// add border defs
		border-style: $ace-btn-border-style;
		border-width: $ace-btn-border-width;
		border-color: $bg-color;
		color: $font-color;
		background-color: $bg-color;
		line-height: $ace-btn-line-height;
		appearance: none; // just in case it's a button or input

		// include button transition properties
		@include ace-btn-hover-transition();

		// filters have an icon
		@if $type == filter {
			.icon-wrap {
				display: inline-block;
				font-size: remUnit(12px);
				padding: 2px;
				vertical-align: middle;
				margin-left: 8px;
			}
			.icon {
				@if $icon-fill { // if specified
				fill: $icon-fill;
				} @else { // else same as font color
				fill: $font-color;
				}
			}
		}




		&:focus,
		&:active,
		&:hover {
			text-decoration: none;
			outline: none;

			.icon,
			.icon-wrap {
				text-decoration: none;
			}
		}
		&:visited {
			color: $font-color;
		}

		&:active,
		&:focus,
		&:hover {
			color: $font-color-active;
			background-color: $bg-color-active;
			border-color: $bg-color-active;
			text-decoration: none;
			outline: none;
			// filters have an icon
			@if $type == filter {
				.icon {
					@if $icon-fill { // if specified
					fill: $icon-fill-active;
					} @else { // else same as font color active
					fill: $font-color-active;
					}
				}
			}
		}

}
