// Mixin defining a stroke button
// Notes:
// - All arguments default to variables set in ace-buttons-settings.
//
// - Theme keys must be stored in the `$map-ace-btn-themes-stroke` in
//   `_ace-buttons-themes.scss`
//
// - If passing individual arguments you must pass null as the first argument
// - and use name arguments unless all arguments are passed [see underloaded example].
//
// Examples:
// default:         ace-btn-stroke()
// theme:           ace-btn-stroke(red)
// overloaded:      ace-btn-stroke(null, #000, #fff, #000)
// oveunderloaded:  ace-btn-stroke(null, $font-color: #000)

@mixin ace-btn-stroke-micro (
		$theme:             null,
		$font-color:        $ace-btn-font-color-stroke,
		$font-color-active: $ace-btn-font-color-active-stroke,
		$bg-color-active:   $ace-btn-bg-color-active-stroke,
		$size: micro,// pass micro through to btn base in btn stroke
		$block: null
	)

{
	// Check for theme and set args to those values
	@if $theme {
		$map: map-get($map-ace-btn-themes-stroke-micro, $theme);
		$font-color: map-get($map, font-color);
		$font-color-active: map-get($map, font-color-active);
		$bg-color-active: map-get($map, bg-color-active);
	}
	// pass $theme as null to ensure the use of this map
	// in the event that it needs to break off from shared styles
	@include ace-btn-stroke (
			null,
			$font-color: $font-color,
			$font-color-active: $font-color-active,
			$bg-color-active: $bg-color-active,
			$size: $size,
			$block: $block
		)
}
