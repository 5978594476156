$module: 'widget';

.widget {
    padding-bottom: 30px;

    &--bordered {
        margin-bottom: 30px;
        border-bottom: 1px solid $gray-med;
    }

    &--bordered-top {
        padding-top: 20px;
        border-top: 1px solid $gray-med;
    }

    &--drawer {
        @media (min-width: 992px) {
            position: relative;
            margin: 0 0 -1px;
            padding: 0;
            border-top: 1px solid $gray-med;
            border-bottom: 1px solid $gray-med;

            .widget__title {
                position: relative;
                margin: 0;
                padding: 30px 0;
                font-family: 'Roboto';
                font-weight: 700;
                cursor: pointer;

                &::after {
                    content: "\2013";
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    font-size: 150%;
                    color: inherit;
                    transform: translateY(-50%);
                }
            }

            &.collapsed {
                .widget__link-list {
                    display: none;
                }

                .widget__title {
                    &::after {
                        content: "\002b";
                    }
                }
            }
        }
    }
    //
    // Widget Header Elements
    // ====================================================
    &__title {
        color: $gray;
        @extend %font-roboto-medium;
        font-size: remUnit(13px);
        text-transform: uppercase;

        .#{$module}__ace-pros--other-pros & {
            margin-bottom: 2rem;
            text-transform: none;
            text-align: left;
            font-size: $font-size-h3;
            font-weight: 400;
            color: $blue;
        }
    }

    &__poster {
        text-align: center;
        margin: 0 auto 30px auto;

        .content__secondary-two-column__search-results--certified & {
            margin-top: 30px;
        }

        img {
            display: block;
            width: 100%;
            max-width: 170px;
            margin: 0 auto;
            box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.3);
        }
    }
    //
    // Link Lists
    // ====================================================
    &__link-list {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: remUnit(14px);
        line-height: lh(14px, 16px);

        a {
            font-weight: $font-weight-light;
        }

        li {
            margin-bottom: 11.5px;
            transform: translate3d(0, 0, 0);
        }

        > li:last-child {
            margin-bottom: 0;
        }
    }

    &__link-list-content li.selected a {
        text-decoration: underline;
    }

    &__link-list li.selected-topic a {
        color: $red;
        text-decoration: underline;
    }

    &__link-list-subtitle {
        display: block;
        font-size: remUnit(11px);
        font-weight: $font-weight-semibold;
        line-height: 1.45;
        text-transform: uppercase;
    }
    // Sidebar accordion widget
    &__link-list--accordion {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: remUnit(14px);
        }

        .#{$module}__link-list-header {
            position: relative;
            padding-right: remUnit(20px); // then offset this in icon

            &--no-subitems {
                cursor: inherit;
            }
        }

        .#{$module}__link-list-title {
            position: relative;
            font-size: remUnit(20px);
            color: $blue;
            cursor: pointer;
            margin-bottom: 0;
            font-weight: $font-weight-regular;

            &--no-subitems {
                font: inherit;
            }

            .icon-wrap {
                display: inline-block;
                position: absolute;
                right: - remUnit(20px); // offset header padding
                top: 0;
                bottom: 0;
                // top:25%;bottom: 25%;
            }

            .icon {
                width: remUnit(11px);
            }
        }

        .accordion-trigger.selected {
            .icon {
                transform: rotate(-180deg);
            }
        }

        .#{$module}__link-list-content {
            display: none;
            // Here in case right gutter is needed on these later
            // padding-right: remUnit(20px); // same as header
        }

        .#{$module}__link-list-title,
        .#{$module}__link-list-content li {
            margin-bottom: 11.5px;
        }
    }

    &__link-list--recent-posts {
        li {
            margin-bottom: 11.5px;
        }
    }
    // Topics Widget Accordion
    &--topics {
        .#{$module}__title {
            color: $blue;
        }

        .#{$module}__link-list-title {
            font-size: remUnit(14px);
        }

        .accordion-trigger.selected {
            .#{$module}__link-list-title {
                color: $red;
            }
        }

        .#{$module}__link-list-content {
            padding-left: remUnit(20px);
            padding-right: remUnit(20px); // same as header
        }

        .#{$module}__link-list-content a {
            color: $red;
        }
    }

    &--topics-exercises {
        .#{$module}__title {
            color: $gray;
        }
    }
    //
    // Post Brief
    // ====================================================
    &__post-brief {
        margin-bottom: 20px;
        @include make-xs-column(12, 0);

        .content__secondary & {
            @media (min-width: 768px) and (max-width: 992px) {
                height: 180px;
            }
        }
    }

    &__post-brief-row {
        @include make-row(0);
    }

    &__post-brief-title {
        margin-top: 5px;
        font-size: remUnit(16px);
    }

    &__post-brief-video-link {
        display: block;
        position: relative;
    }

    &__post-brief-btn-video {
        display: block;
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 2.5em;
        background: #fff;
        border-radius: 1.25em;
        font-size: remUnit(20px);
        line-height: 2.5;
        text-align: center;
    }

    &__post-brief-bg-img {
        display: block;
        box-sizing: border-box;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: -9999em -9999em;

        @media (min-width: $screen-sm-min) {
            background-position: center center;
            width: 100%;
            padding-top: 50%;
        }

        &--inline-duplicate {
            display: block;
            width: 100%;
            height: auto;
            margin: 0 auto;

            @media (min-width: $screen-sm-min) {
                display: none;
            }
        }
    }
    //
    // Search
    // ====================================================
    &__search-form {
        position: relative;
        width: 100%;
        border-bottom: 1px solid $blue;
    }

    &__search-label {
        @extend .sr-only;
    }

    &__search-input {
        width: 100%;
        padding-right: 21px;
        position: relative;
        top: 0;
        @extend .form-control;
        padding-left: 0;
        background-color: transparent;
        border: none;
    }

    &__search-submit {
        width: 28px;
        border: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        cursor: pointer;
        text-align: center;
        padding-top: 2px 4px;
        background-color: #fff;
        color: $blue;
        font-size: remUnit(20px);
    }
    //
    // ACE Pro
    // ====================================================
    &__ace-pros {
        text-align: center;
        margin-bottom: 0;

        &--other-pros {
            padding: 2rem 0 0;
            background-color: #fff;
            border-top: 1px solid #dadfe1;

            .widget {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    &__ace-pro {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        max-width: 300px;
        padding: .25rem;

        #find-ace-pro-results & {
            margin-bottom: 2rem;
        }

        .#{$module}__ace-pros--other-pros & {
            margin-bottom: 2rem;
            max-width: 275px;

            @media (min-width: $screen-sm-min) {
                max-width: 350px;
            }

            @media (min-width: $screen-md-min) {
                max-width: 200px;
            }

            @media (min-width: $screen-lg-min) {
                max-width: 275px;
            }
        }
    }

    &__ace-pro-wrap {
        &:hover {
            text-decoration: none;
        }
    }

    &__ace-pro-thumbnail {
        max-width: 160px;
        height: auto;
        margin: 0 auto 10px auto;
        overflow: hidden;
        border-radius: 50%;
        transform-style: preserve-3d;
        transform: translate3d(0,0,0);

        img {
            width: 100%;
            transform: scale(1);
            transition: all .3s ease;

            &:hover {
                transform: scale(1.2);
            }
        }
    }

    &__ace-pro-thumbnail-outer {
        display: inline-block;
        position: relative;
    }

    &__ace-pro-caption {
        text-align: center;
        margin: 0 auto;
    }

    &__ace-pro-name {
        font-size: remUnit(16px);
        font-weight: $font-weight-semibold;
        margin-bottom: .25rem;
    }

    &__ace-pro-location,
    &__ace-pro-distance,
    &__ace-pro-cert {
        display: block;
        font-size: remUnit(14px);
        line-height: lh(14px, 22px);
        font-weight: $font-weight-regular;
        color: $gray;
    }
    //
    // Accordion
    // ====================================================
    &--accordion {
        clear: both;
        margin-right: -20px;
        margin-left: -20px;
        padding: 0;
        border-bottom: 1px solid #415c6d;
        font-size: remUnit(13px);
        line-height: 1.7;

        .#{$module}__link-list {
            display: none;
            padding: 0 20px 20px;
            font-size: remUnit(13px);

            a {
                color: $blue-light;
            }
        }

        .#{$module}__head {
            &:hover,
            &:active,
            &.active,
            &:focus,
            &.focus {
                background-color: darken($blue, 10%);
                cursor: pointer;
            }
        }

        .#{$module}__title {
            position: relative;
            margin: 0;
            padding: 15px 40px 15px 20px;
            color: #fff;
            @extend %font-roboto-regular;
            font-size: remUnit(14px);

            .icon {
                float: right;
                right: 20px;
            }
        }
        // .#{$module}__title::after {
        // 	display: block;
        // 	position: absolute;
        // 	right: 20px;
        // 	top: 50%;
        // 	content: '⚀';
        // 	-webkit-transform: translateY(-50%);
        // 	-ms-transform: translateY(-50%);
        // 	transform: translateY(-50%);
        // }

        .accordion-trigger.selected {
            .icon {
                transform: rotate(-180deg);
            }
        }
    }
    //
    // Share
    // ====================================================
    &--share {
        @include clearfix;

        .#{$module}__title {
            display: inline-block;
            margin: 0;
            padding-right: 3px;
            vertical-align: middle;
        }

        .#{$module}__share-wrap {
            display: inline-block;
            vertical-align: middle;
        }

        .#{$module}__share-btn {
            display: block;
            float: left;
            margin-right: 3px;
            font-size: remUnit(40px);

            &:last-child {
                margin: 0;
            }
        }
    }
    //
    // Contact
    // ====================================================
    &--site-contact {
        float: none;
        padding: 0;
        font-size: remUnit(13px);
        line-height: 1.7;
        display: block;
        text-align: center;

        address {
            margin-bottom: 10px;
            line-height: 1.7;
        }

        .#{$module}__title {
            color: #fff;
            @extend %font-roboto-regular;
            font-size: remUnit(14px);
        }
    }

    @media (min-width: $screen-xs-min) {
        &--site-contact {
            float: right;
            text-align: left;
        }
    }

    @media (min-width: $screen-sm-min) {
        &__post-brief {
            margin-bottom: 20px;
            @include make-xs-column(4, 40px);
        }

        &__post-brief-row {
            @include make-row(40px);
        }
    }

    @media (min-width: $screen-md-min) {
        &__post-brief {
            @include make-xs-column(12, 0);
        }

        &__post-brief-row {
            @include make-row(0);
        }
    }

    @media (min-width: $screen-lg-min) {
        &--accordion {
            float: left;
            clear: none;
            margin: 0 0 60px;
            padding-right: 36px;
            border: none;

            .#{$module}__link-list {
                display: block !important;
                padding: 0;
            }

            .#{$module}__head h2 {
                padding: 0;
            }

            .#{$module}__head .icon {
                display: none;
            }
        }
    }
}
