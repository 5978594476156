$module: ace-hero;

/*
<div class="ace-hero bg-pos-xc-yc" style="background-image: url(desktop.jpg);">
  <div class="ace-hero__inner ace-hero__inner--pad-y-20-pct">
    <div class="ace-hero__content">
      SOME CONTENT
    </div>
    <div class="ace-hero__footer">
      FOOTER CONTENT
    </div>
  </div>
</div>
*/

.#{$module} {
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 6rem 1rem;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    /*
      Add % based proportional y axis padding to determine hero height
        .ace-hero__inner--pad-y-20-pct {
          padding: 20% 1rem;
        }
    */

    @media (min-width: $screen-md-min) {
      $pctVals: 30;
      @for $i from 5 through $pctVals {
        $padY: #{$i} + '%';

        &--pad-y-#{$i}-pct {
          padding: #{$padY} 1rem;
        }
      }
    }
  }

  &__content {
    display: block;
    width: 100%;
    position: relative;
    margin: 0 auto;
  }

  &__sub-content {
    width: 100%;
  }

  &__footer {
    display: block;
    position: relative;
    width: 100%;
    padding: 1rem;
    bottom: 0;
    left: 0;
    right: 0;

    @media (min-width: $screen-md-min) {
      position: absolute;
    }
  }

    // .ace-hero__h2 is legacy -- don't use

    &__h1 {
      font-size: 7.5vw;
  
      @media (min-width: $screen-md-min) {
        font-size: 4rem;
      }
    }
  
    // .ace-hero__h2 is legacy -- don't use
  
    &__h2 {
      font-size: 5vw;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
  
      @media (min-width: $screen-md-min) {
        font-size: 3rem;
        max-width: none;
      }
    }
}
