$module: 'link-grid';

.#{$module} {
	padding: 50px 0;
	background-position: center;
	background-repeat: repeat;
	background-size: cover;


	&__wrap {
		@extend .container-fluid;
		max-width: 1000px;
		padding-right: 20px;
		padding-left: 20px;
	}

	&__header {
		@include sub-section-header-styles(full);
	}
	&__header
	&__label,
	&__title,
	&__description {
		color: #fff;
	}

	&__grid {
		@include make-row(0);
		list-style: none;
		margin: 30px auto 0 auto;
		padding: 0;
	}

	@each $label, $map in $map-stepped-color-red {

    $index: $label;
    $bg: map-get($map, bg);
    $bgHover: map-get($map, bgHover);

    &__grid {
      li:nth-child(#{$index}) {
        background-color: $bg;
      }
    }
  }

	&__item {
		@include make-sm-column(3, 0);
		display: table;
		position: relative;
		width: 100%;
		color: #fff;
		transition: all 0.2s ease;
		padding: 9px 17px;
		transform-origin: 50%;
		will-change: transform;
	}

	&__item-icon-wrap {
		width: 15px;
		height: 100%;
		position: absolute;
		top: 0;
		right: 11px;
		bottom: 0;
		.icon {
			position: relative;
			top: 25%;

		}
	}

	li.#{$module}__item.#{$module}__item--cta {
		background: transparent;
		background-color: transparent;
		text-align: center;
		margin-top: 20px;
		.#{$module}__item-label {
			@extend %ace-btn-stroke--reversed;
			@extend %margin-auto;
			width: 100%;
		}
		&:hover {
			transform: none;
			box-shadow: none;
		}
	}

	&__item-content {
		display: table-cell;
		vertical-align: middle;
		color: #fff;
		@include ace-btn-hover {
			color: #fff;
			text-decoration: none;
		}
	}
	&__item-marker,
	&__item-label,
	&__item-icon-wrap {
		vertical-align: middle;
		display: inline-block;
	}

	&__item-marker {
		width: 30px;
		height: 30px;
		line-height: 30px;
		font-size: remUnit(13px);
		text-align: center;
		border: 1px solid #fff;
		border-radius: 50%;

		margin-right: 4px;
		margin-left: -4px;
		@extend %font-roboto-medium;
	}

	&__item-label {
		font-size: remUnit(16px);
		font-weight: $font-weight-regular;
	}

	// Small devices (tablets, 768px and up)
	// ==========================================================================
	@media (min-width: $screen-sm-min) {
			text-align: center;
			padding-top: 70px;
			padding-bottom: 90px;
			&__item {
				padding: 0 30px;
				height: 180px;
				z-index: 200;
                width: 33%;

				&:hover {
					z-index: 220;
					box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
					transform: scale(1.03);
				}
			}

			&__item-marker {
				width: 34px;
				height: 34px;
				line-height: 34px;
				font-size: remUnit(15px);
				vertical-align: top;
				margin: 0 auto 10px auto;
			}
			&__item-label {
				display: block;
				padding: 0;
				line-height: 1.15;
			}
			&__item-icon-wrap {
				display: none;
			}
			li.#{$module}__item.#{$module}__item--cta {
				margin-top: 0;
				background-color: $red;
				.#{$module}__item-label {
					width: auto;
				}
				&:hover {
					box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
					transform: scale(1.03);
				}
			}
	} // end: Small devices (tablets, 768px and up) ==========================



	// Medium devices (desktops, 992px and up)
	// ========================================================================

	@media (min-width: $screen-md-min) {

		&__header {
			margin-bottom: 60px;
		}

		&__item-label {
			display: block;
			font-size: remUnit(20px);
		}
	} // end: Medium devices (desktops, 992px and up) ========================


	// Large devices (large desktops, 1200px and up)
	// ========================================================================
	// @media (min-width: $screen-lg-min) {
	// } // end: Large devices (large desktops, 1200px and up) ================


	&--certification-exam {
		.#{$module}__wrap {
			max-width: 1200px;
		}

		@media (max-width: $screen-sm-min - 1) {
			.link-grid__item-content {
				display: inline-block;
				vertical-align: top;
				width: calc(100% - 45px);
				margin-left: 15px;
			}
			.ace-btn-primary--reversed {
				margin-right: 1rem;
			}
		}

		@media (min-width: $screen-sm-min) {
			.#{$module}__grid {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				height: 100%;
			}

			.#{$module}__item {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				align-items: stretch;
				height: auto;
				padding: 1.5rem;
				float: none;

				p {
					font-size: .85rem;
					line-height: 1.5;
				}
			}


			li.#{$module}__item {
				&:hover {
					box-shadow: none;
					transform: none;
				}
			}

			.#{$module}__item-marker {
				flex: 30px 0 0;
			}

			.#{$module}__item-content {
				display: block;
				flex: 30px 1 0;

				img {
					margin-left: auto;
					margin-right: auto;
				}
			}

			.#{$module}__item-label {
				margin-bottom: 1rem;
			}
		}
	}
}
