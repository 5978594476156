// Mixin defining a stroke button
// Notes:
// - All arguments default to variables set in ace-buttons-settings.
//
// - Theme keys must be stored in the `$map-ace-btn-themes-stroke` in
//   `_ace-buttons-themes.scss`
//
// - If passing individual arguments you must pass null as the first argument
// - and use name arguments unless all arguments are passed [see underloaded example].
//
// Examples:
// default:         ace-btn-stroke()
// theme:           ace-btn-stroke(red)
// overloaded:      ace-btn-stroke(null, #000, #fff, #000)
// oveunderloaded:  ace-btn-stroke(null, $font-color: #000)

@mixin ace-btn-stroke (
		$theme:             null,
		$font-color:        $ace-btn-font-color-stroke,
		$font-color-active: $ace-btn-font-color-active-stroke,
		$bg-color-active:   $ace-btn-bg-color-active-stroke,
		$icon-fill:         null,
		$icon-fill-active:  null,
		$size: null,
		$block: null
	)

{
	// Check for theme and set args to those values
	@if $theme {
		$map: map-get($map-ace-btn-themes-stroke, $theme);
		$font-color: map-get($map, font-color);
		$font-color-active: map-get($map, font-color-active);
		$bg-color-active: map-get($map, bg-color-active);
		$icon-fill: map-get($map, icon-fill);
		$icon-fill-active: map-get($map, icon-fill-active);
	}
	// include shared styles
	@include ace-btn-base();
	// include button transition properties
	@include ace-btn-hover-transition();
	// jumbo or micro is passed through when this is called from an alias
	@include ace-btn-size($size);
	@if $block {
		display: block;
		width: 100%;
	} @else {
		display: inline-block;
	}
	color: $font-color;
	background-color: rgba($bg-color-active, 0.0); // set to 0 so it can transition on hover
	border-color: $font-color;
	border-style: $ace-btn-border-style;
	border-width: $ace-btn-border-width;

	.icon {
		@if $icon-fill {
		fill: $icon-fill;
		} @else {
		fill: $font-color;
		}
	}

	&:visited {
		color: $font-color;
	}

	&:active,
	&:focus,
	&:hover {
		color: $font-color-active;
		background-color: rgba($bg-color-active, 1.0);
		border-color: $bg-color-active;

		.icon {
			@if $icon-fill-active {
			fill: $icon-fill-active;
			} @else {
			fill: $font-color-active;
			}
		}
	}

	// Disabled State
	//
	// The disabled state uses the class .disabled, is-disabled,
	// and the form attribute disabled="disabled".
	// The use of !important is only added because this is a state
	// that must be applied to all buttons when in a disabled state.
	// &.disabled,
	// &.is-disabled,
	// &:disabled {}
}
