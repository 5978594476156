$module: 'nav-pane';

$mobile-nav-breakpoint: 1200px;

.nav-pane {
	display: none;
	position: fixed;
	left: 100%;
	z-index: 1;
	width: 100%;
	background-color: $red;
	color: #fff;

	&__back {
		@extend %button;
		@include button-size(15px, 20px, remUnit(14px), 1.66666667, 0);
		display: block;
		position: relative;
		width: 100%;
		padding-left: 40px;
		font-family: $font-family-roboto;
		font-weight: $font-weight-medium;
		text-transform: uppercase;
		text-decoration: none;
		text-align: center;
		letter-spacing: 1px;
		@include button-variant(#fff, $blue, transparent);

		.icon {
			position: absolute;
			left: 20px;
			width: 7px;
			height: 21.5px;
			transform: rotate(180deg);
		}
	}

	&__cert-columns-wrap {
		@media (min-width: $mobile-nav-breakpoint) {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: flex-start;
			width: 100%;
		}
	}

	&__cert-column {
		@media (min-width: $mobile-nav-breakpoint) {
			padding: 0 1rem;

			&--main {
				width: calc(35% - 1rem);
			}

			&--links {
				width: calc(21.66% - 1rem);
			}
		}
	}

	&__cert-column-subgroup {
		margin: 0 0 1.5rem;
	}

	&__cert-list {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-evenly;
		align-items: flex-start;
		padding: 0;
		list-style: none;

		@media (min-width: $screen-xs-min) {
			flex-direction: row;
			align-items: stretch;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}

	&__cert-item {
		display: block;
		box-sizing: border-box;
		width: 100%;
		padding: 0 3px 10px;

		@media (min-width: $screen-xs-min) {
			width: 50%;
		}
	}

	&__cert-item-link {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		position: relative;

		&::before {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-color: rgba(0,0,0,.3);
			z-index: 0;
			transition: background-color .2s;
		}

		&:hover {
			&::before {
				background-color: rgba(0,0,0,0);
			}

			.#{$module}__cert-item-text {
				background-color: rgba(2, 38, 60, 1);
				color: $yellow;
			}
		}

		&:hover,
		&:active,
		&.active,
		&:focus,
		&.focus {
			text-decoration: none;
		}
	}

	&__cert-item-img {
		display: block;
		width: 100%;
		margin: 0;
	}

	&__cert-item-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 100%;
		margin: 0;
		height: 90px;
		padding: .5rem;
		color: #fff;
		font-size: 1rem;
		font-weight: 700;
		text-align: center;
		line-height: 1.2;
		background-color: rgba(2, 38, 60, 0.8);
		z-index: 1;
		transition: all .2s;
	}

	&__menu {
		@include make-xs-column(12, 40px);
		@include make-md-column(3, 40px);
		padding-bottom: 20px;
	}

	&__menu-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__menu-list li {
		margin-bottom: 10px;
		font-size: remUnit(16px);
	}

	&__menu-list li a {
		color: #fff;
		font-weight: $font-weight-light;
	}

	&__menu-group {
		@include make-xs-column(12, 40px);
		@include make-md-column(9, 40px);
	}

	&__nested-group {
		@include make-xs-column(12, 40px);
		@include make-md-column(12, 40px);

		&--certs {
			@include make-md-column(3, 40px);
		}
	}

	&__nested-menu {
		@include make-xs-column(12, 40px);
		@include make-md-column(4, 40px);
		padding-bottom: 20px;

		&--certs {
			@include make-md-column(3, 40px);
		}
	}

	&__nested-menu--wide {
		@include make-md-column(6, 40px);
	}

	&__nested-row {
		@include make-row(40px);
	}

	&__menu-sub-title {
		display: block;
		margin-bottom: 10px;
		color: $blue;
		font-family: $font-family-roboto;
		font-size: remUnit(14px);
		font-weight: $font-weight-medium;
		text-transform: uppercase;
		letter-spacing: 1px;
		line-height: 1.1;
	}

	&__cert-grid-title,
	&__menu-title {
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #ea646b;
		color: #fff;
		font-size: remUnit(24px);
		font-weight: $font-weight-light;
	}

	&__more {
		display: inline-block;
		margin-bottom: 20px;
		font-size: remUnit(16px);
		text-transform: none;

		&--in-column {
			@media (min-width: $screen-md-min) {
				position: absolute;
				left: 1.3rem;
				top: -2.4rem;
			}
		}
	}

	&__promos {
		@include make-xs-column(12, 0);
		@include make-md-column(3, 0);
		padding: 20px;
		background: $red-dark;
		text-align: center;

		hr {
			margin-top: 20px;
			margin-bottom: 20px;
			border-color: #ea646b;
		}
	}

	&__promo-btn {
		@extend %button;
		white-space: normal;
		@include button-variant(#fff, $red-dark, transparent);
	}

	&__promo-cta {
		margin-bottom: 10px;
		color: #fff;
		font-size: remUnit(18px);
		font-weight: $font-weight-light;
		line-height: 1.25;
	}

	&__promo-feature-icon {
		display: block;
		margin: 0 auto;
		font-size: remUnit(30px);
	}

	&__promo-title {
		color: $blue;
		font-family: $font-family-roboto;
		font-size: remUnit(10px);
		font-weight: $font-weight-medium;
		text-transform: uppercase;
		letter-spacing: 1px;

		.icon {
			display: block;
			margin: 0 auto 5px auto;
			font-size: remUnit(30px);
		}
	}

	&__row {
		@include make-row(0);
	}

	&__sub-menu {
		@include make-xs-column(12, 0);
		padding-bottom: 20px;
	}

	&__sub-menu ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__sub-menu ul li {
		margin-bottom: 10px;
		font-size: remUnit(16px);
	}

	&__sub-menu ul li a {
		color: #fff;
		font-weight: $font-weight-light;
	}

	&__wrap {
		@extend .container-fluid;
		max-width: 1400px;
		padding: 20px;

		@media (min-width: $mobile-nav-breakpoint) {
			max-height: calc(100vh - 70px);
			overflow-y: auto;
		}
	}

	@media (min-width: $screen-sm-min) {
		&__cert-btn {
			height: 180px;
			background-position: 50% 25%;
		}
	}

	@media (min-width: $mobile-nav-breakpoint) {
		position: absolute;
		right: 0;
		left: 0;
		z-index: 1000;

		&__back {
			display: none;
		}

		&__cert-grid {
			padding-top: 30px;
			padding-right: 60px;
			padding-bottom: 30px;
			@include make-valign-col;
		}

		&__cert-title-btn {
			line-height: 4;
		}

		&__menu {
			padding-top: 30px;
			padding-bottom: 30px;
			@include make-valign-col;
		}

		&__menu-group {
			width: 100%;
			@include make-valign-col;
		}

		&__nested-menu {
			padding-bottom: 0;
		}

		&__cert-grid-title,
		&__menu-title {
			margin-bottom: 20px;
			padding-bottom: 20px;
			font-size: remUnit(36px);
		}

		&__promo-cta {
			font-size: remUnit(24px);
		}

		&__promo-feature-icon {
			font-size: remUnit(40px);
		}

		&__promo-title .icon {
			margin-bottom: 10px;
		}

		&__promos {
			@include make-valign-col(middle);
			padding: 30px;
		}

		&__row {
			@include make-row(0);
			@include make-valign-row;
		}

		&__sub-menu {
			@include make-md-column(4, 40px);
		}

		&__wrap {
			padding: 30px;
		}
	}

	&#certification-pane {

		.#{$module}__menu-title {
			white-space: nowrap;
		}
	}
}
