﻿$module: 'marquee-manager';

$slick-dot-active: #fff;
$slick-dot-inactive: rgba(0,0,0,.3);

.#{$module}-rotator {
	width: 100%;
	position: relative;
	padding-bottom: 40px;
	margin-bottom: 30px;

	.marquee-manager-item {
		width: 100%;
	}

	.slick-dots {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: center;
		z-index: 100;

		li {
			display: inline-block;
		}

		button {
			@extend %button;
			background-color: transparent;
			color: transparent;
			border-color: transparent;
			position: relative;
			width: 20px;
			height: 40px;
			padding: 0;
			font-size: 0;
		}

		button::before {
			content: '';
			display: block;
			position: absolute;
			top: 15px;
			left: 5px;
			width: 10px;
			height: 10px;
			background-color: $slick-dot-inactive;
			border-radius: 50%;
		}

		button:hover::before, li.cycle-pager-active button::before {
			background-color: $slick-dot-active;
		}
	}

	&-dots-inline {
		padding-bottom: 0;

		@media (max-width: #{$screen-sm-min - 1}) {
			.cycle-slideshow {
				padding-bottom: 23px;
			}
		}

		@media (min-width: $screen-sm-min) {
			.slick-dots {
				display: inline;
				width: auto;
				padding: 0 20px;
				left: auto;
				right: 0;
			}
		}
	}
}

.#{$module}-flat {
	width: 100%;
	position: relative;
}

.#{$module}-item img {
	max-width: 100%;
}

// SITEWIDE TOP BAR

.Ads-Sitewide-TopBar,
.Ads-Sitewide-TopBarB {
	@extend .marquee-manager-rotator-dots-inline;
	margin-bottom: 0;
	overflow: hidden;

	.slick-dots button {
		height: 25px;
	}

	.slick-dots button::before {
		background-color: $slick-dot-inactive;
		top: 5px;
	}

	.slick-dots button:hover::before,
	.slick-dots li.cycle-pager-active button::before {
		background-color: $slick-dot-active;
	}

	.sitewide-top-slide-cont {
		//------------------
		// Wrap cycle slide content in <div class='sitewide-top-slide-cont'></div>
		// and apply a bg-color-* class that matches the inner element's background
		//
		//
		// EXAMPLE:
		//
		//   <div class="sitewide-top-slide-cont bg-color-red">
		//	   <a class="btn btn--teaser">Inner Element</a>
		//   </div>
		//
		//------------------
		> * {
			position: relative;
		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 150px;
			left: 0;
			right: 0;
			top: 0;
		}

		$colors: 'blue', 'blue-light', 'gray', 'gray-med', 'gray-light', 'green', 'red', 'red-dark', 'yellow';
		$colorVars: $blue, $blue-light, $gray, $gray-med, $gray-light, $green, $red, $red-dark, $yellow;

		@each $color in $colors {
			$colorVar: nth($colorVars, index($colors, $color));

			&.bg-color-#{$color} {
				&::before,
				.btn--teaser {
					background-color: $colorVar !important;
				}

				.btn--teaser {
					border-color: $colorVar !important;
				}
			}
		}
	}
}

.Ads-Sitewide-TopBarB {
	.sitewide-top-slide-cont {
		.btn--teaser {
			font-family: Muli;
			font-weight: 700;
			font-size: 1.4rem;
			letter-spacing: 0;
			color: $blue;
			text-transform: none;
			
			.icon {
				fill: $blue;
			}
		}
	}
}


// NAV PANE ADS
.ads-section-nav {
	border-bottom: 1px solid #eee;
	border-color: $red;
	margin-bottom: 0;
}

.ads-section-nav.marquee-manager-flat {
	padding-bottom: 20px;
}

// CERTIFIED QUIZ PANEL AD

.certified-quiz-panel-marq-slot {
	@media (min-width: 992px) and (max-width: 1039px), (min-width: 1200px) and (max-width: 1239px) {
		padding-top: .65rem;
	}
}

// STANDARD VIOLATOR

.standard-violator {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;

	@media (max-width: 413px) {
		max-width: calc(100% - 96px);
	}

	&__link {
		display: block;
		text-decoration: none;
		position: relative;
		z-index: 1;
	}

	&__img,
	&__img-mobile {
		border: 0;
		outline: none;
		text-decoration: none;
	}

	&__img {
		display: block;
	}

	&__img-mobile {
		display: none;
	}

	&__close {
		display: block;
		position: absolute;
		left: 8px;
		top: 25%;
		cursor: pointer;
		z-index: 2;

	}

	&__close img {
		display: block;
		width: 19px;
		height: 19px;
		opacity: 0;
	}

	@media (max-width: 991px) {
		&__img {
			display: none;
		}
		&__img-mobile {
			display: block;
		}
		&__close {
			top: 3px;
			right: 7px;
			left: auto;
		}
	}
}
