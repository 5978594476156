$module: 'post-related';

.post-related {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 20px;
	background-color: #fff;

	.sub-section--reverse & {
		background-color: $gray-light;
	}

	&__head,
	&__img {
		text-align: center;
	}

	&__img-wrap {
		position: relative;
		overflow: hidden;
		flex-shrink: 0;
	}

	&__img {
		width: remUnit(134px);
		height: remUnit(109px);
		flex-shrink: 1;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		position: relative;
		transition: all 0.4s;

		&:before {
			content: "";
			display: none;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__head,
	&__taxonomy {
		color: $blue;
	}

	&__head {
		display: flex;
		flex-grow: 1;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: 0 remUnit(15px);
	}

	&__taxonomy,
	h2.#{$module}__title {
		font-weight: $font-weight-semibold;
	}

	&__taxonomy {
		font-size: remUnit(12.5px);
		line-height: lh(12.5px,14px);
		list-style: none;
		margin: 0 0 7px;
		padding: 0;
	}

  &__description {
    $lines-to-show: 4;
    $font-size: 14px;
    $line-height: lh($font-size, 21px);
    $use-max-height: true;
    font-size: $font-size;
    line-height: $line-height;
    @include text-overflow( $lines-to-show, $font-size, $line-height, $use-max-height);
    @media (max-width: 650px) {
      $lines-to-show: 2;
      $font-size: 13px;
      $line-height: lh($font-size, 19.5px);
      $use-max-height: true;
      font-size: $font-size;
      line-height: $line-height;
      @include text-overflow( $lines-to-show, $font-size, $line-height, $use-max-height);
    }
  }

  h4.#{$module}__title {
    @media (min-width: $screen-sm-min) {
      @include text-overflow( $lines-to-show: 1, $font-size: 20px, $line-height: lh(20px,22px) );
    }
  }

	h2.#{$module}__title {
		margin: 0;
		@include text-overflow( $lines-to-show: 3, $font-size: 16px, $line-height: lh(16px,17.5px) );
		@include ace-btn-hover-transition();
	}

	&--slide {
		display: block;
		margin: 0;

		h2.#{$module}__title {
			@include text-overflow( $lines-to-show: 3, $font-size: 18px, $line-height: lh(16px,17.5px) );
		}

		.#{$module}__head,
		.#{$module}__img {
			width: 100%;
			display: block;
			position: relative;
		}

		.#{$module}__img {
			height: 160px;
			display: block;
		}

		.#{$module}__head {
			left: 0;
			padding: 20px;
		}
	}

	&:active,
	&:focus,
	&:hover {
		text-decoration: none;
		outline: none;

		.#{$module}__title {
			color: $red;
			@include ace-btn-hover-transition();
		}
	}
	@media (min-width: $screen-sm-min) {
		width: 100%;
		float: none;
		overflow: hidden;
		margin-bottom: 40px;
		min-height: 320px;
		display: block;

		h2.#{$module}__title {
			@include text-overflow( $lines-to-show: 3, $font-size: 24px, $line-height: lh(24px,28px) );
		}

		&__taxonomy {
			font-size: remUnit(14px);
			line-height: lh(14px, 19px);
			list-style: none;
			margin: 0 0 5px;
			padding: 0;
		}

		&__head,
		&__img {
			width: 100%;
			display: block;
			position: relative;
		}

		&__img {
			height: 160px;
			display: block;
			padding: 0;
		}

		&__head {
			left: 0;
			padding: 20px;
		}
	}
}
