$module: 'post-preview';

.post-preview {
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid $gray-med;

	&:last-child { border-bottom: none; }

	&__body--small {
		max-width: 600px;
		margin-right: auto;
		margin-left: auto;
	}

	&__btn {
		@extend %ace-btn-floating--green;
	}

	&__head {
		margin-bottom: 15px;
	}

	&__img {
		margin-bottom: 20px;
		width: 100%;
		height: auto;
	}

	&__meta {
		font-size: remUnit(12.5px);
	}

	&__taxonomy {
		margin: 0 0 20px;
		padding: 0;
		list-style: none;
		line-height: 1;
	}

	&__taxonomy li {
		display: inline-block;
		margin-bottom: 4px;
	}

	&__taxonomy li a {
		@extend %ace-btn-tag--gray-light;
	}

	&__taxonomy--small {
		color: $blue;
		font-weight: $font-weight-semibold;
	}

	&__taxonomy--small {
		font-size: remUnit(14px);
		list-style: none;
		margin: 0 0 5px 0;
		padding: 0;
	}

	&__title {
		margin-top: 0;
		font-size: remUnit(28px);
		a {
			@include ace-btn-title-hover();
		}
	}

	&__title--small {
		max-width: 550px;
		margin-right: auto;
		margin-left: auto;
    line-height: 1.25;
	}

	@media (min-width: $screen-md-min) {
		margin-bottom: 40px;
		padding-bottom: 30px;

		&__title h2 {
			font-size: remUnit(32px);
		}

		&__meta {
			font-size: remUnit(14px);
		}
	}
}
