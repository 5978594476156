/*
* Base Button Style
*
* The default values for the .ace-btn class
*/
@mixin ace-btn ($size: null, $block:null, $hover-transition:null ) {
	// floating defaults to false
	@include ace-btn-base();
	@if $block {
		display: block;
		width: 100%;
	} @else {
		display: inline-block;
	}
	@include ace-btn-size($size);
	@if $hover-transition {
		@include ace-btn-hover-transition();
	}
}
