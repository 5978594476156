$module: tooltip-box;

.#{$module} {
  display: block;
  position: absolute;
  width: 90%;
  height: auto;
  margin: 0 auto;
  padding: 1.85rem 1.5rem;
  bottom: calc(100% + 20px);
  left: 0;
  right: 0;
  z-index: -20;
  opacity: 0;
  background-color: rgba(2, 38, 60, .95);
  transition: opacity .3s ease;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    right: auto;
    bottom: -20px;
    left: 2rem;
    border-style: solid;
    border-width: 20px 20px 0 20px;
    border-color: rgba(2, 38, 60, .95) transparent transparent transparent;
  }

  p, li {
    margin-bottom: .5rem;
    color: #fff;
    line-height: 1.6;
    font-size: .8rem;
    font-weight: 400;

    &:last-child {
        margin-bottom: 0;
    }
  }

  ol, ul {
    padding-left: 1rem;
  }

  &.tooltip-box--active {
    z-index: 1;
    opacity: 1;
  }

  &__trigger {

  }

  &__close {
    display: inline-block;
    position: absolute;
    padding: .5rem;
    top: -4px;
    right: 2px;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
  }
}
