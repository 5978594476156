$right-slot-padding: 2rem;
$light-green: lighten($green, 25%);
$field-padding: .75rem;
$label-heading-font-size: 0.8125rem;
$form-control-height: 52px;

//BASE ace-form STYLES

$module: ace-form;

.#{$module} {
    display: block;
    width: 100%;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    padding: .5rem 0;

    &--wide {
        max-width: 992px;
    }

    // Section Heading

    &__section-heading {
        margin: 0 0 1rem;
        padding-bottom: .25rem;
        border-bottom: 1px solid $gray;
        color: $gray;
        font-size: 1.25rem;

        @media (min-width: $screen-sm-min) {
            font-size: 1.5rem;
        }
    }

    // Section Sub-heading

    &__section-sub-heading {
        margin: 1rem 0 0;
        padding-top: .25rem;
        color: $gray;
        font-size: 1rem;

        @media (min-width: $screen-sm-min) {
            font-size: 1.25rem;
        }
    }

    // Group Heading

    &__group-heading {
        display: inline-block;
        margin-bottom: .15rem;
        font-size: $label-heading-font-size;
        line-height: 1.44444444;
        color: $green;
        font-weight: 400;
        min-height: 18px;
    }

    // Section Group

    &__section-group {
        margin-bottom: 1rem;
        padding: .5rem;
        box-shadow: 0 0 2px 1px #e6e6e6;
        border-radius: 3px;
        @extend .clearfix;

        @media (min-width: $screen-sm-min) {
            padding: 1rem;
        }

        // Server Validation Message Block

        &.#{$module}__section-group--server-validation-message {
            background-color: #e9c9ca;
            box-shadow: none;
            border: 1px solid #d8b8b9;

            &.#{$module}__section-group--server-validation-message--success {
                background-color: #49bfad; //#98dcad !important;
                h2 {
                    color: #fff!important;
                    border-bottom: 1px solid #fff;
                }

            }

            ul {
                margin-left: 0;
                padding-left: 24px;

                li {
                    font-size: 16px;
                }
            }

            p {
                font-size: 16px;
            }
        }

        &.#{$module}__section-group--related {
            display: none;
        }
    }

    // Labels

    label {
        &.checkbox-inline,
        &.radio-inline,
        &.checkbox,
        &.radio {
            color: $gray;
            font-size: 16px;
            font-weight: 300;
        }

        &.checkbox,
        &.radio {
            margin-bottom: 10px;

            &:first-of-type {
                margin-top: 0;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    // Form Elements

    // <editor-fold>
    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="search"],
    input[type="date"],
    input[type="tel"],
    input[type="number"],
    textarea,
    select,
    .ace-form__control--read-only-display {
        width: 100%;
        padding: $field-padding;
        font-size: 16px;
        box-shadow: none;
        border: 0;
        border-radius: 0;
        height: $form-control-height;
        transition: width .3s ease;

        @include placeholder(#ccc);

        &:focus {
            outline: none;
        }
    }

    select,
    input[type="date"] {
        appearance: none;
        height: $form-control-height;
        transition: width .3s ease;
    }

    select {
        position: relative;
        background-color: rgba(0,0,0,0);
        z-index: 1;
        padding-right: 2.5rem;
    }

    textarea {
        min-height: 150px;
        padding: $field-padding;
        border: 1px solid $light-green;
        resize: vertical;
        transition: width .3s ease;
    }
    // </editor-fold>

    // File Attachment Inputs

    .btn-file {
        @extend .ace-btn-primary--green;
        display: block;
        position: relative;
        margin-top: 0;
        overflow: hidden;
        opacity: 1;
        border: 1px solid $green;
        line-height: 1;
        color: #fff;
        white-space: normal;

        input[type=file] {
            position: absolute;
            width: 2px;
            height: 2px;
            left: -10px;
            z-index: -9999;
            filter: alpha(opacity=0);
            opacity: 0;
            outline: none;
            background: transparent;
            cursor: inherit;
            display: block;
        }

        &-name {
            display: block;
            min-height: 18px;
            margin-bottom: .15rem;
            font-style: italic;
            font-size: $label-heading-font-size;
            color: $green;
        }
    }

    // Form Groups

    &__group {
        display: block;
        position: relative;
        width: 100%;
        margin-bottom: .25rem;
        padding: .25rem 0 0;

        // Toggleable Hidden Related Fields

        &.#{$module}__group--related {
            display: none;
            top: -10px;
            margin-bottom: 0;
            padding: 0;
            background-color: #fff;
            z-index: 1;

            .#{$module}__group-heading {
                display: none !important;
            }

            .#{$module}__input-group {
                border-top: 1px dashed $light-green;
                padding: 0 !important;
            }
        }

        // INPUT TYPE CONTAINERS

        // .input-type-text
        // .input-type-email
        // .input-type-tel
        // .input-type-date
        // .input-type-select
        // .input-type-textarea
        // .input-type-single-check
        // .input-type-radio-inline
        // .input-type-check-inline
        // .input-type-radio-stacked
        // .input-type-check-stacked
        // .input-type-file


        &.input-type-file,
        &.input-type-textarea:not(.read-only),
        &.input-type-single-check {
            .ace-form__input-group {
                border: 0;
            }
        }

        &.input-type-radio-inline,
        &.input-type-check-inline,
        &.input-type-radio-stacked,
        &.input-type-check-stacked {
            &:not(.read-only) {
                .ace-form__input-group {
                    padding: .75rem;
                }
            }
        }

        &.input-type-radio-inline,
        &.input-type-check-inline:not(.input-type-single-check) {
            .ace-form__input-group {
                min-height: 54px;
            }
        }

        &.input-type-radio-stacked,
        &.input-type-check-stacked {
            .checkbox,
            .radio {
                padding-left: 20px;
            }
        }

        &.input-type-select {
            .#{$module}__input-group {
                &::after {
                    display: block;
                    content: url('/images/layout/2017/shared/angle-down-bold.svg');
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    width: 17px;
                    height: 19px;
                    z-index: 0;
                }
            }
        }

        &.input-type-image-preview {
            .ace-form__input-group {
                > *:not(.ace-form__group-tip) {
                    display: inline-block;
                    vertical-align: middle;
                    width: auto;
                }
                .img-preview-link {
                    width: 180px;

                    img {
                        width: 160px;
                    }
                }
                .btn-primary {
                    padding: 17px 20px;
                }
                .ace-form__control {
                    width: calc(100% - 339px);
                    white-space: nowrap;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        // RIGHT SLOT FOR CERTAIN INPUTS

        &.right-slot {

            // Text, Email, Tel, Date, etc

            input[type="text"],
            input[type="email"],
            input[type="url"],
            input[type="password"],
            input[type="search"],
            input[type="date"],
            input[type="tel"],
            input[type="number"] {
                width: calc(100% - #{$right-slot-padding});
            }

            // Select, Textarea and File
            &.input-type-select,
            &.input-type-textarea,
            &.input-type-file {
                .#{$module}__input-group {
                    transition: width .3s ease;
                    width: calc(100% - #{$right-slot-padding});
                }
                .#{$module}__group-tip {
                    right: -2rem;
                }
            }
        }

        // Error State

        &.input-group-error {

            // Icons Inside Element Border
            input[type="text"],
            input[type="email"],
            input[type="url"],
            input[type="password"],
            input[type="search"],
            input[type="date"],
            input[type="tel"],
            input[type="number"],
            .ace-form__control--read-only-display {
                width: calc(100% - #{$right-slot-padding});
                transition: width .3s ease;
            }

            input[type="date"] {
                padding-right: 0;
            }

            // Icons Outside Element Border

            &.input-type-select,
            &.input-type-textarea,
            &.input-type-file {
                .#{$module}__input-group {
                    transition: width .3s ease;
                    width: calc(100% - #{$right-slot-padding});

                    .#{$module}__group-tip {
                        right: -$right-slot-padding;
                    }
                }
            }

            // Terms Agreement Checkmark

            &.input-type-single-check .ace-form__input-group {
                border: 1px solid $red;
            }

            // Headings and Field Text Error State

            .#{$module}__group-heading,
            .#{$module}__input-group,
            select,
            textarea {
                color: $red;
                border-color: $red;
            }

            .btn-file {
                background-color: $red;
                border-color: $red;
            }

            .btn-file-name {
                color: $red;
            }

            option {
                color: $gray;
            }
        }

        // Side By Side 50% Form Groups

        &--pair {
            @extend .clearfix;

            .#{$module}__group {
                @media (min-width: $screen-sm-min) {
                    display: block;
                    float: left;
                    width: 50%;
                    z-index: 1;
                }
            }
        }

        &--auto-height {
            &.input-type-check-inline:not(.input-type-single-check),
            &.input-type-radio-inline {
                .#{$module}__input-group {
                    height: auto;
                }
            }
        }
    }

    // Input Groups

    &__input-group {
        display: block;
        position: relative;
        width: 100%;
        border-collapse: separate;
        border: 1px solid $light-green;
        transition: width .3s ease;
    }

    // Form Controls

    &__control {
        &--read-only-display {
            background: #e6e6e6;
            margin: 0;
        }
    }

    // Group Tips

    &__group-tip {
        display: block;
        position: absolute;
        width: auto;
        padding: 0;
        line-height: 1;
        text-align: center;
        border: 0;
        white-space: nowrap;
        vertical-align: middle;
        top: 0;
        right: 0;

        // Group Tip Icon

        &-icon {
            display: inline-block;
            position: relative;
            cursor: pointer;
            vertical-align: middle;
            z-index: 1;

            .icon-wrap {
                display: block;
            }

            .icon {
                height: 1.5rem;
                width: $right-slot-padding;
                padding-top: .15rem;
                padding-bottom: .15rem;
            }
        }

        // Group Tip Text

        &-text {
            display: none;
            position: absolute;
            width: 200px;
            height: auto;
            padding: .5rem;
            top: 23px;
            right: 0;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 3px;
            box-shadow: 1px 1px 2px 1px rgba(0,0,0,.15);
            font-weight: 300;
            line-height: 1.2;
            color: $gray;
            text-align: left;
            white-space: normal;
            font-size: .8rem;
            z-index: 2;
        }

        // Error Tip

        &--error {
            display: none;
            top: 0;
            color: $red;

            .#{$module}__group-tip-text {
                color: $red;
            }

            .input-group-error & {
                display: block;
            }
        }

        // Error beneath standard tip icon

        ~ .#{$module}__group-tip--error {
            top: 1.5rem;
        }
    }

    // EXTRAS
    .word-count-wrapper {
        text-align: right;
        font-size: 1rem;
        color: #999;
    }

    &__terms-wrap {
        overflow: auto;
        overflow-y: scroll;
        height: 200px;
        border: solid 1px #eee;
        text-align: left;
        padding: 10px 20px 10px 0px;
        margin : 15px 0px 10px;
    }

    //************//
    //  VARIANTS  //
    //************//

    // Specialist Program Pricing Table
    &.pricing-table-detail__form {
        .#{$module}__group {
            .#{$module}__input-group {
                background-color: #fff;
            }

            select {
                padding: 0.5rem 0.25rem;
            }

            &.input-group-error {
                .#{$module}__input-group {
                    border-color: $magenta;
                    width: 100%;

                    select {
                        color: $magenta;
                        width: 100%;
                        border-right: 0;
                    }

                    .#{$module}__group-tip {
                        height: 100%;

                        &-icon {
                            cursor: default;
                            top: -23px;
                            right: 25px;
                            color: $magenta;
                        }

                        &-text {
                            display: block !important;
                            width: auto;
                            padding: .25rem .75rem;
                            top: auto;
                            bottom: -23px;
                            right: 31px;
                            background-color: $magenta;
                            box-shadow: none;
                            color: #fff;
                            white-space: nowrap;
                            border-radius: 0;
                            border: 0;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }


    // QQ Upload Field //
    .input-type-file-azure {
        .qq-uploader {
            padding: 2rem;
        }

        .qq-upload-button-selector {
            display: block;
            width: auto;
            margin-bottom: auto;
            padding: 0;
            float: none;
            background: none;
            color: inherit;
            border-radius: 0;
            border: 0;
            box-shadow: none;
        }

        .qq-upload-list {
            box-shadow: none;

            li {
                border: 0;
                font-size: 1rem;
                padding: .75rem;

                &.qq-upload-success {
                    background-color: #fff;
                    border: 1px dotted $green;
                }

                &.qq-upload-fail {
                    background-color: #f8e8e9;
                    border: 1px dotted $red;
                }
            }

            .qq-upload-file {
                width: 100%;
                max-width: 300px;
                color: $green;
                font-style: italic;
                font-weight: 300;
                font-size: .9rem;
            }

            .qq-btn {
                margin-right: 2px;
                padding: .5rem;
                box-shadow: none;
                border: 0;
                letter-spacing: 1px;
                line-height: 1.14286;
                font-size: .875rem;
                font-weight: 500;
                font-family: Roboto;
                text-transform: uppercase;
                text-shadow: none;
                color: #fff;

                &.qq-upload-delete-selector {
                    background-color: $red;
                }

                &.qq-upload-cancel-selector {
                    background-color: $red;
                }

                &.qq-upload-retry-selector {
                    background-color: $green;
                }
            }

            .qq-edit-filename-selector {
                padding: 0;
                height: auto;
            }

            .qq-upload-status-text {
                line-height: 1.6;
            }
        }

        .qq-alert-dialog-selector {
            border: 1px solid #000;
        }
    }


    // MCE Editor //

    .input-type-html-editor {
        &.input-group-error {
            .#{$module}__input-group {
                border: 1px solid #e53d46;
            }
            .mce-container {
                border: 0 !important;
                box-shadow: none;
            }
        }
    }

    // ACE Answers

    &#formAceAnswers {
        max-width: 900px;
        position: relative;

        .#{$module}__section-heading {
            display: none;
        }
        .#{$module}__section-group {
            box-shadow: none;
            position: relative;

            .ace-form__group:not(.input-type-textarea) {
                @media (min-width: $screen-sm-min) {
                    width: 48%;
                }
            }
            .ace-form__group.input-type-textarea {
                @media (min-width: $screen-sm-min) {
                    position: absolute;
                    width: 48%;
                    top: 15px;
                    right: 0;
                    height: 100%;

                    textarea {
                        height: 230px;
                    }
                }
            }
        }
        .ace-btn-primary.ace-btn-primary--red.size-w-100 {
            display: block;
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
            border: 1px solid $green;
            background-color: transparent;
            text-align: center;
            color: $green;


            @media (min-width: $screen-sm-min) {
                top: 318px;
                left: 52%;
                position: absolute;
                width: 200px !important;
            }

            svg {
                fill: $green;
            }

            &:hover {
                background-color: $green;
                color: #fff;

                svg {
                    fill: #fff;
                }
            }
        }

    }
} // end .ace-form
